import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Cascader, Select, Radio, Row, Col, Space } from 'antd';
import konsole from '../../network/konsole';
import { GlobalData } from './SeminarFile';
import commonLib from '../../control/commonLib';
const SeminarFare = ({ form }) => {
  const { seminarFareField, setSeminarFareField, editFuncData, setStopFareEditFunc, stopFareEditFunc } = useContext(GlobalData);
  // const {form} = form.useForm();

  const [showPriceInput, setShowPriceInput] = useState(false)
  const [refresh, setRefresh] = useState(false)

  konsole.log("getPriceValue", form.getFieldsValue())

  // useEffect(()=>{

  //   if(editFuncData !== undefined && editFuncData !== null && editFuncData !=="" && stopFareEditFunc !== true){
  //     let PaidFreeDAta = editFuncData?.isSeminarPaid ? "Paid" : "Free"
  //     fixEditFuncData(PaidFreeDAta)
  //   }


  // },[editFuncData])


  // const fixEditFuncData = (e) =>{

  //   konsole.log("ShowPriceTextArea",e)
  //   form.setFieldsValue({
  //     radioButton: e
  //   })
  //   setRefresh(!refresh);
  // }


  const ShowPriceTextArea = (e) => {
    if (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") {
      setStopFareEditFunc(true)
    }
    konsole.log("ShowPriceTextArea", e)
    form.setFieldsValue({
      radioButton: e.target.value
    })
    setRefresh(!refresh);
  }

  useEffect(() => {
    if (seminarFareField == "1") {

      form.setFieldsValue({
        "SeminarFarePrices": ""
      })
      setSeminarFareField(2)
    }
  }, [seminarFareField])


  konsole.log("editFuncDataSeminarFare", editFuncData?.isSeminarPaid)

  return (
    <div>
      <div className="seminarcard bg-white ms-1 border-bottom-0 border-end-0 border-top-0" style={{ border: "none" }}>
        <p className='fs-4 fw-bold seminartxt ms-2'>Seminar Fare</p>
        <Space direction="vertical" >
          <Form.Item
            name="radioButton"
            rules={[
              {
                required: true,
                message: "Please select seminar type!"
              }
            ]}
          >
            <Radio.Group name="Prices" onChange={ShowPriceTextArea} >
              <Radio value="Free" className=" gap-2  " style={{ fontSize: "20px" }}>Free</Radio>
              <Radio value="Paid" className=" gap-2" style={{ fontSize: "20px" }} >Paid</Radio>
            </Radio.Group>
          </Form.Item>
        </Space>

        {
          (form.getFieldsValue().radioButton == "Paid") ?


            (<Form.Item
              name="SeminarFarePrices"
              label="price(if paid)"
              className='mt-2'
              rules={[
                // {
                //   pattern: /^[0-9]+$/,
                //   message: 'Please Enter Number Only!',
                // },
                {
                  required: true,
                  message: "Please enter price!"
                }
              ]}
            >
              <Input prefix="$" suffix="/person" />
            </Form.Item>) : ""
        }
      </div>
    </div>
  )
}

export default SeminarFare
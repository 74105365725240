import React, { useEffect, useState } from 'react'

import { Form,Select,Input,Row, Col,Switch,Alert} from "antd";
import Services from '../../network/Services';
import konsole from '../../network/konsole'
import "./Seminar.css"

const ListSeminar = ({ setPublishSeminar, publishseminar, setPastSeminar,setSearchSeminar, changeBorderToTopButtons, setChangeBorderToTopButtons,setfilteredSubtinentId}) => {
  const [btnborder, setbtnborder] = useState(1)
  const[checkclick,setcheckclick]=useState(false)
  const[togglecheckbox,settogglecheckbox]=useState()
  const [allSubtinents, setallSubtinents] = useState()
  let userroleId = JSON.parse(sessionStorage.getItem("stateObj")).roleId;
  let subtenantId = sessionStorage.getItem("subtenantId")
  let userLoggedInDetail = JSON.parse(sessionStorage.getItem("userLoggedInDetail"))
  const { Option } = Select;


  useEffect(()=>{

    if(changeBorderToTopButtons == 1){
      setbtnborder(1)
      setChangeBorderToTopButtons(0)
    }
  },[changeBorderToTopButtons])
useEffect(() => {
  Services.postSubtenantDetails().then((res)=>{
    setallSubtinents(res.data.data)
  })
}, [])


  const pubseminar = () => {
    setPublishSeminar(true)
    setbtnborder(1)

  }

  const pubishseminar = () => {
    setPastSeminar('Present')
    setPublishSeminar(false)
    setbtnborder(2)
    setcheckclick(false)
    settogglecheckbox(true)
    document.getElementById('publishSeminar').style.border = '2px solid #720c20'

  }

  const checkbox = (e) => {
    if (e.target.checked == true) {
      setPastSeminar("Past")
      setPublishSeminar(false)
      setbtnborder(3)
      setcheckclick(true)
    } else {
      btnborder(2)
      setPastSeminar('Present')
      setPublishSeminar(false)
      setcheckclick(false)
    }


  }

  const togglecheckboxchange=(e)=>{
    settogglecheckbox(e)
    if(e==true){
      setPastSeminar('Present')
    }else{
      setPastSeminar('past')
    }

  }
  const onChangeData = (value, key) => {
    setfilteredSubtinentId(key.value)
    
  };
 
  return (
  
    <div className='pt-2'style={{borderBottom:"2.5px solid rgb(242, 242, 242)"}}>
      <button className={`${(btnborder == 1) ? "schedule-active" : ""} me-2 schedule`} onClick={pubseminar}>Publish Seminar</button>
      {/* <button className='publish fw-bold text-dark p-0 m-0' id='publishSeminar' onClick={pubseminar}>Publish Seminar1</button> */}
      {/* <button className='publish  fw-bold text-dark ' id='PublishedSeminar' onClick={pubishseminar}>Published Seminar</button> */}
      <button className={`${(btnborder == 2) ? "schedule-active" : ""} me-2 schedule`} onClick={pubishseminar}>Published Seminar</button>
      
      {
        (publishseminar==false) ? <>
        <div class="SetPass ms-2 float-end">
          <span className={`${(togglecheckbox === false) ? 'spantogglecolorinlestseminar fw-bold' : ''} m-2`}>Past</span>
        <Switch 
        className={`${(togglecheckbox === true) ? 'togglecolorinlestseminar' : ''} m-2`}
        // checkedChildren="on" unCheckedChildren="off" 
         defaultChecked={togglecheckbox} onChange={togglecheckboxchange}   />
         <span className={`${(togglecheckbox === true) ? 'spantogglecolorinlestseminar fw-bold' : ''} m-2`}>Upcoming</span>
      </div>
      <Row span={24} className="mt-2" >
        <Col span={24}>
          <div style={{ display: "flex",paddingLeft:"5px"}}>
            <Col span={10} style={{}}>
              <Input.Search   placeholder="Search for Seminar" onChange={(e)=>setSearchSeminar(e.target.value)} style={{width: "100%", border: "1px solid #d9d9d9", borderRadius: "4px"}}/>
            </Col>

            <Col span={6}>
              {userroleId == 4 && 
            <Form.Item
                name="seminarOrganizingPartner"
                className='ms-4'
              >
                <Select
                  onChange={onChangeData}
                  allowClear
                  style={{  }}
                  placeholder="Select your Subtenant"
                  defaultValue={userLoggedInDetail?.subtenantName}
                  value={subtenantId}
                >
                  {
                    allSubtinents?.map((items, index) => {

                      return <Option value={items.subtenantId} key={items.value}>
                        {items.subtenantName}
                        </Option>
                    })
                  }

                </Select>
              </Form.Item>}
            </Col>
          </div>
        </Col>
      </Row>
        </> :""
      }

      
    </div>


  )
}

export default ListSeminar
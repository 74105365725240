import React, { useMemo } from "react";
import { useState,useContext} from "react";
import { format, compareAsc } from 'date-fns'
import Table from 'react-bootstrap/Table';
import { DatePicker, Space, message } from 'antd';
import Services from "../../network/Services";
import moment from "moment";
// import { Col, Row, Modal, Divider, Input, Select, Space, Button, Form, Radio } from 'antd';
import {
    Button,
    Modal,
    Container,
    Row,
    Col,
    Form,
    Card,

    Accordion,
} from "react-bootstrap";
import "./Manage.css";
import konsole from "../../network/konsole";
import { useEffect } from "react";
import commonLib from "../../control/commonLib";
import { formatDate, isNullUndefine } from "../../control/Constant";
import { completeEventMessage } from "../../control/Constant";
import { capitalizeFirstLetter,isNumberRegex } from "../../control/Constant";
import CurrencyInput from "react-currency-input-field";
import { UserContext } from "../../App";
import Tooltip from "../Tooltip";
// import CurrencyInput from "react-currency-input-field";
// import { createSubtenantRateCardObj } from "../../control/Constant";

const createRateCardObj = () =>{
    // packageType: rateCardOf == "Product" ? "IND" : "",
    return {rateCardId: 0,rateCardName: '',skuListId: 0,skuListName :'',slabStartingNo: 0,slabEndingNo: 0,minPriceSku: 0,minPriceSkuPercent: "",currency: "USD",isActive: true,currencyTypeId: 1,serviceId: 0,groupId: 0,packageType: ''}
}

const createApplicableSubsModelObj = () =>{
    return { skuSubsModelId: 0,appRateCardId: 0,subsModelId: 3,endSubscriptionAmt: 0,appCurrency: 'USD',isDisAllowed: true,appIsActive: true,appCurrencyTypeId: 1,appUpsertedBy: ''}
}

const createSubtenantRateCardObj = () =>{
    return {subtenantRateCardId: 0,subtenantId: 0,subtenantName: '',subSkuListId: 0,subRateCardId: 0,subSkuSubsModelId: 0,validityPeriod: '',validityPeriodDuration: '',validityFromDate: '',validityToDate: '',marginAmt: 0,marginPercent: 0,subIsActive: null,subIsDisAllowed: null,}
}

function AddRateCardPartner(props) {
    const loginUserId = commonLib.getSessionStoarge("loggedUserId");
    const [validated, setValidated] = useState(false);
    const [loginUserID, setloginUserID] = useState("");
    const [rateCardData, setRateCardData] = useState({...createRateCardObj(),upsertedBy : loginUserId})
    const [productsList, setProductsList] = useState([])
    const [packagesList, setPackagesList] = useState([]) 
    const [sameProductValidityToAll,setSameProductValidityToAll] = useState(null)
    const [sameValidityFromDateToAll,setSameValidityFromDateToAll] = useState(null)
    const [sameEndUserPrice,setSameEndUserPrice] = useState(null)
    const [showTable, setShowTable] = useState(null);
    const [ updateRadio, setUpdateRadio ] = useState("")
    const [discountForAll, setDiscountForAll] = useState(null)
    const [subtenantRateCardData,setSubtenantRateCardData] = useState([])
    const [validityFromOnUpdate, setValidityFromOnUpdate] = useState("")
    const [productValidityOnUpdate, setProductValidityOnUpdate] = useState("")
    const [endSubscPriceOnUpdate, setEndSubscPriceOnUpdate] = useState("")
    const [productGrpsList, setProductGrpsList] = useState([]);
    const [render, setRender] = useState(true)
    const [sameProductValidityError, setSameProductValidityError] = useState(null)
    const [sameValidityFromDateError, setSameValidityFromDateError] = useState(null)
    const [productValidityArr, setProductValidityArr] = useState(null)
    const [sameEndUserPriceError, setSameEndUserPriceError] = useState(null)
    const [addOrUpdateRateCardFor, setAddOrUpdateRateCardFor] = useState('Product')
    const [disableSaveBtn, setDisableSaveBtn] = useState(false) 

    const { setLoader } = useContext(UserContext);
    const subtenantID = sessionStorage.getItem("subtenantId")
    const productTypeArray = [{value : 1, label : "Product"},{value : 2, label : "Product group"},{value : 2, label : "Package"}]
    const alreadyCreatedRateCardsList = props?.rateCardsList;
    const rateCardListToUpdate = props.updateRateCard;
    const applicableSubsModel = rateCardListToUpdate?.applicableSubsModel;
    const subtenantRateCardList = applicableSubsModel?.length > 1 ? applicableSubsModel.flatMap(item =>item.subtenantRateCard.map(det => ({...det, endSubscriptionAmt: item.endSubscriptionAmt, subsModelId : item.subsModelId}))) : applicableSubsModel?.length == 1 ? applicableSubsModel[0].subtenantRateCard : [];   //  ? different applicables because of different endSubscriptionAmnt : When applicable is same but subtenants are different
    const numberOfSelectedFirms = subtenantRateCardData?.filter(item => item.subIsActive == true)


    konsole.log("rateCardData",applicableSubsModel,subtenantRateCardList,rateCardData,subtenantRateCardData,rateCardListToUpdate,alreadyCreatedRateCardsList,updateRadio)

    useEffect(() => {
        setloginUserID(loginUserId);
        services();
        productsValidityList();
        getGroupedService();
        SkuList();
        // setLocalSubtenantsList(props.subtenantsList)
        setRateCardDataInObj()
        setSubtenantListDataWithObject()
    }, [props.subtenantsList,props.updateRateCard]);

    useEffect(()=>{
        retieveValueForAllPartnerQuesRadio()
    },[rateCardListToUpdate])


    const setRateCardDataInObj = (skuServiceId,setIdFor) => {
        if (rateCardListToUpdate) {
            setRateCardData((prev) => {
                const { applicableSubsModel , partnerCount, remarks,subscriptionCategoryId,stCeilingPrice, ...rest } = rateCardListToUpdate;
                return {
                    ...createRateCardObj(),
                    ...rest,
                    ...((skuServiceId && setIdFor == "service") ? { serviceId: skuServiceId } : (skuServiceId && setIdFor == "group") ? { groupId: skuServiceId } : {}),
                    upsertedBy : loginUserId
                };
            });
        }
    };
    

    const setSubtenantListDataWithObject=()=>{
        let newArray=[]
        if (subtenantRateCardList.length > 0) {
            const newArray = props.subtenantsList.map((subtenant) => {
                const matchingRateCard = subtenantRateCardList.find(
                    (rateCard) => rateCard.subtenantId === subtenant.subtenantId
                );
                return {
                    ...createSubtenantRateCardObj(),
                    ...(matchingRateCard ? {
                              ...matchingRateCard,
                              validityFromDate: formatDate(matchingRateCard?.validityFromDate),
                              validityToDate: formatDate(matchingRateCard?.validityToDate),
                              endSubscriptionAmt : applicableSubsModel.length == 1 ? applicableSubsModel[0].endSubscriptionAmt : matchingRateCard?.endSubscriptionAmt,
                              subsModelId : applicableSubsModel?.length == 1 ? applicableSubsModel[0]?.subsModelId : matchingRateCard?.subsModelId,
                          } : {}),
                        subtenantId: subtenant.subtenantId,
                        subtenantName: subtenant.subtenantName
                };
            });
            konsole.log("newArray12121",newArray)
            setSubtenantRateCardData(newArray);
        }        
        else{
            for(let i=0; i < props.subtenantsList.length; i++){
                const newArr={...createSubtenantRateCardObj(),subtenantId:props.subtenantsList[i].subtenantId,subtenantName:props.subtenantsList[i].subtenantName}
                newArray.push(newArr)
            }
            setSubtenantRateCardData(newArray)
        }
        konsole.log("subtenantRateCardList",subtenantRateCardList,newArray)
    }

    const retieveValueForAllPartnerQuesRadio = () => {
        const valueOfValidityFromDate = subtenantRateCardList?.length > 0 ? subtenantRateCardList[0]?.validityFromDate : '';
        // const valueOfProductValidity = subtenantRateCardList?.length > 0 ? subtenantRateCardList[0].subsModelId : '';
        
        if(subtenantRateCardList.length > 0){
            const checkForDiscount = subtenantRateCardList.every((item) => item?.subIsDisAllowed === true);
            const areAllValidityFromDateSame = subtenantRateCardList.every(item => item.validityFromDate === valueOfValidityFromDate);
            // const areAllProductValiditySame = subtenantRateCardList.every(item => item.subsModelId == valueOfProductValidity);
            const areAllSubtSelectedWhileUpdate = subtenantRateCardData.every(item => item.subIsActive == true);

            const validityFrom = areAllValidityFromDateSame == true ? formatDate(valueOfValidityFromDate) : '';
            // const ProductValidity = areAllProductValiditySame == true ? valueOfProductValidity : '';
            const endSubscPrice = applicableSubsModel?.length == 1 ? applicableSubsModel[0]?.endSubscriptionAmt : '';
            const ifProductValidityIsSame = applicableSubsModel?.length == 1 ? applicableSubsModel[0]?.subsModelId : '';
            
            konsole.log("subtenantRateCardList12121",subtenantRateCardList,subtenantRateCardData,areAllSubtSelectedWhileUpdate,areAllValidityFromDateSame,checkForDiscount,validityFrom,applicableSubsModel,endSubscPrice)
            
            if(areAllSubtSelectedWhileUpdate == true){
                setUpdateRadio("allSubtenant")
            }
            else if(areAllSubtSelectedWhileUpdate == false){
                setUpdateRadio("SpecificSubtenant")
            }
            if (checkForDiscount != null) {
                setDiscountForAll(checkForDiscount);
            }
            konsole.log("areAllValidityFromDateSame11",areAllValidityFromDateSame)
            if(areAllValidityFromDateSame != null){
                setSameValidityFromDateToAll(areAllValidityFromDateSame)
                setShowTable(!areAllValidityFromDateSame)
                setValidityFromOnUpdate(validityFrom)
            }
            // if(areAllProductValiditySame != null){
            //     setSameProductValidityToAll(areAllProductValiditySame)
            //     setShowTable(!areAllValidityFromDateSame)
            //     // setProductValidityOnUpdate(ProductValidity)
            // }
            if(applicableSubsModel?.length == 1){
                setShowTable(true)
                setSameEndUserPrice(true)
                setEndSubscPriceOnUpdate(endSubscPrice)
                setProductValidityOnUpdate(ifProductValidityIsSame)
                setSameProductValidityToAll(true)
                // setRender(!render)
            }
            else if(applicableSubsModel?.length > 1){
                setSameEndUserPrice(false)
                setEndSubscPriceOnUpdate("")
                setProductValidityOnUpdate("")
                setSameProductValidityToAll(false)
                setShowTable(true)
            }

        }
    };
    

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };

    const closeModal = () => {
        props.setAddShowModal(false);
        setAddOrUpdateRateCardFor("Product")
    };

    const validitiesValidationFunc = () => {
        // const checkRateCardIsAssignedToPartOrNot = subtenantRateCardData.some(item => item?.subIsActive == true)
        let isValid = true;
    
        
        if(discountForAll == null && sameProductValidityToAll == null && sameValidityFromDateToAll == null && sameEndUserPrice == null){
            isValid = false;
            completeEventMessage("error","Please Add Rate Card Details")
        }
        else{
            for (let item of subtenantRateCardData) {
                
                if (item.subIsActive == true) {
                    konsole.log("ssssss", item,isNullUndefine(item.subIsDisAllowed),item.subIsDisAllowed);
                    if (!(item.subIsDisAllowed !== undefined && item.subIsDisAllowed !== null && item.subIsDisAllowed !== "")) {
                        item.errorSubIsDisAllowed = "Please Enter Value";
                        isValid = false;
                        setRender(!render)
                    }
        
                    if (isNullUndefine(item.endSubscriptionAmt)) {
                        item.errorEndSubscriptionAmt = "Please Enter Value";
                        if(sameEndUserPrice == true){
                            setSameEndUserPriceError("Please Enter Value")
                        }
                        isValid = false;
                        setRender(!render)
                    }
        
                    if (isNullUndefine(item.subsModelId)) {
                        item.errorProductValidity = "Please Enter Value";
                        if(sameProductValidityToAll == true){
                            setSameProductValidityError("Please Enter Value")
                        }
                        isValid = false;
                        setRender(!render)
                    }
        
                    if (isNullUndefine(item?.validityFromDate)) {
                        item.errorValidityFromDate = "Please Enter Value";
                        if(sameValidityFromDateToAll == true){
                            setSameValidityFromDateError("Please Enter Value")
                        }
                        isValid = false;
                        setRender(!render)
                    }
        
                    if (isNullUndefine(item.validityToDate)) {
                        setRender(!render)
                        item.errorValidityToDate = "Please Enter Value";
                        isValid = false;
                    }
                }
            }
        }
    
        return isValid;
    };
      
    const validateRCTypeAndName = () => {
        konsole.log("addOrUpdateRateCardFor145",addOrUpdateRateCardFor,addOrUpdateRateCardFor,addOrUpdateRateCardFor)
        const errorForProductName = addOrUpdateRateCardFor === "Product" && rateCardData.serviceId === 0;
        const errorForProductGroupName = addOrUpdateRateCardFor === "Product group" && rateCardData.groupId === 0;
        const errorForPackage = addOrUpdateRateCardFor === "Package" && rateCardData.skuListId === 0;
    
        if (errorForProductName) {
            completeEventMessage("error", "Please select product name");
            return false; // Return false if validation fails
        }
    
        if (errorForProductGroupName) {
            completeEventMessage("error", "Please select product group name");
            return false; // Return false if validation fails
        }
    
        if (errorForPackage) {
            completeEventMessage("error", "Please select package name");
            return false; // Return false if validation fails
        }
    
        if (rateCardData.rateCardName === "") {
            completeEventMessage("error", "Please enter rate card name");
            return false; // Return false if validation fails
        }
        return true; // Return true if all validations pass
    }
    
        const addRateCard = async(e) => {
            e.preventDefault()
            setDisableSaveBtn(true)
                const checkRateCardIsAssignedToPartOrNot = subtenantRateCardData.some(item => item?.subIsActive == true)
                let isRateCardNameAvailable;
                if(validateRCTypeAndName()){
                    isRateCardNameAvailable = await Services.isRateCardNameAvaiFunc(rateCardData?.rateCardName)
                    if((rateCardData.serviceId != 0 || rateCardData.groupId != 0 || rateCardData.skuListId != 0) && (rateCardData.rateCardName != "") && (isRateCardNameAvailable?.data == 0 || (rateCardListToUpdate && isRateCardNameAvailable?.data == 1))){
                        if(rateCardData.serviceId != 0){
                         rateCardData.packageType = "IND"
                        }
                        else if(rateCardData.groupId != 0){
                         rateCardData.packageType = "GRP"
                        }
    
                        const rateCardJson = [rateCardData];
                        if(updateRadio != "" && checkRateCardIsAssignedToPartOrNot == true){
                            if(validitiesValidationFunc()){
                                rateCardApi(rateCardJson)
                            }
                        }
                        else{
                            const promise = Services.postToAddRateCard(rateCardJson);
                            promise.then((res) => {
                               konsole.log("res",res)
                               completeEventMessage("success",`${(rateCardListToUpdate != undefined && rateCardListToUpdate != null && rateCardListToUpdate != "") ? "Rate card updated successfully" : "Rate card added successfully"}`)
                             props.rateCards("","",subtenantID)
                             closeModal()
                            }).catch((err) => {
                                    konsole.log("error", err);
                                });
                        }
                    }
                    else{
                        const rateCardNameExists = isNullUndefine(rateCardListToUpdate) && isRateCardNameAvailable?.data > 0;
                        const rateCardNameExistsForUpdate = rateCardListToUpdate && isRateCardNameAvailable?.data > 1;
                    
                        if (rateCardNameExists || rateCardNameExistsForUpdate) {
                            completeEventMessage("error", "Rate card name already exists");
                        }
                    }
                }
                setDisableSaveBtn(false)
    };

    const rateCardApi = (rateCardJson) =>{
        setLoader(true)
        const promise = Services.postToAddRateCard(rateCardJson);
        promise.then((res) => {
            setLoader(false)
                rateCardForSubtenant(res.data.data[0])
            }).catch((err) => {
                konsole.log("error", err);
            });
    }

    const appSubtForRateCard = (addApplSubtForRateCrdJson) => {
        const promise = Services.postAppSubtForRateCard(addApplSubtForRateCrdJson);
        promise
            .then((res) => {
                setLoader(false)
                konsole.log("res12", res);
                completeEventMessage("success",`${(rateCardListToUpdate != undefined && rateCardListToUpdate != null && rateCardListToUpdate != "") ? "Rate card updated successfully" : "Rate card added successfully"}`)
                props.rateCards("","",subtenantID)
                closeModal()
            })
            .catch((err) => {
                konsole.log("error12", err);
            });
    };
    // const addRateCard = () =>{
    //    form.resetFields()
    // }

    // ----------------------------------- New Rate Card Design -----------------------------------------------------------
    const updateRateRadioBtn =(el)=>{
        if(el == "allSubtenant"){
            setSubtenantRateCardData(subtenantRateCardData.map((subtenant) => ({ ...subtenant, subIsActive: true })));  
         }
        else{
            setSubtenantRateCardData(subtenantRateCardData.map((subtenant) => ({ ...subtenant, subIsActive: false })));
        }
        setUpdateRadio(el)
    }

    const selectAllRadioVal = useMemo(()=>{
        const allSelected = subtenantRateCardData.every((item)=>item.subIsActive==true)
        const allNotSelected = subtenantRateCardData.every((item)=>item.subIsActive !==true );
        return allNotSelected ? 'null': allSelected;
    },[subtenantRateCardData])


console.log("selectAllRadioValselectAllRadioVal",selectAllRadioVal)

    const selectForAllOrNot = (selectedRadio,e) => {
        konsole.log("selectedRadio", selectedRadio);
    
        if (selectedRadio === "giveDiscountToAll" || selectedRadio === "notGiveDiscountToAll") {
            setDiscountForAll(selectedRadio === "giveDiscountToAll");
            setSubtenantRateCardData((prev) =>
            prev.map((det) => {
                if (det.subIsActive === true) {
                    return {
                        ...det,
                        ["subIsDisAllowed"]: selectedRadio === "giveDiscountToAll" ? true : false,
                        ["errorSubIsDisAllowed"] : ""
                    };
                }
                return det;
            }));
        }
    
        if (
            selectedRadio === "giveSameProductValidityToAll" ||
            selectedRadio === "notGiveSameProductValidityToAll"
        ) {
            setSameProductValidityToAll(selectedRadio === "giveSameProductValidityToAll");
        }
    
        if (
            selectedRadio === "giveSameValidityFromDateToAll" ||
            selectedRadio === "notGiveSameValidityFromDateToAll"
        ) {
            setSameValidityFromDateToAll(selectedRadio === "giveSameValidityFromDateToAll");
        }
    
        if (
            selectedRadio === "giveSameEndUserPriceToAll" ||
            selectedRadio === "notGiveSameEndUserPriceToAll"
        ) {
            setSameEndUserPrice(selectedRadio === "giveSameEndUserPriceToAll");
        }
        if(selectedRadio === "notGiveDiscountToAll" || selectedRadio === "notGiveSameProductValidityToAll" || selectedRadio === "notGiveSameValidityFromDateToAll" || selectedRadio === "notGiveSameEndUserPriceToAll"){
            setShowTable(true)
        }
        setRender(!render)
    };
    
    const productsValidityList = () => {
        // setLoader(true);
        const promises = Services.getSubsModel();
        promises
          .then((res) => {
            // setLoader(false);
            konsole.log("resresresServRate", res.data.data);
            setProductValidityArr(res.data.data)
          })
          .catch((err) => {
            konsole.log("errr", err);
          });
      };

    const services = () => {
        // setLoader(true);
        const promises = Services.indServices(true);
        promises
          .then((res) => {
            // setLoader(false);
            konsole.log("resresresServRate", res.data.data);
            setProductsList(res.data.data)
          })
          .catch((err) => {
            konsole.log("errr", err);
          });
      };

      const getGroupedService = () => {
        const promise = Services.groupedService(true);
        promise
          .then((res) => {
            konsole.log("grpRes", res.data.data);
            setProductGrpsList(res.data.data);
    
            // setServiceGrpName(res.data.data)
          })
          .catch((err) => {
            konsole.log("error", err);
          });
      };

      const SkuList = () => {
        // setLoader(true);
        const promises = Services.getSkuList(null,true);
        promises
          .then((res) => {
            // setLoader(false);
            const findServiceIdUsingSku = res.data.data.find(item => item.skuListId == rateCardListToUpdate?.skuListId)
            konsole.log("respSkuRate", res,rateCardListToUpdate,findServiceIdUsingSku?.services);
            if(findServiceIdUsingSku?.services?.length == 1 && findServiceIdUsingSku?.services[0]?.services.length == 0){
                setRateCardDataInObj(findServiceIdUsingSku?.services[0]?.skuServiceId,"service")
            }
            else if(findServiceIdUsingSku?.services?.length == 1 && findServiceIdUsingSku?.services[0]?.services.length > 0){
                setRateCardDataInObj(findServiceIdUsingSku?.services[0]?.skuServiceId,"group")
            }
            setPackagesList(res.data.data)  
        })
          .catch((err) => {
            konsole.log("erroeee", err);
          });
      };

    const rateCardForSubtenant = (rateCardRes) => {
        setLoader(true)
        const rateCardId = rateCardRes?.rateCardId;
        const skuListId = rateCardRes?.skuListId;
        const findEndUserPrice = subtenantRateCardData.find(item => item.endSubscriptionAmt)
        const findSubsModelId = subtenantRateCardData.find(item => item.subsModelId)
        // const checkDifferentEndSubsPrice = subtenantRateCardData.every(item => item.endSubscriptionAmt == findEndUserPrice?.endSubscriptionAmt)
        let addApplSubtForRateCrdJson = [];
    
        const applicableObj = {
            appRateCardId: rateCardId,
            // subsModelId: 3,
            appCurrency: "USD",
            isDisAllowed: true,
            appCurrencyTypeId: 1,
            appUpsertedBy: loginUserID,
        };
    
        if (sameEndUserPrice == false || sameProductValidityToAll == false) {
            addApplSubtForRateCrdJson = subtenantRateCardData
                .filter(item => (item.subIsActive == true && item.subSkuSubsModelId == 0) || (item.subIsActive == true && item.subSkuSubsModelId != 0) || (item.subIsActive == false && item.subSkuSubsModelId != 0))
                .map(item => {
                    const { endSubscriptionAmt,marginAmt,subsModelId,errorEndSubscriptionAmt,errorSubIsDisAllowed,errorValidityFromDate,errorValidityToDate,errorProductValidity, ...rest } = item;
                    return {
                        ...applicableObj,
                        endSubscriptionAmt: item?.endSubscriptionAmt,
                        subsModelId : item?.subsModelId,
                        skuSubsModelId : item?.subSkuSubsModelId ?? 0, 
                        appIsActive: (item.subIsActive == false && item.subSkuSubsModelId != 0) ? false : true,
                        subtenantRateCard: [{
                            ...rest,
                            subSkuListId: skuListId,
                            subRateCardId: rateCardId,
                            marginAmt: isNaN(marginAmt) ? 0 : marginAmt,
                        }]
                    };
                });
        } else {
            const applicableModelObj = {
                ...applicableObj,
                skuSubsModelId: applicableSubsModel?.length == 1 ? applicableSubsModel[0]?.skuSubsModelId : 0,
                appIsActive : true,
                endSubscriptionAmt: findEndUserPrice?.endSubscriptionAmt,
                subsModelId : findSubsModelId?.subsModelId,
                subtenantRateCard: subtenantRateCardData
                    .filter(item => (item.subIsActive === true && item.subSkuSubsModelId == 0) || (item.subIsActive === true && item.subSkuSubsModelId != 0) || (item.subIsActive == false && item.subSkuSubsModelId != 0))
                    .map(({ endSubscriptionAmt,marginAmt,subsModelId,errorEndSubscriptionAmt,errorSubIsDisAllowed,errorValidityFromDate,errorValidityToDate,errorProductValidity, ...rest }) => ({
                        ...rest,
                        subSkuListId: skuListId,
                        subRateCardId: rateCardId,
                        marginAmt: isNaN(marginAmt) ? 0 : marginAmt,
                    }))
            };
            addApplSubtForRateCrdJson.push(applicableModelObj);
        }
        konsole.log("addApplSubtForRateCrdJson", addApplSubtForRateCrdJson,JSON.stringify(addApplSubtForRateCrdJson));
        appSubtForRateCard(addApplSubtForRateCrdJson)
    };
    

    const handleChange = (e,shareName) => {
        
        // const { name, value } = e.target;
        const value = shareName === "minPriceSkuPercent" ? e : e.target.value
        const name = shareName === "minPriceSkuPercent" ? shareName : e.target.name
      
        const updatedValue =
          name === "rateCardName" ? capitalizeFirstLetter(value) :
          name === "minPriceSkuPercent" ? (isNumberRegex(value) ? value : '') :
          value;
      
        setRateCardData(prev => ({
          ...prev,
          [name]: updatedValue
        }));
      };

    // -------------------------------- JSX ----------------------------------------

    const selectProduct = () =>{
        return (
            <div className="col-6 mt-3">
                <p className="mb-1">Product Name<span style={{color:"red"}}>*</span></p>
                <div className=" position-relative ProductNameMainSelectDiv">
                    <img src="./icons/greyProductIcon.svg"  alt="product" className="subscriptioModelboxStyleImage"/>
                    <div className="">
                    <Form.Select aria-label="Default select example" className="selectForProductName" name="serviceId" value={rateCardData?.serviceId} onChange={(e)=>handleChange(e)}>
                        <option value={0}>Select product</option>
                        {productsList.length && productsList.map((item,ind)=>{
                            return (item.subtenantId == subtenantID) && <option value={item.serviceId}>{item.serviceName}</option>
                        })}
                    </Form.Select>
                    </div>
                </div>
            </div>
        )
    }

    const selectProductGrp = () =>{
        return (
            <div className="col-6 mt-3">
                <p className="mb-1">Product Group Name<span style={{color:"red"}}>*</span></p>
                <div className=" position-relative ProductNameMainSelectDiv">
                    <img src="./images/greyProductGroup.svg"  alt="x" className="subscriptioModelboxStyleImage"></img>
                    <div className="">
                    <Form.Select aria-label="Default select example" className="selectForProductName" name="groupId" value={rateCardData?.groupId} onChange={(e)=>handleChange(e)}>
                        <option value={0}>Select product group</option>
                        {productGrpsList.length && productGrpsList.map((item,ind)=>{
                            return (item.subtenantId == subtenantID) && <option value={item.serviceGrpId}>{item.serviceGrpName}</option>
                        })}
                    </Form.Select>
                    </div>
                </div>
            </div>
        )
    }

    const selectPackage = () =>{
       return(
        <div className="col-6 mt-3">
        <p className="mb-1">Package Name<span style={{color:"red"}}>*</span></p>
        <div className=" position-relative ProductNameMainSelectDiv">
            <img src="./icons/Group.svg"  alt="x" className="subscriptioModelboxStyleImage"></img>
            <div className="">
            <Form.Select aria-label="Default select example" className="selectForProductName" name="skuListId" defaultValue={rateCardListToUpdate?.skuListId} onChange={(e)=>handleChange(e)}>
                <option value={0}>Select package</option>
                {packagesList.length && packagesList.map((item, ind) => {
                    return (item.isVisible === true && item.subtenantId == subtenantID) ? <option key={ind} value={Number(item.skuListId)}>{item.skuListName}</option> : null;
                    })}
            </Form.Select>
            </div>
        </div>
    </div>
       )
    }

    const selectSubtenant = (subtenant,ind) =>{

        setSubtenantRateCardData((prevList) =>
        prevList.map((det) =>
        det.subtenantId === subtenant.subtenantId
        ? { ...det, subIsActive: !det.subIsActive }
        : det));

        if(applicableSubsModel?.length == 1){
                const {subtenantId,subtenantName,subtenantRateCardId,subSkuSubsModelId,subIsActive}=subtenant
                setSubtenantRateCardData((prev)=>{
                    const newArray=[...prev]
                   let findAlreadyAddedSubt=  newArray.filter(item => item.subSkuSubsModelId !== 0);
                   const filterSameData = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.subSkuSubsModelId === item.subSkuSubsModelId));
                   const filtreSameMarginAmnt = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.marginAmt == item.marginAmt && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.marginAmt ?? 0;
                   const filtreSameMarginPerc = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.marginPercent == item.marginPercent && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.marginPercent ?? 0;
                //    const filtreSameValiditypPeriod = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.validityPeriod == item.validityPeriod && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.validityPeriod ?? '';
                   const filtreSameValidityFromDate = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.validityFromDate == item.validityFromDate && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.validityFromDate ?? '';
                   const filtreSameValidityToDate = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.validityToDate == item.validityToDate && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.validityToDate ?? '';
                   const filtreSameEndSubscriptionAmt = findAlreadyAddedSubt.filter(item => findAlreadyAddedSubt.every(it => it.endSubscriptionAmt == item.endSubscriptionAmt && it.subSkuSubsModelId === item.subSkuSubsModelId))[0]?.endSubscriptionAmt ?? '';
                //    konsole.log("filterSameData",filterSameData,filtreSameMarginAmnt,filtreSameMarginPerc,filtreSameValiditypPeriod,filtreSameValidityFromDate,filtreSameValidityToDate,subtenant)
                   if(filterSameData.length>0 && newArray[ind].subSkuSubsModelId == 0){
                       newArray[ind]={...filterSameData[0],marginAmt:filtreSameMarginAmnt,marginPercent:filtreSameMarginPerc,validityFromDate:filtreSameValidityFromDate,
                      validityToDate:filtreSameValidityToDate,endSubscriptionAmt:filtreSameEndSubscriptionAmt,subSkuSubsModelId,subtenantId,subtenantName,subtenantRateCardId,subIsActive:newArray[ind].subIsActive}
                   }
                    return newArray;
                })
        }
    }

    const calculateMargin = (minPriceSkuPerc,endSubsAmnt) =>{
        let marginPercentage;
        let marginAmount;
        konsole.log("minPriceSkuPerc,endSubsAmnt",minPriceSkuPerc,endSubsAmnt)
        if(minPriceSkuPerc != undefined && minPriceSkuPerc != null && minPriceSkuPerc != ''){
            marginPercentage = 100 - minPriceSkuPerc;
            marginAmount = (marginPercentage/100) * Number(endSubsAmnt)
            return {marginPercentage, marginAmount}
        }  
    }

    const isDateValid = (date,array,dataForValidity,validateFor) => {
        const year = date.year();
        if (!date.isValid() || year < 1000 || year > 9999) {
            completeEventMessage("error", "Please enter a valid date.");
                for (const item of array) {
                    const checkValidityFor = validateFor == "validateValidityTo" ? item?.validityToDate : validateFor == "validateValidityFrom" ? item?.validityFromDate : item?.validityFromDate;
                    const compareValiditywith = validateFor == "validateValidityTo" ? dataForValidity?.validityToDate : validateFor == "validateValidityFrom" ? dataForValidity?.validityFromDate : dataForValidity?.validityFromDate;
                    if ((checkValidityFor === compareValiditywith) || validateFor == "validateValidityFromDateForAll") {
                       if(validateFor == "validateValidityTo"){
                           item.validityToDate = "";
                       }
                       else if(validateFor == "validateValidityFrom"){
                        item.validityFromDate = "";
                       }
                       else if(validateFor == "validateValidityFromDateForAll"){
                        if(item.subIsActive == true){
                            item.validityFromDate = "";
                        }
                       }
                        setRender(!render);
                    }
                }
                if(validateFor == "validateValidityFromDateForAll"){
                    setValidityFromOnUpdate("")
                }
            return true;
        }
    };

    const checkValidityTo = (e, dataForValidity,validateFor) => {
        const array = subtenantRateCardData;
        const dateStrings = e.target.value;
        const date = moment(dateStrings, "YYYY-MM-DD", true);
        
        if(!isDateValid(date,array,dataForValidity,validateFor) &&  validateFor == "validateValidityTo"){
            let isValid = true;
            let checkEarlierDate;
            let checkSameDate;
            for (const item of array) {
                if (item?.validityToDate === dataForValidity?.validityToDate) {
                    const validityFromDate = moment(dataForValidity?.validityFromDate, "YYYY-MM-DD", true);
                    checkEarlierDate = date.isBefore(validityFromDate);
                    checkSameDate = date.isSame(validityFromDate)
                    if (checkEarlierDate || checkSameDate) {
                        item.validityToDate = "";
                        isValid = false;
                        setRender(!render);
                    }
                }
            }
        
            setSubtenantRateCardData(array);
        
            if (!isValid) {
                completeEventMessage("error", `The rate card end date cannot be ${checkSameDate == true ? "same" : "earlier"} than the rate card start date of ${dataForValidity?.subtenantName}`);
            }
        }
    
    };
    
    
    
    const selectSubtenantRateCardData = (e, ind,endUserPriceName,errorKey) => {
        // const { name, id, value } = e?.target;
        const name = (endUserPriceName == "endSubscriptionAmt" || (ind === "setForAll" && endUserPriceName == "endSubscriptionAmt"))  ? endUserPriceName : e?.target?.name
        const value = (endUserPriceName == "endSubscriptionAmt" || (ind === "setForAll" && endUserPriceName == "endSubscriptionAmt")) ? e : e?.target?.value
        konsole.log("EndUserPriceName",name,value,e?.target, ind,endUserPriceName)
        
        const discountValue = (name === "subIsDisAllowed" && value === "Yes") ? true : (name === "subIsDisAllowed" && value === "No") ? false : null;
        konsole.log("valuevalue",name,value,ind)

        if (ind === "setForAll") {
            setSubtenantRateCardData((prev) =>
                prev.map((det) => {
                    if (det.subIsActive === true) {
                        return {
                            ...det,
                            [name]: value,
                            subIsDisAllowed: discountForAll,
                            [errorKey]:''
                        };
                    }
                    return det;
                })
            );
            if(name == "subsModelId"){
                setSameProductValidityError(null)
            }
            if(name == "validityFromDate"){
                setValidityFromOnUpdate(value)
                setSameValidityFromDateError(null)
            }
            if(name == "endSubscriptionAmt"){
                setSameEndUserPriceError(null)
            }
        } else {
            setSubtenantRateCardData((prev) =>
            prev.map((item, index) =>
                (index === ind) ? {
                          ...item,
                          [name]: name === "subIsDisAllowed" ? discountValue : value,
                          [errorKey]:''
                      } : item
            )
        ); 
    }
    insertValidityToAndAmntInArr();
    }

    const insertValidityToAndAmntInArr = () => {
        setSubtenantRateCardData((prev) =>
            prev.map((det) => {
                if (det.subIsActive === true) {
                    // const calculetValitidityTo = calculateValidityToDate(det.validityFromDate, det.validityPeriod);
                    // const valitidityTo = formatDate(calculetValitidityTo);
                    // const margins = ((rateCardListToUpdate === undefined || rateCardListToUpdate === null || rateCardListToUpdate === '') || applicableSubsModel?.length > 1) ? calculateMargin(rateCardData?.minPriceSkuPercent, det?.endSubscriptionAmt) : '';
                    const margins = calculateMargin(rateCardData?.minPriceSkuPercent, det?.endSubscriptionAmt)
                    konsole.log("marginss",margins,det.marginPercent, det.marginAmt,rateCardListToUpdate,applicableSubsModel?.length)

                    return {
                        ...det,
                        // validityToDate: valitidityTo,
                        marginPercent: margins?.marginPercentage || det.marginPercent,
                        marginAmt: (margins?.marginAmount == NaN || margins?.marginAmount == undefined || margins?.marginAmount == 0) ? det.marginAmt : margins?.marginAmount,
                    };
                }
                return det;
            })
        );
    };

    // const validateDate = (dateString) => {
    //     const date = new Date(dateString);
    //     if (!(date instanceof Date) || isNaN(date)) {
    //       alert("Please enter a valid date");
    //       return false;
    //     }
    //     return true;
    //   };
    

    

    return (
        <>
        <style jsx global>{`
          .modal-open .modal-backdrop.show {
            opacity: 0.7;
          }
          .modal-dialog {
            max-width: 60rem;
            margin: 1.75rem auto;
          }
        `}</style>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="Modal-Main-Class"
                centered
                show={props?.AddShowModal}
                onHide={closeModal}
            //   footer={false}
            >
                <Modal.Header closeButton className="modalHeader" closeVariant="white">
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className=" w-100"
                    >
                        <div className="row">
                                <div className="col-1 cursor-pointer" style={{width:"5%"}} onClick={closeModal}>
                                    <img src="./icons/backArrow2.svg" style={{height:"45%"}}></img>
                                </div>
                                <div className="col-11">
                                    <p className="text-start mb-0">{`${rateCardListToUpdate ? "Update" : "Add"} Rate Card`}</p>
                                </div>
                                {/* <div className="col-5">
                                    d
                                </div> */}
                        </div>
                        {/* <div>Add Rate Card Partner</div> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                        {rateCardListToUpdate && <div>
                <div className=" mainRow" >
                        <div className="updateProduct">
                          <h6>{`${rateCardListToUpdate?.skuListName.includes("INDPKG_") ? "Product" : rateCardListToUpdate?.skuListName.includes("GRPPKG_") ? "Product Group" : "Package"} Name`}</h6>
                          <h4>{`${rateCardListToUpdate?.skuListName.includes("INDPKG_") || rateCardListToUpdate?.skuListName.includes("GRPPKG_") ? rateCardListToUpdate?.skuListName.split(rateCardListToUpdate?.skuListName.includes("INDPKG_") ? "INDPKG_" : "GRPPKG_").pop() : rateCardListToUpdate?.skuListName}`}</h4>
                        </div>
                        <div className="updateProduct">
                          <h6>Rate Card Name</h6>
                          <h4>{rateCardListToUpdate?.rateCardName}</h4>
                        </div>
                        <div className="updateProduct">
                          <h6>AO Percentage Share</h6>
                          <h4>{rateCardListToUpdate?.minPriceSkuPercent}</h4>
                        </div>
                        {/* <div className="updateProduct">
                          <h6>Slabs From To</h6>
                          <h4>{rateCardListToUpdate?.slabStartingNo} - {rateCardListToUpdate?.slabEndingNo}</h4>
                        </div> */}
                    </div>
                    <div className="mt-3" style={{border:"1.8px solid #F2F2F2", margin:" 0px 280px"}}></div>
                        </div>}
                   <div className="pe-2" style={{overflowY:"auto", height:"42vh", overflowX:"hidden"}}>
                {(rateCardListToUpdate == undefined || rateCardListToUpdate == null || rateCardListToUpdate == "")  && <div className="d-flex justify-content-start align-items-center">
                    <div className="d-flex h-50 mt-2">Create Rate Card For : </div>
                <div className="d-flex h-50 mt-2 ms-4 selectProductsRadio">
                {productTypeArray.map((item, index) => (
                    <React.Fragment key={item}>
                        {index === 1 && (
                            <div style={{ border: "1px solid #EAEAEA", margin: "8px" }}></div>
                        )}
                        <div className="px-2 d-flex justify-content-center align-items-center">
                            <Form.Check type="radio" id={`check-api-${index}`} name="productType" checked={addOrUpdateRateCardFor == item.label} onChange={()=>setAddOrUpdateRateCardFor(item.label)}/>
                            <Form.Label className="mx-2 my-2">{item.label}</Form.Label>
                        </div>
                        {index === productTypeArray.length - 2 && (
                            <div style={{ border: "1px solid #EAEAEA", margin: "8px" }}></div>
                        )}
                    </React.Fragment>
                ))}
              </div>
                </div>}
                   <div className="row d-flex align-items-center">
                   {(rateCardListToUpdate == undefined || rateCardListToUpdate == null || rateCardListToUpdate == "") && (addOrUpdateRateCardFor == "Product" ? selectProduct() : addOrUpdateRateCardFor == "Product group" ? selectProductGrp() : addOrUpdateRateCardFor == "Package" ? selectPackage() : "")}
                         <div className="col-6 mt-3">
                        <p className="mb-1">Rate Card Name<span style={{color:"red"}}>*</span></p>
                            <div className="rateCardDivOutlines">
                                <span className="spanRateCardname" >Aa</span>
                                <div className="rateCardNameInputMainDiv" >
                                    <input type="text" placeholder="Enter Rate Card Name" style={{height: "38px",background: "transparent"}} name="rateCardName" value={rateCardData?.rateCardName} onChange={(e)=>handleChange(e)}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 mt-3">
                         <p className="mb-1">AO Percentage Share</p>
                         <div className="col-6 w-100" style={{border: "1.2px solid rgb(234, 234, 234)",borderRadius:"10px"}}>
                         {/* <input type="text" placeholder="Enter Percentage" className="slabsFromInputBox w-100" name="minPriceSkuPercent" value={rateCardData?.minPriceSkuPercent} onChange={(e)=>handleChange(e)} /> */}
                         <CurrencyInput type="text" placeholder="Enter Percentage" className="slabsFromInputBox w-100" name="minPriceSkuPercent" value={rateCardData?.minPriceSkuPercent} onValueChange={(value,name)=>handleChange(value,name)} suffix="%"/>
                         </div>
                        </div>
                   </div>
                   {/* <div className="d-flex flex-wrap gap-3">
                        <div>
                        <p className="mb-1 mt-3">Slabs From</p>
                         <input type="text" placeholder="Eg 1,2,3" className="slabsFromInputBox" name="slabStartingNo" defaultValue={rateCardListToUpdate?.slabStartingNo} onChange={(e)=>handleChange(e)}/>
                        </div>
                        <div>
                         <p className="mb-1 mt-3">Slabs To</p>
                         <input type="text" placeholder="Eg 1,2,3" className="slabsFromInputBox" name="slabEndingNo" defaultValue={rateCardListToUpdate?.slabEndingNo} onChange={(e)=>handleChange(e)}/>
                        </div>

                        If Slots inputs needed put AO Share div here
                         
                  </div> */}

                  <Row className="row mt-4">
                                <Col sm={6} md={4} lg={4}>
                                        <p className="generateRateCardLabel">Generate Rate Card For :</p>
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <div className="d-flex gap-3 justify-content-end me-3">
                                          <div className="d-flex">
                                                <Form.Check type="radio" checked={selectAllRadioVal == true} name="allSubtenant" onClick={()=>updateRateRadioBtn('allSubtenant')}/>
                                                <span className="rateCardPartnersBorders">All Firms</span>
                                            </div>
                                            <div className="d-flex">
                                                < Form.Check type="radio" checked={selectAllRadioVal == false} name="SpecificSubtenant" onClick={()=>updateRateRadioBtn('SpecificSubtenant')} />
                                                <span className="rateCardPartnersBorders">Specific Firms</span>
                                            </div>
                                    </div>
                                </Col>
                        </Row>
                       {updateRadio != "" && <div>
                       <div className="partnerData" >
                        {subtenantRateCardData.length && subtenantRateCardData.map((item,ind)=>{
                            return(
                           <Tooltip text={item.subtenantName}>
                                <div className={`${item.subIsActive == true ? "rateCardGenerateOptionsMarron" : "rateCardGenerateOptionsGrey"} d-flex justify-content-center mb-2 cursor-pointer `} onClick={()=>selectSubtenant(item,ind)}>
                                <div  className= "dotShow">{item.subtenantName}</div>
                               <div style={{padding:"9px 7px"}}> {item.subIsActive == true ? <img src="./icons/checked.svg"/> : <img src="./icons/add.svg"/>}</div>
                            </div>
                           </Tooltip>
                            )             
                        })}
                        </div>
                        <div className="mt-4">
                        <div  style={{border:"1.8px solid #F2F2F2", margin:"0px 13px"}}></div>
                            <p className="labelToaddUpdatePartner"><img src="./icons/icons8-add-user-100.png" className="addUpdateParterImage"></img>Add Rate Card Details</p>
                        </div>
                        <div className="row">
                            <div className="col-10  ">
                                    <p><img src="./icons/icons8-dot-60.png" className="dotImageStyling"></img>{`Would you like to give discount to ${numberOfSelectedFirms?.length == 1 ? "the" : "all"} selected firm${numberOfSelectedFirms?.length == 1 ? "" : "s"}`}</p>
                            </div>
                            <div className="col p-0 ">
                                    <div className="row justify-content-end">
                                    <div className=" d-flex col p-0">                                           
                                        <Form.Check  type="radio" name="discount" id='subIsDisAllowed' checked={discountForAll == true ? true : false}  onChange={(e)=>selectForAllOrNot("giveDiscountToAll",e)}/>
                                        <span className="rateCardPartnersBorders" >Yes</span>                                           
                                    </div>
                                    <div className="d-flex col p-0">       
                                        <Form.Check  type="radio" name="discount" id='subIsDisAllowed' checked={discountForAll == false ? true : false} onChange={(e)=>selectForAllOrNot("notGiveDiscountToAll",e)}/>
                                        <span className="rateCardPartnersBorders">No</span>                                         
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10  ">
                                    <p><img src="./icons/icons8-dot-60.png" className="dotImageStyling "></img>{`Would you like to have same product validity for the selected firm${numberOfSelectedFirms?.length == 1 ? "" : "s"}`}</p>
                            </div>
                            <div className="col p-0 ">
                                    <div className="row justify-content-end">
                                    <div className="d-flex col p-0">                                           
                                                <Form.Check type="radio" name="sameProductValidityToAll" checked={sameProductValidityToAll == true ? true : false} onChange={()=>selectForAllOrNot("giveSameProductValidityToAll")} />
                                                <span className="rateCardPartnersBorders" >Yes</span>                                           
                                    </div>
                                    <div className="d-flex col p-0">       
                                                <Form.Check type="radio" name="sameProductValidityToAll"  checked={sameProductValidityToAll == false ? true : false} onChange={()=>selectForAllOrNot("notGiveSameProductValidityToAll")} />
                                                <span className="rateCardPartnersBorders">No</span>                                         
                                    </div>
                                    </div>
                            </div>
                        </div>
                        {sameProductValidityToAll==true &&
                        <>
                                <div className="d-flex justify-content-end">
                                    <div className="updateCardSelect">
                                    <select className="discountAndValidityPeriodSelect" name='subsModelId' defaultValue={productValidityOnUpdate} onChange={(e)=>selectSubtenantRateCardData(e,"setForAll","","errorProductValidity")}>
                            <option value="">Product Validity</option>
                            {productValidityArr?.length && productValidityArr.map((item,ind)=>{
                                return <option value={item.value}>{item.label}</option>})}
                                </select>
                                    </div>
                            </div>
                                <div className="text-danger d-flex justify-content-end mb-3">{sameProductValidityError}</div>
                        </> 
                        }


                        <div className="row">
                            <div className="col-10  ">
                                    <p><img src="./icons/icons8-dot-60.png" className="dotImageStyling"></img>{`Would you like to have same validity starting time for the selected firm${numberOfSelectedFirms?.length == 1 ? "" : "s"}`}</p>
                            </div>
                            <div className="col p-0 ">
                                    <div className="row justify-content-end">
                                    <div className="d-flex col p-0">                                           
                                                <Form.Check  type="radio" className="" name="validityFromDate" checked={sameValidityFromDateToAll == true ? true : false} onChange={()=>selectForAllOrNot("giveSameValidityFromDateToAll")} />
                                                <span className="rateCardPartnersBorders" >Yes</span>                                           
                                    </div>
                                    <div className="d-flex col p-0">       
                                                <Form.Check  type="radio" className="" name="validityFromDate" checked={sameValidityFromDateToAll == false ? true : false} onChange={()=>selectForAllOrNot("notGiveSameValidityFromDateToAll")}/><span className="rateCardPartnersBorders">No</span>                                         
                                    </div>
                                    </div>
                            </div>
                        </div>
                        {sameValidityFromDateToAll==true &&
                        <>
                          <div className="d-flex justify-content-end">
                          <div className="datePickerStyleInRateCard">
                                   <input type="date" name="validityFromDate" value={validityFromOnUpdate} onChange={(e)=>selectSubtenantRateCardData(e,"setForAll","","errorValidityFromDate")} onBlur={(e)=>checkValidityTo(e,"","validateValidityFromDateForAll")} max="9999-12-31"/>
                          </div>
                      </div>
                          <div className="text-danger d-flex justify-content-end mb-3">{sameValidityFromDateError}</div>
                        </>
                        }
                      
                        <div className="row">
                            <div className="col-10  ">
                                    <p><img src="./icons/icons8-dot-60.png" className="dotImageStyling"></img>{`Would you like to have same end user price for the selected firm${numberOfSelectedFirms?.length == 1 ? "" : "s"}`}</p>
                            </div>
                            <div className="col p-0 ">
                                    <div className="row justify-content-end">
                                    <div className="d-flex col p-0">                                           
                                            <Form.Check  type="radio" className="" name="endUserPrice" checked={sameEndUserPrice == true ? true : false} onChange={()=>selectForAllOrNot("giveSameEndUserPriceToAll")}/>
                                            <span className="rateCardPartnersBorders" >Yes</span>                                           
                                    </div>
                                    <div className="d-flex col p-0">       
                                            <Form.Check  type="radio" className="" name="endUserPrice" checked={sameEndUserPrice == false ? true : false} onChange={()=>selectForAllOrNot("notGiveSameEndUserPriceToAll")}/>
                                            <span className="rateCardPartnersBorders">No</span>                                         
                                    </div>
                                    </div>
                            </div>
                        </div>
                        {sameEndUserPrice==true &&
                        <>
                                <div className="d-flex justify-content-end">
                                    <div className="datePickerStyleInRateCard">
                                    <CurrencyInput prefix="$" allowNegativeValue={false}  placeholder="$0.00"  name="endSubscriptionAmt" defaultValue={endSubscPriceOnUpdate} onValueChange={(value,name)=>selectSubtenantRateCardData(value,"setForAll",name,"errorEndSubscriptionAmt")} decimalScale="2"/>
                                            {/* <input  type="text" placeholder="Enter end user price" name="endSubscriptionAmt" defaultValue={endSubscPriceOnUpdate} onChange={(e)=>selectSubtenantRateCardData(e,"setForAll")}/> */}
                                    </div> 
                               </div>
                               <div className="text-danger d-flex justify-content-end mb-3">{sameEndUserPriceError}</div>
                        </>
                        }
                        </div>}
                        </div>
                </Col>
                {updateRadio != "" && <div>
                {showTable == true && <Col>
                <div style={{border:"1.3px solid #F2F2F2"}} ></div>
                <div className="table-responsive mt-3 pe-2" style={{overflowY:"auto", height:"28vh", overflowX:"hidden"}}>
                    <table style={{width:"100%"}}>
                        <thead style={{position:"sticky", top:"0px", zIndex:"111",backgroundColor:"white"}}>
                            <tr className="updateRateTable">
                            <th>Firm Name</th>
                            <th>Discount</th>
                            <th>End User Price</th>
                            <th>Product Validity</th>
                            <th>Rate Card Start Date</th>
                            <th>Rate Card End Date</th>
                            </tr> 
                        </thead>
                         <tbody>
                           {subtenantRateCardData?.length>0 && subtenantRateCardData?.map((item,ind)=>{
                            return item.subIsActive == true ? (
                           <tr className="updateRateTd" style={{borderBottom:'2px solid #F2F2F2'}}>
                            <td>{item.subtenantName}</td>
                            <td><div>
                             <div>
                            <select className="discountAndValidityPeriodSelect" name='subIsDisAllowed' onChange={(e)=>selectSubtenantRateCardData(e,ind,"","errorSubIsDisAllowed")} disabled={discountForAll} value={(item.subIsDisAllowed == true || discountForAll == true) ? "Yes" : (item.subIsDisAllowed == false) ? "No" : ""}>
                            <option value="">Discount</option>
                            <option>Yes</option>
                            <option>No</option>
                            </select>
                                    </div>
                                    <div className="text-danger">{item?.errorSubIsDisAllowed != "" ? item?.errorSubIsDisAllowed : ""}</div>
                                </div>
                                </td>
                            <td className="updateInput">
                                <div>
                                    <div>{/* <input type="text" placeholder="$0.00" name="endSubscriptionAmt" disabled={sameEndUserPrice} value={item.endSubscriptionAmt} onChange={(e)=>selectSubtenantRateCardData(e,ind)}/> */}
                                <CurrencyInput prefix="$" allowNegativeValue={false} placeholder="$0.00" name="endSubscriptionAmt" disabled={sameEndUserPrice} value={item.endSubscriptionAmt} onValueChange={(value,name)=>selectSubtenantRateCardData(value,ind,name,"errorEndSubscriptionAmt")} decimalScale="2"/>
                                </div>
                                <div className="text-danger">{item?.errorEndSubscriptionAmt !== "" ? item?.errorEndSubscriptionAmt : ""}</div>
                                </div>
                                </td>
                            <td>
                                <div>
                                    <div>
                                <select className="discountAndValidityPeriodSelect" name='subsModelId' disabled={sameProductValidityToAll} value={item.subsModelId} onChange={(e)=>selectSubtenantRateCardData(e,ind,"","errorProductValidity")}>
                            <option value="">Product Validity</option>
                            {productValidityArr?.length && productValidityArr.map((item,ind)=>{
                                return <option value={item.value}>{item.label}</option>})}
                                </select>
                                    </div>
                                    <div className="text-danger">{item?.errorProductValidity !== "" ? item?.errorProductValidity : ""}</div>
                                </div>
                                </td>
                            <td className="dateTD">
                                <div>
                                <div><input type="date" name="validityFromDate" disabled={sameValidityFromDateToAll} onChange={(e)=>selectSubtenantRateCardData(e,ind,"","errorValidityFromDate")} onBlur={(e)=>checkValidityTo(e,item,"validateValidityFrom")} value={item.validityFromDate} max="9999-12-31"/></div>
                                <div className="text-danger">{item?.errorValidityFromDate !== "" ? item?.errorValidityFromDate : ""}</div>
                                </div>
                            </td>
                            <td className="dateTD" id="validityToDate">
                                <div>
                                <div><input type="date" name="validityToDate" value={item.validityToDate} onChange={(e)=>selectSubtenantRateCardData(e,ind,"","errorValidityToDate")} onBlur={(e)=>checkValidityTo(e,item,"validateValidityTo")} max="9999-12-31"/></div>
                                <div className="text-danger">{item?.errorValidityToDate !== "" ? item?.errorValidityToDate : ""}</div>
                                </div>
                                {/* <div className="text-danger">{item?.errorValidityToDate !== "" ? item?.errorValidityToDate : ""}</div> */}
                                </td>
                           </tr>
                            ) : null 
                           })}
                         </tbody>
                    </table>
                </div>
                </Col>}
                </div>}

                </Row>
          </Modal.Body>
                <Modal.Footer>
                <div className="rateCardSaveButtonWrapper">
                    <button className="rateCardSaveButton" onClick={(e) => addRateCard(e)} disabled={disableSaveBtn}>
                        {`${rateCardListToUpdate ? "Update" : "Save"}`}
                    </button>
                </div>
                </Modal.Footer>
            </Modal>
        </>

        
    );
}
export default AddRateCardPartner;

import React, { useState, useEffect, createContext, useContext } from "react";
// import moment from "moment";
// import "moment/locale/de";
import {
  Form,
  Input,
  Cascader,
  Select,
  DatePicker,
  Button,
  Radio,
  Row,
  Col,
  Space,
  TimePicker,
  AutoComplete,
  notification,
  message,
  Upload,
  Steps,
  Modal,
  Divider,
} from "antd";
import "./Classroom.css";
import "antd/dist/antd.css";
import konsole from "../../network/konsole";
// import locale from "antd/lib/date-picker/locale/en_US";
import ListClassroom from "./ListClassroom";

import Services from "../../network/Services";
import TextArea from "antd/lib/input/TextArea";
import "moment/locale/en-gb";
import locale from "antd/es/locale/en_GB";
import moment from "moment";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Stepper from "./Stepper";
import Preview from "./Preview";
import Congratulations from "./Congratulation";
import AddEditVenue from "../AddEditVenue";
import { UploadOutlined } from "@ant-design/icons";
import AddEditVenueCls from "./AddEditVenueCls";
import { enAU, zhCN } from "date-fns/locale";
import InputMask from "../PhoneInputMask";
// import InstructorInformation from "./InstructorInformation";
import AddEditSpeaker from "../AddEditSpeaker";
import AddEditClsSpeaker from "./AddEditClsSpeaker";
import AddEditCoornatorperson from "./AddEditCoornatorperson";
import PublishedCls from "./PublishedCls";
import { UserContext } from "../../App";
export const GlobalData = createContext();

const { Step } = Steps;
const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const Classroom = () => {
  const { setLoader } = useContext(UserContext);
  const [form] = Form.useForm();
  const [publishclassroom, setPublishClassroom] = useState(true);
  const [previewBtn, setPreviewBtn] = useState("");
  const [ChangePage, setChangePage] = useState(0);
  const [preData, setPreData] = useState(false);
  const [sendToPreview, setsendToPreview] = useState("");
  const [sendToMetaData, setsendToMetaData] = useState("");
  const [venueAddressIdTextPublish, setVenueAddressIdTextPublish] =
    useState("");
  const [radioValueNo, setRadioValueNo] = useState();
  const [radioValueDirect, setRadioValueDirect] = useState('');
  const [dataGet, setDataGet] = useState([]);
  const [dataGet2, setDataGet2] = useState(["Weekend", "Week Day"]);
  const [classroomDate, setClassroomDate] = useState();
  const [loading, setLoading] = useState(false);
  const [clsthumbnailurl, setClsthumbnailurl] = useState();
  const [IncStepperSteps, setIncStepperSteps] = useState(0);
  const [loggedId, setLoggedId] = useState("");
  const [venueData, setVenueData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [venueSave, setVenueSave] = useState("");
  const [addressId, setAddressId] = useState("");
  const [location, setLocation] = useState("");
  const [venueaddressId, setVenueAddressId] = useState("");
  const [venue, setVenue] = useState("");
  const [coOrdinatorList, setCoOrdinatorList] = useState([]);
  const [coordinatorNum, setCoordinatorNum] = useState();
  const [seminarFareField, setSeminarFareField] = useState("");
  const [radioValue, setRadioValue] = useState();
  const [weekErr, setweekErr] = useState(false);
  const [add5Days, setAdd5Days] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userDetails, setuserDetails] = useState([]);
  const [selectSpeakerName, setSelectSpeakerName] = useState("");
  const [sendSpeakerStoredVal, setSendSpeakerStoredVal] = useState("");
  const [speakerprofile, setSpeakerProfile] = useState("");
  const [postSubtenantDetailResp, setpostSubtenantDetailResp] = useState([]);

  const [storedVal, setstoredVal] = useState("");
  const [coordinatorPerson, setCoordinatorPerson] = useState("");
  const [speakerId, setSpeakerId] = useState();
  const speakerData = [];
  const [postSubtenantName, setpostSubtenantName] = useState("");
  const [subtenantIdToApi, setSubtenantIdToApi] = useState();
  const [coordinatorName, setCoordinatorName] = useState();

  let FromDatevalue;

  const [file, setFile] = useState();
  const [createdByuserId, setCreatedByuserId] = useState("");
  const [fileTypeId, setFileTypeId] = useState(15);
  const [fileCategoryId, setFileCategoryId] = useState(1);
  const [fileStatusId, setFileStatusId] = useState(2);
  const [imageAlt, setImageAlt] = useState(" Image of Speaker");
  const [imgLink, setImgLink] = useState("");
  const [valueSelectName, setValuaSelectName] = useState("");
  const [subtenantValueId, setSubtenantValueId] = useState("");
  const [primaryMobile, setPrimaryMobile] = useState();
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [coordinatorId, setCoordinatorId] = useState("");

  const [modalAddPerson, setModalAddPerson] = useState(false);
  const [classroomData, setClassroomData] = useState();
  const Cornatorperson = [];
  const [editClassroom, setEditClassroom] = useState();
  let coordiPersoncontact;

  const onGenderChange = (value, key) => {
    konsole.log("onGenderChange", key);
    konsole.log("onGenderChange", key.value);

    // setSubtenantValueId(key.value);
    userListApiCall(key.value);
  };

  const userListApiCall = () => {
    let getCoOrdinatorUserListApiCall = Services.getUserListByRoleId(
      745,
      true,
      6
    );
    getCoOrdinatorUserListApiCall
      .then((res) => {
        konsole.log("responseCoordinator", res.data.data);
        setCoOrdinatorList(res.data.data);
        // setIsModalVisible(false);
        // form.resetFields();
        setLoader(false);

        if (
          editClassroom !== null &&
          editClassroom !== undefined &&
          editClassroom !== ""
        ) {

          let resultfilter = res.data.data.filter(function (items) {
            return (
              items.userId == editClassroom?.coordinators[0]?.coordinatorUserId
            );
          });

          resultfilter.map((item) => {
            konsole.log("mapresultfilter", item);
            setPrimaryMobile(item.primaryMobile);
            setPrimaryEmail(item.primaryEmail);
            konsole.log(item.primaryEmail);
            konsole.log(item.primaryMobile);
            setCoordinatorId(item.userId);
            setCoordinatorPerson(item.fullName);
            setCoordinatorName(item.fullName);
            // form.setFieldsValue({
            //   Name_of_Contact_Person: item.fullName,
            // });

            // setCoordinatorName(item.fullName);
            // props.form.setFieldsValue({
            //   "CoordinatorField": item.fullName,
            //   "CoordinatorContact": item.primaryMobile,
            //   "CoordinatorEmail": item.primaryEmail

            // })
          });
          let mapResultdata = editClassroom?.coordinators.map((x) => {
            return x?.coordinatorUserId
          })

          // konsole.log("mapResultdatamapResultdata",mapResultdata)
          form.setFieldsValue({
            Name_of_Contact_Person: mapResultdata,
          });
          setCoordinatorId(mapResultdata)
        }
      })
      .catch((error) => {
        konsole.log("errorcoordinator", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  konsole.log("coordinatorNamecoordinatorName", coordinatorName);

  let upsertedloggedIn = sessionStorage.getItem("loggedUserId");
  const upsertedBy = upsertedloggedIn;

  moment.locale("en", {
    week: {
      dow: 1,
      /// Date offset
    },
  });

  useEffect(() => {
    getvenueData();
    SepkaerListOptionApi();
    postSubtenantDetails();
    let loggedUser = sessionStorage.getItem("loggedUserId");
    setLoggedId(loggedUser);

    const promise = Services.subtenantType();
    setLoader(true);
    promise
      .then((res) => {
        konsole.log(res, "reserr");
        setDataGet(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        konsole.log(`error %%  ${err}`);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const postSubtenantDetails = () => {
    let postSubtenantDetailApiCall = Services.postSubtenantDetails();
    postSubtenantDetailApiCall
      .then((res) => {
        
        setpostSubtenantDetailResp(res.data.data);
        setValuaSelectName(res.data.data[3].subtenantName);
        setSubtenantValueId(res.data.data[3].subtenantId);
        userListApiCall(res.data.data[3].subtenantId);
        let subtenantNames = res.data.data.filter((ele)=>{
            return ele?.subtenantId == 745
            
          
        })
    
        form.setFieldsValue({
          Class_Room_Organizing_Partner: subtenantNames[0]?.subtenantName,
        });
        // setpostSubtenantName(res.data.data[3].subtenantName)
      })
      .catch((error) => {
        konsole.log("error", error);
      });
  };

  const selectPrimaryContactEmail = (fullName, key) => {
    konsole.log("SelectfullName", fullName);
    konsole.log("key", key.key);
    let userId = key.key;
    konsole.log(userId);
    let resultfilter = coOrdinatorList.filter(function (items) {
      return items.userId == userId;
    });

    resultfilter.map((item) => {
      konsole.log("mapresultfilter", item);
      setPrimaryMobile(item.primaryMobile);
      setPrimaryEmail(item.primaryEmail);
      konsole.log(item.primaryEmail);
      konsole.log(item.primaryMobile);
      setCoordinatorId(item.userId);
      // setCoordinatorName(item.fullName);
      // props.form.setFieldsValue({
      //   "CoordinatorField": item.fullName,
      //   "CoordinatorContact": item.primaryMobile,
      //   "CoordinatorEmail": item.primaryEmail

      // })
    });
  };

  // const CoordName = (e) => {
  //   setSendCoordName(e)
  // }
  // konsole.log("MyCoord", sendCoordName)
  // getCoordContactPerson(sendCoordName || coordinatorNameFromApiRes)
  // getCoordContactNo(primaryMobile || getMobileNoCoorApi)
  // getCoordEmail(primaryEmail || getEmailCoordinatorApi)

  const getvenueData = () => {
    let subtenantId = sessionStorage.getItem('subtenantId')
    let callgetvenueApi = Services.getSeminarVenueData(subtenantId);
    callgetvenueApi
      .then((res) => {
        setVenueData(res.data.data);
      })
      .catch((error) => {
        konsole.log("response", error);
      });
  };

  const onFinish = (values) => {
    konsole.log("values", values);
    setClassroomData(values);
    if (values != " " && values != null && values != []) {
      setIncStepperSteps(1);
    }
  };

  const getAddressByAddressId = (addressId) => {
    const AddressIdToAddress = Services.getAddressId(addressId);
    AddressIdToAddress.then((res) => {
      konsole.log("AddressIdData", res.data.data.addressLine1);
      setLocation(res.data.data.addressLine1);
    }).catch((err) => {
      konsole.log("AddressIdError", err);
    });
  };

  const getCallPreviewFunction = (itemCallPreviewFunction) => {
    // sendToPreview = itemCallPreviewFunction;
    setsendToPreview(itemCallPreviewFunction);
  };
  const getCallMetaDataFunction = (itemgetCallMetaDataFunction) => {
    konsole.log("getCallMetaDataFunction", itemgetCallMetaDataFunction);

    setsendToMetaData(itemgetCallMetaDataFunction);
  };
  const radioDirectbtn = (a) => {
    form.setFieldsValue({radioSelect: parseInt(a.value)})
    setRadioValueNo(a.value);
    setRadioValueDirect(a.label);
    konsole.log("radioonchage", a);
  };
  const radioonchange = (a) => {
    setRadioValue(a.target.value);
    setweekErr(false);
    if (radioValue == 1 || radioValue == 2) {
      form.setFieldsValue({
        FromDate: "",
        ToDate: "",
        FromTime: "",
        ToTime: "",
      });
    }
  };

  const ClassroomTopicValidation = (e) => {
    // if(!commonLib.isAlphabetRegex(e.target.value)){
    //   form.setFieldsValue({
    //     "topic" : ""
    //   })
    // }
    konsole.log("TopicValidation", e.target.value);
  };
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please Select Date!",
      },
    ],
  };
  const changeData = (date, dateString) => {
    konsole.log("Getting Date", date);
    konsole.log("Getting DateString", dateString);
    setClassroomDate(dateString);
  };
  const disabledforfromDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };
  const GetVEnuueDataa = (a, key) => {
    konsole.log("editFuncDataGetVEnuueDataa", key);
    if (key !== undefined && key !== null && key !== "") {
      let filterdata = venueData.filter((items) => {
        return items.venueId == key.key;
      });
      konsole.log("KeyFilterData", filterdata);

      form.setFieldsValue({
        Venue: filterdata[0].venueText,
      });
      konsole.log("FilterData", filterdata[0].addressId);
      getAddressByAddressId(filterdata[0].addressId);
      setAddressId(filterdata[0].addressId);
    } else {
      let filterdata = venueData.filter((items) => {
        return items.venueText == a;
      });
      konsole.log("aFilterData", filterdata);
      getAddressByAddressId(filterdata[0].addressId);
      setAddressId(filterdata[0].addressId);

      // konsole.log("editFuncDataGetVEnuueDataa", a)
    }
  };

  const showUserModal = () => {
    let GetVenueField = form.getFieldValue().Venue;
    konsole.log(GetVenueField, "GetVenueField");
    setVenueSave(GetVenueField);
    setVisible(true);
  };
  const hideUserModal = () => {
    setVisible(false);
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      setFile(info.file);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setClsthumbnailurl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  // const onChange = (date, dateString) => {
  //   if (radioValue == null) {
  //     setweekErr(true);
  //     form.setFieldsValue({
  //       FromDate: "",
  //       ToDate: "",
  //       FromTime: "",
  //       ToTime: "",
  //     });
  //   } else {
  //     setweekErr(false);
  //   }
  // };

  const BackSeminarFile = () => {
    setIncStepperSteps(0);
  };
  const PreviemPage = () => {
    // setIncStepperSteps(1);
    onFinish();
  };
  const PublishPage = () => {
    // setIncStepperSteps(2);
  };
  const disabledDate = (current) => {
    if (radioValue == 2) {
      const date = new Date(current);
      const start = date.getDay() === 6;
      const start2 = date.getDay() === 0;
      const final = start + start2;
      return final;
    } else if (radioValue == 1) {
      const date = new Date(current);
      const start = date.getDay() === 6;
      const start2 = date.getDay() === 0;
      const final = start + start2;
      return !final;
    } else {
    }
  };

  const disabledToDate = (current) => {
    if (radioValue == 1) {
      const date = new Date(current);
      const start = date.getDay() === 6;
      const start2 = date.getDay() === 0;
      const final = start + start2;
      return !final;
    } else {
    }
  };
  const defaultvalueTo = () => {
    if (radioValue == 1) {
      //  { let fromDate = form.getFieldValue().FromDate;
      //   // let fromDate = FromDatevalue;
      //     var result = new Date(fromDate);
      //     result.setDate(result.getDate() + 5);
      //     return result;
    }

    if (radioValue == 2) {
      const date = new Date(FromDatevalue);
      date.setDate(date.getDate() + 4);

      konsole.log("date", date);
      // setAdd5Days(date)
      return date;
    } else {
    }
  };

  //text file upload api
  const send = () => {
    let loggedUser = sessionStorage.getItem("loggedUserId");
    konsole.log("resp", clsthumbnailurl);
    setLoggedId(loggedUser);

    fileupload(loggedUser);
  };

  //fileupload
  const fileupload = (createdByuserId, datauserid) => {
    const fileuploadCallApi = Services.postfileuploadspeaker(
      file,
      upsertedBy,
      createdByuserId,
      fileTypeId,
      fileCategoryId,
      fileStatusId
    );
    fileuploadCallApi
      .then((res) => {
        konsole.log("responsefileupload", res);
        // setspeakerImageId(res.data.data.fileId)
        konsole.log("response", res.data.data.fileId);
        // postspekaeruser(res.data.data.fileId,datauserid)
      })
      .catch((error) => {
        konsole.error("responseerrrr", error);
      });
  };

  // const userFileUpload = () =>{
  //   const userFileUploadApi = Services.postuploadUserDocumantapi(file, upsertedBy, createdByuserId, fileTypeId, fileCategoryId, fileStatusId)
  //   userFileUploadApi.then((res)=>{
  //     konsole.log("res",res)
  //   }).catch((err)=>{
  //     konsole.log("err",err)
  //   })

  // }

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === "image/png";

      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
        return;
      }

      return false;
    },

    onChange(info) {
      konsole.log("fileList", info);
      konsole.log("fileListFile", info.file);
      setFile(info.file);

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      }
    },
  };

  const onChangePhone = (value) => {
    form.setFieldsValue({
      Contact: value,
    });
  };

  const SepkaerListOptionApi = () => {
    let subtenantID = parseInt(sessionStorage.getItem('subtenantId'))
    const Details = Services.postSeminarDetailsList(true,subtenantID);
    Details.then((response) => {
      konsole.log("POST_GET", response);
      konsole.log("Getted", response.data.data.speakers);
      setuserDetails(response.data.data.speakers);

      if (
        editClassroom !== null &&
        editClassroom !== undefined &&
        editClassroom !== ""
      ) {
        let resultfilter = response.data.data.speakers.filter(function (items) {
          return (
            items.speakerUserId == editClassroom?.speakers[0]?.speakerUserId
          );
        });
        konsole.log("resultfilter", resultfilter);

        resultfilter.map((items) => {

          setImgLink(items.speakerProfileUrl);
          setImageAlt(items.speakerProfileUrl);
          setSpeakerId(items.speakerUserId);
          setSelectSpeakerName(items.speakerFullName);
          setSpeakerProfile(items.speakerProfile);
        });
      }

      // if (
      //   speakerData !== null &&
      //   speakerData !== undefined &&
      //   speakerData !== ""
      // ) {
      //   let resultfilter = response.data.data.speakers.filter(function (items) {
      //     return (
      //       items.speakerUserId ==
      //       speakerData?.data?.data.speakers[0].speakerUserId
      //     );
      //   });
      //   konsole.log("resultfilternew", resultfilter);

      //   resultfilter.map((items) => {
      //     setImgLink(items.speakerProfileUrl);
      //     setImageAlt(items.speakerProfileUrl);
      //     setSpeakerId(items.speakerUserId);
      //     setSelectSpeakerName(items.speakerFullName);
      //     setSpeakerProfile(items.speakerProfile);
      //   });
      // }
    }).catch((error) => {
      konsole.log("ErrorSpeakerList", error);
    });
  };

  const selectspeakerfilter = (value, key) => {
    let selectuserId = key.key;
    konsole.log("findKeyData", key);
    let resultfilter = userDetails.filter(function (items) {
      return items.userId == selectuserId;
    });
    konsole.log("resultfilter", resultfilter);

    resultfilter.map((items) => {
      konsole.log("SpeakerListed", items);
      // setVedio(items.videoLinkPath);

      if (items?.speakerImageId !== null && items?.speakerImageId !== undefined && items?.speakerImageId !== "") {
        setImgLink(items.speakerProfileUrl)
      } else {
        setImgLink("/images/ApeakerNoImage1.png")
      }
      // setImgLink(items.speakerProfileUrl);
      setImageAlt(items.speakerProfileUrl);
      setSpeakerId(items.speakerUserId);
      setSelectSpeakerName(items.speakerFullName);
      setSpeakerProfile(items.speakerProfile);

      konsole.log("SpeakerId", items.speakerUserId);
      konsole.log("speakerProfile1111", items.speakerProfile);
      // setSpeakerDescription(items.speakerProfile)
      // form.setFieldsValue({
      //   'Name_of_Speaker': speakerObjectData.fullNameSpeaker,
      //   'VideoLinkOfSpeaker': items.videoLinkPath,
      //   'DescriptionSpeaker': items.speakerProfile,

      // })
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showModalAddPerson = () => {
    setModalAddPerson(true);
  };
  const CloseModalAddPerson = () => {
    setModalAddPerson(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const getinputdata = (e) => {
  //   setstoredVal(e)
  //   setSendSpeakerStoredVal(e)

  // }

  const ImageAlt = (checkImgUrl) => {
    if (checkImgUrl == "") {
      setImageAlt("No Image Found!");
    }
    // else if(checkImgUrl == "https://devintakeformdocs.blob.core.windows.net/paralegal/1.png"){
    //   setImageAlt("No Image Found!")
    // }
  };
  const getinputdata = (e) => {

    setstoredVal(e);
  };
  const getinputdataCo = (e) => {
    setCoordinatorPerson(e);
  };
  const speakerAbout = (e) => {
    setSpeakerProfile(e);
  };

  const datatospeakercls = () => {
    konsole.log(speakerData, "speakerData");
    if (speakerData != "" || speakerData != null) {
      form.setFieldsValue({
        Name_of_Speaker: speakerData[0]?.data?.data.speakers[0].speakerFullName,
        DescriptionSpeaker: speakerData[0]?.data?.data.speakers[0].speakerProfile,
        "speaker-Img": speakerData[0]?.data?.data.speakers[0].speakerProfileUrl,
      });
      setImgLink(speakerData[0]?.data?.data.speakers[0].speakerProfileUrl);
      setSpeakerProfile(speakerData[0]?.data?.data.speakers[0].speakerProfile);
    }
    // SepkaerListOptionApi();
  };
  const coordinatorsetcls = () => {
    konsole.log(Cornatorperson, coordinatorNum, "num");
    if (Cornatorperson != "" || Cornatorperson != null) {
      form.setFieldsValue({
        // Name_of_Contact_Person:
        //   Cornatorperson[1].data.data.firstName +
        //   " " +
        //   Cornatorperson[1].data.data.lastName,
        Coordinator_Contact: Cornatorperson[0] ? Cornatorperson[0] : coordinatorNum,
        Coordinator_Email: Cornatorperson[1].data.data.emailId,
      });
      setPrimaryMobile(Cornatorperson[0] ? Cornatorperson[0] : coordinatorNum);
    }

    userListApiCall(subtenantValueId);
  };

  useEffect(() => {
    if (
      editClassroom !== undefined &&
      editClassroom !== null &&
      editClassroom !== ""
    ) {
      SepkaerListOptionApi();
      userListApiCall();
      konsole.log("editClassroom", editClassroom);
      let ToDate = editClassroom.remarks.slice(0, 11);
      let ToTime = editClassroom.remarks.slice(11, 31);

      form.setFieldsValue({
        radioSelect: parseInt(editClassroom?.seminarTypeId),
        topic: editClassroom?.seminarTopic,
        "date-picker": moment(editClassroom?.seminarDate),
        "time-picker": moment(editClassroom?.seminarTime, "HH:mm:ss"),
        No_of_Seats: editClassroom?.noOfSeats,
        Venue: editClassroom?.seminarVenue,
        Maps_link: editClassroom?.mapsLink,
        VideoLink: editClassroom?.videoLink,
        Discription: editClassroom?.description,
        FromDate: moment(editClassroom?.seminarDate),
        ToDate: moment(ToDate),
        FromTime: moment(editClassroom?.seminarTime, "HH:mm:ss"),
        ToTime: moment(ToTime, "HH:mm:ss"),
        Name_of_Speaker:
          editClassroom?.speakers[0]?.fName +
          " " +
          editClassroom?.speakers[0]?.lName,
        DescriptionSpeaker: editClassroom?.speakers[0]?.speakerProfile,
        "speaker-Img": editClassroom?.speakers[0]?.speakerProfileUrl,
        radioSelectWeek: editClassroom?.remarks.slice(20, 40) == 1 ? 1 : 2,

        //  "VideoLinkOfSpeaker": editFuncData?.speakers[0]?.videoLinkPath,
        //  "DescriptionSpeaker" : editFuncData?.speakers[0]?.speakerProfile,
        //  "seminarOrganizingPartner": editFuncData?.speakers[0]?.  ,
        // "Name_of_Contact_Person" : editFuncData?.coordinators[0]?.coordinatorUserId,
        // "Coordinator_Contact" : editFuncData?.coordinators[0]?.   ,
        // "Coordinator_Email" : editFuncData?.coordinators[0]?.  ,
        // radioButton : PaidFreeDAta,
        Price: editClassroom?.isSeminarPaid ? editClassroom?.seminarFee : "",
        Webinar:
          editClassroom?.seminarTypeId == 47 ? editClassroom?.webinarLink : "",
      });
      // selectspeakerfilter(editClassroom?.speakers[0]?.speakerUserId,editClassroom?.speakers[0]?.speakerUserId )
      // seminarSpeakerId speakerUserId
      // editSpeaker(editClassroom?.speakers[0]?.speakerUserId)
      setRadioValueNo(editClassroom?.seminarTypeId);
      setRadioValue(editClassroom?.remarks.slice(20, 40));
      // setRadioValueNo(editClassroom?.seminarTypeId)
      // // setRadiovalue(editClassroom?.seminarType)
      // setSeminarDate(editClassroom?.seminarDate)
      // setNOSeats(editClassroom?.noOfSeats)
      // setVedioLink(editClassroom?.videoLink)
      // setDescription(editClassroom?.description)
      // setMapLink(editClassroom?.mapsLink)
      // setSubtenantIdToApi(editClassroom?.subtenantId)
      // setCoordinatorId( editClassroom?.coordinators[0]?.seminarCoordinatorId)

      // editCoordinator(editClassroom?.coordinators[0]?.seminarCoordinatorId,editClassroom?.coordinators[0]?.seminarCoordinatorId)
      if (editClassroom?.seminarTypeId == 47) {
        // setWebinarLink(editClassroom?.webinarLink)
      } else {
        // setWebinarLink("")
      }
      //  let timeZone1 = commonLib.timeConvertion(moment(editClassroom?.seminarTime, 'HH:mm:ss')._i)

      //  setSeminarTime(timeZone1)

      if (
        editClassroom?.seminarVenue !== null &&
        editClassroom?.seminarVenue !== undefined &&
        editClassroom?.seminarVenue !== ""
      ) {
        GetVEnuueDataa(editClassroom?.seminarVenue);
        // getAddressByAddressId(editClassroom?.addressId)
      }
      // konsole.log("moment(editFuncData?.seminarTime, 'HH:mm:ss')", commonLib.timeConvertion(moment(editFuncData?.seminarTime, 'HH:mm:ss')._i), "timeZone", seminarTime)
      // konsole.log("editFuncData?.speakers[0]?.VideoLinkOfSpeaker", editFuncData?.speakers[0]?.VideoLinkOfSpeaker)
      // konsole.log("editFuncData?.subtenantId", editFuncData?.subtenantId)
      // timeZone = moment(editFuncData?.seminarTime, 'HH:mm:ss')._i
    }
  }, [editClassroom]);

  if (primaryMobile) {
    let sPhoneNumber = primaryMobile.split("");
    let removeCountryCode = sPhoneNumber.splice(sPhoneNumber.length - 10, 10);
    coordiPersoncontact = removeCountryCode.join("");
  }
  // ..........................................................................

  const multiCoordinatorChange = (e) => {
    konsole.log("multiCoordinatorChangemultiCoordinatorChange", e);
    // form.setFieldsValue({
    //   'Name_of_Contact_Person':e
    // })
    setCoordinatorId(e)

  };


  // ..........................................................................
  // const disabledforfromDate = (current) => {
  //   let customDate = moment().format("YYYY-MM-DD");
  //   return current && current < moment(customDate, "YYYY-MM-DD");
  // };

  //---------------------------------------------------------------------------
  return (
    <>
      <div className="container-fluid ">
        <ListClassroom
          setPublishClassroom={setPublishClassroom}
          publishclassroom={publishclassroom}
        />
      </div>
      <Modal
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add Speaker"
        footer={false}
        maskClosable={false}
      >
        <AddEditClsSpeaker
          form={form}
          firstName={storedVal}
          onCancel={handleCancel}
          SepkaerListOptionApi={SepkaerListOptionApi}
          speakerData={speakerData}
          datatospeakercls={datatospeakercls}
        />
      </Modal>
      {konsole.log("storedVal", storedVal)}
      <Modal
        centered
        visible={modalAddPerson}
        onOk={handleOk}
        onCancel={CloseModalAddPerson}
        okText="Add Person"
        footer={false}
        maskClosable={false}
      >
        <AddEditCoornatorperson
          firstName={coordinatorPerson}
          onCancel={CloseModalAddPerson}
          subtenantValueId={subtenantValueId}
          valueSelectName={valueSelectName}
          Cornatorperson={Cornatorperson}
          coordinatorsetcls={coordinatorsetcls}
          setCoordinatorNum={setCoordinatorNum}
        />
      </Modal>

      {publishclassroom === true ? (
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ remember: true }}
          scrollToFirstError
        >
          <div
            className="mt-2 mb-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <div style={{ width: 810 }}></div> */}
            <Steps
              labelPlacement="vertical"
              current={IncStepperSteps}
              size="small"
            >
              <Step
                title="Create"
                onClick={BackSeminarFile}
                style={{ cursor: "pointer" }}
              />
              <Step
                title="Preview"
                onClick={PreviemPage}
                style={{ cursor: "pointer" }}
              />

              <Step
                title=" Publish"
                onClick={PublishPage}
                style={{ cursor: "pointer" }}
              />
              {/* <Step title=" Publish" onClick={Congratulation} style={{ cursor: "pointer" }} /> */}
            </Steps>
          </div>
          {IncStepperSteps === 0 ? (
            <div className=" pb-2" style={{
              maxHeight: 'calc(100vh - 150px)',
              overflowY: 'auto',
              // border:"1px solid red"
            }} >
              <div
                className=" mt-2 ps-2 pe-2 me-1 rounded "
                style={{ border: "1px dashed black" }}
              >
                <Row className="d-flex justify-content-between" >
                  {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                  <div className=" fs-4 fw-bold seminartxt">
                    {/* Class Room Details */}
                    Workshop Details
                  </div>
                  <div className="mt-1">
                    <Form.Item
                      name="radioSelect"
                      rules={[
                        {
                          required: (radioValueDirect == "") ? true : false,
                          message: "Please choose Workshop type!",
                        },
                      ]}
                    >
                      {/* <Radio.Group name="radiogroup" onChange={radioDirectbtn}> */}
                      {dataGet.map((items, value) => {
                        return (
                          <Radio
                            value={items.value}
                            style={{ fontSize: "20px" }}
                            onChange={() => radioDirectbtn(items)}
                            checked={radioValueNo == items.value ? true : false}
                            key={items.label}
                            className="gap-2 m-0 p-0"
                          >
                            {" "}
                            {items.label}
                          </Radio>
                        );
                      })}
                      {/* </Radio.Group> */}
                    </Form.Item>
                  </div>
                </Row>
                <h6>Workshop Name* </h6>
                <Form.Item
                  name="topic"
                  className="fs-2"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Workshop Name ",
                    },
                  ]}
                >
                  <Input
                    placeholder="Workshop Name "
                    style={{ color: "rgba(215, 155, 72, 1)" }}
                    className="fw-bold fs-2 border-2"
                    onChange={ClassroomTopicValidation} onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                  />
                </Form.Item>
                {/* <Row className="mt-2  d-flex justify-content-between">
                <Col>
                  <Form.Item
                    name="date-picker"
                    label="Date"
                    required
                    {...config}
                  >
                    <DatePicker
                      className="border-2 "
                      format="MM/DD/YYYY"
                      inputReadOnly={true}
                      onChange={changeData}
                      style={{ width: "220px" }}
                      disabledDate={disabledforfromDate}
                    />
                  </Form.Item>
                </Col>
              </Row> */}
                <Row className="mt-2  d-flex justify-content-between">
                  {radioValueNo == null || radioValueNo == 46 ? (
                    <Col xs={24} lg={24}>
                      <h6>Venue </h6>
                      <Form.Item
                        name="Venue"
                        rules={[
                          {
                            // required: true,
                            // message: "Please Enter Venue",
                          },
                        ]}
                      >
                        {
                          <AutoComplete
                            // value={venue}
                            onSelect={GetVEnuueDataa}
                            // onSearch={(value) => {
                            //   konsole.log("value search", value)
                            // }
                            // }

                            className="fs-5 border-2"
                            notFoundContent={
                              <>
                                <Button
                                  // type="primary"
                                  style={{ float: "right",background:"#720c20",color:"white",border:"none" }}
                                  onClick={showUserModal}
                                >
                                  Add
                                </Button>
                              </>
                            }
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {venueData.map((items, index) => {
                              return (
                                <Option
                                  value={items.venueText}
                                  key={items.venueId}
                                  style={{ height: "200" }}
                                >
                                  {" "}
                                  {items.venueText}{" "}
                                </Option>
                              );
                            })}
                          </AutoComplete>
                        }
                      </Form.Item>
                    </Col>
                  ) : radioValueNo == 47 ? (
                    <Col xs={24} lg={24}>
                      <h6>Webinar* </h6>
                      <Form.Item
                        name="Webinar"
                        rules={[
                          // {pattern: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')
                          // , message: 'Please enter valid url with http!'},
                          {
                            required: true,
                            message: "Webinar Link is required!!",
                          },
                        ]}
                      >
                        <Input placeholder="https://" />
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs={24} lg={24}>
                    <h6>Maps link (if any)</h6>
                    <Form.Item name="Maps_link">
                      <Input placeholder="Maps link" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={24}>
                    <h6>Description* </h6>
                    <Form.Item
                      name="Discription"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Description",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Description" />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={24} xs={10}>
                    <h6>Thumbnail for Classroom</h6>
                    <Form.Item name="uploadThumbnail">
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader "
                        style={{ border: "2px dashed black" }}
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        mt-2
                      >
                        {clsthumbnailurl ? (
                          <img
                            src={clsthumbnailurl}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>

                     
                    </Form.Item>
                    <br />
                  </Col> */}
                </Row>
              </div>
              <div
                className="mt-2 ps-2 pe-2 me-1 rounded"
                style={{ border: "1px dashed black" }}
              >
                {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                <Col>
                  <div className=" fs-4 fw-bold seminartxt">
                    Workshop Schedule
                  </div>

                </Col>
                {/* <Col>
                  <div className="mt-2">
                    <Form.Item
                      name="radioSelectWeek"
                      rules={[
                        {
                          required: true,
                          message: "Please choose classroom type!",
                        },
                      ]}
                    >
                      <Radio.Group name="radiogroup" onChange={radioonchange}>
                        <Radio value={1} key={1} style={{ fontSize: "20px" }}>
                          Weekend
                        </Radio>
                        <Radio value={2} key={2} style={{ fontSize: "20px" }}>
                          {" "}
                          WeekDay
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    {weekErr == true ? (
                      <p className="weekErr">Please Select One</p>
                    ) : null}
                  </div>
                </Col> */}
                <Row className="d-flex justify-content-between">
                  <Col lg={6} >
                    <h6>From Date*</h6>
                    <Form.Item
                      name="FromDate"
                      rules={[
                        {
                          required: true,
                          message: "Please choose From Date ",
                        },
                      ]}
                    >
                      <DatePicker
                        locale={locale}
                        style={{ width: "97%", }}

                        format="MM/DD/YYYY"
                        // onChange={(value) => {
                        //   onChange();
                        //   // setFromDate(value);
                        //   FromDatevalue = value;
                        // }}
                        disabledDate={disabledforfromDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} >
                    <h6 style={{marginLeft:"2px"}}>To Date*</h6>
                    <Form.Item
                      name="ToDate"
                      rules={[
                        {
                          required: true,
                          message: "Please choose To Date ",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "97%",marginLeft:"2px" }}
                        format="MM/DD/YYYY"
                        // onChange={onChange}
                        defaultValue={defaultvalueTo}
                        disabledDate={disabledforfromDate}
                      // disabledDate={disabledDate}

                      // disabled
                      />
                    </Form.Item>

                  </Col>
                  <Col lg={6}>
                    <h6 style={{marginLeft:"5px"}}>From Time*</h6>

                    <Form.Item
                      name="FromTime"
                      rules={[
                        {
                          required: true,
                          message: "Please choose From Time ",
                        },
                      ]}
                    >
                      <TimePicker
                        format="h:mm a"
                        style={{ width: "97%",marginLeft:"5px" }}


                      // onChange={onChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <h6 style={{marginLeft:"8px"}}>To Time*</h6>
                    <Form.Item
                      name="ToTime"
                      rules={[
                        {
                          required: true,
                          message: "Please choose To Time ",
                        },
                      ]}
                    >
                      <TimePicker
                        style={{ width: "97%",marginLeft:"8px" }}

                        format="h:mm a"
                      // onChange={onChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div
                className="mt-2 ps-2 pe-2 me-1 rounded"
                style={{ border: "1px dashed black" }}
              >
                <Row className=" d-flex justify-content-between">
                  {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                  <div className=" fs-4 fw-bold seminartxt">
                    Speaker Information
                  </div>
                </Row>
                <Row>
                  <Col lg={5} xs={5} >
                    <Form.Item name="speaker-Img">
                      <h6>Image of Speaker</h6>
                      <div
                        className=" d-flex align-items-center imgDivOfSpeaker "
                        style={{
                          height: "192px",
                          width: "147px",
                          border: "1px dashed black",
                          padding: "2px"
                        }}
                      >
                        <img
                          src={imgLink || imageAlt}
                          alt={imageAlt}
                          className=" img-fluid"
                          style={{ height: "188px" }}
                        />
                      </div>
                      {/* <button onClick={send} >send</button> */}
                    </Form.Item>
                  </Col>
                  <Col lg={19} xs={24}>
                    <h6>Name of Speaker *</h6>
                    <Form.Item
                      name="Name_of_Speaker"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Name of Speaker",
                        },
                      ]}
                    >
                      <AutoComplete
                        onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                        onChange={getinputdata}
                        onSelect={selectspeakerfilter}
                        notFoundContent={
                          <>
                            <Button
                              // type="primary"

                              style={{ float: "right", background: "#720c20", border: "none", borderRadius: "5px", color: "white" }}
                              onClick={showModal}
                            >
                              Add Speaker
                            </Button>
                            <p
                              style={{ float: "left" }}
                              className="text-danger fw-bold"
                            >
                              {" "}
                              Speaker Not Found
                            </p>
                          </>
                        }
                        placeholder="Select Speaker Name "
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        {userDetails.map((items, index) => {
                          return (
                            <Option
                              value={items.speakerFullName}
                              key={items.userId}
                            >
                              {" "}
                              {items.speakerFullName}{" "}
                            </Option>
                          );
                        })}
                      </AutoComplete>
                    </Form.Item>
                    <h6>About Speaker *</h6>
                    <Form.Item
                      name="DescriptionSpeaker"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please Enter About Speaker",
                      //   },
                      // ]}
                      style={{ border: "1px solid #d9d9d9", borderRadius: "4px" }}
                    >
                      <Input.Group>
                        <Input.TextArea
                          placeholder="About Speaker *"
                          id="WorkshopAboutSpeaker"
                          value={speakerprofile}
                          prefix={speakerprofile}
                          rows={5}
                          disabled={true}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div
                className="mt-2 ps-2 pe-2 me-1 rounded"
                style={{ border: "1px dashed black" }}
              >
                <Row className="d-flex justify-content-between">
                  <Col lg={16} xs={24} className="pe-2">
                    <Row className=" d-flex justify-content-between ">
                      {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                      <div className=" fs-4 fw-bold seminartxt">
                        Organizer Information
                      </div>
                    </Row>
                    <h6>Workshop Organizing Firm *</h6>

                    <Form.Item
                      name="Class_Room_Organizing_Partner"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Workshop Organizing Firm",
                        },
                      ]}
                    >
                      <Select

                        onChange={onGenderChange}
                        disabled
                        style={{ width: "" }}
                      >
                        {postSubtenantDetailResp.map((items, index) => {
                          return (
                            <Option
                              value={items.subtenantId}
                              key={items.subtenantName}
                            >
                              {items.subtenantName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <h6>Name of Contact Person *</h6>

                    <Form.Item
                      name="Name_of_Contact_Person"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Name of Contact Person",
                        }
                      ]}

                    >

                      <Select

                        mode='multiple'
                        showSearch
                        maxTagCount={"responsive"}
                        onChange={multiCoordinatorChange}
                        optionFilterProp="children"

                        dropdownRender={(menu) => (

                          <div >
                            {menu}
                            <Divider style={{
                              margin: "8px 0",
                            }} />
                            <div className='d-flex justify-content-between'>

                              <div className='ms-2'>
                                <Input className='d-none' placeholder='Search Coordinator' />
                              </div>
                              <div className='me-2'>
                                <Button
                                  style={{ background: "#720c20", border: "none", borderRadius: "5px", color: "white" }}

                                  onClick={showModalAddPerson}>Add Coordinator</Button>
                              </div>
                            </div>
                          </div>
                        )}

                      >

                        {coOrdinatorList.map((items, index) =>

                          <Select.Option value={items.userId}>
                            {items.fullName}
                          </Select.Option>

                        )}


                      </Select>


                    </Form.Item>
                    {/* <Row className="d-flex justify-content-between">
                      <Col lg={11} xs={24}>
                        <h6>Contact *</h6>

                        <Form.Item
                          name="Coordinator_Contact"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Your Contact",
                            },
                          ]}
                        >
                          <Input
                            // defaultValue={primaryMobile}
                            prefix={primaryMobile}
                            onValueChange={onChangePhone}
                          />
                          {konsole.log(coordiPersoncontact)}
                          <InputMask
                            value={coordiPersoncontact}
                            onValueChange={(e) => {
                              setPrimaryMobile(e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={11} xs={24}>
                        <h6>Email *</h6>
                        <Form.Item
                          name="Coordinator_Email"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Your Email",
                            },
                          ]}
                        >
                          <Input
                            defaultValue={primaryEmail}
                            prefix={primaryEmail}
                          />
                        </Form.Item>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col lg={8} className="border-left">
                    <div className="borderbottom ps-2">
                      <Row className=" d-flex justify-content-between">
                        {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                        <div className=" fs-4 fw-bold seminartxt">
                          Workshop Strength
                        </div>
                      </Row>
                      <h6>No of Seats*</h6>
                      <Form.Item
                        name="No_of_Seats"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter No of Seats",
                          },
                        ]}
                      >
                        <Input
                          placeholder="No of Seats"
                          suffix={<img src="./images/onOfroom.png" />}
                        />
                      </Form.Item>
                    </div>
                    <div className="ps-2">
                      <Row className=" d-flex justify-content-between">
                        {/* <Col className='col-sm-8 fs-4 fw-bold seminartxt'>Seminar Details</Col> */}
                        <div className=" fs-4 fw-bold seminartxt">
                          Workshop Fare
                        </div>
                      </Row>
                      <h6>Price<sup>*</sup></h6>
                      <Form.Item
                        name="Price"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Price",
                          },
                        ]}
                      >
                        <Input prefix="$" suffix="/person" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <GlobalData.Provider value={{}}>
                {/* <SpeakerInformation statuSpeakerId={setIdSpeaker} formSeminar={form} setImgAgain={previewData.getImgUrl || mataData.getImgUrl} /> */}

                {/* <OrganizerInformation form={form} /> */}

                {/* <AddEditVenueCls form={form} visible={visible}
                //  getAddressByAddressId={getAddressByAddressId} 
                 onCancel={hideUserModal} 
                //  status={setVenue} 
                //  statusId={setVenueAddressId} 
                //  RecallgetvenueDataAPI={getvenueData}
                  /> */}
              </GlobalData.Provider>
              <AddEditVenueCls
                form={form}
                visible={visible}
                onCancel={hideUserModal}
                VenueSave={form.getFieldsValue().Venue}
                getAddressByAddressId={getAddressByAddressId}
                status={setVenue}
                statusId={setVenueAddressId}
                RecallgetvenueDataAPI={getvenueData}
                venueSave={venueSave}
              />
              <Form.Item
              // wrapperCol={{
              //   offset: 10,
              //   span: 16,
              // }}
              >
                <div className=" d-flex justify-content-center">
                  <button
                    className="seminarbtn mt-4  mb-4 text-white"
                    type="submit"
                  >
                    Preview
                  </button>
                </div>
              </Form.Item>
            </div>
          ) : IncStepperSteps === 1 ? (
            <div>
              <Preview
                classroomData={classroomData}
                clsthumbnailurl={clsthumbnailurl}
                speakerImg={imgLink}
                setIncStepperSteps={setIncStepperSteps}
                subtenantValueId={subtenantValueId}
                speakerId={speakerId}
                coordinatorId={coordinatorId}
                loggedId={loggedId}
                venueaddressId={addressId}
                speakerprofile={speakerprofile}
                editClassroom={editClassroom}
                radioValueNo={radioValueNo}
                location={location}
              />
            </div>
          ) : IncStepperSteps === 2 ? (
            <>
              <Congratulations />{" "}
            </>
          ) : (
            <></>
          )}
        </Form>
      ) : (
        <PublishedCls
          setIncStepperSteps={setIncStepperSteps}
          setPublishClassroom={setPublishClassroom}
          setEditClassroom={setEditClassroom}
        />
      )}
    </>
  );
};

export default Classroom;
import React from "react";
import {Col,Row} from "react-bootstrap"
import Tooltip from "../Tooltip";

function AddProductsGroups({productAndProductGrpList,checkAddedProduct,cardsInnerBorderLine,selectProduct,setHideList,hideList,heading}){
    return(
        <Row className='mt-3 ms-2'>
        <Col lg="12" md="12" sm="12">
            <div style={{fontSize:"12px",fontWeight:"bold"}}>{`${heading == "Package" ? "Added products/groups" : "Added products"} (${checkAddedProduct?.length}):`}</div>
            <div className="partnerData mt-2" >
            {productAndProductGrpList?.length && productAndProductGrpList.map((item,ind)=>
                item.checked == true && (
                    <Tooltip text = {`${item?.serviceName || item?.serviceGrpName}`}>
                    <div className="addedProductPillRed d-flex justify-content-between align-items-center px-2 mb-2 cursor-pointer">
                        <div>{(item.subtenantId == 3 && item?.roleId == null) && (item?.serviceName || item?.serviceGrpName) ? 
                        <img src='./icons/AOLogo.svg' style={{ height: "18px", width: "18px" }} alt="AO Logo" /> :
                         item?.serviceName ? <img src='./icons/redProductIcon.svg' style={{height:"18px",width:"18px"}}/> : 
                         <img src='./images/redProductGroup.svg' style={{height:"18px",width:"18px"}}/>}</div>
                    <div  className= "addedProductDotShow ms-1" style={{fontSize:"15px"}}>{`${item?.serviceName || item?.serviceGrpName}`}</div>
                    <div style={{fontSize:"17px"}} className='mb-1' onClick={()=>selectProduct(item,ind)}>x</div>
                </div>
                    </Tooltip>
                )             
            )}
            {hideList == false && <div className="addNewProductBtn d-flex justify-content-center align-items-center mb-2 cursor-pointer" onClick={()=>setHideList(true)}>
                <div style={{fontSize:"17px"}} className='mb-1'><img src="./icons/add.svg"/></div>
                <div  className= "addedProductDotShow ms-2" style={{fontSize:"15px"}}>Add New</div>
            </div>}
            </div>
            </Col>
            {hideList && cardsInnerBorderLine("afterSelectProduct")}
            </Row>
    )
}
export default AddProductsGroups;
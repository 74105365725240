import React, { useState } from 'react';
import moment from 'moment';
import "../Styles/subtenant.css";
import { message } from 'antd';
import { isNullUndefine } from '../control/Constant';

const DatePickerComp = (props) => {
    let value = props.value ? moment(props.value).format("DD-MM-YYYY") : "";
    const handleDate = (date) => {
      const selectedDate = new Date(date.target.value);
      const currentDate = new Date();
  
      if (selectedDate > currentDate) {
          const currentDateValue = getCurrentDate();
          props?.handleDateData(currentDateValue); // Update parent component state with current date
          date.target.value = currentDateValue; // Reset input field to current date
          // Optionally, you can prevent further execution by returning from the function
          return;
      }
  
      // Proceed with handling the valid date
      props?.handleDateData(date.target.value);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];
  
    return !isNullUndefine(currentDate) ? currentDate : null;
  };
  
  const isValidDate = (dateString) => {
    // Define the range of acceptable dates
    const minDate = moment().subtract(200, "years");
    const maxDate = moment(); // Current date
    
  
    const date = moment(dateString.target.value, "YYYY-MM-DD", true); // Parse dateString as a date in YYYY-MM-DD format

    return date.isValid() && date >= minDate && date <= maxDate;
};


 const handleValidate =(date)=>{
    if(!isValidDate(date)){
        message.warn('Please enter a valid date.');
       props?.handleDateData("");
    }
 }   

  

    const inputStyle = {
        padding: "0.3rem",
        color: props?.dateSatate === null ||  props?.dateSatate === undefined || props?.dateSatate === "" ? "darkgray" : "black"  // Set color based on value condition
      };
    return (
        <>
            <div className="position-relative w-100">
              <input className={`border datePeaker ${(value == "") ? 'inptDateType' : ""} w-100`} max={getCurrentDate()} 
                    type={"date"} value={props?.dateSatate} placeholder={`${(value == "") ? `${props.placeholderText}` : ""}`}onChange={handleDate}style={inputStyle}onBlur={handleValidate} />
                    </div>
        </>
    )
}

export default DatePickerComp
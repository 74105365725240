import React from "react";


const DashBoard = () =>{
    return(
        <>
        <h1 className="">DashBoard</h1>
        </>
    )
}

export default DashBoard;
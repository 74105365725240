// import {UserAddOutlined} from '@ant-design/icons';
import {
  Modal,
  Form,
  Upload,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Card,
  Input,
  DatePicker,
  Typography,
  Item,
  message,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

import config from "../../config.json";
import "../../Styles/subtenant.css";
import PrimaryContact from "./PrimaryContact";
import Services from "../../network/Services";
import { useRef } from "react";
import FormItem from "antd/lib/form/FormItem";
import Address from "../Address";
import commonLib from "../../control/commonLib";
import EditContactInfo from "../FirmPartner/EditContactInfo";
import ContactInfo from "./ContactInfo";
import konsole from "../../network/konsole";
import PersonalInfo from "./PersonalInfo";
import InputMask from "../PhoneInputMask";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { UserContext } from "../../App";


const onSearch = (value) => {
  konsole.log("search:", value);
};

const AddSubtenantUser = ({
  visible,
  onCancel,
  getUserListApi,
  selectedSubtenantUser,
  subtenantValueSelected
}) => {
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Title } = Typography;
  const [contactType, setContactType] = useState([]);
  const [countrycode, setcountryCode] = useState([]);
  const [subtenantdata, setsubtenantdata] = useState([]);
  const [subtenantValue, setSubtenantValue] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const { setLoader } = useContext(UserContext);
  const [rolesid, setROlesid] = useState([]);
  const [showDeleteModalStatus, setShowDeleteModalStatus] = useState(false);
  const [deleteModalStatus, setDeleteModalStatus] = useState();
  const [optionsBackUp, setOptionsBackUp] = useState([])
  const [getRoleIdValue, setGetRoleIdValue] = useState([])
  const [multiRoleIdData, setMultiRoleIdData] = useState([])
  const [options, setOptions] = useState([]);
  const [speakerDetailsData, setSpeakerDetailsData] = useState()

  let arr2 = ["1","2","5", "9", "10", "12","17","18","19","20"];
    const createdBy = null;
  const isActive = true;

  useEffect(() => {
    let roleId = commonLib.getObjFromStorage('stateObj')?.roleId
    let subtenant = sessionStorage.getItem('subtenantId')
    let arr2 = ["1","4", "2","5", "9", "10", "12","17","18","19","20"];
    let promise = Services.getAllUserRoleIdDetails();
    promise
      .then((response) => {
        konsole.log("getAllUserRoleIdDetailsRes", response.data?.data);
        let filterData = response.data?.data.filter(
          (element) => !arr2.includes(element.value)
        );
        konsole.log("filterDatagetAllUserRoleIdDetailsRes", filterData);
        let filterData1 = filterData.filter((x)=>{
          if(x.value == 11){
            x.label = "Administrator"
            
          }
        })
        // let filterData = response.data?.data.filter((data)=>{
        //   return data.value
        // })
        getUserRoleFunction(selectedSubtenantUser?.userRgstrtnId, selectedSubtenantUser?.subtenantId, filterData, roleId, subtenant)
        // setOptions(filterData);
      })
      .catch((error) => {
        konsole.log("getAllUserRoleIdDetailserror", error);
      })
      .finally(() => {
        konsole.log("getAllUserRoleIdDetailsEnd");
      });
  }, []);

  useEffect(() => {
    konsole.log("selectedSubtenantUser", selectedSubtenantUser);
    form.setFieldsValue(selectedSubtenantUser);
    form.setFieldsValue({
      primaryEmail: selectedSubtenantUser.primaryEmail,
      primaryMobile: selectedSubtenantUser.primaryMobile.slice(-10),
      primaryCode: selectedSubtenantUser.primaryMobile.substring(
        0,
        selectedSubtenantUser.primaryMobile.length - 10
      ),
    });
    // getUserRoleFunction(selectedSubtenantUser?.userRgstrtnId, selectedSubtenantUser?.subtenantId)
  }, []);

  useEffect(()=>{
    if(deleteModalStatus == true){
      // let intersectionData = getRoleIdValue.filter(element => !rolesid.includes(element))

      
      putRoleApiData()
      // callAddUserApi(intersectionData);
      
    }else if(deleteModalStatus == false){
      onCancel()
    }
  },[deleteModalStatus])

  konsole.log("deleteModalStatusdeleteModalStatus",deleteModalStatus)


  const getUserRoleFunction = (LoginUserId, SubtenantId, hideData, roleId, subtenant) =>{
    let promise = Services.getUserRoleDetails(LoginUserId, SubtenantId)
    let arr2 = [1, 2,5, 9, 10, 12,17,18,19,20];
    promise.then((response)=>{
      konsole.log("getUserRoleFunctionres",response.data.data)
      setMultiRoleIdData(response.data.data)
      let filterData = response.data.data.map((items)=>{
        return items.roleId
      })
      SepkaerListOptionApi(selectedSubtenantUser.userId,filterData)
      konsole.log("getUserRoleFunctionres",filterData)

      if(filterData.includes(10)){
        
        filterData.splice(filterData.indexOf(10), 1)
        
        konsole.log("indexValueindexValue",filterData)
      }
      
      if(filterData.includes(2)){
        
        filterData.splice(filterData.indexOf(2), 1)
        
        konsole.log("indexValueindexValue",filterData)
      }
      if(filterData.includes(1)){
        
        filterData.splice(filterData.indexOf(1), 1)
        
        konsole.log("indexValueindexValueLast",filterData)
      }

      if(subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant == config.AOADMINSUBTENANTID){
        let filterLegalRoleData = [3,4,10,13,14,15,16]
        konsole.log("filterDatafilterDataffaarrr",filterData)

        for(let i =0; i<filterLegalRoleData.length; i++){
            
            if(filterData.includes(parseInt(filterLegalRoleData[i]))){
              
              filterData.splice(filterData.indexOf(filterLegalRoleData[i]), 1)
              
              konsole.log("indexValueindexValueforlast",filterData)
            }
            
          }




      }

      // ...................................................................................


      let hideArrValue = [3,13,14,15]
    
      let intersection = hideArrValue.filter(x => filterData?.includes(x));
  
      if(intersection.length > 0){
        
        hideArrValue.splice(hideArrValue?.indexOf(intersection[0]), 1)
        
        for(let i = 0; i< hideArrValue.length; i++){
          
          hideData.filter((disableFields)=>{
            if(disableFields.value == hideArrValue[i]){
              disableFields['disabled'] = true
            }
            
          })
        }

        if(subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant == config.AOADMINSUBTENANTID){
          let filterLegalRoleData = [3,13,14,15]

          // for(let i =0; i<filterLegalRoleData.length; i++){
            
          //   if(filterData.includes(filterLegalRoleData[i])){
              
          //     filterData.splice(filterData.indexOf(1), 1)
              
          //     konsole.log("indexValueindexValue",filterData)
          //   }
            
          // }



          // alert("Super Admin")

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
      
  
      
        }
    //     else if(roleId != 4 && subtenant == 3){
          
    //       let filterLegalRoleData = [3,13,14,15]

    //     let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
    //   konsole.log("differencedifference",difference)
    //   setOptions(difference)
    //   setOptionsBackUp(difference)
    // }
    else{
      setOptions(hideData)
      setOptionsBackUp(hideData)

        }










        
        
      }else if(intersection.length <= 0){


        if( roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant ==config.AOADMINSUBTENANTID)){
          // alert("Super Admin")
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
      
  
      
        }
        else if(roleId != 4 && subtenant == config.AOADMINSUBTENANTID){
          
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
        }else{
          setOptions(hideData)
          setOptionsBackUp(hideData)

        }






        // setOptions(hideData)
        // setOptions(hideData)
      }


konsole.log("optionsoptions", hideData)












      // ...................................................................................



      // if(filterData.includes(7)){
        // alert("7")
        // setGetSubtenantRoleId(filterData)
      // }else{
      //   // alert("no 7")
      // }
      
  
      const newData = filterData.filter((ele,index)=>{
            return !arr2.includes(ele)
        

      }
      )
      
     
      form.setFieldsValue({
        'subtenantRole': newData
        
      })
      setGetRoleIdValue(filterData)
      setROlesid(filterData)
      //   setMultiRole(filterData)
    }).catch((error)=>{
      konsole.log("getUserRoleFunctionerror",error)
    }).finally(()=>{
      konsole.log("getUserRoleFunctionEnd")
    })
    
  }
  
  const SepkaerListOptionApi = (userId, roleIdData) => {
    const Details = Services.getPartnerUserSpeakerDetailsList(userId, true);
    Details.then((response) => {
      setSpeakerDetailsData(response.data?.data?.speakers[0])
      
        form.setFieldsValue({
          'speakerLink': response.data?.data?.speakers[0]?.videoLinkPath,
          'speakerDescription': response.data?.data?.speakers[0]?.speakerProfile,
          // 'fileUpload': response.data?.data?.speakers[0]?.speakerProfileUrl
        })
   }).catch((error) => {
      konsole.log("ErrorSpeakerList", error);
    });

  }
  let formData = form.getFieldsValue()




  useEffect(() => {
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    // konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
      konsole.log("ressss1111", res.data.data);
      setsubtenantdata(res.data.data);
    }).catch((err) => {
      konsole.log("err", err);
      // message.error("Enter valid details")
    });
  }, []);

  const handleChange = (value) => {
    konsole.log(`selected ${value}`);
    setSubtenantValue(value);
  };

  useEffect(() => {
    getContactTypecode();
  }, []);

  const getContactTypecode = () => {
    let getContactTypepromise = Services.getContactType();
    getContactTypepromise
      .then((res) => {
        setContactType(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
        // message.error("Enter valid details")
      });
  };

  useEffect(() => {
    const countryCode = Services.getcountrycode();
    countryCode
      .then((response) => {
        setcountryCode(response.data.data);
      })
      .catch((err) => {
        konsole.log("Error", err);
        // message.error("Enter valid details")
      });
  }, []);

  const [deletedRoleName, setDeletedRoleName] = useState("")
  
  const onFinishForm = (data) => {
    let formData = form.getFieldsValue()
    const userId = selectedSubtenantUser.userId;
    let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
    if(rolesid.length > 0){
      let difference;
      if(rolesid.length > getRoleIdValue.length){

        difference = rolesid.filter(x => !getRoleIdValue.includes(x));
      }else{
        difference = getRoleIdValue.filter(x => !rolesid.includes(x));

      }
    if(speakerDetailsData !== null && speakerDetailsData !== undefined && speakerDetailsData !== "" && formData?.subtenantRole?.includes(7)){
     
    
      if(speakerDetailsData?.videoLinkPath !== formData?.speakerLink || speakerDetailsData?.speakerProfile !== formData?.speakerDescription){
        postspekaeruser(speakerDetailsData?.speakerUserId, userId, formData?.speakerDescription, formData?.speakerLink, speakerDetailsData?.speakerImageId, "Done", true,upsertedloggedIn
        )
        }
    }
    if(difference.length > 0){
     let intersectionData = getRoleIdValue.filter(element => !rolesid.includes(element));
    konsole.log("intersectionDataintersectionData",intersectionData, getRoleIdValue, rolesid)
    let loaderDatacount = 1
    if(intersectionData.length > 0){
      let rolesArray = []
      for(let i = 0; i < intersectionData.length; i++){
        let filterData = multiRoleIdData.filter((x)=>{
          return x.roleId == intersectionData[i]
        })
        rolesArray.push(filterData[0]?.roleKey)
      }
      
      
      let deletedRoleToString = rolesArray.join(", ")
      konsole.log("rolesArrayrolesArray",deletedRoleToString)
      setDeletedRoleName(deletedRoleToString)

      setShowDeleteModalStatus(true)
      loaderDatacount = 2
      
     
    }
    
    callAddUserApi(intersectionData, loaderDatacount,data);
 
    }else{
    onCancel()
    }
    }else{
    
    if(rolesid.length == 0){

      message.error("At least one role requried!")
    }
    }
    };

const callAddUserApi = (intersectionData, loaderDatacount,data) => {
    konsole.log("loaderDatacountloaderDatacount",loaderDatacount)
    const formdata = form.getFieldsValue();
    const loggedUserId = commonLib.getObjFromStorage("stateObj").userId;
    const subtenantId = subtenantdata.filter((data, index) => {
      return data.subtenantName == formdata.subtenantName;
    })[0].subtenantId;
    const userId = selectedSubtenantUser.userId;
    const postData = {
      subtenantId: subtenantId,
      loginUserId: selectedSubtenantUser.userRgstrtnId,
      // roleId: formdata.subtenantRole,
      isActive: true,
      createdBy: loggedUserId,
    };
    // setLoader(true);
    for (let i = 0; i < rolesid.length; i++) {
      postData.roleId = rolesid[i];
      postAnsycRoleApifun(userId, postData, i, rolesid.length, intersectionData, loaderDatacount,data);
    }
    // postAnsycRoleApifun(userId,postData)
  };

  const postAnsycRoleApifun = (userId, postData, i, AddDatalength, intersectionData, loaderDatacount,data) => {
    let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
    if(loaderDatacount == 1){
      setLoader(true)
    }
    const postAnsycRoleApiResponse = Services.postAnsycRoleApi(userId,postData);
    postAnsycRoleApiResponse.then((res) => {
        setLoader(false);
        if(loaderDatacount == 1){
          if(i == AddDatalength - 1){

            getUserListApi(i, AddDatalength);
            postspekaeruser(0, userId, data.speakerDescription, data.speakerLink,0, "Done", true,upsertedloggedIn)
            if(intersectionData.length == 0 ){
              message.success("Update Successfully ");
              
            }
            
            
          }
        }
        getUserListApi(1)
        // onCancel();
      })
      .catch((err) => {
        konsole.log("error", err);
        // message.error(err.response.data.messages[0]);
        setLoader(false);
      })
      .finally(() => {
        // setLoader(false);
      });
  };


  


const putRoleApiData = () =>{
let subtenantId = sessionStorage.getItem('subtenantId')
const loggedUserId1 = commonLib.getObjFromStorage("stateObj").userId

  setLoader(true)

  const intersection = getRoleIdValue.filter(element => !rolesid.includes(element));

  konsole.log("intersectionintersection",intersection)

  for(let i =0; i< intersection.length; i++){

    
    let filterData = multiRoleIdData.filter((data)=>{
      return data.roleId == intersection[i]
    })
    konsole.log("filterDatafilterData",filterData)
    
    konsole.log("intersection",intersection)
      let promise = Services.putRoleIdDetails(filterData[0]?.loginUserId, subtenantId, filterData[0]?.roleId, false, filterData[0]?.userRoleId, loggedUserId1, "")
      promise.then((response)=>{
        if(filterData[0]?.roleId === 7){
          const userId = selectedSubtenantUser.userId;
          let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
        postspekaeruser(speakerDetailsData?.speakerUserId, userId, speakerDetailsData?.speakerProfile, speakerDetailsData?.speakerLink, speakerDetailsData?.speakerImageId, "Done", false,upsertedloggedIn)
        }
   
      
      if(i == intersection.length - 1){
        getUserListApi(i, intersection.length)
        message.success("Update Successfully")
      }
      setDeleteModalStatus(false)
      setShowDeleteModalStatus(false)
      // setLoader(false)
      // onCancel()
    }).catch((error)=>{
      konsole.log("putRoleIdDetailsError",error)
      // setLoader(false)
    }).finally(()=>{
      konsole.log("putRoleIdDetailsEnd")
    })
      
  
}
  


}
const postspekaeruser=(speakerUserId, userId, speakerProfile, videoLinkPath, speakerImageId, remarks, isActive, upsertedBy,speakersignId)=>{



    const postspeakerupsertCallApi = Services.postspeakerupsertuser(speakerUserId, userId, speakerProfile, videoLinkPath, speakerImageId, remarks, isActive, upsertedBy,speakersignId)
    postspeakerupsertCallApi
      .then((res) => {
        

      })
      .catch((error) => {
        konsole.log("upsertError", error)
      }).finally(()=>{
        // setLoader(false)
      })
  }



  

  const onChangePhone = (value) => {
    // konsole.log("value",value);
    form.setFieldsValue({
      mobile: value,
    });
  };

  const modalhandleOk = () => {
    setIsModalVisible(true);
  };

  const modalhandleCancel = () => {
    setIsModalVisible(false);
  };

  const onchangeroles = (value) => {
    
    setROlesid(value);


    let hideArrValue = [3,13,14,15]
    
    let intersection = hideArrValue.filter(x => value.includes(x));

    if(intersection.length > 0){

      hideArrValue.splice(hideArrValue.indexOf(intersection[0]), 1)

      for(let i = 0; i< hideArrValue.length; i++){
        
        options.filter((disableFields)=>{
          if(disableFields.value == hideArrValue[i]){
            disableFields['disabled'] = true
          }
          
        })
      }
      
      
    }else{
      for(let i =  0; i< hideArrValue.length; i++){
        
        options.filter((disableFields)=>{
          if(disableFields.value == hideArrValue[i]){
            disableFields['disabled'] = false
          }
         
        })
      }

    }
   
  };

  return (
    <>
      <Modal
        visible={visible}
        okText="Add"
        onCancel={onCancel}
        footer={null}
        closable={true}
        centered={true}
        maskClosable={false}
      >
        <Form
          form={form}
          name="AdduserForm"
          style={{ width: "100%" }}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 0 }}
          className="w-100"
          layout="inline"
          onFinish={onFinishForm}
        >
          <div className="w-100">
            <Card
              className=" w-100 mt-5"
              style={{ border: "1px dashed black", borderRadius: "5px" }}
            >
              <p className="fw-bold" style={{ color: "#720C20" }}>
                Personal Informations
              </p>
              <Row
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 0 }}
                className="w-100"
                layout="inline"
              >
                <Row className="w-100" gutter={[8, 8]}>
                  <Col xs={24} lg={8}>
                    <Form.Item
                      name="fName"
                      className="mt-1"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                        {
                          pattern: /^[a-zA-Z\d]+$/,
                          message: "Please input Valid First Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="First Name"
                        disabled
                        style={{ color: "black" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item
                      name="lName"
                      className="mt-1"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },

                        {
                          pattern: /^[a-zA-Z\d]+$/,
                          message: "Please input Valid Last Name!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last Name"
                        disabled
                        style={{ color: "black" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
            </Card>
            <div>
              <Card
                className=" w-100 mt-1"
                style={{ border: "1px dashed black", borderRadius: "5px" }}
              >
                <p className="fw-bold" style={{ color: "#720C20" }}>
                  Contact Information
                </p>
                <Row
                  className="mt-2 p-2 justify-content-around"
                  style={{ border: "ridge", borderRadius: "10px" }}
                >
                  <Col className="mt-2" lg={12} xs={24} span={12}>
                    <Form.Item
                      name="primaryEmail"
                      rules={[
                        {
                          type: "email",
                          required: true,
                         
                          message: "Please enter your E-mail",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        disabled
                        style={{ color: "black" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="mt-2 ms-2" lg={4} xs={24} span={3}>
                    <Form.Item
                      name="primaryCode"
                      // rules={[
                      //     {
                      //         required: true,
                      //         message: "Please select your country code",
                      //     },
                      // ]}
                    >
                      <Select
                        placeholder="Code"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onSearch={onSearch}
                        style={{ width: "113px" }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        disabled
                      >
                        {countrycode.map((countryProp) => (
                          <Option value={countryProp.value}>
                            {countryProp.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="mt-2 mobileInputContactWidth" lg={7} xs={24} span={8}>
                    <Form.Item
                      name="primaryMobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your number",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please input valid number",
                        },
                        {
                          pattern: /^[\d]{0,10}$/,
                          message: "Value should be less than 10 character",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Mobile no." /> */}
                      <InputMask
                        placeholder="Mobile no."
                        value={form.getFieldsValue().mobile}
                        onValueChange={onChangePhone}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </div>

            <Card
              className=" w-100 mt-1"
              style={{ border: "1px dashed black", borderRadius: "5px" }}
            >
              <p className="fw-bold" style={{ color: "#720C20" }}>
                Assign Roles
              </p>
              <Row lg={24} className="w-100 d-flex justify-content-between">
                <Col xs={24} lg={24} span={16} className="w-100">
                  <Row className="w-100 mb-2" gutter={[8, 8]}>
                    <Col xs={24} lg={12}>
                      <Form.Item
                        name="subtenantName"
                        rules={[
                          {
                            required: true,
                            message: "Please select Firm",
                          },
                        ]}
                      >
                        <Select
                          // defaultValue="Life Point Law"
                          placeholder="Firm"
                          className="fs-7"
                          disabled={true}
                          dropdownMatchSelectWidth={false}
                        >
                          {subtenantdata.map((dataprop) => (
                            <Option
                              value={dataprop.subtenantName}
                              key={dataprop.subtenantId}
                              className="ant-select-selection-placeholder"
                            >
                              {dataprop.subtenantName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={12}>
                      <Form.Item
                        name="subtenantRole"
                        required
                        // style={{"width":"18rem"}}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Role"
                          maxTagCount={'responsive'}
                          showArrow
                          optionFilterProp="children"
                          onChange={onchangeroles}
                        >
                          {options?.map((items) => {
                            return (
                              <>
                                <Option value={parseInt(items?.value)} disabled={(items?.disabled)? items?.disabled : false }   >
                                  {items?.label}
                                </Option>
                              </>
                            );
                          })}

                          {/* <Option value={11}> <input type="checkbox" id="11" onChange={onchangeroles}/>Administrator</Option> */}
                          {/* <Option value={7}><input type="checkbox" id="7" onChange={onchangeroles} />Speaker</Option> */}
                          {/* <Option value={6}><input type="checkbox" id="6" onChange={onchangeroles} />Seminar Coordinator</Option> */}
                          {/* <Option value={8}><input type="checkbox" id="8" onChange={onchangeroles} />Planner</Option> */}
                          {/* <Option value={3}><input type="checkbox" id="3" onChange={onchangeroles} />Paralegal</Option> */}
                          {/* <Option value={11}> Administrator</Option> 
                                                  <Option value={7}>Speaker</Option> 
                                                  <Option value={6}>Event Coordinator</Option> 
                                                   <Option value={8}>Planner</Option> 
                                                   <Option value={3}>Paralegal</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {rolesid?.includes(7) &&

                <div>
                  <Card
                    className=" w-100 mt-1"
                    style={{ border: "1px dashed black", borderRadius: "5px" }}
                  >
                    <p className="fw-bold" style={{ color: "#720C20" }}>
                      About
                    </p>
                    <Row
                      className="mt-2 p-2"
                      style={{ border: "ridge", borderRadius: "10px" }}
                    >
                      <Col className="mt-2" lg={24} xs={24} span={24}>
                        <Form.Item
                          name="speakerLink"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Speaker Link",
                            },
                          ]}
                        >

                          <Input placeholder="Enter Link" />
                        </Form.Item>
                      </Col>
                      <Col className="mt-2" lg={24} xs={24} span={24}>
                        <Form.Item
                          name="speakerDescription"
                          rules={[
                            {

                              required: true,
                              message: "Please Enter Speaker Description!",
                            },
                          ]}
                        >
                          <TextArea placeholder="Speaker Description" rows={4} />
                        </Form.Item>
                      </Col>


                    </Row>
                  </Card>
                </div>
              }
            </Card>
          </div>

          <Row className="mt-3 w-100">
            <Col className="w-100 d-flex justify-content-between">
              <Button
                className="fw-bold"
                onClick={onCancel}
                style={{
                  color: "#720C20",
                  borderColor: "#720C20",
                  borderRadius: 5,
                }}
              >
                Cancel
              </Button>
              <Button
                className="fw-bold"
                htmlType="submit"
                style={{
                  background: "#720C20",
                  color: "white",
                  borderRadius: 5,
                }}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {

        showDeleteModalStatus == true && <DeleteConfirmModal visible={showDeleteModalStatus} hideModal={setShowDeleteModalStatus} titleText="Delete Role" bodyContent={`Do you want to delete "${deletedRoleName}" ${deletedRoleName.split(",").length ==1? "role" : "roles" } for this user?`} deleteStatus={setDeleteModalStatus} />
      }
    </>
  );
};

export default AddSubtenantUser;

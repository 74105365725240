import React, { useContext, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { FileCabinetContext } from './ParentCabinet'
import { useState } from 'react';
import konsole from '../../network/konsole';
import AddCabinet from './AddCabinet';
import { folderOperationType, createCategoryObjJson, operationForCategoryType, createFolderObjJson, cabinetFileCabinetId, sortingFolderForCabinet } from '../../control/Constant';
import AddCategoryFolder from './AddCategoryFolder';
import commonLib from '../../control/commonLib';
import { UserContext } from '../../App';
import AddFile from './AddFile';
import FileViewer from './FileViewer';
import { message, Popconfirm, Tooltip } from 'antd';
import { InputSelectCom } from './InputCom';
import Services from '../../network/Services';

const FolderStructure = ({ apiCallGetMainCabinetContainer, callApiGetCabinetCategory, callApiCabinetFolder, callApiCabinetCategory, allMainCabinetContainer, setManageShowFileTypeFromManageFile, setUpOfMailContainerSelectContainer }) => {
    const { setShowAddEditFolder, showAddEditFolder, setShowScreenType, mappingSubtenantInfo, allPartnerAdminDetails, belongsScreen, showScreenType, fileInformation, categoryTypeListCheckMapping, myAllCabinetList, setSelectedCabinet, selectedCabinet } = useContext(FileCabinetContext)


    let msgForAccessContent = "Access Denied : You do not have permission to view the content."
    konsole.log('mappingSubtenantInfomappingSubtenantInfo', mappingSubtenantInfo)

    // let checkShoeAddEdit = categoryTypeListCheckMapping.length > 0 ? false : true
    let checkShoeAddEditUpdateMapping = categoryTypeListCheckMapping.length > 0 ? false : true
    let checkShoeAddEdit = true
    konsole.log('checkShoeAddEditcheckShoeAddEdit', checkShoeAddEdit)
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const loggedUserId = sessionStorage.getItem('loggedUserId')
    konsole.log('loggedUserIdloggedUserId', loggedUserId)
    const { setLoader } = useContext(UserContext)
    konsole.log('allMainCabinetContaineraaa', allMainCabinetContainer)
    //  define state--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [manageUpdate, setManageUpdate] = useState({ updatedBy: stateObj.userId, updatedOn: new Date().toISOString() })
    const [manageCreate, setManageCreate] = useState({ createdBy: stateObj.userId, createdOn: new Date().toISOString() })

    const [cabinetId, setCabinetId] = useState('')
    const [categoryTypeList, setCatetoryTypeList] = useState([])
    konsole.log('categoryTypeListcategoryTypeListcategoryTypeList',categoryTypeList)
    const [newCategoryTypeList, setNewCatetoryTypeList] = useState([])
    // const [showAddEditFolder, setShowAddEditFolder] = useState('')
    const [selectFolderInfo, setSelectFolderInfo] = useState([])
    const [actionType, setActionType] = useState('ADD')
    const [selectIndexOfCategory, setSelectIndexOfCategory] = useState('')
    const [refrencePage, setRefrencePage] = useState('FolderStructure')
    const [manageShowFileType, setManageShowFileType] = useState('Folder')
    const [openFileInfo, setOpenFileInfo] = useState({ primaryUserId: '', fileId: 0, fileTypeId: 0, fileCategoryId: 0, requestedBy: loggedUserId })
    const [isFileOpen, setIsFileOpen] = useState(false)
    const [fileAddFrom, setFileAddFrom] = useState('')
    const [previousFolderInfo, setPreviousInfo] = useState([])

    const [partnerSubtenantId, serPartnerSubtenantAdmin] = useState('')
    const [callGetCabinetIsActive, setCallGetCabinetIsActive] = useState(true)
    const [categoryOpenStates, setcategoryOpenStates] = useState({});
    const [isSpecificDrawerSelect,setIsSpecificDrawerSelect]=useState(false)



    konsole.log('isSpecificDrawerSelect',isSpecificDrawerSelect)
    konsole.log('cabinetIdcabinetIdcabinetId', partnerSubtenantId, cabinetId)


    //  selected Cabinet----------------------------------------------------------------------------------------------------------------------------------------------------


    useEffect(() => {
        const stateObj = commonLib.getObjFromStorage("stateObj");
        if (myAllCabinetList.length > 0) {
            let jsonObj = { target: { value: myAllCabinetList[0].cabinetId } }
            handleSelectCabinet(jsonObj)
        }
        if (belongsScreen == 'Firmpartner') {
            let partSubId = sessionStorage.getItem('partAdminSubtenant')
            konsole.log('partSubId', partSubId)
            if (partSubId && callGetCabinetIsActive == true) {
                setCallGetCabinetIsActive(false)
                apiCallGetMainCabinetContainer(partSubId, 11)
            }
            serPartnerSubtenantAdmin(partSubId)
        }
    }, [myAllCabinetList, partnerSubtenantId, belongsScreen])
    const handleSelectCabinet = (e) => {

        let value = e.target.value
        let myNewArr = JSON.parse(JSON.stringify(myAllCabinetList))
        const filterCategoryList = myNewArr?.filter(item => item.cabinetId == value)
        let myAllCategoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList.filter(({ subtenantId }) => subtenantId == partnerSubtenantId) : [];
        if (belongsScreen !== 'Firmpartner') {
            myAllCategoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList.filter(({ roleId }) => roleId == stateObj.roleId) : [];
        }
        konsole.log('myAllCategoryListmyAllCategoryListmyAllCategoryList',belongsScreen,filterCategoryList, myAllCategoryList)
        const categoryList = myAllCategoryList
        let myCabinetArr = [...categoryList]

        konsole.log('myCabinetArrmyCabinetArrmyCabinetArr', filterCategoryList, myCabinetArr)
        if (myCabinetArr.length > 0) {
            for (let [index, item] of myCabinetArr.entries()) {
                myCabinetArr[index].fileCabinetFolderList = createNestedArray(item.fileCabinetFolderList)
            }
        }
        let fileCategoryId = myCabinetArr.length > 0 ? myCabinetArr[0].fileCategoryId : null
        callApiGetCabinetCategory(fileCategoryId)
        setCabinetId(value)
        konsole.log('categoryListcategoryListAz',categoryList)

        setCatetoryTypeList(categoryList);
        setNewCatetoryTypeList(categoryList)
        setSelectedCabinet(filterCategoryList)
    }


    const addNewCabinet = () => {
        setShowScreenType('showManageFileCabinetScreen')
    }
    //  selected Cabinet----------------------------------------------------------------------------------------------------------------------------------------------------


    //  handle delete Folder ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const deleteFolderfun = (item) => {
        let { folderId, folderRoleId, folderSubtenantId, folderName, folderFileCategoryId } = item
        let jsonObj = {
            "memberUserId": stateObj.userId,
            "folderId": folderId,
            'subtenantId': folderSubtenantId,
            "folderName": folderName,
            "roleId": belongsScreen == 'Firmpartner' ? 11 : stateObj.roleId,
            "deletedBy": stateObj.userId,
            "folderCategoryId": folderFileCategoryId

        }

        konsole.log("jsonObjjsonObj", JSON.stringify(jsonObj))
        konsole.log('itemitemitem', item)
        // if (item?.fileCabinetFolderList.length !== 0) {
        //     message.error('To delete a folder, you must first delete its subfolders. Only after removing all the subfolders can you delete the main folder.')
        //     return;
        // }
        let roleId = stateObj.roleId;
        let postJson = createFolderObjJson({ ...manageUpdate, ...item, folderRoleId: roleId, folderSubtenantId: subtenantId, folderIsActive: false, folderOperation: folderOperationType[0] })
        konsole.log('postJson', postJson)
        // return;
        // callApiCabinetFolder([postJson], 'Folder delete successfully.')
        deleteFileCabinetFolderFun(jsonObj)

    }




    const deleteFileCabinetFolderFun = (jsonObj) => {
        setLoader(true)
        Services.deleteFileCabinetFolder({ ...jsonObj }).then((res) => {
            konsole.log('res of deleting folder', res)

            setLoader(false)
            screenReload('Folder deleted successfully.')
        }).catch((err) => {
            setLoader(false)
            let messagea = "Sorry Folder/ Subfolder can not be deleted as it has Sub Folder / Documents associated with it."
            message.error(messagea)

            konsole.log('err in deleting folder', err)
        })

    }

    const screenReload = (msg) => {
        (message) && message.success(msg)
        window.location.reload()
    }
    const deleteCategoryfun = (item) => {
        if (item?.fileCabinetFolderList.length !== 0) {
            message.error('To delete a folder, you must first delete its subfolders. Only after removing all the subfolders can you delete the main folder.')
            return;
        }
        // return;
        let roleId = stateObj.roleId;
        let postJson = createCategoryObjJson({ ...manageUpdate, ...item, categoryIsActive: false, roleId: roleId, categoryIsActive: false, subtenantId, operation: operationForCategoryType[0] })
        postJson['categoryMapIsActive'] = false
        konsole.log('postJsonpostJsonpostJson', postJson, JSON.stringify(postJson))
        // return;
        callApiCabinetCategory([postJson], 'deteted')
        konsole.log('postJsonpostJson', postJson)

    }




    // useEffect(() => {
    //     let cabinetId = allMainCabinetContainer.length > 0 && allMainCabinetContainer[0].cabinetId
    //     allMainCabinetContainer.length > 0 && handleCabinet(cabinetId, 'useEffect')

    // }, [allMainCabinetContainer, showScreenType])

    //  handle cabinet drop down--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleCabinet = (e, type) => {
        let allValue = (type == 'useEffect') ? e : e?.target?.value
        const filterCategoryList = allMainCabinetContainer.length > 0 && allMainCabinetContainer?.filter(item => item.cabinetId == allValue)
        const categoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList : [];
        setCatetoryTypeList(categoryList);
        setNewCatetoryTypeList(categoryList)
        setCabinetId(allValue)
    }
    //  handle Category List------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleCategory = (index, item, type,specificCategory) => {
        // topSchroll()
        setIsSpecificDrawerSelect(specificCategory)
        setShowAddEditFolder('')
        setShowScreenType('')
        setManageShowFileTypeFromManageFile('')
        if (type == 'customMapping') {
            setSelectIndexOfCategory(index)
            let filterSelectCategory = newCategoryTypeList.filter(({ fileCategoryId }) => fileCategoryId == item.fileCategoryId)
            setCatetoryTypeList(filterSelectCategory)
        } else {
            setSelectIndexOfCategory(index)
            setCatetoryTypeList(newCategoryTypeList)
        }

    }

    //  handle Add Edit Folder -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleAddEditFolder = (show, action, value, previousarray, refrencePage, manageFileType) => {
        konsole.log('valuevaluevalue', value)
        setManageShowFileTypeFromManageFile('')
        topSchroll()
        konsole.log('showAddEditFolder', show, action, value, previousarray)
        setRefrencePage(refrencePage)
        setShowAddEditFolder(show)
        setActionType(action)
        setSelectFolderInfo(value)
        setManageShowFileType(manageFileType)
        setFileAddFrom(fileAddFrom)
        setShowScreenType('')
        setPreviousInfo(previousarray)

    }

    //  file view function--------------------------------------------------------------------------------------
    const viewFileFun = (item) => {
        setOpenFileInfo({ ...openFileInfo, ...item, requestedBy: loggedUserId })
        setIsFileOpen(true)
    }
    function topSchroll() {
        const element = document.getElementById('scrollToTopScreen');
        konsole.log('elementelement', element)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "start", });
        }
    }

    const handleSelectPartnerAdmin = (e) => {
        serPartnerSubtenantAdmin(e.target.value)
        sessionStorage.setItem("partAdminSubtenant", e.target.value);
        apiCallGetMainCabinetContainer(e.target.value, 11)

        konsole.log('target', e.target.value)
        konsole.log('mappingSubtenantInfo', mappingSubtenantInfo)
    }

    const toggleCategoryCollapse = (categoryIndex) => {
        setcategoryOpenStates(prevStates => ({
            ...prevStates,
            [categoryIndex]: !prevStates[categoryIndex]
        }));
    };

    const addNewCategoryDrawer = () => {
        setManageShowFileType('Category')
    }
    //  konsole--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    konsole.log('showAddEditFoldershowAddEditFolder', showAddEditFolder)
    konsole.log('categoryTypeListcategoryTypeList', categoryTypeList.length)
    konsole.log('newCategoryTypeListnewCategoryTypeList', newCategoryTypeList)
    konsole.log('selectFolderInfo', selectFolderInfo)
    konsole.log('categoryTypeListcategoryTypeListaa', categoryTypeList)



    const SideCategorySelectDesign = ( prop ) => {
        return (
            <>
                <Col lg="2" className={prop.displayCSS}>
                    <Row className='p-2 pe-4'>
                        <Col className='p-0'>
                            <div>  {newCategoryTypeList.length > 0 &&
                                <div className='file-cabinet-file border '>
                                    <div className="d-flex flex-column align-items-center justify-content-center" id=''>
                                        <>
                                            <Row >
                                                <Col className='m-0 p-0 m-2'>
                                                    <div className='File_cabinate_category'>
                                                        {/* <h6 className="m-0 p-0" style={{ cursor: "pointer" }}>Category</h6> */}
                                                    </div>
                                                    <div className=''>
                                                        <div className='d-flex flex-row-sm align-items-center justify-content-center '>
                                                            {
                                                                newCategoryTypeList?.sort((a, b) => a?.fileCabinetOrder - b?.fileCabinetOrder)?.map((item, index) => <>
                                                                    <button className={`${(selectIndexOfCategory === index) ? 'activeButtonCard' : 'buttonCard'}`}
                                                                        key={index} onClick={() => handleCategory(index, item, 'customMapping', true)} >
                                                                        <center className="categoryfileName ms-1 text-center">{item.fileCategoryType}</center>
                                                                    </button>
                                                                </>)}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {/* <Row className='mt-1'>
                                                <Col className='m-0 p-0'>
                                                    <button className='buttonCard'  ><img src="./share2.svg" alt='life' /><center className="ms-1">Share</center></button>
                                                </Col>
                                            </Row> */}
                                            <Row className='' >
                                                <Col className='m-0 p-0 m-2'>
                                                    <h6 className="m-0 p-0" style={{ cursor: "pointer" }} onClick={() => handleCategory('', '', 'viewAll', false)} >View All</h6>
                                                </Col>
                                            </Row>
                                        </>
                                    </div>
                                </div>}
                            </div>
                        </Col>

                    </Row>
                </Col>
            </>
        )
    }
    //  konsole---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    return (
        <>
            <div className='mb-5'>
                <Row className={(belongsScreen !== 'Firmpartner' && stateObj.roleId=='4') && "flex-wrap-reverse"}>
                {(belongsScreen == 'Firmpartner' || stateObj.roleId=='11') && <SideCategorySelectDesign displayCSS='d-none-sm'/> }
                    <Col lg="10" className=''>
                        <Row className='mt-1 me-3 mb-1 border-bottom'>
                            <div className='d-flex pt-1 pb-1'>
                                {belongsScreen !== 'Firmpartner' ?

                                    <h4 className='file_name'>Cabinet: {myAllCabinetList.length > 0 && `${myAllCabinetList[0].cabinetName}`}</h4> : <>
                                        {mappingSubtenantInfo.length != 0 ? <>
                                            <Col lg='3'><h4 className='file_name mt-2'> Select Firm</h4></Col>
                                            <Col lg='4'>
                                                <Form.Select aria-label="Default select example" value={partnerSubtenantId} onChange={(e) => handleSelectPartnerAdmin(e)}>
                                                    <option disabled value='' defaultValue>Select Firm</option>
                                                    {mappingSubtenantInfo.map((item, index) => {
                                                        const matchingSubtenant = allPartnerAdminDetails.find(({ subtenantId }) => subtenantId === item.subtenantId);
                                                        const subtenantName = matchingSubtenant ? matchingSubtenant.subtenantName : '';
                                                        return (<>
                                                            <option key={index} value={item.subtenantId}>{subtenantName}
                                                            </option>
                                                        </>)
                                                    })}

                                                </Form.Select>
                                            </Col></> : 'No Cabinet assigned yet.'}
                                        {categoryTypeList.length > 0 &&
                                            <h6 className='mx-2 m-0 p-0 folder-text-class mt-2'>
                                                <span style={{ cursor: 'pointer' }} onClick={() => handleAddEditFolder('Category', 'ADD', '', '', 'ManageAllFile', 'Category')} > Add Drawer </span>
                                            </h6>}
                                    </>
                                }
                            </div>
                        </Row>
                        {(belongsScreen == 'Firmpartner' || stateObj.roleId=='11') && <SideCategorySelectDesign displayCSS='d-none-lg'/> }
                        <div id='scrollToTopScreen'>

                            {(showAddEditFolder == 'Folder' || showAddEditFolder == 'Category') ?
                                <AddCategoryFolder
                                    callApiCabinetFolder={callApiCabinetFolder}
                                    callApiCabinetCategory={callApiCabinetCategory}
                                    refrencePage={refrencePage}
                                    actionType={actionType}
                                    // manageShowFileType='Folder'
                                    manageShowFileType={manageShowFileType}
                                    selectFolderInfo={selectFolderInfo}
                                    setShowAddEditFolder={setShowAddEditFolder}
                                    key={selectFolderInfo}
                                    previousFolderInfo={previousFolderInfo}
                                    uniqueref='STRUCTURE'
                                    partnerSubtenantId={partnerSubtenantId}
                                /> : (showAddEditFolder == 'File') ?
                                    <AddFile
                                        uniqueref='STRUCTURE'
                                        refrencePage='STRUCTURE'
                                        key={selectFolderInfo}
                                        selectFolderInfo={selectFolderInfo}
                                        fileAddFrom={fileAddFrom}
                                    /> : ''}
                            {isFileOpen && <FileViewer isFileOpen={isFileOpen} setIsFileOpen={setIsFileOpen} openFileInfo={openFileInfo} />}
                        </div>
                        <div className='mb-2' style={{ overflowY: 'auto', height: "78vh", overflowX: 'hidden' }}>
                            {categoryTypeList.length > 0 ? mapFolderNFile() : (!cabinetId) ? '' : ''}
                        </div>
                    </Col>
                    
                {(belongsScreen !== 'Firmpartner' && stateObj.roleId=='4') && <SideCategorySelectDesign/> }

                </Row>
            </div>
        </>
    )
    function mapFolderNFile() {
        // return categoryTypeList?.length > 0 && categoryTypeList.sort((a, b) => a?.fileCategoryType.localeCompare(b?.fileCategoryType))?.map((fileCab, index) => {
        return categoryTypeList?.length > 0 && categoryTypeList?.sort((a, b) => a?.fileCabinetOrder - b?.fileCabinetOrder)?.map((fileCab, index) => {
            konsole.log("fileCabshow", fileCab);
            konsole.log('categoryTypeListcategoryTypeList', JSON.stringify(categoryTypeList))
            const { fileCategoryType, categoryDescription, cabinetName, fileCategoryId, fileCabinetFolderList, ...rest } = fileCab;

            konsole.log('descriptiondescription', categoryDescription)
            konsole.log('fileCabfileCabfileCabfileCab', fileCab)
            konsole.log('categoryTypeListaz', categoryTypeList)


            
            if (isSpecificDrawerSelect == false) return;

            return (
                <>
                    <Row className='border'>
                        <Col lg="5">
                            <div className='d-flex h-100 justify-content-between px-1'>
                                <div className='d-flex justify-content-start'>
                                    {fileCabinetFolderList.length > 0 && <>{(categoryOpenStates[index] ||  isSpecificDrawerSelect == true ) ? <img src="./icons/collapseOpenIcon.svg" className='mt-1' style={{ height: '1.4rem',cursor:'pointer' }} onClick={() => toggleCategoryCollapse(index)} /> : <img src="./icons/collapseCloseIcon.svg" className=' mt-1' style={{ height: '1.4rem' }} onClick={() => toggleCategoryCollapse(index)} />}</>}
                                    <span className='m-0 p-0'><b>{fileCategoryType == 'Legal_Demo' ? 'Legal' : fileCategoryType} </b></span>
                                </div>
                            </div>

                        </Col>
                        <Col lg="3" className=''>
                            <div className='h-100 d-flex align-items-center'>
                                <h6 className='m-0 p-0 text-truncate ms-3' style={{ fontFamily: "sans-serif", fontSize: "14px" }}>{categoryDescription}</h6>
                            </div>
                        </Col>
                        {(stateObj.roleId == 4) && <>
                            {checkShoeAddEdit == true &&
                                <Col lg="4" className="d-flex justify-content-end align-items-center">
                                    <div className='d-flex'>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h6 className='mx-2 m-0 p-0 folder-text-class'>
                                                {/* <span style={{ cursor: 'pointer' }} onClick={() => handleAddEditFolder('Folder', 'EDIT', fileCab, '', 'FolderStructure', 'Category')}  >Edit</span> | */}
                                                <span style={{ cursor: 'pointer' }} onClick={() => handleAddEditFolder('Folder', 'ADD', fileCab, '', 'ManageAllFile', 'Folder')} > Add Folder </span>
                                                {checkShoeAddEditUpdateMapping !== false && <>  |    <span style={{ cursor: 'pointer' }} onClick={() => handleAddEditFolder('Folder', 'EDIT', fileCab, '', 'FolderStructure', 'Category')} > Edit  </span>|
                                                    <Popconfirm title="Are you sure you want to delete this Category?"
                                                        okButtonProps={{ style: { background: '#720C20', color: 'white', border: 'none' } }}
                                                        cancelButtonProps={{ style: { border: 'none', color: 'black' } }}

                                                        onConfirm={() => deleteCategoryfun(fileCab)} okText="Yes" cancelText="No">
                                                        <span style={{ cursor: 'pointer' }}> Delete </span>
                                                    </Popconfirm>
                                                </>}
                                            </h6>
                                        </div>
                                    </div>
                                </Col>}</>
                        }
                    </Row>
                    {(categoryOpenStates[index] || isSpecificDrawerSelect==true) && (
                        <Row className='mt-1 ms-3'>
                            <Col>
                                {/* {mapInsideFolder(fileCabinetFolderList)} */}
                                {fileCab?.fileCabinetFolderList?.sort((a, b) => a.folderName.localeCompare(b.folderName)).sort((a, b) => {
                                    const labels = sortingFolderForCabinet
                                    const aIndex = labels.indexOf(a.folderName);
                                    const bIndex = labels.indexOf(b.folderName);
                                    // return aIndex - bIndex;
                                    return bIndex - aIndex;
                                }).map((folder) => (
                                    <RecursiveFolderCom
                                        key={folder.folderId}
                                        folder={{ ...folder, isChild: false }}
                                        handleAddEditFolder={handleAddEditFolder}
                                        deleteFolderfun={deleteFolderfun}
                                        checkShoeAddEditUpdateMapping={checkShoeAddEditUpdateMapping}
                                        isSpecificDrawerSelect={isSpecificDrawerSelect}
                                    />
                                ))}
                            </Col>

                        </Row>
                    )}
                </>
            )
        })
    }

    function mapInsideFolder(folderArr, previousarray) {
        if (folderArr?.length === 0) return <></>
        // return folderArr?.sort((a, b) => a?.folderName.localeCompare(b?.folderName))?.map((d, index, arr) => {
        return folderArr?.sort((a, b) => {
            const labels = sortingFolderForCabinet
            const aIndex = labels.indexOf(a.folderName);
            const bIndex = labels.indexOf(b.folderName);
            // return aIndex - bIndex;
            return bIndex - aIndex;
        }).map((d, index, arr) => {
            const { folderName, folderId, folderDescription, displayOrder, fileCabinetFolderList, ...rest } = d;
            const filefilterAll = fileInformation.length > 0 && fileInformation.filter((item) => item.folderId == d?.folderId)
            konsole.log('fileCabinetFolderListfileCabinetFolderList', d, fileCabinetFolderList)
            let previousarrayInfo = previousarray !== undefined ? previousarray : d
            konsole.log('filefilterAll', filefilterAll)
            return (
                <Row className=''>
                    <Col>
                        <div className='d-flex flex-column'>
                            <Row>
                                <Col lg='5' className='' >
                                    <div className='d-flex justify-content-start align-items-start'>
                                        {/* <Tooltip title={`${(stateObj.roleId != 4) ? msgForAccessContent : ''}`} color='#720C20' > */}
                                        <div style={{ width: "40px" }} className='d-flex justify-content-start align-items-start h-100'>
                                            <img src="./Group.png" className='w-75' />
                                        </div>
                                        {/* </Tooltip> */}
                                        <h6 className='m-0 p-0 text-truncate w-100'>
                                            {folderName}</h6>
                                    </div>
                                </Col>
                                <Col lg='3' className='ps-5'>
                                    <div className='d-flex align-items-center h-100'>
                                        <h6 className='m-0 p-0 ms-5 text-truncate' style={{ fontFamily: "sans-serif", fontSize: "14px" }}>{folderDescription}</h6>
                                    </div>
                                </Col>
                                <Col lg='4'>
                                    <div className="d-flex justify-content-end align-items-center h-100">
                                        <h6 className='m-0 p-0 folder-text-class'>
                                            {/* <span onClick={() => handleAddEditFolder('File', 'ADD', d, previousarray, 'ManageAllFile', 'Folder', 'Folder')}>  Add File</span> */}
                                            {(stateObj.roleId == 4) && <>
                                                {checkShoeAddEdit == true &&
                                                    <>
                                                        {!sortingFolderForCabinet.some((folder) => folder === folderName) && <span onClick={() => handleAddEditFolder('Folder', 'ADD', d, previousarrayInfo, 'FolderStructure', 'Folder')}> Add Sub Folder</span>}
                                                        {checkShoeAddEditUpdateMapping !== false && <>   |  <span onClick={() => handleAddEditFolder('Folder', 'EDIT', d, previousarrayInfo, 'FolderStructure', 'Folder')}> Edit </span> |
                                                            <Popconfirm
                                                                title="?"
                                                                onConfirm={() => deleteFolderfun(d)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                okButtonProps={{ style: { background: '#720C20', color: 'white', border: 'none' } }}
                                                                cancelButtonProps={{ style: { border: 'none', color: 'black' } }}
                                                            >
                                                                <span > Delete</span>

                                                            </Popconfirm>
                                                        </>}
                                                    </>}
                                            </>}
                                        </h6>
                                    </div>
                                </Col>
                            </Row>
                            {/* <div className='d-flex flex-column'>
                                {filefilterAll.length > 0 && filefilterAll.map((item, index) => {
                                    konsole.log('itemitem', item)
                                    let { userFileName, description } = item
                                    return (<>
                                        <div className="d-flex justify-content-between ms-4 ps-2 mt-2">
                                            <div className="d-flex align-items-start" >
                                                <div className="d-flex align-items-center h-100w-100">
                                                    <img src="./textfile.png" className="cursor-pointer" style={{ width: '2rem' }} onClick={() => viewFileFun(item)} />
                                                    <h6 className="m-0 p-0 ms-2"><b>File:</b> {userFileName}</h6>
                                                </div>
                                            </div>
                                            <div className="w-50">
                                                <h6 className="m-0 p-0 folder-text-class ms-4">{description}</h6>
                                            </div>
                                            <div className="w-20 cursor-pointer">
                                                <h6 className="me-3 p-0" onClick={() => viewFileFun(item)} style={{ cursor: 'pointer' }}>View</h6>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </div> */}
                        </div>
                        <div className='ps-4 ms-1 mt-2'>
                            {mapInsideFolder(d.fileCabinetFolderList, d)}
                        </div>
                    </Col>
                </Row>
            )
        })
    }
}

const RecursiveFolderCom = ({isSpecificDrawerSelect, folder, handleAddEditFolder, deleteFolderfun, checkShoeAddEditUpdateMapping }) => {
    const stateObj = commonLib.getObjFromStorage("stateObj");
    // let msgForAccessContent = "Access Denied : You do not have permission to view the content."
    const [isCollapsed, setIsCollapsed] = useState(true);
    let checkShoeAddEdit = true

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    useEffect(() => {
        setIsCollapsed(!isSpecificDrawerSelect)
    }, [isSpecificDrawerSelect])
    const { folderName, folderId, folderDescription, displayOrder, fileCabinetFolderList, ...rest } = folder;
    konsole.log('folderfolderfolder', folder)

    return (


        <>
            <Row className=''>
                <Col>
                    <div className='d-flex flex-column'>
                        <Row>
                            <Col lg='5' className='' onClick={() => toggleCollapse()} >
                                <div className='d-flex justify-content-start align-items-start'>
                                    {/* <Tooltip title={`${(stateObj.roleId != 4) ? msgForAccessContent : ''}`} color='#720C20' > */}
                                    <div style={{ width: "40px" }} className='d-flex justify-content-start align-items-start h-100 '>
                                        <img src="./Group.png" className='w-75' />
                                        {folder?.fileCabinetFolderList?.length > 0 ? <>  {isCollapsed ? <img src="./icons/collapseCloseIcon.svg" className='w-50 mt-1' style={{cursor:'pointer'}} /> : <img src="./icons/collapseOpenIcon.svg" style={{cursor:'pointer'}} className='w-50 mt-1' />}</> : ''}
                                    </div>
                                    {/* </Tooltip> */}
                                    <h6 className={`${folder?.fileCabinetFolderList?.length > 0 ? 'ms-2' : 'm-0'}  p-0 text-truncate w-100`}>{folderName}</h6>
                                </div>
                            </Col>
                            <Col lg='3' className='ps-5'>
                                <div className='d-flex align-items-center h-100'>
                                    <h6 className='m-0 p-0 ms-5 text-truncate' style={{ fontFamily: "sans-serif", fontSize: "14px" }}>{folderDescription}</h6>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className="d-flex justify-content-end align-items-center h-100">
                                    <h6 className='m-0 p-0 folder-text-class'>
                                        {/* <span onClick={() => handleAddEditFolder('File', 'ADD', d, previousarray, 'ManageAllFile', 'Folder', 'Folder')}>  Add File</span> */}
                                        {(stateObj.roleId == 4) && <>
                                            {checkShoeAddEdit == true &&
                                                <>
                                                    {!sortingFolderForCabinet.some((folder) => folder === folderName) && <span onClick={() => handleAddEditFolder('Folder', 'ADD', folder, '', 'FolderStructure', 'Folder')}> Add Sub Folder |</span>}
                                                    {checkShoeAddEditUpdateMapping !== false && <>   |  <span onClick={() => handleAddEditFolder('Folder', 'EDIT', folder, '', 'FolderStructure', 'Folder')}> Edit </span> |
                                                        <Popconfirm
                                                            title="Are you sure? you want to delete this folder ?"
                                                            onConfirm={() => deleteFolderfun(folder)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                            okButtonProps={{ style: { background: '#720C20', color: 'white', border: 'none' } }}
                                                            cancelButtonProps={{ style: { border: 'none', color: 'black' } }}
                                                        >
                                                            <span > Delete</span>
                                                        </Popconfirm>
                                                    </>}
                                                </>}
                                        </>}


                                        {!sortingFolderForCabinet.some((folder) => folder === folderName) &&

                                            <Popconfirm
                                                title="Are you sure? you want to delete this folder?"
                                                onConfirm={() => deleteFolderfun(folder)}
                                                okText="Yes"
                                                cancelText="No"
                                                okButtonProps={{ style: { background: '#720C20', color: 'white', border: 'none' } }}
                                                cancelButtonProps={{ style: { border: 'none', color: 'black' } }}
                                            >
                                                <span > Delete</span>


                                            </Popconfirm>

                                        }

                                    </h6>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='ps-4 ms-1 mt-2'>

                        {!isCollapsed && (
                            <div className="nested-folders">
                                {folder.fileCabinetFolderList?.sort((a, b) => a.folderName.localeCompare(b.folderName)).sort((a, b) => {
                                    const labels = sortingFolderForCabinet
                                    const aIndex = labels.indexOf(a.folderName);
                                    const bIndex = labels.indexOf(b.folderName);
                                    // return aIndex - bIndex;
                                    return bIndex - aIndex;
                                }).map((nestedFolder) => (
                                    <RecursiveFolderCom key={nestedFolder.folderId}
                                        folder={nestedFolder}
                                        handleAddEditFolder={handleAddEditFolder}
                                        deleteFolderfun={deleteFolderfun}
                                        checkShoeAddEditUpdateMapping={checkShoeAddEditUpdateMapping}
                                        isSpecificDrawerSelect={isSpecificDrawerSelect}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>

        </>
    );
};

function createNestedArray(folders) {
    konsole.log('folders', folders)
    const nestedArray = [];

    function buildNestedArray(parentId, level) {
        const folder = folders.filter(folder => folder.parentFolderId === parentId);

        if (folder.length === 0) {
            return [];
        }
        const nestedChildren = folder.map(child => {
            return {
                ...child,
                fileCabinetFolderList: buildNestedArray(child.folderId, level + 1)
            };
        });

        return nestedChildren;
    }

    nestedArray.push(...buildNestedArray(0, 1));

    return nestedArray;
}




export default FolderStructure
import React, { useState, useEffect ,useContext} from "react";
import {
  Form,
  Input,
  Select,
  Space,
  Row,
  Col,
  Button,
  Modal,
  Table,
  message,
  Popconfirm
} from "antd";
import UploadFilepic from "./AddSubtenantUser";
import "../../Styles/subtenant.css";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import AddSubtenantUsers from "./AddSubtenantUsers";
import AddSubtenantNewRoles from "./AddSubtenantNewRoles";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { UserContext } from "../../App";

const { Option } = Select;
const { Search } = Input;

const SubtenantTable = (props) => {
  const [data, setdata] = useState([]);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState("");
  const [subtenantValue, setSubtenantValue] = useState('');
  const [subtenantdata, setsubtenantdata] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [value, setValue] = useState("");
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [show, setshow] = useState(false)
  const [showRolesModal, setShowRolesModal] = useState(false)
  const [selectedSubtenant, setselectedSubtenant] = useState('')
  const [selectedSubtenantUser, setSelectedSubtenantUser] = useState(null);
  const { setLoader } = useContext(UserContext)
  const [roleid, setroleid] = useState('')
  const [defaultvalue,setDefaultvalue]=useState('Select a partner')
  const [disabledselect,setdisableselect]=useState(false)
  const [searchevent, setSearchevent] =useState("");
  
  
  konsole.log("subtenantValue",subtenantValue,selectedSubtenant)

  const showUserModal = () => {
    setUpdatePartnerUsersData()
    setVisible(true);
    setshow(!show);
  };

  const showRolesModalFunc = (record) => {
    setSelectedSubtenantUser(record);
    konsole.log("recordrecord",record)
    // if(record.isActive == false){
    //   message.error("User not activated yet, Please activate the user.")
    // }else {
    //   setShowRolesModal(true);
    // }
    setShowRolesModal(true);
  };

  const hideRolesModalFunc = () => {
    setShowRolesModal(false);
  };

  const hideUserModal = () => {
    setVisible(false);
    setshow(false)
  };

  const onFinish = (values) => {
    konsole.log("Finish:", values);
  };

  const [placement, SetPlacement] = useState("topLeft");

  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };
  const onSelect = (e, key) => {
    setDefaultvalue(e);
    konsole.log("key", key.key);
    konsole.log(`selected ${e}`);
    konsole.log("eeeee",e)
    setSubtenantValue(key.key);
    setselectedSubtenant(e)
    konsole.log(value);
  };
  konsole.log("subtenantValueee", subtenantValue,selectedSubtenant);
  konsole.log("selectedSubtenantselectedSubtenant",selectedSubtenant)

// const seletedValue=(e)=>{
//   konsole.log("selectedsubt",e)
// }

  useEffect(() => {
    getUserListApi();
  }, [subtenantValue]);



  useEffect(()=>{
    let userroleId=JSON.parse(sessionStorage.getItem("stateObj"))
    let subtenantId=sessionStorage.getItem("subtenantId")
    setroleid(userroleId?.roleId)
    if(subtenantdata !=='' && subtenantdata !==undefined && roleid == "11"){
      setdisableselect(true)
      setSubtenantValue(subtenantId);
      setselectedSubtenant(subtenantId)
      let datafilter=subtenantdata.filter((val)=>{
        return val.subtenantId==subtenantId
      })
      setDefaultvalue(datafilter[0]?.subtenantName)
      setselectedSubtenant(datafilter[0]?.subtenantName)
      konsole.log("datafilterdatafilter",datafilter)

    }else{
      setDefaultvalue(subtenantdata[0]?.subtenantName)
      
      setSubtenantValue(subtenantdata[0]?.subtenantId);
      setselectedSubtenant(subtenantdata[0]?.subtenantName)
      setdisableselect(false)
    }
    
  },[subtenantdata])


  
  const createdBy = null;
  const isActive = true;

  useEffect(() => {
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    Subtenantdetailspromise.then((res) => {
      setsubtenantdata(res.data.data);
    }).catch((err) => {
      konsole.log("err", err);
    });
  }, []);

  const getUserListApi = (startValue, EndValue) => {
    if(startValue !== 1){
      setLoader(true)
    }
    const subtenantId = subtenantValue;
    if(subtenantId !=='' && subtenantId !==undefined && subtenantId !==null){
    konsole.log("subtenantId",subtenantId)
    
    const getUserlistpromise = Services.getUserList(subtenantId);
    getUserlistpromise
      .then((res) => {
        konsole.log("ressssressss", res.data.data);
        setdata(res.data.data);
        konsole.log(res.data.data)
        setAssignOptionsList(res.data.data);
        setLoader(false)
        if(startValue !== 1){

          hideUserModal()
        }
        if(startValue == EndValue - 1){

          hideRolesModalFunc()
        }
      })
      .catch((err) => {
        konsole.log("errerr", err);
      }).finally(()=>{
        setLoader(false);
      });
    }
  };
  const onSearch = (value) => konsole.log(value);
  // konsole.log("defaultvaluedefaultvalue",defaultvalue)
  konsole.log("selectedSubtenantUser",selectedSubtenantUser)

  konsole.log("assignOptionsListassignOptionsList",assignOptionsList)


  const searchonchange=()=>{
    let filteredData = [];
    if(searchevent !==null && searchevent !=='' && searchevent !==undefined){
      let filter=data.filter((items)=>{
        let name=items.fullName 
        let userName=items.userName
        return name.toLowerCase().includes(searchevent.toLocaleLowerCase()) || 
        userName.toLowerCase().includes(searchevent.toLocaleLowerCase()) 
      })
      konsole.log("filteredData",filter)
      filteredData=filter
    }else{
      filteredData=data
    }

    return filteredData
  }
  const [deleteModal , setDeleteModal] = useState(false)

  useEffect(()=>{
    if(deleteModal == true){
      CallDeleteAPi()
    }

  },[deleteModal])

  const CallDeleteAPi = () =>{
    konsole.log("deleteModaldeleteModal",deleteModal)

    setLoader(true)

     let detetedBy = sessionStorage.getItem('loggedUserId')
    let promise = Services.deletePartnerUserDetails(userDataToDelete?.userId, userDataToDelete?.userRgstrtnId, detetedBy)
    promise.then((response)=>{
      message.success("User Deleted Successfully")
      setShowDeleteModal(false)
      setDeleteModal(false)
      getUserListApi()
      konsole.log("deletePartnerUserDetailsRes",response)
    }).catch((error)=>{
      // setLoader(false)
      konsole.log("deletePartnerUserDetailsError",error)
    }).finally(()=>{
      konsole.log("deletePartnerUserDetailsEnd")
      // setLoader(false)
    })





  }


  const [showDeleteModal , setShowDeleteModal] = useState(false)
  const [userDataToDelete , setUserDataToDelete] = useState()
  const deleteData = (data) =>{
    konsole.log("Clicked", data)
    setUserDataToDelete(data)
    setShowDeleteModal(true)
   
    // setDeleteModal(true)
  }
  
  const [updatePartnerUsersData , setUpdatePartnerUsersData] = useState()
  const updatePartnerUsers = (data) =>{
    konsole.log("updatePartnerUsersupdatePartnerUsers", data)
    setUpdatePartnerUsersData(data)
    setVisible(true);
    setshow(!show)

    
  }

  konsole.log("updatePartnerUsersDataupdatePartnerUsersData",updatePartnerUsersData)

  const confirm = (data) =>{
    setLoader(true)
    
    let createdBy = sessionStorage.getItem('loggedUserId')
    konsole.log("aaaaaaWorking",createdBy)

    let promise = Services.postSendUserActivationLink(data?.subtenantId, data?.userRgstrtnId, data?.userId, data?.fullName, data?.primaryEmail, data?.userRgstrtnId, createdBy, "::1")
    promise.then((response)=>{
      konsole.log("postSendUserActivationLinkres",response)
      setLoader(false)
      message.success('Link Send Successfully')
    }).catch((error)=>{
      konsole.log("postSendUserActivationLinkerror",error)
      setLoader(false)
    }).finally(()=>{
      konsole.log("postSendUserActivationLinkend")
      setLoader(false)
    })

    
    // let jsonSendLInk = {
    //   "subtenantId": data?.subtenantId,
    //   "loginUserId": data?.userRgstrtnId,
    //   "userId": data?.userId,
    //   "memberName": data?.fullName,
    //   "emailAddress": data?.primaryEmail,
    //   "signUpPlatform": data?.userRgstrtnId,
    //   "createdBy": createdBy,
    //   "clientIPAddress": "::1"
    // }
    
    // konsole.log("sendLinkInactive",data, jsonSendLInk)
  }

 


  return (
    <div className="d-flex flex-column">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "userForm") {
            const { basicForm } = forms;

            setVisible(false);
          }
        }}
        className="me-4"
      >
        {/* "fs-4 mb-b" */}
        <Form name="basicForm" onFinish={onFinish}>
          <div className="select-container">
            <p className= {`${roleid==4?'mb-1':'mb-2 mt-2'} fs-4`} style={{marginTop:"5px"}}>
           {(roleid==4) && 'Select a Firm'}
             </p>
             {(roleid !=4) &&   <br />}
            
            <Row>
              <Select
                placeholder={defaultvalue}
                value={defaultvalue}
                disabled={disabledselect}
                style={{
                  width: "50%",
                }}
                className="fs-5"
                dropdownMatchSelectWidth={false}
                placement={placement}
                onSelect={onSelect}
                // onChange={seletedValue}
              >
                {subtenantdata.map((dataprop) => (
                  <Option
                    value={dataprop.subtenantName}
                    key={dataprop.subtenantId}
                  >
                    {dataprop.subtenantName}
                  </Option>
                ))}
              </Select>
            </Row>
          </div>
          <Row className="mt-3">
            <Col
              xs={24}
              lg={24}
              span={24}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Col span={12} style={{ display: "flex" }}>
                {/* <Input.Search
                  placeholder="Search for Firm user"
                  onSearch={onSearch}
                  onChange={(e) =>
                    // setuserAgent(e.target.value)
                    {
                      const currValue = e.target.value;
                      setValue(currValue);
                      let filteredData = [];
                      let data = [];
                      
                      data = assignOptionsList;
                      
                      if (currValue !== "") {
                        filteredData = data.filter((entry) => {
                        const incl=entry?.fullName && entry?.userName 
                        console.log("entry",entry.roleKey)

                          if (

                            incl
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) 
                            

                          //   || 
                          //  ( entry.subtenantId !== 3 &&
                          //   entry?.roleKey
                          //   .toLowerCase()
                          //   .includes(currValue.toLocaleLowerCase()))
                            ) {
                              return entry;
                            }
                          });
                        } else {
                          filteredData = data;
                      }
                      // konsole.log("mpaeed filtered", data);
                      setdata(filteredData);
                    }
                  }
                  style={{
                    width: "100%",
                  }} */}
          <div className="w-100 d-flex" style={{border:"2px solid lightgray",borderRadius:"5px", justifyContent:"space-between",height:"39px"}}>
              <input
              className="form-control fs-20"
              style={{border:"none"}}
              type="text"
              placeholder= {`${(roleid==4 )? 'Search for firm user':'Search for staff member'}`}
              // "Search for Firm user"
              onChange={(e) => setSearchevent(e.target.value.toLowerCase())}
              
              />
              <img
              style={{padding:"2px",height:"20px",marginTop:"8px", marginRight:"4px"}}
              src="/search icon.png"
              />
              </div>

              
              </Col>

              <Col>
                <Button
                  htmlType="button"
                  style={{
                    margin: "0 8px",
                    backgroundColor: "#720c20",
                    color: "white",
                    borderRadius: "5px",
                    fontWeight: "600"
                    , height: "40px"
                    // ,padding:"10px"
                    // marginLeft: '220%'
                  }}
                  onClick={showUserModal}
                >
                  <span style={{ fontFamily: "sans-serif" }} status={setFile}>
                    {" "}
                    + ADD NEW USER
                  </span>
                </Button>
              </Col>
            </Col>

           { show ?<AddSubtenantUsers
              visible={visible}
              setshow={setshow}
              onCancel={hideUserModal}
              getUserListApi={getUserListApi}
              selectedSubtenant={selectedSubtenant}
              subtenantValueSelected={subtenantValue}
              setdisableselect={setdisableselect}
              disabledselect={disabledselect}
              updatePartnerUsersData={updatePartnerUsersData}
            />: <></>}

            {
            showRolesModal ? <AddSubtenantNewRoles
              visible={showRolesModal}
                onCancel={hideRolesModalFunc}
              getUserListApi={getUserListApi}
              subtenantValueSelected={subtenantValue}
              selectedSubtenantUser= {selectedSubtenantUser}
            /> : <></>}
            {
              showDeleteModal ? <DeleteConfirmModal visible={showDeleteModal} hideModal={setShowDeleteModal} titleText="Delete User" bodyContent="Do you want to delete this user?" deleteStatus={setDeleteModal} /> : ""
            }
          </Row>
        </Form>
      </Form.Provider>
      
     
      <Table   dataSource={searchonchange(data)}  pagination={false}   className="mt-3 flex-grow-1 subtanantTable"
        scroll={{ y: 'calc(100vh - 350px)' }} // Adjust the height as needed
         >
        <Row
          title="Name"
          className="manage fw-bold p-3  shadow-sm"
          dataIndex="fullName"
          key="fullName"
          render={(_, record) =>(
            <a
              onClick={()=>updatePartnerUsers(record)}
            >
{record.fullName}
            </a>
          )}
        />
        <Row
          title="Role"
          className="manage fw-bold  p-2 border-top-0 border-buttom-0 border-start-0 shadow-sm text-center border-gray"
          dataIndex="roleKey"
          key="roleKey"
        />
        <Row
          title="Email"
          className="manage fw-bold p-2 shadow-sm text-center "
          dataIndex="primaryEmail"
          key="primaryEmail"
        />
        <Row
          title="Status"
          className="manage fw-bold p-2 shadow-sm "
          dataIndex="isActive"
          key="isActive"
          render={(_, record) =>(
            <p
              // onClick={()=>updatePartnerUsers(record)}
            >
{record.isActive ? 
<>
<p style={{color: "green",marginTop:"18px"}}>Active</p>
</> : 
<>
                  <Popconfirm
                    title=" "
                    // description="Send LInk"
                    className="d-flex justify-content-center"
                    style={{alignItems:"center"}}
                    okText={`Resend Invite Link`}
                    icon={false}
                    showCancel={false}
                    onConfirm={() => confirm(record)}
                  >
                    <a><p style={{ color: "orange", cursor: "pointer", marginTop: "18px" }}>Inactive1p</p>
                    </a>
                  </Popconfirm>
</>
}
            </p>
          )}
        />

        <Row
          className="manage fw-bold  shadow-sm "
          gutter={[8,8]}
          title="Action"
          key="action"
          render={(_, record) => (
            <Col xs={24} className="d-flex justify-content-center gap-5">
              <a className="text-center text-decoration-none text-black" style={{marginLeft:"5px"}} onClick={()=> showRolesModalFunc(record)}>
                {" "}
                {/* {konsole.log("record",record)} */}
                <img src="/images/vector.png"></img>
                <br />
                Assign Roles
              </a>
              <a className="text-center text-decoration-none text-black"
               style={{marginRight:"10px"}}
              onClick={()=>deleteData(record)}
              >
                {" "}
                <img src="/images/vector.png"></img>
                <br />
                Delete
              </a>
            </Col>
          )}
          style={{ width: "10px" }}
        />
      </Table>
    </div>
  );
};

export default SubtenantTable;

import { Col, Row, Modal, message, Alert } from 'antd';
import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Input, Select, Button, Form } from 'antd';
import commonLib from '../../control/commonLib';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import "./CommunicationModal/communicationmodal.css";
import { UserContext } from "../../App.js";
import Tooltip from 'react-bootstrap/Tooltip';
// import JoditEditor, { EditorState, ContentState, htmlToDraft } from "jodit-react";
// import 'jodit';
import { useTransition } from 'react';

// import 'jodit/build/jodit.min.css';
import { ModalBody } from 'react-bootstrap';
import { useRef } from 'react';
// import TextArea from 'antd/lib/input/TextArea';

const initialContent = `
<table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='padding: 10px 0 30px 0;'> 
<table style='border: 1px solid #cccccc; border-collapse: collapse;' border='0' width='600' cellspacing='0' cellpadding='0' align='center'> <tbody> <tr> <td style='border-bottom: 1px; border-bottom-style: solid; padding: 20px 0 30px 0; color: #153643; font-size: 28px; font-weight: bold; font-family: Arial, sans-serif;' align='center'>
<p style='font-size: 16px; font-weight: bold; font-family: sans-serif; text-decoration: none; line-height: 40px; width: 100%; display: inline-block; color: #871a27 !important;'>
AgingOptions<br/>@@SUBTENANTNAME</p>
</td></tr><tr> <td style='padding: 40px 30px 0px 30px;' bgcolor='#ffffff'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody><tr>
 <td style='padding: 20px 0 30px 0; color: #153643; font-family: Arial, sans-serif; font-size: 16px; line-height: 20px'>

Please enter your email text here.

</td>
</tr><tr> <td style='padding: 30px 30px 30px 30px;' bgcolor='#6d6864'> <table border='0' width='100%' cellspacing='0' cellpadding='0'> <tbody> <tr> <td style='color: #ffffff; font-family: Arial, sans-serif; font-size: 14px;' width='50%'>AgingOptions<br/><span style='color: #ffffff;'>31919 6th Ave S, Suite A100</span><br/><span style='color: #ffffff;'>Federal Way, WA 98003.</span></td><td><span style='color: #ffffff;'>info@agingoptions.com</span><br/><span style='color: #ffffff;'>www.agingoptions.com</span><br/><span style='color: #ffffff;'> 1-877-76-AGING (24464) </span></td></tr></tbody> </table> </td></tr></tbody> </table> </td></tr></tbody></table>
`

export const Viewandupdatemodal = (props) => {
    // const[getCkEditorText,setCkEditorText]=useState()
    const [lastdataa, setlastdataa] = useState()
    const [isPending, startTransition] = useTransition();
    const [getCkEditorText, setCkEditorText] = useState()
    const [initialcommchannelid, setinitialcommchannelid] = useState(null)
    const [form] = Form.useForm();
    const { setLoader } = useContext(UserContext)
    const [getChannelValue, setChannelValue] = useState()
    const [getSelectRoleFromValue, setSelectRoleFromValue] = useState()
    const [getSelectRoleToValue, setSelectRoleToValue] = useState()
    const [isDisabled, setISDisabled] = useState(true)
    const [commonChannelSelect, setCommonChannelSelect] = useState([]);
    const [getUserRoleSelect, setUserRoleSelect] = useState([])
    const [getEmailTemplateSelect, setEmailTemplateSelect] = useState(false)
    const [getTextTemplateSelect, setTextTemplateSelect] = useState(false)
    const [getEmailSubjectValue, setEmailSubjectValue] = useState();
    const [postOccurrence, setPostOccurrence] = useState()
    
    const [getCkEditorEmail, setCkEditorEmail] = useState("")
    const [fromToRoles, setFromToRoles] = useState([])
    const [putCkEditorText, setPutCkEditorText] = useState([])
    const [postCkEditorEmail, setPostCkEditorEmail] = useState([])
    const [callPutAllData, setPutAllData] = useState([])
    const [GetCommMediumData, setCommMediumData] = useState([]);
    const [inblidEmailTemplate, setInblidEmailTemplate] = useState([])
    const [inblidTextTemplate, setInblidTextTemplate] = useState([])
    const [RoleFromLength, setRoleFromLength] = useState(0)
    const [RoleToLength, setRoleToLength] = useState(0)
    const [fromRoleIdd, setfromRoleId] = useState(0)
    const [toRoleId, settoRoleId] = useState(0)
    const [isTrueValue, setIsTrueValue] = useState(false)
    const [isStopApi, setISStopApi] = useState(false)
    const [EmailSubject, setEmailSubject] = useState("");
    const [comChanID, setComChanID] = useState(0)
    const [EmailTemtID, setEmailTemopID] = useState()
    const [subtenantID, setSubtenantID] = useState()
    const [isUpdate, setISUpdate] = useState(false)
    const [selectedChannelID, setSelectedChannelID] = useState(4)
    const [ckeditorID, setCkEditorID] = useState(4)
    const [selectedFromID, setSelectedFromID] = useState(2)
    const [selectedToID, setSelectedToID] = useState(2)
    const [templateDataEdit, setTemplateDataEdit] = useState(initialContent);
    const [emialTempID, setEmailTempID] = useState()
    const [textTempID, setTextTempID] = useState()
    const [channelEmailLabel, setChannelEmailLabel] = useState([])
    const [channelTextLabel, setChannelTextLabel] = useState([])
    const [channeBothLabel, setChanneBothLabel] = useState([])
    const [atTheRateSelectData, setAtTheRateSelectData] = useState()
    const [atTheRateValue, setAtTheRateValue] = useState()
    const [Textdata, setTextdata] = useState(null)
    const [ddn, setddn] = useState(false)
    const [keystore, setkeystore] = useState(0);
    const [satteonmouse, setsatteonmouse] = useState(false)
    let userId = commonLib.getObjFromStorage("stateObj").userId
    const [errortexarea, seterrortexarea] = useState(false)


    // console.log("UserID", userId)

    // konsole.log("getSelectRoleFromValuegetSelectRoleFromValue", getSelectRoleFromValue)
    // konsole.log("getSelectRoleToValuegetSelectRoleToValue", getSelectRoleToValue)

    useEffect(() => {
        // konsole.log("props",this.props)
        let subtenantID = commonLib.getSessionStoarge("subtenantId")
        setSubtenantID(subtenantID);
        // getCommonChannelValue()
        getCommonChannelfunction();
        getUserRolesfunction();
        // konsole.log("props?.selectedRowData?.applicableEmailTempId", props?.selectedRowData?.applicableEmailTempId, props?.selectedRowData?.applicableTextTempId, subtenantID)

        if (props?.selectedRowData?.applicableEmailTempId != (null || "" || undefined) && props?.selectedRowData?.applicableTextTempId != (null || "" || undefined)) {

            getEmailTemplatesPathApiFunction(subtenantID, props?.selectedRowData?.applicableEmailTempId);
            getTextTemplatesPathApiFunction(subtenantID, props?.selectedRowData?.applicableTextTempId);
        }

        else if (props?.selectedRowData?.applicableTextTempId != (null || "" || undefined)) {
            getTextTemplatesPathApiFunction(subtenantID, props?.selectedRowData?.applicableTextTempId);
        }
        else if (props?.selectedRowData?.applicableEmailTempId != (null || "" || undefined)) {
            getEmailTemplatesPathApiFunction(subtenantID, props?.selectedRowData?.applicableEmailTempId);
        }


    }, [props?.selectedRowData?.applicableEmailTempId, props?.selectedRowData?.applicableTextTempId])


    useEffect(() => {
        let formtoRole = props?.selectedRowData?.commMediumRoles

        let nerFormtoRoleArr = []

        for (let i = 0; i < formtoRole.length; i++) {
            nerFormtoRoleArr.push(
                {
                    commMediumId: formtoRole[i]?.commMediumId,
                    commMediumRolesId: formtoRole[i]?.commMediumRolesId,
                    fromRoleId: formtoRole[i]?.fromRoleId,
                    toRoleId: formtoRole[i]?.toRoleId
                }
            )
        }

        // konsole.log("props?.selectedRowData?.commMediumRoles",formtoRole)
        // konsole.log("props?.selectedRowData?.commMediumRoles", nerFormtoRoleArr)
        setFromToRoles(nerFormtoRoleArr)
        setISStopApi(true)
        // console .warn(inblidEmailTemplate[0]?.emailSubject)
        if (isTrueValue === true) {
            setfromRoleId(GetCommMediumData[0]?.commMediumRoles[0]?.fromRoleId)
            settoRoleId(GetCommMediumData[0]?.commMediumRoles[0]?.toRoleId)
        }

        // let valuedata =
        //  (props?.selectedRowData?.commChannelId) === 1 ? channelEmailLabel
        //     : (props?.selectedRowData?.commChannelId) === 2 ? channelTextLabel
        //         : props?.selectedRowData?.commChannelId === 3 ? channeBothLabel : ""
        setEmailSubjectValue(inblidEmailTemplate[0]?.emailSubject)
        form.setFieldsValue({

            "occurrence": props?.selectedRowData?.occurrenceDetails,
            // "commChannel": valuedata ? valuedata : getChannelValue?.value,
            "roleFrom": getSelectRoleFromValue ? getSelectRoleFromValue : fromRoleNameFuction(props?.selectedRowData?.commMediumRoles),
            "asignRoleTo": getSelectRoleToValue ? getSelectRoleToValue : ToRoleNameFuction(props?.selectedRowData?.commMediumRoles),
            "emailsubject":
                props?.selectedRowData?.applicableEmailTempId ? inblidEmailTemplate[0]?.emailSubject
                    : getEmailTemplateSelect == false ? postOccurrence || GetCommMediumData[0]?.applicableEmailTempId == ("" || null || undefined) ? ""
                        : inblidEmailTemplate[0]?.emailSubject : inblidEmailTemplate[0]?.emailSubject,

        })


    }, [props?.selectedRowData?.occurrenceDetails, props?.selectedRowData?.commMediumRoles, GetCommMediumData[0]?.commChannelId, GetCommMediumData[0]?.commMediumRoles, inblidEmailTemplate[0]?.emailSubject, GetCommMediumData[0]?.commMediumRoles[0]?.fromRoleId, GetCommMediumData[0]?.commMediumRoles[0]?.toRoleId])

    konsole.log("fromToRolesfromToRoles", fromToRoles)

    const [channelIdData, setChannelIdData] = useState()


    useEffect(() => {
        // document.getElementById('testAreaId').innerHTML=initialContent

        setChannelIdData(props?.selectedRowData?.commChannelId)

        let valuedata =
            (props?.selectedRowData?.commChannelId) === 1 ? channelEmailLabel
                : (props?.selectedRowData?.commChannelId) === 2 ? channelTextLabel
                    : props?.selectedRowData?.commChannelId === 3 ? channeBothLabel : ""
        // konsole.log(props?.selectedRowData?.commChannelId,"",getChannelValue?.value)
        setinitialcommchannelid(props?.selectedRowData?.commChannelId)
        form.setFieldsValue({
            "commChannel": valuedata ? valuedata : getChannelValue?.value,
            // "roleFrom": getSelectRoleFromValue ? getSelectRoleFromValue : fromRoleNameFuction(props?.selectedRowData?.commMediumRoles),
            // "asignRoleTo": getSelectRoleToValue ? getSelectRoleToValue : ToRoleNameFuction(props?.selectedRowData?.commMediumRoles),
        })
        konsole.log("props?.selectedRowData?.commMediumRoles", props?.selectedRowData?.commChannelId, channelTextLabel)

    }, [props?.selectedRowData?.commChannelId, channelEmailLabel, channelTextLabel, channeBothLabel])

    konsole.log("commcommonChannelSelectonChannelSelect", channelTextLabel)


    const handleClose = () => {
        // form.resetFields();

        setChannelValue("")
        setSelectRoleFromValue("")
        setSelectRoleToValue("")
        setSelectedChannelID(4)
        setISUpdate(false)
        setISDisabled(true)
        setDisableTemplateData(true)
        props.setShowAddModal(false);
        props.GetCommMediumPathApiFuction()

    }
    let linkButton = document.getElementById("createLink");
    // let linkButton2 = document.getElementById("createLink2");

    const modifyText = (command, defaultUi, value) => {
        document.execCommand(command, defaultUi, value)
        setISUpdate(false)
    };

    const linkCreateButtonFunctionButton = (getids) => {
        konsole.log(getids)
        let userLink2 = prompt("Enter a URL");
        konsole.log(userLink2)
        if (!userLink2) {
            setISUpdate(false)
            return;
        }
        else {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const editableTextArea = document.getElementById(getids);
            konsole.log(getids)


            if (
                (!editableTextArea.contains(range.startContainer)
                    || !editableTextArea.contains(range.endContainer) ||
                    range.startContainer !== range.endContainer)) {
                alert("Cant chnage outside the range")
                setISUpdate(false);
                return;
            }


            const newLink = document.createElement("a");
            newLink.href = userLink2;
            newLink.textContent = range.toString();
            // newLink.classList.add("my-link-class");
            // newLink.setAttribute('style','color:white;background-color: #720c20;text-transform:uppercase;text-decoration: none;font-size:13px;padding:3px 10px;border-radius: 3px;margin:0px 5px');
            newLink.setAttribute('style','color:white;background-color: #720c20;text-transform:uppercase;text-decoration: none;font-size:13px;padding:3px 10px;border-radius: 3px;margin:0px 5px');
            range.deleteContents();
            range.insertNode(newLink);
            setISUpdate(false);
            // alert("inrange")
        }


    }


    const linkCreateButtonFunctionLink = () => {

        let userLink = prompt("Enter a URL");
        if (!userLink) {
            setISUpdate(false)
            return;
        }
        konsole.log("Link function triggered")
        if (/http/i?.test(userLink)) {

            modifyText(linkButton?.id, false, userLink);
            setISUpdate(false);
            konsole.log("Link if")

        }
        else {

            userLink = "" + userLink;

            konsole.log("Link else")
            modifyText(linkButton?.id, false, userLink)
            konsole.log("classs list nai hai linkCreateButtonFunctionLink")
            setISUpdate(false)
        }
    }

    konsole.log("kljlkj", inblidEmailTemplate)

    const getCommonChannelValue = (value, key) => {
        konsole.log("setChannelIdDatasetChannelIdData", key)

        setChannelIdData(key?.key)
        setChannelValue(key)

        if (key?.key == 1) {
            setSelectedChannelID(1)
            setComChanID(1)
            setinitialcommchannelid(1)
            setddn(false)

        }
        else if (key?.key == 2) {
            setSelectedChannelID(2)
            setComChanID(2)
            setddn(false)

        }
        else if (key?.key == 3) {
            setSelectedChannelID(3)
            setComChanID(3)
            setinitialcommchannelid(3)
            setddn(false)

        }
        else {
            setSelectedChannelID(4)

        }

    }


    const handleFromRoleChange = (value, key) => {

        konsole.log("handleFromRoleChangehandleFromRoleChange", key)
        setSelectRoleFromValue(key)
        setRoleFromLength(0)
        setfromRoleId(0)
        setIsTrueValue(false)
        setISStopApi(true)


        if (getSelectRoleToValue?.length > 0) {
            setSelectedFromID(2)
            setSelectedToID(2)

        }

        else {
            setSelectedToID(1)

        }

    };
    const [attherate, setattherate] = useState(false)
    konsole.log("gjgjgj", getSelectRoleFromValue?.key)

    const handleToRoleChange = (value, key) => {
        konsole.log("handleToRoleChange", key)
        setSelectRoleToValue(key)
        setRoleToLength(0)
        settoRoleId(0)
        setISStopApi(true)
        // konsole.log("getSelectRoleFromValue", getSelectRoleFromValue?.length)


    }
    // const config = useMemo(
    //     () => ({
    //         readonly: isDisabled,
    //         buttons: [
    //             // 'source',
    //             // '|',
    //             'bold',
    //             'italic',
    //             'underline',
    //             // '|',
    //             // 'ul',
    //             // 'ol',
    //             '|',
    //             'font',
    //             'fontsize',
    //             // 'brush',
    //             'paragraph',
    //             // '|',
    //             // 'image',
    //             // 'table',
    //             // 'link',
    //             '|',
    //             'left',
    //             'center',
    //             'right',
    //             'justify',
    //             '|',
    //             'undo',
    //             'redo',
    //             // '|',
    //             // 'hr',
    //             // 'eraser',
    //             // 'fullsize',
    //         ],
    //         uploader: { insertImageAsBase64URI: true },
    //         removeButtons: ['brush', 'file'],
    //         showXPathInStatusbar: false,
    //         showCharsCounter: false,
    //         showWordsCounter: false,
    //         toolbarAdaptive: false,
    //         toolbarSticky: false,

    //     }),
    //     [isDisabled]
    // );
    // const config = {
    //     readonly: isDisabled, // all options from https://xdsoft.net/jodit/doc/
    //     // height: '450px',
    //     // width: '100%',
    //     // enableDragAndDropFileToEditor: true,

    //     buttons: [
    //         // 'source',
    //         // '|',
    //         'bold',
    //         'italic',
    //         'underline',
    //         // '|',
    //         // 'ul',
    //         // 'ol',
    //         '|',
    //         'font',
    //         'fontsize',
    //         // 'brush',
    //         'paragraph',
    //         // '|',
    //         // 'image',
    //         // 'table',
    //         // 'link',
    //         '|',
    //         'left',
    //         'center',
    //         'right',
    //         'justify',
    //         '|',
    //         'undo',
    //         'redo',
    //         // '|',
    //         // 'hr',
    //         // 'eraser',
    //         // 'fullsize',
    //     ],
    //     // uploader: { insertImageAsBase64URI: true },
    //     // removeButtons: ['brush', 'file'],
    //     // showXPathInStatusbar: false,
    //     // showCharsCounter: false,
    //     // showWordsCounter: false,
    //     toolbarAdaptive: false,
    //     toolbarSticky: false,
    //     // style: {
    //     //     background: '#27272E',
    //     //     color: 'rgba(255,255,255,0.5)',
    //     // },
    // };

    // const handleUpdate = (event) => {
    //     // const editorContent = event.target?.innerHTML;
    //     setCkEditorEmail(event);

    //     konsole.log("lllllllllllll", event)

    // };
    const [textBeforeCursorPosition, settextBeforeCursorPosition] = useState("");
    const [textAfterCursorPosition, settextAfterCursorPosition] = useState("");
    const [formousehover, setformousehover] = useState(false);
    const[CkEditorTextLength,setCkEditorTextLength]=useState(0)
    const[ChangeDataForLeftRight,setChangeDataForLeftRight]=useState(false)
    const getAtTheRateSelectFunction = (e) => {
        
        setAtTheRateValue(e.target.value)
        var a = e.target.value;
        konsole.log("ddddddd2", a)
        var up = a.slice(2)
        konsole.log("ddddddd", e.target.value)
        konsole.log("ENDINasasaG", textBeforeCursorPosition, textAfterCursorPosition)
        konsole.log("fc", document.querySelector(".textarea_text").value)

        document.querySelector(".textarea_text").value =getCkEditorText + up

        setddn(false)
        konsole.log(satteonmouse)

        document.getElementById("hellotext").focus()
        if(ChangeDataForLeftRight==true)
        {
            document.querySelector(".textarea_text").value =textBeforeCursorPosition + up + textAfterCursorPosition;
        }
        if (formousehover == true && ChangeDataForLeftRight==false) {
            // alert("workin")
            konsole.log("ENDING", textBeforeCursorPosition, textAfterCursorPosition)
            document.querySelector(".textarea_text").value =textBeforeCursorPosition + up + textAfterCursorPosition;
            setformousehover(false)

        }
        if(formousehover==false && getCkEditorText.length==2)
        {
            // konsole.log("ctrla-<");
            document.querySelector(".textarea_text").value=e.target.value
        }
        
            
        
        // setsatteonmouse(false)
    }

    const [cou34, setcou34] = useState(0)
    konsole.log("uyuyuy", atTheRateValue)


    const handleTextUpdate = (e) => {
        konsole.log("handleTextUpdate",e.keyCode)


        var keyyy = e.keyCode

        
            if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
                setformousehover(true)
                setChangeDataForLeftRight(true)
                // alert(",.")
              const textarea = e.target;
              const caretPosition = textarea.selectionStart;
              const text = textarea.value;
              const textBeforeCursor = text.substring(0, caretPosition);
              const textAfterCursor = text.substring(caretPosition);
              settextBeforeCursorPosition(textBeforeCursor)
              settextAfterCursorPosition(textAfterCursor)
            //   console.log("Text Before Cursor:", );
              konsole.log("-------", textAfterCursor,"-----",textBeforeCursor);
            }
          
          
        if (keyyy == 32 || keyyy == 13 || keyyy == 8) {
            konsole.log("enter");
            setddn(false);
            setkeystore(0);
        }
        // if (keyyy !== 50) {
        //     setddn(false);
        //     return;
        // }
        // if(keyyy==8 && CkEditorTextLength==2)
        // {
        //     settextAfterCursorPosition("")
        //     settextAfterCursorPosition("")
        //     // alert("keyyy8")
        // } 
        if (keyyy == 50 && satteonmouse == true) {

            setkeystore(keystore + 1);
            konsole.log("keypressed", keystore)
            setattherate(true)

            if (keystore == 1) {

                setddn(true);
                // setkeystore(0);
                // setsatteonmouse(true) 
                konsole.log("v503", keystore);
            }

            else if (keystore > 1) {
                konsole.log("v2503", keystore);
                setddn(false);
            }
            else if (keystore == 0) {
                konsole.log("002503", keystore);
                setddn(false);

            }
            else if (keyyy !== 50) {
                alert("!50")
                konsole.log("yes");
                setddn(false);
                setattherate(false)
                // setsatteonmouse(false)
            }
        }
        if (getCkEditorText.slice(-2) == "@@" || getCkEditorText.slice(-2) == " @@") {
            setddn(true);
            konsole.log("rrrt")
            if (keyyy == 32 || keyyy == 13 || keyyy == 37 || keyyy == 8) {
                konsole.log("enter");
                setddn(false);
                setkeystore(0);
            }

        }

        if (getCkEditorText.slice(-3) == "@@@" || getCkEditorText.slice(-4) == "@@@@") {
            setddn(false);
            konsole.log("rfrdrt")
        }
       
          
    }





    konsole.log("avavajjkjvavva", satteonmouse)
    function onmouseposition(e) {

    }


    const getValueSubjectInput = (e) => {
        setEmailSubjectValue(e?.target?.value)

    }
    konsole.log("getEmailSkjhkjubjectValue", props?.selectedRowData?.commChannelId, props?.selectedRowData?.applicableEmailTempId, channelIdData)

    const updateRecordFunction = (data) => {
        // props?.selectedRowData?.applicableTextTempId && props.selectedRowData?.commChannelId == 2
        setDisableTemplateData(true)
        // konsole.log('props?.selectedRowData?.commChannelId === 1 && getChannelValue?.value === "Email"',props?.selectedRowData?.commChannelId, getChannelValue?.value)

        if (channelIdData == 1) {
            // alert("Email inside")
            // let EmailtestAreaId =  document.getElementById('EmailtestAreaId').innerHTML
            // konsole.log("datadatadata",EmailtestAreaId)
            // setISStopApi(true)

            updateEmailRecordFunction(emialTempID);
        }
        else if (channelIdData == 2) {
            // let TexttestAreaId =  document.getElementById('TexttestAreaId').innerHTML
            // konsole.log("datadatadata",TexttestAreaId)
            // alert("text inside")
            // setISStopApi(true)

            updateTextRecordFunction(null)
        }
        else if (channelIdData == 3) {
            // alert("both inside")

            // let BothEmailAreaId =  document.getElementById('BothEmailAreaId').innerHTML
            // konsole.log("datadatadata",BothEmailAreaId)
            // setISStopApi(true)

            updateEmailRecordFunction(textTempID);

        }
        else {

            // alert("else updae function call")
        }
    }


    const updateEmailRecordFunction = (data) => {
        let EmailtestAreaId = document.getElementById('EmailtestAreaId')?.innerHTML
        let BothEmailAreaId = document.getElementById('BothEmailAreaId')?.innerHTML
        konsole.log("EmailtestAreaIdEmailtestAreaId", EmailtestAreaId)
        konsole.log("EmailtestAreaIdEmailtestAreaIdB", BothEmailAreaId)
        konsole.log(getEmailSubjectValue)
        // alert("inside function")
        if (
            (getChannelValue?.key == 1 && EmailSubject == (null || "" || undefined)) || (getChannelValue?.key == 3 && props?.selectedRowData?.applicableTextTempId != null) || (getChannelValue?.key == 1 && comChanID !== 0)) {
            // alert("if email condition ")
            let jsonObj = {
                "templateType": props?.selectedRowData?.occurrenceDetails,
                "emailSubject": getEmailSubjectValue,
                // "templateContent": getCkEditorEmail,
                "templateContent": (EmailtestAreaId !== undefined && EmailtestAreaId !== null && EmailtestAreaId !== "") ? EmailtestAreaId : BothEmailAreaId,
                "isActive": true,
                "createdBy": userId
            }

            let strValue = JSON.stringify(jsonObj)
            konsole.log("emailobjnewcreate", strValue)

            const promises = Services.postEmailTemplatesPathApi(props?.selectedRowData?.occurrenceDetails, getEmailSubjectValue, (EmailtestAreaId !== undefined && EmailtestAreaId !== null && EmailtestAreaId !== "") ? EmailtestAreaId : BothEmailAreaId, true, userId)
            promises.then((response) => {
                konsole.log("PostCkEditorEmail", response);
                setPostCkEditorEmail(response.data.data);

                if (getChannelValue?.key == 1) {
                    // alert("new email create")
                    // alert("if of both")
                    PutUpsertCommMediumApiFuctioni(response.data.data?.templateId ? response.data.data?.templateId : props?.selectedRowData?.applicableEmailTempId);

                }
                else {

                    //         let BothtestAreaId =  document.getElementById('BothtestAreaId').innerHTML
                    // konsole.log("datadatadata",BothtestAreaId)
                    // alert("else of both")
                    updateTextRecordFunction(response.data.data?.templateId ? response.data.data?.templateId : props?.selectedRowData?.applicableEmailTempId)

                }

            })
                .catch((error) => {
                    konsole.log("errorpostEmailTemplatesPathApi", error);
                })
                .finally(() => {
                    konsole.log("finish");
                });
        }
        else {
            // alert("else email condition")
            let jsonObj = {
                "templateType": props?.selectedRowData?.occurrenceDetails,
                "emailSubject": getEmailSubjectValue ? getEmailSubjectValue : inblidEmailTemplate[0]?.emailSubject,
                // "templateContent": (EmailtestAreaId !== undefined && EmailtestAreaId !== null && EmailtestAreaId !== "") ? EmailtestAreaId : BothEmailAreaId,
                "templateContent": (EmailtestAreaId !== undefined && EmailtestAreaId !== null && EmailtestAreaId !== "") ? BothEmailAreaId : EmailtestAreaId,
                "isActive": true,
                "templateId": props?.selectedRowData?.applicableEmailTempId,
                "createdBy": userId
            }

            let strValue = JSON.stringify(jsonObj)
            konsole.log("emailobj", jsonObj)


            // let templateEmailId = GetCommMediumData[0]?.applicableEmailTempId ? GetCommMediumData[0]?.applicableEmailTempId : 0

            const promises = Services.putUpdateEmailTemplatesPathApi(
                props?.selectedRowData?.occurrenceDetails,
                getEmailSubjectValue ? getEmailSubjectValue : inblidEmailTemplate[0]?.emailSubject,
                (EmailtestAreaId !== undefined && EmailtestAreaId !== null && EmailtestAreaId !== "") ? EmailtestAreaId : BothEmailAreaId,
                true,
                props?.selectedRowData?.applicableEmailTempId,
                userId
            )
            promises.then((response) => {
                konsole.log("PostCkEditorEmail", response.data.data);
                setPostCkEditorEmail(response.data.data);

                //  konsole.log("getChannelValuejljl", GetCommMediumData[0]?.commChannelId)

                if ((props?.selectedRowData?.commChannelId == 1 && getChannelValue?.key != 3) || (EmailSubject !== null && comChanID == 0 && props?.selectedRowData?.commChannelId !== 3)) {
                    // alert("email update k ander aaya")

                    PutUpsertCommMediumApiFuctioni(props?.selectedRowData?.applicableEmailTempId);
                    // alert("elsse ka if")
                }
                else {
                    // alert("textfunction call update else")
                    //         let BothtestAreaId =  document.getElementById('BothtestAreaId').innerHTML
                    // konsole.log("datadatadata",BothtestAreaId)

                    updateTextRecordFunction(props?.selectedRowData?.applicableEmailTempId)
                    // alert("elsse ka else")
                }
                // konsole.log("templateId", response.data.data.templateId)

            })
                .catch((error) => {
                    konsole.log("errorpostEmailTemplatesPathApi", error);
                })
                .finally(() => {
                    konsole.log("finish");
                });
        }


    }

    // konsole.log("getEmailSubjectValue", postCkEditorEmail)


    const updateTextRecordFunction = (EmailId, TexttestAreaId) => {
        // alert("inside text update function ")
        // if (getCkEditorText == "<p><br></p>" || getCkEditorText == "" || getCkEditorText == null || getCkEditorText == undefined) {
        //     message.error("a")
        //     // message.success("kljll")
        // } else {

        let jsonObj = {
            "textTemplateId": props?.selectedRowData?.applicableTextTempId ? props?.selectedRowData?.applicableTextTempId : 0,
            "textTemplateType": props?.selectedRowData?.occurrenceDetails,
            "textTemplateContent": getCkEditorText ? getCkEditorText : inblidTextTemplate[0]?.textTemplateContent,
            "isActive": true,
            "upsertedBy": userId
        }

        let textTemplateId = props?.selectedRowData?.applicableTextTempId ? props?.selectedRowData?.applicableTextTempId : 0

        let strValue = JSON.stringify(jsonObj)
        // konsole.log("textobj", jsonObj)

        const promises = Services.postTextTemplatesPathApi(
            textTemplateId,
            props?.selectedRowData?.occurrenceDetails,
            getCkEditorText ? getCkEditorText : inblidTextTemplate[0]?.textTemplateContent,
            true,
            userId
        )
        // konsole.log("data1", 0,
        //     getOccurrenceSelectedValue,
        //     getCkEditorText,
        //     true,
        //     userId)
        promises.then((response) => {
            konsole.log("PostCkEditorText", response.data.data);
            setPutCkEditorText(response.data.data)

            if ((props?.selectedRowData?.commChannelId == 2 && getChannelValue?.key != 3) || getChannelValue?.key == 2) {
                // alert("text function call kiya hai")

                PutUpsertCommMediumApiFuctioni(response.data.data?.textTemplateId ? response.data.data?.textTemplateId : props?.selectedRowData?.applicableTextTempId);

            }
            else {
                // alert("else text function update")
                PutUpsertCommMediumApiFuctioni(EmailId, props?.selectedRowData?.applicableTextTempId ? props?.selectedRowData?.applicableTextTempId : response.data.data?.textTemplateId);
            }
        })
            .catch((error) => {
                konsole.log("errorpostTextTemplatesPathApi", error);
                seterrortexarea(true)
            })
            .finally(() => {
                konsole.log("finish");
            });
        // }

    }

    konsole.log("putCkEditorText", putCkEditorText)

    //Api Code

    // 

    useEffect(() => {

        const promises = Services.atTheRateGetOccurrenceDatalApi(props.selectedRowData.occurrenceId, true)
        promises.then((response) => {
            konsole.log("resssresslklk", response.data.data);
            setAtTheRateSelectData(response.data.data)
        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });

    }, [])


    konsole.log("atTheRateSelectData", atTheRateSelectData)

    const getCommonChannelfunction = () => {
        const promises = Services.getCommonChannelApi()
        promises.then((response) => {
            // konsole.log("resssress", response);
            setCommonChannelSelect(response.data.data);
            // konsole.log(response.data.data)
            setChannelEmailLabel(response.data.data[0]?.label)
            setChannelTextLabel(response.data.data[1]?.label)
            setChanneBothLabel(response.data.data[2]?.label)


        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };

    const getUserRolesfunction = () => {
        const promises = Services.getUSerRolesAdminApi()
        promises.then((response) => {
            // konsole.log("resssress", response);
            setUserRoleSelect(response?.data?.data);

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };

    // konsole.log("getUserRoleSelect", getUserRoleSelect)

    const getEmailTemplatesPathApiFunction = (subtenantID, emailID) => {
        //  alert(subtenantID,emailID)
        konsole.log("subtenanlkjlj", subtenantID, emailID)
        const emailObj = props.inblidEmailTemplate.filter(ele => ele.templateId == emailID)

        if(emailObj.length) {
            setInblidEmailTemplate(emailObj);
            setEmailTempID(emailObj[0]?.templateId)
        }

        // const promises = Services.getEmailTemplatesPathApi(subtenantID, emailID)
        // promises.then((response) => {
        //     konsole.log("EmailTemplatesData", response?.data?.data);
        //     setInblidEmailTemplate(response?.data?.data);
        //     setEmailTempID(response.data?.data[0]?.templateId)

        // })
        //     .catch((error) => {
        //         konsole.log("error", error);
        //     })
        //     .finally(() => {
        //         konsole.log("finish");
        //         props.GetCommMediumPathApiFuction()
        //     });
    };



    const getTextTemplatesPathApiFunction = (subtenantID, id) => {
        // konsole.log("subtenanlkjljkhkh", subtenantID, id)

        const promises = Services.getTextTemplatesPathApi(subtenantID, id)
        promises.then((response) => {
            konsole.log("TextTemplatesData", response.data.data);
            setInblidTextTemplate(response?.data?.data);
            setTextTempID(response.data?.data[0]?.textTemplateId)

        })
            .catch((error) => {
                konsole.log("error", error);
            })
            .finally(() => {
                konsole.log("finish");
            });
    };


    const PutUpsertCommMediumApiFuctioni = (templateId, textTempId, TexttestAreaId) => {

        // alert("dssdddllll")
        // From array filter data start

        let arrData = [], newArr = [];
        let arrayNew = []
        let FromArrData = []
        arrData.push(props?.selectedRowData?.commMediumRoles);

        for (let i = 0; i <= arrData[0]?.length - 1; i++) {
            // konsole.log("arrDataarrDataarrDataarrData", arrData[0]?.[i]?.fromRoleName)
            newArr.push(arrData[0]?.[i]?.fromRoleId)
        }
        // konsole.log("NewArrayArrkjllllyNewArray", newArr)

        // konsole.log("NewArrayArrayNewArrayNewArrayArrayNewArray", [...new Set(newArr)])

        arrayNew.push([...new Set(newArr)])

        FromArrData.push(arrayNew)
        // konsole.log("hhhhhhhhhwArray", FromArrData[0]?.[0]?.[1])

        // From array filter data end


        // To select array filter data start

        let ToarrData = [], TonewArr = [];
        let ToarrayNew = []
        let ToArrData = []

        ToarrData.push(props?.selectedRowData?.commMediumRoles);

        // konsole.log("ToarrData", ToarrData)

        for (let i = 0; i <= ToarrData[0]?.length - 1; i++) {
            // konsole.log("arrDataarrDataarrDataarrData", arrData[0]?.[i]?.fromRoleName)
            TonewArr.push(ToarrData[0]?.[i]?.toRoleId)
        }
        // konsole.log("NewArrayArrayNewArrayNewArrayArrayNewArray", [...new Set(TonewArr)])

        ToarrayNew.push([...new Set(TonewArr)])

        ToArrData.push(ToarrayNew)
        // konsole.log("TTTTTToooooooooArray", ToArrData[0]?.[0]?.[1])

        // To select array filter data end

        // let arr = [];
        // let i = 0, j = 0;
        // let k = 0;

        // for (i = 0; i <= FromArrData[0]?.[0]?.length - 1; i++) {

        //     for (j = 0; j <= ToArrData[0]?.[0]?.length - 1; j++) {

        //         arr.push(
        //             {
        //                 "commMediumRolesId": props?.selectedRowData?.commMediumRoles[k]?.commMediumRolesId,
        //                 "fromRoleId": FromArrData[0]?.[0]?.[i],
        //                 "toRoleId": ToArrData[0]?.[0]?.[j],
        //                 "isActive": false
        //             }
        //         )
        //         k++;
        //     }
        // }

        // konsole.log("uiuijgjggjgkgk", RoleToLength)

        // for (i = 0; i <= (RoleFromLength > 0 ? RoleFromLength - 1 : getSelectRoleFromValue?.length - 1); i++) {
        //     for (j = 0; j <= (RoleToLength > 0 ? RoleToLength - 1 : getSelectRoleToValue?.length - 1); j++) {

        //         arr.push(
        //             {
        //                 "commMediumRolesId": 0,
        //                 "fromRoleId": FromArrData[0]?.[0]?.[i] ? FromArrData[0]?.[0]?.[i] : getSelectRoleFromValue[i]?.key,
        //                 "toRoleId": ToArrData[0]?.[0]?.[j] ? ToArrData[0]?.[0]?.[j] : getSelectRoleToValue[j]?.key,
        //                 "isActive": true

        //             }
        //         )
        //     }
        // }
        // ............................................ Kartik .................................................................

        let arr = []
        let finalArray = []



        // konsole.log("onFinish Arr test",fromToRoles)

        for (let i = 0; i < getSelectRoleFromValue.length; i++) {
            for (let j = 0; j < getSelectRoleToValue.length; j++) {

                arr.push(
                    {
                        "commMediumRolesId": 0,
                        "fromRoleId": parseInt(getSelectRoleFromValue[i]?.key),
                        "toRoleId": parseInt(getSelectRoleToValue[j]?.key),
                        "isActive": true

                    }
                )
            }
        }



        let RepeatedData = getRepeated(arr, fromToRoles)
        let removedData = getRemoved(arr, fromToRoles)
        let newGetData = getNewData(arr, fromToRoles)


        konsole.log("onFinish Arr test", RepeatedData, removedData, newGetData)

        for (let i = 0; i < RepeatedData?.length; i++) {
            finalArray.push(
                {
                    "commMediumRolesId": RepeatedData[i]?.commMediumRolesId,
                    "fromRoleId": RepeatedData[i]?.fromRoleId,
                    "toRoleId": RepeatedData[i]?.toRoleId,
                    "isActive": true
                }
            )
        }

        for (let i = 0; i < removedData?.length; i++) {
            finalArray.push(
                {
                    "commMediumRolesId": removedData[i]?.commMediumRolesId,
                    "fromRoleId": removedData[i]?.fromRoleId,
                    "toRoleId": removedData[i]?.toRoleId,
                    "isActive": false
                }
            )
        }

        for (let i = 0; i < newGetData?.length; i++) {
            finalArray.push(
                {
                    "commMediumRolesId": 0,
                    "fromRoleId": newGetData[i]?.fromRoleId,
                    "toRoleId": newGetData[i]?.toRoleId,
                    "isActive": true
                }
            )
        }

        konsole.log("onFinish Arr test", finalArray)

        // ............................................ Kartik .................................................................


        konsole.log("arayfirjlkjjlj", finalArray);
        // konsole.log("putid", putCkEditorText)

        let obj = (props?.selectedRowData?.commChannelId === 3) && (templateId && textTempId) || getChannelValue?.key == 3 ? {
            "commMediumId": props?.selectedRowData?.commMediumId,
            "commChannelId": getChannelValue?.key ? getChannelValue?.key : props?.selectedRowData?.commChannelId,
            "subtenantId": subtenantID,
            "applicableEmailTempId": templateId,
            "applicableTextTempId": textTempId,
            "occurrenceId": props?.selectedRowData?.occurrenceId,
            "isActive": true,
            "upsertedBy": userId,
            "commMediumRoles": finalArray
        }
            : (props?.selectedRowData?.commChannelId === 2 && templateId && getChannelValue?.key != 1) || getChannelValue?.key == 2 ? {
                "commMediumId": props?.selectedRowData?.commMediumId,
                "commChannelId": getChannelValue?.key ? getChannelValue?.key : props?.selectedRowData?.commChannelId,
                "subtenantId": subtenantID,
                "applicableTextTempId": templateId,
                "occurrenceId": props?.selectedRowData?.occurrenceId,
                "isActive": true,
                "upsertedBy": userId,
                "commMediumRoles": finalArray
            }
                : (props?.selectedRowData?.commChannelId === 1 && templateId && getChannelValue?.key != 2) || (props?.selectedRowData?.commChannelId === 1 && templateId && getChannelValue?.key == 2) || getChannelValue?.key == 1 ? {
                    "commMediumId": props?.selectedRowData?.commMediumId,
                    "commChannelId": getChannelValue?.key ? getChannelValue?.key : props?.selectedRowData?.commChannelId,
                    "subtenantId": subtenantID,
                    "applicableEmailTempId": templateId,
                    "occurrenceId": props?.selectedRowData?.occurrenceId,
                    "isActive": true,
                    "upsertedBy": userId,
                    "commMediumRoles": finalArray
                } : {}

        konsole.log("putobj", obj)

        let strValue = JSON.stringify(obj)
        konsole.log("strValuestrValue", strValue)

        if (isStopApi == true) {
            setLoader(true)
            const promises = Services.postUpsertCommMediumApi(obj)
            promises.then((response) => {
                konsole.log("hkhkjlkkhlhlh", response?.data?.data);
                setPutAllData(response.data.data)
                getTextTemplatesPathApiFunction(subtenantID, response?.data?.data[0]?.applicableTextTempId)
                getEmailTemplatesPathApiFunction(subtenantID, response?.data?.data[0]?.applicableEmailTempId)
                props.setupdateTableData(true)
                setISDisabled(true)
                setDisableTemplateData(true)
                setLoader(false)
                setISUpdate(false)
                handleClose();

            })
                .catch((error) => {
                    konsole.log("error", error);
                })
                .finally(() => {
                    konsole.log("finish");
                    props.GetCommMediumPathApiFuction()
                    setLoader(false)

                });
        }
        else {
            // alert("else")
            handleClose();
            // window.location.reload()
        }
    }
    const [getRolesFromTo, setGetRolesFromTo] = useState([])

    const updateRolesFunc = (arrFlat) => {
        let updateData = [];
        for (let i = 0; i < arrFlat.length; i++) {
            updateData.push(
                // {
                //     toRoleId :  arrFlat[i]?.toRoleId,
                //     fromRoleId : arrFlat[i]?.fromRoleId,
                //     commMediumId : arrFlat[i]?.commMediumId,
                //     commMediumRolesId : arrFlat[i]?.commMediumRolesId,
                //     key: arrFlat[i]?.fromRoleId
                // }
                {
                    children: arrFlat[i]?.fromRoleName,
                    key: arrFlat[i]?.fromRoleId,
                    value: arrFlat[i]?.fromRoleName
                }
            )
        }

        let newArrayData = updateData.filter((v, i, a) => a.findIndex(v2 => (v2.key === v.key)) === i)
        konsole.log("updateDataupdateData", newArrayData)
        setSelectRoleFromValue(newArrayData)

    }
    konsole.log("getRolesFromTogetRolesFromTo", getRolesFromTo)


    const fromRoleNameFuction = (data) => {

        let arrData = [], newArr = [];
        arrData.push(data);
        konsole.log("formarrData", arrData)
        let arrFlat = arrData.flat(1)
        updateRolesFunc(arrFlat)
        for (let i = 0; i <= arrData[0]?.length - 1; i++) {
            newArr.push(arrData[0]?.[i]?.fromRoleName)
        }
        for (let k = 1; k <= [...new Set(newArr)].length; k++) {
            setRoleFromLength(k);
        }
        return [...new Set(newArr)]
    }


    const updateAssignRoleFunc = (data) => {

        let updateAssignRoles = []

        for (let i = 0; i < data.length; i++) {
            updateAssignRoles.push(
                {
                    children: data[i]?.toRoleName,
                    key: data[i]?.toRoleId,
                    value: data[i]?.toRoleName
                }
            )
        }

        let newArrayData = updateAssignRoles.filter((v, i, a) => a.findIndex(v2 => (v2.key === v.key)) === i)
        konsole.log("updateAssignRoles", newArrayData)
        setSelectRoleToValue(newArrayData)
    }

    const ToRoleNameFuction = (data) => {

        let arrData = [], newArr = [];
        arrData.push(data);
        updateAssignRoleFunc(data)
        for (let i = 0; i <= arrData[0]?.length - 1; i++) {
            newArr.push(arrData[0]?.[i]?.toRoleName)
        }
        for (let q = 1; q <= [...new Set(newArr)].length; q++) {
            setRoleToLength(q);
        }
        return [...new Set(newArr)]
    }

    const [disableTemplateData, setDisableTemplateData] = useState(true)
    const [linkState, setLinkstate] = useState(false)

    const onFinish = (e) => {

        if (isUpdate == true) {

            setLoader(true)
            setISStopApi(true)
            updateRecordFunction()

        }
        else {
            if (isStopApi == true) {
                setLinkstate(true)
            }
            setISUpdate(true)
            setISDisabled(false)
            setDisableTemplateData(false)
            setDisableTemplateData(false)
        }
    }

    const getRepeated = (array1, array2) => {
        return array2?.filter(object1 => {
            return array1?.some(object2 => {
                return (object1?.fromRoleId == object2?.fromRoleId && object1?.toRoleId == object2?.toRoleId)
                    ;
            });
        });
    }
    const getRemoved = (array1, array2) => {
        return array2?.filter(object1 => {
            return !array1?.some(object2 => {
                return (object1?.fromRoleId == object2?.fromRoleId && object1?.toRoleId == object2?.toRoleId)
                    ;
            });
        });
    }
    const getNewData = (array1, array2) => {
        return array1?.filter(object1 => {
            return !array2?.some(object2 => {
                return (object1?.fromRoleId == object2?.fromRoleId && object1?.toRoleId == object2?.toRoleId)
                    ;
            });
        });
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            For Link
        </Tooltip>
    );
    const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            For Button
        </Tooltip>
    );

    const linkCreateButtonFunction = () => {

        let userLink = prompt("Enter a URL");
        //if link has http then pass directly else add https
        if (/http/i?.test(userLink)) {
            modifyText(linkButton?.id, false, userLink);
            setISUpdate(false)
        } else {
            userLink = "" + userLink;
            modifyText(linkButton?.id, false, userLink);
            setISUpdate(false)
        }

    }
    const [pos, setpos] = useState()
    var getCaretCoordinates = require('textarea-caret');
    const [Top2, setTop] = useState(0)
    const [Left2, setLeft] = useState(0)
    const [Height2, setHeight] = useState(0)
    function typeInTextarea(newText, el = document.activeElement) {
        const [start, end] = [el.selectionStart, el.selectionEnd];
        el.setRangeText(newText, start, end, 'select');
    }

    return (
        <div>

            <Modal
                centered={true}
                className="Modal-Main-Class"
                title={<div className='d-flex justify-content-center'>{isUpdate == true ? "Update Content" : "View Content"} </div>}
                visible={props?.showAddModal}
                footer={false}
                onCancel={handleClose}
                closeIcon={<div className='text-light' style={{ fontFamily: "sans-serif", fontWeight: 100 }}> X</div>}
                maskClosable={false}
            // style={{ marginBottom: "40px" }}

            >
                <Form form={form} onFinish={onFinish}
                //         style={{
                //     maxHeight: "calc(100vh - 210px)",
                //     overFlowY : "auto"
                // }}
                >
                    <Row>
                        <Col span={24} xs={24} sm={24} md={24} lg={24} >
                            <h6 style={{ marginLeft: "1px" }}>Occurrence</h6>
                            <Form.Item
                                name="occurrence"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Occurrence"
                                    }
                                ]}>
                                <Select
                                    className='SeminarRegistrationSelect'
                                    disabled
                                    showArrow={false}
                                >

                                </Select>
                            </Form.Item>

                        </Col>


                    </Row>

                    <Row className='d-flex justify-content-between'>
                        <Col className='CommunicationChannel-Col' span={8} xs={24} sm={24} md={8} lg={7} >
                            <h6 style={{ marginLeft: "1px" }}>Communication Channel</h6>
                            <Form.Item
                                name="commChannel"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select channel"
                                    }
                                ]}>
                                <Select size='sm'
                                    className='commChannelSelect'
                                    listHeight={100}
                                    onChange={getCommonChannelValue}
                                    placeholder="Communication Channel"
                                    style={{ width: "100%" }}
                                    disabled={isDisabled}

                                >
                                    {commonChannelSelect.map((data) => (

                                        <option value={data?.label} key={data?.value}>{data?.label}</option>

                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} sm={24} md={8} lg={7}>
                            <h6 style={{ marginLeft: "1px" }}>From</h6>
                            <Form.Item
                                name="roleFrom"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Role"
                                    }
                                ]}
                                className="asignRoleTo-col m-0 pb-2"

                            >
                                <Select
                                    showSearch
                                    listHeight={100}
                                    style={{ width: "100%" }}
                                    className='commChannelSelect'
                                    mode="multiple"
                                    maxTagCount={'responsive'}
                                    showArrow
                                    onChange={handleFromRoleChange}
                                    placeholder="Select a role"
                                    disabled={isDisabled}
                                >
                                    {
                                        getUserRoleSelect.map((userdata) => (
                                            <option value={userdata?.label} key={userdata?.value}>{userdata?.label}</option>

                                        ))
                                    }
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={8} xs={24} sm={24} md={8} lg={7} className="asignRoleTo-col m-0 p-0">
                            <h6 style={{ marginLeft: "1px" }}>To</h6>
                            <Form.Item
                                name="asignRoleTo"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Asign Role"
                                    }
                                ]}
                                className="asignRoleTo-col m-0 pb-3"
                            >
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    className='commChannelSelect'
                                    listHeight={100}
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    showArrow
                                    onChange={handleToRoleChange}
                                    placeholder="Select a role"
                                    disabled={isDisabled}

                                >
                                    {
                                        getUserRoleSelect.map((userdata) => (
                                            <option value={userdata?.label} key={userdata?.value}>{userdata?.label}</option>


                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row className='justify-content-end'>
                        <Col span={24} lg={4} className="m-0 p-0 d-flex justify-content-end" >
                            {/* <div className="m-0 p-0 buttonClass" style={{ zIndex: "5" }}> */}
                            <Form.Item>
                                <Button
                                    className="Add-Edit-btton text-white"
                                    htmlType="submit"
                                    type='primary'
                                    style={{ backgroundColor: "#720c20" }}
                                >
                                    {isUpdate == true ? "Update" : "Edit"}
                                </Button>
                            </Form.Item>
                            {/* </div> */}


                        </Col>
                        <Col span={24} lg={2} className="m-0 p-0 d-flex justify-content-end">

                            {
                                props?.selectedRowData?.applicableEmailTempId && props.selectedRowData?.commChannelId == 1 && getChannelValue?.key != 2
                                    || (getChannelValue?.key == 1 && selectedChannelID == 1)
                                    || props?.selectedRowData?.applicableEmailTempId && props?.selectedRowData?.applicableTextTempId && getChannelValue?.key != 1 && getChannelValue?.key != 2 || (getChannelValue?.key == 3 && selectedChannelID == 3)
                                    ?
                                    <div style={{ width: "36px", height: "35px", }}>

                                        <button id="createLink2" className="adv-option-button "
                                            onClick={() => {
                                               if (initialcommchannelid === 1) {
                                                    linkCreateButtonFunctionButton("EmailtestAreaId");
                                                    konsole.log("EmailtestAreaId")
                                                }
                                                if (initialcommchannelid === 3) {
                                                    linkCreateButtonFunctionButton("BothEmailAreaId");
                                                    konsole.log("BothEmailAreaId")
                                                }


                                            }}
                                            disabled={isDisabled}
                                            style={{ backgroundColor: "white", float: "right", cursor: "pointer", border: "none", paddingRight: "0px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 100, hide: 100 }}
                                                overlay={renderTooltip2}
                                            >
                                                <img src="images/link5.jpg" className='img-fluid linkicons' style={{ opacity: ".7" }}


                                                />
                                            </OverlayTrigger>
                                        </button>






                                    </div>


                                    : ""}
                        </Col>
                        <Col span={24} lg={2} className="m-0 p-0 d-flex justify-content-end">
                            {
                                props?.selectedRowData?.applicableEmailTempId && props.selectedRowData?.commChannelId == 1 && getChannelValue?.key != 2
                                    || (getChannelValue?.key == 1 && selectedChannelID == 1)
                                    || props?.selectedRowData?.applicableEmailTempId && props?.selectedRowData?.applicableTextTempId && getChannelValue?.key != 1 && getChannelValue?.key != 2 || (getChannelValue?.key == 3 && selectedChannelID == 3)
                                    ?
                                    <div style={{ width: "36px", height: "35px", }}>

                                        <button id="createLink" className="adv-option-button "
                                            onClick={linkCreateButtonFunctionLink}
                                            disabled={isDisabled}
                                            style={{ backgroundColor: "white", float: "right", cursor: "pointer", border: "none", paddingRight: "0px" }}>

                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 1, hide: 1 }}
                                                overlay={renderTooltip}

                                            >
                                                <img src="images/link5.jpg" className='img-fluid linkicons' style={{ opacity: ".7" }}

                                                /></OverlayTrigger>
                                        </button>

                                    </div>


                                    : ""}
                        </Col>


                    </Row>


                    <ModalBody
                        className='Modal-Body-Add-Modal'
                        style={{
                            maxHeight: 'calc(100vh - 116px)',
                            overflowY: 'auto',
                            height: "auto"


                        }}
                    >


                        <Row style={{ marginRight: "5px" }} >
                            <Col span={24}>

                                {

                                    props?.selectedRowData?.applicableEmailTempId && props?.selectedRowData?.applicableTextTempId && getChannelValue?.key != 1 && getChannelValue?.key != 2
                                        || (getChannelValue?.key == 3 && selectedChannelID == 3)
                                        ?
                                        <div>
                                            <Row >
                                                <Col span={24}>
                                                    <Row className='d-flex justify-content-between mt-2'>

                                                        <div className='d-flex justify-content-between w-100 '>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <h6>SMS-Text</h6>
                                                            </div>

                                                            {ddn == true ?
                                                            // <div>
                                                                   <select
                                                                    size={atTheRateSelectData?.length} 
                                                                    className=""
                                                                    onChange={(e) => {getAtTheRateSelectFunction(e);}}
                                                                    placeholder="occurrence Predefine Name"
                                                                    style={{ width: "30%", height: "AUTO", position: "absolute", top: Top2 + 65, left: Left2, zIndex: "199999999999",maxWidth: "100%" }}
                                                                    disabled={isDisabled}>
                                                                    {atTheRateSelectData?.map((data) => <option value={data?.occurrenceData} key={data?.occurrenceDataMapId}>{data?.occurrenceData}</option>)}</select>
                                                                    // </div>
                                                                
                                                                : ""}
                                                           
                                                             
                                                        
                                                        </div>

                                                        <Col className='Ck-Editor-Text-Col' span={11} xs={24} sm={24} md={24} lg={24}>
                                                            <textarea
                                                                placeholder='Please enter sms-text'
                                                                defaultValue={props?.selectedRowData?.applicableTextTempId ? inblidTextTemplate[0]?.textTemplateContent :getCkEditorText }
                                                                onKeyUp={handleTextUpdate}
                                                                onChange={(e) => {                                                                                          
                                                                    konsole.log("getCkEditorText",e.target.value.length)
                                                                    const textarea = e.target;
                                                                    const caretPosition = textarea.selectionStart;
                                                                    const text = textarea.value;
                                                                    const textBeforeCursor = text.substring(0, caretPosition);
                                                                    const textAfterCursor = text.substring(caretPosition);
                                                                    settextBeforeCursorPosition(textBeforeCursor)
                                                                    settextAfterCursorPosition(textAfterCursor)
                                                                    konsole.log("onchnage",textBeforeCursor,textAfterCursor)
                                                                    setCkEditorText(e.target.value);
                                                                    setCkEditorTextLength(e.target.value)
                                                                    if (getCkEditorText !== "") {seterrortexarea(false); }
                                                                    var getCaretCoordinates = require('textarea-caret');
                                                                    var caret = getCaretCoordinates(document.getElementById("hellotext"), document.getElementById("hellotext").selectionEnd);
                                                                    setTop(caret.top)
                                                                    setLeft(caret.left);
                                                                    setHeight(caret.height);
                                                                    if (getCkEditorText == null || getCkEditorText == undefined) { setCkEditorText("")}

                                                                }}
                                                                onMouseUp={(e) => {

                                                                    setsatteonmouse(true)
                                                                    if (e.target.selectionEnd === e.target.value.length - 2) {
                                                                        konsole.log("mouse used")
                                                                        setpos(e.target.selectionStart)
                                                                        settextBeforeCursorPosition(e.target.value.substring(0, e.target.selectionStart))
                                                                        settextAfterCursorPosition(e.target.value.substring(e.target.selectionStart, e.target.value.length))
                                                                        setsatteonmouse(true)
                                                                        setddn(false);
                                                                        setkeystore(0);
                                                                        setformousehover(false)
                                                                    }
                                                                    if (e.target.selectionStart < e.target.value.length - 2) {
                                                                        setformousehover(true)

                                                                        konsole.log("mouseup", e.target.selectionStart, e.target.value.length - 2)
                                                                        setpos(e.target.selectionStart)
                                                                        settextBeforeCursorPosition(e.target.value.substring(0, e.target.selectionStart))
                                                                        settextAfterCursorPosition(e.target.value.substring(e.target.selectionStart, e.target.value.length))
                                                                        setsatteonmouse(true)
                                                                        setddn(false);
                                                                        setkeystore(0);
                                                                    }
                                                                }}
                                                                disabled={isDisabled}
                                                                id="hellotext"
                                                                className="textarea_text m-0 p-2 mb-0 h-100 w-100"
                                                                rows="4"
                                                            />
                                                        </Col>

                                                        { errortexarea ? <p className='text-danger '>Please enter sms-text</p> : ""}

                                                        {konsole.log("getCkEditorText + atTheRateValue", getCkEditorText + atTheRateValue)}
                                                        <Col span={11} xs={24} sm={24} md={24} lg={24} className="mt-2 mb-2">
                                                            <h6>Email Subject </h6>
                                                            <div className='mb-3 '>
                                                                <Form.Item
                                                                    name="emailsubject"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please Enter email subject"
                                                                        }
                                                                    ]}>
                                                                    <Input placeholder="Enter Email Subject"
                                                                        disabled={isDisabled}
                                                                        value={getEmailSubjectValue}
                                                                        onChange={getValueSubjectInput}
                                                                        onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                                                    />
                                                                </Form.Item>
                                                            </div>



                                                            {/* <JoditEditor
                                                                value={getChannelValue?.value == "Both" ? templateDataEdit : props?.selectedRowData?.applicableEmailTempId ? inblidEmailTemplate[0]?.templateContent
                                                                    :
                                                                    getEmailTemplateSelect == false ? postOccurrence || GetCommMediumData?.length == 0
                                                                        || GetCommMediumData[0]?.applicableEmailTempId == ("" || null || undefined) ? ""
                                                                        : inblidEmailTemplate[0]?.templateContent : inblidEmailTemplate[0]?.templateContent}
                                                                // ref={editor}
                                                                config={config}
                                                                onChange={(e) => handleUpdate(e)}
                                                                tabIndex={1}
                                                                // onChange={onChangeFunction}
                                                            /> */}

                                                            {/* <div className='d-flex justify-content-center'> */}

                                                                <div placeholder='enter your data1' style={{ border: "1px solid grey", width: "90%" }} className='textarea testingtestareas m-auto'
                                                                    id="BothEmailAreaId" rows={20} disabled={disableTemplateData} contenteditable="true" dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            // getChannelValue?.value == "Both" ? templateDataEdit : props?.selectedRowData?.applicableEmailTempId ? inblidEmailTemplate[0]?.templateContent
                                                                            // :
                                                                            // getEmailTemplateSelect == false ? postOccurrence || GetCommMediumData?.length == 0
                                                                            //     || GetCommMediumData[0]?.applicableEmailTempId == ("" || null || undefined) ? ""
                                                                            //     : inblidEmailTemplate[0]?.templateContent : inblidEmailTemplate[0]?.templateContent
                                                                            getChannelValue?.key == 3 && (inblidEmailTemplate[0]?.templateContent !== null && inblidEmailTemplate[0]?.templateContent !== undefined && inblidEmailTemplate[0]?.templateContent !== "") || props?.selectedRowData?.applicableEmailTempId ? inblidEmailTemplate[0]?.templateContent : templateDataEdit
                                                                    }}>
                                                                </div>
                                                            {/* </div> */}




                                                            {konsole.log("BothEmailData", props?.selectedRowData?.applicableTextTempId)}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        :

                                        props?.selectedRowData?.applicableTextTempId && props.selectedRowData?.commChannelId == 2 && getChannelValue?.key != 1
                                            || (getChannelValue?.key == 2 && selectedChannelID == 2)
                                            ? <>

                                                <Row>
                                                    <Col span={24}>
                                                        <Row className='d-flex justify-content-between mt-2'>

                                                            <div className='d-flex justify-content-between w-100 '>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <h6>SMS-Text </h6>
                                                                </div>


                                                            </div>

                                                            <div style={{ width: "100%", }} className="m-0 p-0" >
                                                                <textarea placeholder='Please enter sms-text'
                                                                    defaultValue={props?.selectedRowData?.applicableTextTempId ? inblidTextTemplate[0]?.textTemplateContent :
                                                                        getCkEditorText
                                                                    }

                                                                    onKeyUp={handleTextUpdate}
                                                                    onChange={(e) => {

                                                                        const textarea = e.target;
                                                                        const caretPosition = textarea.selectionStart;
                                                                        const text = textarea.value;
                                                                        const textBeforeCursor = text.substring(0, caretPosition);
                                                                        const textAfterCursor = text.substring(caretPosition);
                                                                        settextBeforeCursorPosition(textBeforeCursor)
                                                                        settextAfterCursorPosition(textAfterCursor)
                                                                        setCkEditorText(e.target.value);
                                                                        setCkEditorTextLength(e.target.value)
                                                                        if (getCkEditorText !== "") {seterrortexarea(false)}
                                                                        var getCaretCoordinates = require('textarea-caret');
                                                                        var caret = getCaretCoordinates(document.getElementById("hellotext"), document.getElementById("hellotext").selectionEnd);
                                                                        setTop(caret.top)
                                                                        setLeft(caret.left);
                                                                        setHeight(caret.height);
                                                                        if (getCkEditorText == null || getCkEditorText == undefined) { setCkEditorText("")}

                                                                    }}
                                                                    onMouseUp={(e) => {
                                                                        setsatteonmouse(true)
                                                                        if (e.target.selectionEnd === e.target.value.length - 2) {
                                                                            konsole.log("mouse used")
                                                                            setpos(e.target.selectionStart)
                                                                            settextBeforeCursorPosition(e.target.value.substring(0, e.target.selectionStart))
                                                                            settextAfterCursorPosition(e.target.value.substring(e.target.selectionStart, e.target.value.length))
                                                                            setsatteonmouse(true)
                                                                            setddn(false);
                                                                            setkeystore(0);
                                                                            setformousehover(false)
                                                                        }

                                                                        if (e.target.selectionStart < e.target.value.length - 2) {
                                                                            setformousehover(true)
                                                                            konsole.log("mouseup", e.target.selectionStart, e.target.value.length - 2)
                                                                            setpos(e.target.selectionStart)
                                                                            settextBeforeCursorPosition(e.target.value.substring(0, e.target.selectionStart))
                                                                            settextAfterCursorPosition(e.target.value.substring(e.target.selectionStart, e.target.value.length))
                                                                            setsatteonmouse(true)
                                                                            setddn(false);
                                                                            setkeystore(0);
                                                                        }



                                                                    }}
                                                                    disabled={isDisabled}
                                                                    id="hellotext"
                                                                    className="textarea_text m-0 p-2 mb-0 h-100 w-100"
                                                                    rows="4"

                                                                />
                                                            </div>
                                                            {/* </Col> */}
                                                            <Col span={24} className='m-0 p-0'>
                                                                {ddn == true ?
                                                                    <select
                                                                        id="selectsd"
                                                                        size={atTheRateSelectData?.length}
                                                                        onChange={
                                                                            (e) => {
                                                                                getAtTheRateSelectFunction(e);
                                                                                konsole.log("ENDING", textBeforeCursorPosition, textAfterCursorPosition)
                                                                            }
                                                                        }


                                                                        placeholder="occurrence Predefine Name"
                                                                        style={{ width: "30%", height: "auto", position: "absolute", top: Top2 - 140, left: Left2 }}

                                                                    >
                                                                        {atTheRateSelectData?.map((data) => (

                                                                            <option value={data?.occurrenceData} key={data?.occurrenceDataMapId}>
                                                                                {data?.occurrenceData}
                                                                            </option>

                                                                        ))}

                                                                    </select>
                                                                    : ""}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                            :

                                            props?.selectedRowData?.applicableEmailTempId && props.selectedRowData?.commChannelId == 1 && getChannelValue?.key != 2
                                                || (getChannelValue?.key == 1 && selectedChannelID == 1)
                                                ?
                                                <>
                                                    <Row className="pt-2">
                                                        <Col span={24}>
                                                            <Row className='d-flex justify-content-between'>

                                                                <Col span={24} xs={24} sm={24} md={24} lg={24} >
                                                                    <h6>Email Subject</h6>
                                                                    <div className='mb-2'>
                                                                        <Form.Item
                                                                            name="emailsubject"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter email subject"
                                                                                }
                                                                            ]}>

                                                                            <Input placeholder="Enter Email Subject"
                                                                                disabled={isDisabled}
                                                                                value={getEmailSubjectValue}
                                                                                onChange={getValueSubjectInput}
                                                                                onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}

                                                                            />
                                                                        </Form.Item>
                                                                    </div>


                                                                    {/* <div className='d-flex justify-content-center'> */}

                                                                        <div placeholder='enter your data' style={{ border: "1px solid grey", width: "90%" }}
                                                                            className='textarea testingtestareas m-auto'


                                                                            id="EmailtestAreaId" rows={20} disabled={disableTemplateData} contenteditable="true" dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    getChannelValue?.key == 1 && inblidEmailTemplate[0]?.templateContent !== ("" || null || undefined) || props?.selectedRowData?.applicableEmailTempId ? inblidEmailTemplate[0]?.templateContent : templateDataEdit
                                                                            }}
                                                                        >
                                                                        </div>
                                                                    {/* </div> */}




                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </> : ""
                                }



                            </Col>
                        </Row>
                    </ModalBody>
                </Form>


            </Modal>
        </div>
    )
}

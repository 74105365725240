import React, { useEffect, useState, useContext } from "react";
import "./Firmpartner.css";
import {
  Select,
  Tag,
  Form,
  Button,
  Table,
  DatePicker,
  TimePicker,
  Space,
  Row,
  Col,
  Checkbox,
  message,
  Alert,
} from "antd";

import Services from "../../network/Services";
import moment from "moment";
import "./scheduletimeslot.css";
import { formatCountdown } from "antd/lib/statistic/utils";
import { Toast } from "bootstrap";
import konsole from "../../network/konsole";
import { UserContext } from "../../App";
import commonLib from "../../control/commonLib";
import SendEmailModal from '../SendEmailModal';
const { Option } = Select;
let index = 0;

const SchedulePlanner = (props) => {


  const [form] = Form.useForm();
  const dateFormat = "MM/DD/YYYY";
  const [data, setdata] = useState([]);
  const [userlistdata, setuserlistdata] = useState([]);
  const [subtenantValue, setSubtenantValue] = useState();
  const [sendButtonStatus, setSendButtonStatus] = useState(false);
  const [planner, setPlanner] = useState("Select a Subtenant Planner");
  const [items, setItems] = useState(["15", "30", "45", "60", "120"]);
  const [toDate, setTodate] = useState("");
  const [fromDate, setFromdate] = useState("");
  const [toTime, setTotime] = useState("");
  const [fromTime, setFromtime] = useState("");
  const [timeSlot, setTimeSlot] = useState();
  const [dateArr, setDateArr] = useState([]);
  const [timeArr, setTimeArr] = useState([]);
  const [slotcreated, setSlotcreated] = useState(false);
  const [sendEmailStatus, setSendEmailStatus] = useState(true)
  const [plannerAllData, setPlannerAllData] = useState([])
  const [selectedSubtenantAllData, setSelectedSubtenantAllData] = useState([])
  const extra30 = 30;
  const [slotbreak, setSlotBreak] = useState();
  const [roleid, setroleid] = useState('')
  const { setLoader } = useContext(UserContext)

  const [defaultvalue, setDefaultvalue] = useState('Select a Firm')
  const [disabledselect, setdisableselect] = useState(false)
  const [date, setdate] = useState(null)

  console.log("fromDatefromDatet898", date)
  konsole.log("fromDatefromDate", fromDate)
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false)
  const [previewTemplateData, setpreviewTemplateData] = useState()
  const [bool, setbool] = useState(false)
  let roleUserId = commonLib.getSessionStoarge("roleUserId")

  const [subtenantIdd, setsubtenantId] = useState('')
  const [upcommingSlotData, setupcommingSlotData] = useState([])
  const [deleteSlots, setdeleteSlots] = useState(true)
  const [deleteSlotsData2, setdeleteSlotsData2] = useState([])
  const [slotDeleted, setSlotDeleted] = useState(false)
  const [uniqueTimeSlots, setUniqueTimeSlots] = useState([])
  const [emailTemplateData, setEmailTemplateData] = useState([])
  const [startDate, setstartDate] = useState(null)
  const [endDate, setendDate] = useState(null)
  const [currentData, setCurrentData] = useState("")
  const [currentTime, setCurrentTime] = useState("")
  const [currentTimeAMPM, setCurrentTimeAMPM] = useState("")
  const [allSloats, setallSloats] = useState(null)
  

  const dataArr = [];
  var arr1 = [];
  var arr2 = [];
  var farr = [];

  console.log("sartdatettete",startDate,upcommingSlotData)


  const onSelect = (value, key) => {

    konsole.log(`selected ${value}`);
    // setSubtenantValue(key.key);
    let resultData = data.filter((x) => {
      return x.subtenantId == value
    })
    setSelectedSubtenantAllData(resultData)
    getSubtenantPlannerListApi(key.key);
    setPlanner(null);
    konsole.log("subtenantValue", subtenantValue);
  };

  useEffect(() => {
   if((startDate == null || startDate == "") || (endDate == null || endDate == "") && roleUserId == 6){
      setupcommingSlotData(allSloats)
    }
  }, [startDate,endDate])
  


  const onSelectPlanner = (value, key) => {
    setbool(true)
    konsole.log("key", key?.id);
    let plannerId = key?.id
    konsole.log("abbbbbbbbbbbbbbbbb", value, key)


    let resultData = userlistdata.filter((data) => {
      return data.userRgstrtnId == plannerId
    })

    konsole.log("resultDataresultData", resultData)
    setPlannerAllData(resultData)
    setPlanner(value);

    getPlennerSlotDetails(value)




  };

  const getPlennerSlotDetails = (plannerUserId) => {
    setupcommingSlotData([])
    konsole.log("getPlennerSlotDetails", plannerUserId)
    setLoader(true)
    let json = {
      "plannerUserId": plannerUserId,
      "subtenantId": subtenantIdd,
      "isActive": true,
      "slotStatusId": 1,
      "slotStatus": "UPCOMING"
    }
    konsole.log("jsongetpostPlannerSlotDetails", JSON.stringify(json))
    let result = Services.getpostPlannerSlotDetails(json)
    result.then((res) => {
      
     if(roleUserId == 6){
      if (date == "") {
        setupcommingSlotData(res.data.data)
        setallSloats(res?.data?.data)
      } else {
        console.log("s,shfkdshfs1",form.getFieldsValue())

        let daTe = moment(date).format('YYYY-MM-DD')
        var specificDate = new Date(daTe); // Replace with your specific date

        specificDate.setDate(specificDate.getDate() + 7);

        var formattedDate = specificDate.toISOString().split('T')[0]; // YYYY-MM-DD
        console.log("s,shfkdshfs",formattedDate)
        let daTes = moment(date).format('MM/DD/YYYY')
        let endDate = moment(formattedDate).format('MM/DD/YYYY')
        let newSloats = res.data.data.filter((ele) => {
          return ele?.plannerDate >= daTes && ele?.plannerDate <= endDate
        })
     
        console.log(form.getFieldsValue(),"ueywueywiuew")
        // setstartDate(date)
        // setendDate(formattedDate)
        setupcommingSlotData(newSloats)
        form.setDate({From_Dates:date})
      }
     }else{
      setupcommingSlotData(res.data.data)
     }
     





      // setupcommingSlotData(newSloats)
      // setupcommingSlotData(res?.data?.data)
      setdeleteSlotsData2(res?.data?.data)
      // setSlotDeleted(false)
      setLoader(false)
    }).catch((err) => {
      konsole.log("jsongetpostPlannerSlotDetails", err)
      setLoader(false)
    })

  }




  const onSearch = (val) => {
    console.log("search:", val);


  }

  useEffect(() => {
    if (sendButtonStatus == true) {

      postApi(finalarray());
    }
  }, [sendButtonStatus])

  useEffect(() => {
    subtenantDetails();
    getEmailTemplatesFunc()
  }, [subtenantValue]);

  useEffect(() => {
    console.log("jhkfdhglkdhgfdh", props)
    if(props?.seminarDate != ""){
      let daTe = moment(props?.seminarDate).format('YYYY-MM-DD')
      var specificDate = new Date(daTe); // Replace with your specific date
  
      specificDate.setDate(specificDate.getDate() + 7);
  
      var formattedDate = specificDate.toISOString().split('T')[0]; // YYYY-MM-DD
      
      // let daTes = moment(props?.seminarDate).format('MM/DD/YYYY')
      let endDate = moment(formattedDate).format('MM/DD/YYYY')
      form.setFieldsValue({"From_Dates":moment(props?.seminarDate)})
      setstartDate(props?.seminarDate)
      setendDate(endDate)
      form.setFieldsValue({"To_Dates":moment(endDate)})
      console.log(form.getFieldsValue(),"ueywueywiuew")
      
      // setendDate(props?.seminarDate)
    }
   
    
    setdate(props?.seminarDate)
  }, [props])



  const getEmailTemplatesFunc = () => {
    let promise = Services.getEmailTemplatesUrlPath(17, true)
    promise.then((response) => {
      konsole.log("getEmailTemplatesFuncRes", response)
      setEmailTemplateData(response.data.data)
    }).catch((error) => {
      konsole.log("getEmailTemplatesFuncError", error)
    }).finally(() => {
      konsole.log("getEmailTemplatesFuncEnd")
    })
  }

  const tableFuncReturn = () => {


    // function formatDate1(date) {
    //   var d = new Date(date),
    //     month = "" + (d.getMonth() + 1),
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [month, day, year].join("/");
    // }



    // let table = document.createElement("table");

    // var th1 = document.createElement("th");
    // var th2 = document.createElement("th");
    // var TableDate = document.createTextNode("DATE");
    // var Slot_Timming = document.createTextNode("SLOT TIMING");
    // th1.appendChild(TableDate);
    // th2.appendChild(Slot_Timming);
    // th1.style.border="1px solid #720c20"
    // table.appendChild(th1);
    // table.appendChild(th2);
    // for (var i = 0; i < dateArr.length; i++) {
    //   const dateFormat1 = formatDate1(dateArr[i]);
    //   const slotDates = dateFormat1.toString();
    // var tr = document.createElement("tr");

    // var td1 = document.createElement("td");
    // var td2 = document.createElement("td");


    // var tableSlotDates = document.createTextNode(slotDates);
    // var createDiv1 = document.createElement('div')
    // for(let j = 0; j< timeArr.length; j++){
    //   var createDiv = document.createElement('div')
    //   var tableSlotTimes = document.createTextNode(timeArr[j]);
    //   createDiv1.style.display="flex"
    //   createDiv.appendChild(tableSlotTimes)
    //   createDiv.style.border="1px solid #720c20"
    //   createDiv.style.borderRadius="5px"
    //   createDiv.style.padding="10px 10px 10px 10px"
    //   createDiv.style.margin="10px"
    //   // createDiv.style.fontSize="12px"
    //   createDiv1.appendChild(createDiv)



    //   td1.appendChild(tableSlotDates);
    //   td1.style.border="1px solid #720c20"
    //   td1.style.padding="20px 20px 20px 20px"
    //   td2.appendChild(createDiv1);
    //   td2.style.border="1px solid #720c20"
    // th2.style.padding = "10px 10px 10px 10px";
    // th2.style.border="1px solid #720c20";
    // tr.appendChild(td1);
    // tr.appendChild(td2);
    // tr.style.padding="5px 5px 5px 5px"


    // table.appendChild(tr);
    // table.setAttribute("border", "2");
    // }

    // }



    function formatDate1(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("/");
    }




    let table = document.createElement("table");

    var th1 = document.createElement("th");
    var th2 = document.createElement("th");
    var TableDate = document.createTextNode("DATE");
    var Slot_Timming = document.createTextNode("SLOT TIMING");
    th1.appendChild(TableDate);
    th2.appendChild(Slot_Timming);
    th1.style.border = "1px solid #720c20"
    table.appendChild(th1);
    table.appendChild(th2);
    // .............................................................
    for (var i = 0; i < dateArr.length; i++) {
      const dateFormat1 = formatDate1(dateArr[i]);
      var mainSpan = document.createElement("div")
      var span = document.createElement("span")
      var span1 = document.createElement("span")
      var span2 = document.createElement("span")
      mainSpan.style.border = "1px solid #720c20"
      mainSpan.style.borderRadius = "5px"
      mainSpan.style.width = "100%"
      mainSpan.style.height = "60%"
      mainSpan.style.padding = "5px"
      // span.style.border = "2px solid #720c20"
      // span1.style.border = "2px solid #720c20"
      // span.style.marginLeft = "2px"
      span1.style.marginLeft = "5px"
      span1.style.padding = "5px"
      span.style.width = "90px"
      // span.style.padding = "5px"
      // span.style.borderRadius = "5px"
      // span1.style.borderRadius = "5px"
      span1.style.width = "30px"
      const slotDates = dateFormat1.toString();
      konsole.log("dsdadasdasd", slotDates)
      const WeekDayName = findWeekDayName(slotDates)

      var tr = document.createElement("tr");

      var td1 = document.createElement("td");
      var td2 = document.createElement("td");

      td2.style.border = "1px solid #720c20";
      td2.style.display = "flex";
      td2.style.flexWrap = "wrap";
      // td2.style.width= "100% ";
      td2.style.gap = '10px';
      td2.style.padding = "20px"
      td1.style.border = "1px solid #720c20"
      td1.style.padding = "10px"


      var tableSlotDates = document.createTextNode(slotDates);
      var tableSlotDates1 = document.createTextNode(WeekDayName);
      var createDiv1 = document.createElement('div')
      for (let j = 0; j < timeArr.length; j++) {
        var createDiv = document.createElement('div')
        var tableSlotTimes = document.createTextNode(timeArr[j]);
        // createDiv1.style = ;
        createDiv.appendChild(tableSlotTimes)
        createDiv.style.border = "1px solid #720c20"
        createDiv.style.borderRadius = "5px"
        createDiv.style.padding = "5px"
        createDiv.style.marginLeft = "5px"
        // createDiv.style.margin="10px"
        createDiv.style.width = "80px"
        // createDiv.style.fontSize="12px"
        // createDiv1.appendChild(createDiv)
        span.appendChild(tableSlotDates)
        span1.appendChild(tableSlotDates1)
        span2.innerHTML = ","
        mainSpan.appendChild(span1)
        mainSpan.appendChild(span2)
        mainSpan.appendChild(span)


        td1.appendChild(mainSpan);
        // td1.appendChild(span);
        // td1.appendChild(span1);
        td1.style.border = "1px solid #720c20"
        td1.style.padding = "20px 20px 20px 20px"

        td2.appendChild(createDiv);
        th2.style.padding = "10px 10px 10px 10px";
        th2.style.border = "1px solid #720c20";
        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.style.padding = "5px 5px 5px 5px"
        tr.style.width = "100%"


        table.appendChild(tr);
        table.setAttribute("border", "2");
      }

    }
    // .............................................................


    // for (var i = 0; i < dateArr.length; i++) {
    //   const dateFormat1 = formatDate1(dateArr[i]);
    //   const slotDates = dateFormat1.toString();
    //   var tr = document.createElement("tr");

    //   var td1 = document.createElement("td");
    //   var td2 = document.createElement("td");


    //   var tableSlotDates = document.createTextNode(slotDates);
    //   var createDiv1 = document.createElement('div')
    //   for (let j = 0; j < timeArr.length; j++) {
    //     var createDiv = document.createElement('div')
    //     var tableSlotTimes = document.createTextNode(timeArr[j]);
    //     createDiv1.style.display = "flex"
    //     createDiv.appendChild(tableSlotTimes)
    //     createDiv.style.border = "1px solid #720c20"
    //     createDiv.style.borderRadius = "5px"
    //     createDiv.style.padding = "10px 10px 10px 10px"
    //     createDiv.style.margin = "10px"
    //     // createDiv.style.fontSize="12px"
    //     createDiv1.appendChild(createDiv)



    //     td1.appendChild(tableSlotDates);
    //     td1.style.border = "1px solid #720c20"
    //     td1.style.padding = "20px 20px 20px 20px"
    //     td2.appendChild(createDiv1);
    //     td2.style.border = "1px solid #720c20"
    //     th2.style.padding = "10px 10px 10px 10px";
    //     th2.style.border = "1px solid #720c20";
    //     tr.appendChild(td1);
    //     tr.appendChild(td2);
    //     tr.style.padding = "5px 5px 5px 5px"


    //     table.appendChild(tr);
    //     table.setAttribute("border", "2");
    //   }

    // var tr = document.createElement("tr");

    // var td1 = document.createElement("td");
    // var td2 = document.createElement("td");

    // td2.style.border = "1px solid #720c20"; 
    // td2.style.display= "flex"; 
    // // td2.style.gridTemplateColumns= " auto auto auto;" 
    // td2.style.flexWrap= "wrap";

    // // td2.style.width= "100% ";
    // td2.style.gap= '10px';
    // td2.style.padding= "14px"
    // td1.style.border="1px solid #720c20"
    // td1.style.padding="10px"




    // }
    return table;


  }

  const postSendEmailCommFunc = () => {

    let AoAdminName = commonLib.getObjFromStorage('userLoggedInDetail')?.memberName
    let tableEmail = emailTemplateData[0]?.templateContent

    let replace = tableEmail.replace('@@SUBTENANTNAME', selectedSubtenantAllData[0]?.subtenantName)
    let replace1 = replace.replace('@@PLANNERNAME', plannerAllData[0]?.fullName)
    let replace2 = replace1.replace('@@ADMINNAME', AoAdminName)

    let gettableFuncReturn = tableFuncReturn()
    konsole.log("gettableFuncReturn", gettableFuncReturn.innerHTML)

    konsole.log("gettableFuncReturnAPi", gettableFuncReturn.innerHTML)

    let tableDAtaString = `<table border='2' cellspacing='0' cellpadding='0' >
    ${gettableFuncReturn.innerHTML}
    <table>`

    let replace3 = replace2.replace('@@SLOTDETAILS', tableDAtaString)


    let loginuserid = sessionStorage.getItem('loggedUserId')
    let promise = Services.postSendEmailComm(emailTemplateData[0]?.templateType, plannerAllData[0]?.primaryEmail, emailTemplateData[0]?.emailSubject, emailTemplateData[0]?.templateId, replace3, 1, "tblUsers", plannerAllData[0]?.userRgstrtnId, loginuserid)
    promise.then((response) => {
      konsole.log("postSendEmailCommRes", response)
      setbool(false)
      message.success("Email Send Successfully")
      setOpenSendEmailModal(false)
      setSendButtonStatus(false)
      setLoader(false)
    }).catch((error) => {
      konsole.log("postSendEmailCommerror", error)
      setLoader(false)
    }).finally(() => {
      setLoader(false)
      konsole.log("postSendEmailCommEnd")
    })

  }

  const subtenantDetails = () => {
    const createdBy = null;
    const isActive = true;
    setLoader(true)
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
     
      console.log("promiseSubtenant", res);
      setdata(res.data.data);
      setLoader(false)
      // message.success("ss")
    }).catch((err) => {
      konsole.log("err", err);
      // message.error("Enter valid details")
    }).finally(() => {
      // setLoader(false)
    })
  };


  useEffect(() => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
    let subtenantId = sessionStorage.getItem("subtenantId")
    setsubtenantId(subtenantId)
    setroleid(userroleId?.roleId)
    if (data !== '' && data !== undefined && roleid == "11" || roleid == "6") {
      setdisableselect(true)
      getSubtenantPlannerListApi(subtenantId);

      let datafilter = data.filter((val) => {
        return val.subtenantId == subtenantId
      })
      setDefaultvalue(datafilter[0]?.subtenantName)
      setSelectedSubtenantAllData(datafilter)
      form.setFieldsValue({
        "Subtenant": datafilter[0]?.subtenantName
      })

      konsole.log("datafilterdatafilter", datafilter)

    } else {
      setdisableselect(false)
    }

  }, [data])

  const getSubtenantPlannerListApi = (subtenantValue) => {
    const roleId = 8;
    const getUserlistpromise = Services.getUserListByRoleId(
      subtenantValue,
      true,
      roleId
    );
    getUserlistpromise
      .then((res) => {
        setuserlistdata(res.data.data);
        konsole.log("selectPlanner", res.data.data);
      })
      .catch((err) => {
        konsole.log("err", err);
        // message.error("Enter valid details");
      });
  };


  const onChangeCheck = (e) => {
    konsole.log(`checked = ${e.target.checked}`);
    setSendEmailStatus(!sendEmailStatus)
  };


  const disabledforfromDate = (current) => {
    let customDate = moment().format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  function disabledfortoDate(current) {
    let customDate = new Date(fromDate);
    return current && current < moment(customDate, "YYYY-MM-DD");
  }
// console.log(form.getFieldsValue(),"ueywueywiuew")
  const onFinishForm = () => {
   
    if (currentData == fromDate && currentTime > fromTime) {
      setbool(false)
      message.error("Please Insert Valid Time")
    } else {
      CreateSlot();
    }
  };

  const CreateSlot = () => {
    setSlotcreated(true);
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

    const startTime = moment(fromTime, "HH:mm:ss a");
    const endTime = moment(toTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const hoursInmins = hours * 60;

    const totalmins = hoursInmins + minutes;
    konsole.log("rExtraa", timeSlot, slotbreak)
    let r = parseInt(timeSlot);

    let extra30 = parseInt(slotbreak) ? slotbreak !== undefined : 0;

    const totalTimeslot = r + extra30;
    konsole.log(totalTimeslot, extra30, r, "totalmins")
    // const Slotsmins2 = totalmins / timeSlot;
    const Slotsmins = Math.round(totalmins / totalTimeslot);
    konsole.log("Slotsmins", Slotsmins)
    // Slotsmins=Math.trunc(Slotsmins)

    for (var i = 1; i <= Slotsmins; i++) {
      const newTime = moment(fromTime, "HH:mm:ss a")
        .add(timeslots, "minutes")
        .format("hh:mm a");
      //hh:mm:ss.SSS
      var timeslots = totalTimeslot * i;
      arr1.push(newTime);
    }
    console.log("arr111",arr1)
    setTimeArr(arr1);
    for (var a = 1; a <= diffDays; a++) {
      const result = new Date(fromDate);
      result.setDate(result.getDate() - 1 + a);

      arr2.push(result);
    }
    console.log("arr222",arr2)
    setDateArr(arr2);

  };

  const ReCreateSlot = () => {
    setSlotcreated(false);
  };

  konsole.log("dateArrdateArr", upcommingSlotData)
  function finalarray() {
    let json = []
    konsole.log("dateArrdateArr", dateArr, timeArr, upcommingSlotData)
    dateArr.forEach((date) => {
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
          console.log("kjsdhkdfhgdlh",d)
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      }
      const plannerDate = formatDate(date);
      timeArr.forEach((time) => {
        const plannerTime = moment(time, "HH:mm:ss a").format("HH:mm:ss.SSS");
        const slot = {
          plannerCalId: 0,
          plannerUserId: planner,
          plannerDate: plannerDate,
          plannerTime: plannerTime,
          plannerServiceFeeId: 1,
          slotStatusId: 1,
          timeDuration: timeSlot,
          isActive: true,
          upsertedBy: "049ffdae-3101-4365-b0f3-174bec421242",
        };
        json.push(slot);

      });
    });
    console.log("farrfarrFinalArr", json)
    return json;
  }




  const onSubmit = () => {
    let slotArr = finalarray()
    console.log("kjsdhkdfhgdlh", finalarray())
    if (sendEmailStatus == true) {
      let newArray = []
      for (let i = 0; i < slotArr.length; i++) {

        slotArr[i].plannerTime = moment(slotArr[i].plannerTime, "HH:mm").format("h:mm A")
        slotArr[i].plannerDate = moment(slotArr[i].plannerDate).format("MM/DD/YYYY")
      }
      newArray.push(slotArr)

      let postArray = newArray.flat(1)
      const array2Only = postArray.filter(
        (item2) =>
          upcommingSlotData.some(
            (item1) => item1.plannerDate === item2.plannerDate && item1.plannerTime === item2.plannerTime
          )
      );
      console.log("kjsdhkdf97978hgdlh", array2Only.length)
      if (array2Only.length > 0) {
        message.warn("Duplicate slot/slots cannot be created, please check and create again.")
        previewTableTemplateData();
      } else {
        previewTableTemplateData();
      }
      // previewTableTemplateData();
    }
    else {
      setSendButtonStatus(true);
    }
    // postApi(finalarray());
    // window.location.reload(true);
  };

  const previewTableTemplateData = () => {

    function formatDate1(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [month, day, year].join("/");
    }



    let table = document.createElement("table");

    var th1 = document.createElement("th");
    var th2 = document.createElement("th");
    var TableDate = document.createTextNode("DATE");
    var Slot_Timming = document.createTextNode("SLOT TIMING");
    th1.appendChild(TableDate);
    th2.appendChild(Slot_Timming);
    th1.style.border = "1px solid #720c20"
    table.appendChild(th1);
    table.appendChild(th2);
    for (var i = 0; i < dateArr.length; i++) {
      const dateFormat1 = formatDate1(dateArr[i]);
      var mainSpan = document.createElement("div")
      var span = document.createElement("span")
      var span1 = document.createElement("span")
      var span2 = document.createElement("span")
      mainSpan.style.border = "1px solid #720c20"
      mainSpan.style.borderRadius = "5px"
      mainSpan.style.width = "100%"
      // mainSpan.style.height = "30%"
      // span.style.border = "2px solid #720c20"
      // span1.style.border = "2px solid #720c20"
      // span.style.marginLeft = "2px"
      span1.style.marginLeft = "5px"
      span1.style.padding = "5px"
      // span1.style.borderRadius = "5px"
      // span.style.borderRadius = "5px"
      span.style.width = "90px"
      span.style.padding = "5px"
      span1.style.width = "30px"
      const slotDates = dateFormat1.toString();
      konsole.log("dsdadasdasd", slotDates)
      const WeekDayName = findWeekDayName(slotDates)

      var tr = document.createElement("tr");

      var td1 = document.createElement("td");
      var td2 = document.createElement("td");

      td2.style.border = "1px solid #720c20";
      td2.style.display = "flex";
      td2.style.flexWrap = "wrap";
      // td2.style.width= "100% ";
      td2.style.gap = '10px';
      td2.style.padding = "14px"
      td1.style.border = "1px solid #720c20"
      td1.style.padding = "10px"


      var tableSlotDates = document.createTextNode(slotDates);
      var tableSlotDates1 = document.createTextNode(WeekDayName);
      var createDiv1 = document.createElement('div')
      for (let j = 0; j < timeArr.length; j++) {
        var createDiv = document.createElement('div')
        var tableSlotTimes = document.createTextNode(timeArr[j]);
        // createDiv1.style = ;
        createDiv.appendChild(tableSlotTimes)
        createDiv.style.border = "1px solid #720c20"
        createDiv.style.borderRadius = "5px"
        createDiv.style.padding = "5px"
        // createDiv.style.margin="10px"
        createDiv.style.width = "100px"
        // createDiv.style.fontSize="12px"
        // createDiv1.appendChild(createDiv)
        span.appendChild(tableSlotDates)
        span1.appendChild(tableSlotDates1)
        span2.innerHTML = ","

        mainSpan.appendChild(span1)
        mainSpan.appendChild(span2)
        mainSpan.appendChild(span)


        td1.appendChild(mainSpan);
        // td1.appendChild(span2);
        // td1.appendChild(span);
        td1.style.border = "1px solid #720c20"
        td1.style.padding = "20px 20px 20px 20px"

        td2.appendChild(createDiv);
        th2.style.padding = "10px 10px 10px 10px";
        th2.style.border = "1px solid #720c20";
        tr.appendChild(td1);
        tr.appendChild(td2);
        tr.style.padding = "5px 5px 5px 5px"
        tr.style.width = "100%"


        table.appendChild(tr);
        table.setAttribute("border", "2");
      }

    }


    let AoAdminName = commonLib.getObjFromStorage('userLoggedInDetail')?.memberName
    let tableEmail = emailTemplateData[0]?.templateContent

    let replace = tableEmail.replace('@@SUBTENANTNAME', selectedSubtenantAllData[0]?.subtenantName)
    let replace1 = replace.replace('@@PLANNERNAME', plannerAllData[0]?.fullName)
    let replace2 = replace1.replace('@@ADMINNAME', AoAdminName)

    // let gettableFuncReturn = tableFuncReturn()
    konsole.log("gettableFuncReturn", table.innerHTML)

    let tableDAtaString = `<table border='2' cellspacing='0' cellpadding='0' >
    ${table.innerHTML}
    <table>`

    let replace3 = replace2.replace('@@SLOTDETAILS', tableDAtaString)

    setpreviewTemplateData(replace3)
    setOpenSendEmailModal(sendEmailStatus == true ? true : false)

  }

  const postApi = (slotArr) => {

    console.log("getfieldsValue", slotArr)

    let newArray = []
    for (let i = 0; i < slotArr.length; i++) {

      slotArr[i].plannerTime = moment(slotArr[i].plannerTime, "HH:mm").format("h:mm A")
      slotArr[i].plannerDate = moment(slotArr[i].plannerDate).format("MM/DD/YYYY")
    }
    newArray.push(slotArr)

    let postArray = newArray.flat(1)
    const array2Only = postArray.filter(
      (item2) =>
        !upcommingSlotData.some(
          (item1) => item1.plannerDate === item2.plannerDate && item1.plannerTime === item2.plannerTime
        )
    );
    console.log("array2Onlyarray2Only", array2Only)

    setLoader(true)
    let postPlannerCalendardataprom = Services.postPlannerCalendardata(array2Only);
    postPlannerCalendardataprom
      .then((res) => {
        konsole.log("ressssSlot Created Successfully", res.data.data);
        setbool(false)
        message.success("Slot Created Successfully")
        if (sendEmailStatus == true) {

          postSendEmailCommFunc()
        } else {
          setOpenSendEmailModal(false)
          setSendButtonStatus(false)
        }
        setSlotcreated(false);
        form.setFieldsValue({
          From_Date: "",
          From_Time: "",
          To_Date: "",
          To_Time: '',
          duration: null,
          slotBreak: null,
          Subtenant_Planner: null
        });
      })
      .catch((err) => {
        konsole.log("err", err);
        setbool(false)
        message.error("Enter valid data")
        setLoader(false)

      }).finally(() => {
        if (sendEmailStatus == false) {

          setLoader(false)
        }
      })
  };

  useEffect(() => {

    // ....................... For System Date ......................................
    let FromDateToLocal = new Date().toLocaleDateString()
    let FromDateToLocal1 = moment().format('MM/DD/YYYY')
    konsole.log("fromDatefromDate1", FromDateToLocal1)
    if (FromDateToLocal1 == fromDate) {
       setCurrentData(FromDateToLocal1)
    } else {
      setCurrentData("")
     }






  }, [fromDate])



  useEffect(() => {

    // ....................... For System Time ......................................

    let FromTimeToLocal = new Date().toLocaleTimeString()
    // let FromTimeToLocal1 = moment(FromTimeToLocal, "HH:mm:ss a")?._i
    let FromTimeToLocal1 = moment().format('hh/mm')
    konsole.log("fromTimefromTime1", FromTimeToLocal1)

    setCurrentTimeAMPM(FromTimeToLocal1)

    setCurrentTime(FromTimeToLocal)
    




  }, [fromTime])

  const findWeekDayName = (date) => {
    const d = new Date(date);
    let day = d.getDay()

    switch (day) {
      case 0:
        return day = "Sun";
        break;
      case 1:
        return day = "Mon";
        break;
      case 2:
        return day = "Tues";
        break;
      case 3:
        return day = "Wed";
        break;
      case 4:
        return day = "Thur";
        break;
      case 5:
        return day = "Fri";
        break;
      case 6:
        return day = "Sat";
    }

  }
  const slotCheckboxfun = (e, index, item) => {

    let loginuserid = sessionStorage.getItem('loggedUserId')
    let arrData = deleteSlotsData2
    let checkedstatus = e.target.checked

    konsole.log("eeeeeeeeeeeeeeeeeeeeee", e.target.checked, index, item, arrData)
    if (checkedstatus == true) {
      arrData[index].isActive = false
      arrData[index].slotStatusId = 3
      arrData[index]['upsertedBy'] = loginuserid
      // setSlotDeleted(true)
    } else if (checkedstatus == false) {
      arrData[index].isActive = true
      arrData[index].slotStatusId = item.slotStatusId
    }
    setdeleteSlotsData2(arrData)
  }

  const deleteSlotsfun = () => {
    konsole.log("deleteSlotsData2deleteSlotsData2", deleteSlotsData2)
    let filterData = deleteSlotsData2.filter(item => item.isActive == false && item.slotStatusId == 3)
    if (filterData.length == 0) {
      message.warning("Please select a slot")
      return;
    }
    konsole.log("filterDatafilterData", filterData.flat().flat())
    setLoader(true)
    let jsonobj = filterData
    let result = Services.upsertPlannerDetails(jsonobj)
    result.then((res) => {
      konsole.log("result", res)
      setLoader(false)
      message.success("Slots Deleted")
      setdeleteSlots(true)
      konsole.log("getPlennerSlotDetails", planner)
      getPlennerSlotDetails(planner)
    }).catch((err) => {
      konsole.log("err", err)
      setLoader(false)
    }).finally(() => {
      konsole.log("completefinally")
      setLoader(false)
    })
  }
  const handleSubmit = (e) => {

    form.validateFields().then((values) => {
      onFinishForm()

    })
      .catch((errorInfo) => {

      });





  }
  const changeDataFrom = (date, dateString) => {
    setstartDate(dateString)
   
    if(endDate != null && endDate != undefined && endDate != ""){
        let daTes = moment(dateString).format('MM/DD/YYYY')
        let endDates = moment(endDate).format('MM/DD/YYYY')
        let newSloats = upcommingSlotData.filter((ele) => {
          return ele?.plannerDate >= daTes && ele?.plannerDate <= endDates
        })
       
        setupcommingSlotData(newSloats)
      }
  }
  const changeDataTo = (date, dateString) => {
   
    setendDate(dateString)
    if(startDate != null){
      let daTes = moment(dateString).format('MM/DD/YYYY')
      let startDates = moment(startDate).format('MM/DD/YYYY')
      let newSloats = upcommingSlotData.filter((ele) => {
        return ele?.plannerDate >= startDates && ele?.plannerDate <= daTes
      })
     
      setupcommingSlotData(newSloats)

    }

  }

  return (
    <div>
      <div className={roleUserId == 6 ? "ms-1" : ""} style={{
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'auto'
      }} >
        <p className="fs-4 fw-600 p-0 m-0 mt-1">Select a Firm and User </p>
        <Form form={form} name="Addtimeslot" onFinish={onFinishForm}>
          <div className="mt-1" style={{ width: "99%", }}>
            <Form.Item
              name="Subtenant"
              rules={[
                {
                  required: true,
                  message: "Please select a Firm",
                },
              ]}
            >
              <Select

                // placeholder={defaultvalue}
                disabled={disabledselect}
                placeholder="Select a Firm"
                style={{
                  width: "100%",
                }}
                className="fs-5 border-2 rounded-2"
                onSelect={onSelect}
              >
                {data.map((props) => (
                  <Option value={props.subtenantId} key={props.subtenantId}>
                    {props.subtenantName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ width: "99%" }}>
            <Form.Item
              name="Subtenant_Planner"
              rules={[
                {
                  required: true,

                  message: "Please select a Firm Planner",
                },
              ]}
            >
              <Select

                placeholder="Select a Subtenant Planner"

                style={{
                  width: "100%",
                  "border-radius": 10,

                }}
                className="fs-5 select-Subtenant-Planner"
                showSearch

                onSelect={onSelectPlanner}
                value={planner}
                onSearch={onSearch}



                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

                }
              >
                {userlistdata.map((props, key) => (
                  <Option value={props.userId} key={key} id={props?.userRgstrtnId}>
                    {props.fullName}

                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <p className="fs-5 fw-500 m-0 p-0">Schedule time slots</p>

          <div className="" style={{ width: "99%" }}>

            <Space>
              <Row className="mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
                <Col>
                  <Form.Item
                    name="From_Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select start date",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="From Date"
                      className="fs-4 date-picker me-2"
                      format={dateFormat}
                      disabledDate={disabledforfromDate}
                      onChange={(date, dateString) => setFromdate(dateString)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="To_Date"
                    rules={[
                      {
                        required: true,
                        message: "Please select end date",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="To Date"
                      className="fs-4  me-2 date-picker"
                      disabledDate={disabledfortoDate}
                      format={dateFormat}
                      onChange={(date, dateString) => setTodate(dateString)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="From_Time"
                    rules={[
                      {
                        required: true,
                        message: "Please select start time",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="From Time"
                      className="fs-4 date-picker me-2"
                      format="h:mm A"
                      onChange={(date, dateString) => setFromtime(dateString)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="To_Time"
                    rules={[
                      {
                        required: true,
                        message: "Please select end time",
                      },
                    ]}
                  >
                    <TimePicker
                      placeholder="To Time"
                      className="fs-4 date-picker me-2"
                      format="h:mm A"
                      onChange={(date, dateString) => setTotime(dateString)}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: "Please select slot duration",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "210px",
                      }}
                      placeholder="Slot Duration"
                      className=" me-2"
                      onChange={(value) => setTimeSlot(value)}
                    >
                      {items.map((item) => (
                        <Option key={item}>{item} mins</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="slotBreak"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select slot break",
                  //   },
                  // ]}
                  >
                    <Select
                      style={{
                        width: "210px",
                      }}
                      placeholder="Slot Break"
                      onChange={(value) => setSlotBreak(value)}
                      required
                    >
                      <Option key="15">15 mins</Option>
                      <Option key="30">30 mins</Option>
                      <Option key="45">45 mins</Option>
                      <Option key="60">60 mins</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Space>

            {slotcreated == false ? (
              <Button
                style={{
                  "background-color": "#720C20",
                  color: "white",
                  borderRadius: "5px",
                  fontWeight: "600"
                }}


                onClick={(e) => handleSubmit(e)}
                className=""
              >
                Create Slots
              </Button>
            ) : (
              <>
                <Row Span={24}>
                  {
                    <table className="slot-table m-0 p-0" id="tableDetails">
                      <tr>
                        <th>Date</th>
                        <th>Slot timing</th>
                      </tr>
                      {dateArr.map((props) => {
                        //format date

                        function formatDate(date) {
                          var d = new Date(date),
                            month = "" + (d.getMonth() + 1),
                            day = "" + d.getDate(),
                            year = d.getFullYear();

                          if (month.length < 2) month = "0" + month;
                          if (day.length < 2) day = "0" + day;

                          return [month, day, year].join("/");
                        }
                        const dateFormat = formatDate(props);
                        const javaScriptRelease = dateFormat.toString();

                        const weekDayName = findWeekDayName(javaScriptRelease)
                        // let WeekDayName = date1(javaScriptRelease, javaScriptRelease)

                        // dataArr.push(javaScriptRelease);
                       
                        return (
                          <>
                            <tr className="tr-main">
                              <td className="date-row">
                                <p className="slot-date">
                                  <div style={{ border: "2px solid #720c20", borderRadius: "5px", maxWidth: "100%", padding: "5px", display: "flex", flexWrap: "wrap" }}>
                                    <div>
                                      {weekDayName},

                                    </div>
                                    <div className="ms-1">

                                      {javaScriptRelease}

                                    </div>
                                  </div>
                                </p>
                              </td>
                              <tr className="slot-row">
                                {timeArr.map((props) => {
                                  // {
                                  //   dataArr.push(props);

                                  // }
                                  return (
                                    <td className="td-timing">
                                      <p className="slot-date">
                                        <div style={{ border: "2px solid #720c20", borderRadius: "5px", maxWidth: "100%", padding: "5px", display: "flex", flexWrap: "wrap" }}>
                                          {props}

                                        </div>
                                      </p>
                                    </td>
                                  );
                                })}
                              </tr>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  }

                  {/* <br /> */}

                  <Checkbox
                    className="checkbox-timeslot"
                    onChange={(e) => onChangeCheck(e)}
                    defaultChecked={sendEmailStatus}
                  // checked={sendEmailStatus}
                  >
                    Send Notification to Staff User{" "}
                  </Checkbox>
                </Row>
                <div className="mt-2 mb-2">
                  <Button
                    className="save-btn"
                    style={{
                      "background-color": "#720C20",
                      color: "white",
                      borderRadius: "5px",
                      fontWeight: "600",
                      height: "40px"

                    }}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    className="save-btn"
                    onClick={() => ReCreateSlot()}
                    style={{
                      "background-color": "#720C20",
                      color: "white",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      fontWeight: "600",
                      height: "40px"
                    }}
                  >
                    Re-Create Slots
                  </Button>
                </div>
              </>
            )}
          </div>
          {/* <hr /> */}
          {/* <div  className={`${(deleteSlots==true)?"justify-content-around":"justify-content-start"} d-flex mb-2`}> */}
          {bool == true ? (<>
            {upcommingSlotData?.length > 0 ? (
              <>
                <div className={roleUserId == 6 ? "me-2 ps-4 mt-2 mb-1":"me-3 mt-2 ps-5 mb-1"} style={{ border: "1px dashed grey" }}>

                  {/* {upcommingSlotData.length > 0 && <Button onClick={() => setdeleteSlots(!deleteSlots)}>{(deleteSlots)?"Uncheck":"Check"}</Button>} */}
                  {/* {deleteSlots && <Button className="ms-2" onClick={()=>deleteSlotsfun()}>Delete</Button> }  */}
                  <div className={roleUserId == 6 ? "row me-2 align-items-center justify-content-center w-100 mb-1 mt-1" 
                                : "row ms-2 me-2 align-items-center justify-content-start w-100 mb-1 mt-1"}>
                  {roleUserId == 6 ? (
                    <div className="">
                    <Space>
                       <Row className="mt-1" style={{ display: "flex", flexWrap: "wrap" }}>
                         <Col>
                           <Form.Item
                             name="From_Dates"
                            //  rules={[
                            //    {
                            //      required: true,
                            //      message: "Please select start date",
                            //    },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="From Date"
                               className="fs-4 date-picker me-2"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataFrom}
                             />
                           </Form.Item>
                         </Col>
                         <Col>
                           <Form.Item
                             name="To_Dates"
                            //  rules={[
                            //   //  {
                            //   //    required: true,
                            //   //    message: "Please select end date",
                            //   //  },
                            //  ]}
                           >
                             <DatePicker
                               placeholder="To Date"
                               className="fs-4  me-2 date-picker"
                               format={dateFormat}
                               disabledDate={disabledforfromDate}
                               onChange={changeDataTo}
                             />
                           </Form.Item>
                         </Col>
 
 
                       </Row>
                     </Space> 
                    </div>
                    ):("")}
                   
                    
                    <div className="fw-bold row me-2 align-items-center justify-content-center w-100  mt-1">Available time slots </div>
                    {upcommingSlotData.length > 0 && upcommingSlotData.map((item, index) => {
                      return <>

                        <div key={index} className="rounded border  border-dark p-2 mt-2 me-1 d-flex" style={{ maxWidth: '220px' }}>
                          {/* {deleteSlots ==true ?  */}
                          <div>
                            <input type="checkbox" style={{ width: "20px", height: "20px" }} onChange={(e) => slotCheckboxfun(e, index, item)}
                              defaultChecked={false}
                            />
                          </div>
                          {/* : ""}  */}
                          &nbsp;

                          <div className="ms-1"> {commonLib.dayFormate(item.plannerDate)}, {item.plannerDate} {item.plannerTime}</div>
                        </div>
                      </>
                    })}
                    <div style={{paddingLeft:"0px"}}>
                      {upcommingSlotData.length > 0
                        && (
                          <>
                          {roleUserId == 6 ? (
                           <Button style={{backgroundColor: "#720C20", color: "white", borderRadius: "5px", borderStyle: "none", height: "35px", }} className="mt-2 mb-1 align-items-start justify-content-start" onClick={() => deleteSlotsfun()}>Delete selected Slots</Button>

                          ):(
                            <div className="" style={{marginLeft:"-4rem"}}>
                               <Button style={{backgroundColor: "#720C20", color: "white", borderRadius: "5px", borderStyle: "none", height: "35px"}} className="mt-3 mb-1 align-items-start justify-content-start" onClick={() => deleteSlotsfun()}>Delete selected Slots</Button>

                            </div>

                          )}
                          </>
                        )
                        

                      }

                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="me-3 mt-2" style={{ border: "1px dashed grey" }}>
                  <div className="fw-bold  row ms-2 me-2 me-2 align-items-center justify-content-center
                   w-100 mb-5 mt-5">
                    No Slots Available
                  </div>
                </div>

              </>
            )
            }
          </>) : ("")}

        </Form>

      </div>
      {
        (openSendEmailModal == true) ? <SendEmailModal cancelMOdal={setOpenSendEmailModal} openSendEmailModal={openSendEmailModal} titleText="Send Email" previewTemplateData={previewTemplateData} sendEmailStauts={setSendButtonStatus} /> : ""
      }
    </div>
  );
};

export default SchedulePlanner;

import React, { useState } from "react";
import { Button, message, Steps,Popover } from "antd";
const { Step } = Steps;

function Stepper() {
  const [IncStepperSteps, setIncStepperSteps] = useState(0);

 

  const BackSeminarFile = () => {
    setIncStepperSteps(0);
  };
  const PreviemPage = () => {
    setIncStepperSteps(1);
  };
  const PublishPage = () =>{
    setIncStepperSteps(2);
  }

  return (
    <>
      <div
        className="mt-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <div style={{ width: 810 }}></div> */}
        <Steps labelPlacement="vertical" current={IncStepperSteps} size="small" >
          <Step
            title="Create"
            onClick={BackSeminarFile}
            style={{ cursor: "pointer" }} 
          />
          <Step
            title="Preview"
            onClick={PreviemPage}
            style={{ cursor: "pointer" }} 
          />

          <Step title=" Publish" onClick={PublishPage} style={{ cursor: "pointer" }}  />
          {/* <Step title=" Publish" onClick={Congratulation} style={{ cursor: "pointer" }} /> */}
        </Steps>
      </div>
    </>
  );
}

export default Stepper;

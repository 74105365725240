import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Select, Space, Row, Col, Button, Modal,message } from "antd";

import SubtenantTable from "./SubtenantTable";
import UploadFilepic from "./AddSubtenantUser";
import "../../Styles/subtenant.css";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
const { Option } = Select;
const { Search } = Input;


const SubtenantUsers = () => {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState("");
  const [data, setdata] = useState([]);
  const [subtenantValue,setSubtenantValue] = useState(2)

  const showUserModal = () => {
    setVisible(true);
  };

  const hideUserModal = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    konsole.log("Finish:", values);
  };

  const [placement, SetPlacement] = useState("topLeft");

  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };
  const onSelect = (value,key) => {
    konsole.log("key",key.key)
    konsole.log(`selected ${value}`);
    setSubtenantValue(key.key);
    konsole.log(value)
    konsole.log("subtenantValue",subtenantValue)
  };
  const onSearch = (value) => konsole.log(value);
  const createdBy = null;
  const isActive = true;

  useEffect(() => {
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
      // konsole.log("ressss", res.data.data);
      setdata(res.data.data);
    }).catch((err) => {
      konsole.log("err", err);
      message.error("Enter the valid details")
    });
  }, []);

  return (
    <div className="ms-2">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "userForm") {
            const { basicForm } = forms;

            setVisible(false);
          }
        }}
        className="me-4"
      >
        <Form name="basicForm" onFinish={onFinish}>
          <div className="select-container">
            <p className="mt-4 fs-5 mb-2" style={{ fontFamily: "sans-serif" }}>
              Select a Subtenant
            </p>
            <Row>
              <Select
                defaultValue="Life Point Law"
                style={{
                  width: 820,
                }}
                className="fs-5"
                dropdownMatchSelectWidth={false}
                placement={placement}
                onSelect={onSelect}
              >
                {data.map((dataprop) => (
                  // konsole.log(dataprop.subtenantId);
                  // konsole.log(dataprop.subtenantName);
                  <Option value={dataprop.subtenantName} key={dataprop.subtenantId}>
                    {dataprop.subtenantName}
                  </Option>
                ))}
              </Select>
            </Row>
          </div>
          <Row className="mt-3">
            <Col
              xs={24}
              lg={24}
              span={24}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Col span={12} style={{ display: "flex" }}>
                <Search
                  placeholder="Search for Subtenant user"
                  onSearch={onSearch}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>

              <Button
                htmlType="button"
                style={{
                  margin: "0 8px",
                  backgroundColor: "rgba(114, 12, 32, 1)",
                  color: "white",
                  borderRadius: "5px",
                  // marginLeft: '220%'
                }}
                onClick={showUserModal}
              >
                <span style={{ fontFamily: "sans-serif" }} status={setFile}>
                  {" "}
                  + ADD NEW
                </span>
              </Button>
            </Col>

            <UploadFilepic visible={visible} onCancel={hideUserModal} />
          </Row>
        </Form>
      </Form.Provider>

      <SubtenantTable id={subtenantValue} />
      
    </div>
  );
};
export default SubtenantUsers;

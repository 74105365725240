import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { InputCom } from './InputCom'
import { message } from 'antd'
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import commonLib from '../../control/commonLib';
import { createCabinetObjJson, operationForCabinet } from '../../control/Constant';
import { FileCabinetContext } from './ParentCabinet';


const AddCabinet = ({ apiCallUpsertMainCabinet ,setManageShowFileType}) => {

    let { allMainCabinetContainer } = useContext(FileCabinetContext)

    const stateObj = commonLib.getObjFromStorage("stateObj");

    // define state---------------------------------------
    const [manageUpdate, setManageUpdate] = useState({ updatedBy: stateObj.userId, updatedOn: new Date().toISOString() })
    const [manageCreate, setManageCreate] = useState({ createdBy: stateObj.userId, createdOn: new Date().toISOString() })
    const [subtenantList, setSubtenantList] = useState([])

    const [cabinetName, setCabinetName] = useState('')
    const [cabinetDescription, setcabinetDescription] = useState('')
    const [subtenantSelectionRadio, setSubtenantSelectionRadio] = useState(0)
    const [cabinetActionType, setCabinetActionType] = useState('addCabinet')
    const [screenCol, setScreenCol] = useState(10)
    //  define useEffect----------------------------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        callApigetAllSubtenantDetails()
    }, [])

    const callApigetAllSubtenantDetails = () => {
        Services.getAllSubtenantDetails({ isActive: true, createdBy: stateObj.userId }).then((res) => {
            konsole.log('res of all subtenants', res)
            setSubtenantList(res?.data?.data)
        }).catch((err) => konsole.log('err in all subtenants fetch', err))
    }
    // handle radio--------------------------------------------------

    const handleChangeSubtenantCheckBox = (e) => {
        console.log('eeeee', e)
    }
    const saveCabinet = () => {
        if (cabinetName == '') {
            message.error("Cabinet name can't be blank")
            return;
        }

        let postJson = createCabinetObjJson({ ...manageCreate, cabinetDescription, cabinetName, cabinetOperation: operationForCabinet[0] })
        konsole.log('postJson', postJson)
        apiCallUpsertMainCabinet(postJson, 'Cabinet created successfully.')
    }
    return (
        <>
            <Row>
                <Col lg='12' className='main-col'>

                    {allMainCabinetContainer.length == 0 &&
                        <Row>
                            <Col>  <h4 style={{ color: "#720c20" }}>Manage Your Cabinet</h4> </Col>
                        </Row>}
                    {/* <Row>
                        <Col className='mt-1'>
                            <div className='d-flex'>
                                <h6>Cabinet: </h6>
                                <div className='d-flex'>
                                    <h6 className={`${cabinetActionType == "addCabinet" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setCabinetActionType('addCabinet')}>Add New Cabinet</h6>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    {cabinetActionType == 'addCabinet' &&
                        <Row className='mt-2'>
                            <Col lg={screenCol}>
                                <InputCom label='Name' placeholder='Enter Name' value={cabinetName} handleInputChange={(e) => setCabinetName(commonLib.capitalizeFirstLetter(e.target.value))} />
                                <InputCom type='textarea' label='Description' id='fileCategoryDescription' value={cabinetDescription} placeholder='Description' handleInputChange={(e) => setcabinetDescription(e.target.value)} />
                                <Row className='mt-2'>
                                    <Col lg="5"></Col><Col lg="7">
                                        <Button className='folder-save-button' onClick={() => saveCabinet()} >Save</Button>
                                        <Button className='folder-save-button ms-3' onClick={() => setManageShowFileType('')} >Cancel</Button>
                                    </Col></Row>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <hr />
        </>
    )
}


export default AddCabinet
import { Col, Row } from 'antd'
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../App.js";
import konsole from '../../network/konsole.js';
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import "./communication.css"
import { Addcommunicationmodal } from './CommunicationModal/Addcommunicationmodal'
import Newmodal from './CommunicationModal/Newmodal'
import Communicationtable from './Communicationtable';


const Communication = (props) => {

    const [showAddModal, setShowAddModal] = useState(false)
    const [GetCommMediumData, setCommMediumData] = useState([]);
    const [showCabinetModal, setShowCabinetModal] = useState(false);
    const [search, setSearch] = React.useState('');
    const { setLoader } = useContext(UserContext)
    const [subtenantID, setSubtenantID] = useState()
    const [emptyState, setEmptyState] = useState()


    useEffect(() => {
        let subtenantIDd = commonLib.getSessionStoarge("subtenantId")
        setSubtenantID(subtenantIDd)
        GetCommMediumPathApiFuction()

    }, [subtenantID])

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

konsole.log("propsprops",props)
konsole.log("kktttttkkk",subtenantID)


    const handleClose = () => {
        setEmptyState({})
        setShowAddModal(false);
    }

    const addEditModalFun = () => {
        setShowAddModal(true)

    }

    const GetCommMediumPathApiFuction = () => {
        let subtenantIDd = sessionStorage.getItem("subtenantId")
        // alert(subtenantIDd)
        // konsole.log("jjjjjjj",subtenantID)
        setLoader(true)
        const promises = Services.GetCommMediumPathApi(subtenantIDd)
        promises.then((response) => {
            konsole.log("GetCommMediumPakjkthApi", response?.data?.data);


            setCommMediumData(response.data.data);
            setEmptyState({})

            setLoader(false)
            // window.location.reload()
        })
            .catch((error) => {
                konsole.log("error", error);
                setLoader(false)
            })
            .finally(() => {
                konsole.log("finish");
            });
    };

    // setTimeout(() => {
    //     setLoader(false)
    // }, 3000)

    konsole.log("GetCommMediumPa;j;j;j;thApi", GetCommMediumData);
    konsole.log("subtenantID", subtenantID)


    return (
        <div>
            <Row >
                <Col span={24}>
                    <Row >
                        <Col span={24} >
                            {/* <h2>Communication</h2> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={14} xs={14} sm={14} md={14} lg={14} className="input-group Serach-Input-Box-Div mt-2">
                            <input type="text" className="form-control" placeholder="Search for occurrences" onChange={handleSearch} />
                            <a href="#" style={{ backgroundColor: "#F4F6F6" }}><img className='' height={40} src="/icons/eventSearchIngIcon.svg" /></a>
                        </Col>
                        <Col span={10} xs={10} sm={10} md={10} lg={10}>
                            <div className=" d-flex justify-content-end mt-2">
                                <button
                                    className="Add-Edit-btn text-white"
                                    type="submit"
                                    onClick={addEditModalFun}
                                >
                                    ADD
                                </button>
                            </div>
                            {
                                showAddModal &&

                            <Addcommunicationmodal showAddModal={showAddModal} setShowAddModal={setShowAddModal} GetCommMediumPathApiFuction={GetCommMediumPathApiFuction} />
                            }

                        </Col>
                    </Row>
                    {/* <Newmodal showAddModal={showAddModal} setShowAddModal={setShowAddModal}/> */}
                    <Row className='mt-2'>
                        <Col span={24}>

                            <Communicationtable search={search} GetCommMediumData={GetCommMediumData} GetCommMediumPathApiFuction={GetCommMediumPathApiFuction} />
                        </Col>
                    </Row>

                </Col>
            </Row>
        </div>
    )
}

export default Communication
import { Col, Form, Input, Row } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useState } from "react";
import { mapApi } from "../control/Constant";
import konsole from "../network/konsole";
import Services from "../network/Services";

const Address = (props) => {
  const [address, updateAddress] = useState({});
  const buttonRef = useRef(null);
  const addressRef = useRef(null);
  const [addressfiledErr, setAddressfieldErr] = useState(false)
  useEffect(() => {
    Services.initMapScript().then(() => initAutoComplete());
  }, []);

  useEffect(()=>{
    if(props.updatePartnerUsersData !== null && props.updatePartnerUsersData !== undefined && props.updatePartnerUsersData !== ""){
      if(props?.subtinentData == true){
       getAddressByAddressid(props.updatePartnerUsersData?.addressId)
      }else{
        getAddressAPIFunction(props?.updatePartnerUsersData?.userId)
      }
 
      
    }
  },[props?.updatePartnerUsersData])

  

  const getAddressAPIFunction = (userId) =>{

    let promise = Services.getAddressDetails(userId)
    promise.then((response)=>{
      let addressData = response.data?.data?.addresses[0]
      konsole.log("getAddressAPIFunction",addressData)
      konsole.log("getAddressAPIFunction",response.data?.data)
      if(addressData !== null && addressData !== undefined && addressData !== ""){
        props.setAddressAPIStatus(addressData)
        
        addressRef.current.value = addressData?.addressLine1 
        props.form.setFieldsValue({
          'address' : addressData?.addressLine1,
          'zipcode' : addressData?.zipcode,
          'country' : addressData?.country,
          'state' : addressData?.state,
          'city' : addressData?.city,
        })
      }else{
        props.setAddressAPIStatus(addressData)

      }
    }).catch((error)=>{
      konsole.log("getAddressAPIFunctionError",error)
      
    }).finally(()=>{
      konsole.log("getAddressAPIFunctionEnd")

    })

  }
  const getAddressByAddressid =(id)=>{
      Services.getAddressId(id).then((res)=>{
        let address = res?.data?.data
    const {addressLine1,zipcode,country,state,city} = address
        addressRef.current.value = addressLine1
           props.form.setFieldsValue({
          'address' : addressLine1,
          'zipcode' : zipcode,
          'country' : country,
          'state' : state,
          'city' : city,
        })
      
      }) .catch((err)=>{
   
      })
    
   }

  const extractAddress = (place) => {
    const address = {
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zipcode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });

    return address;
  };

  const onChangeAddress = (autocomplete) => {
    setAddressfieldErr(false);
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  const setAddress = (address) => {
    address.address = addressRef.current.value;
    
    updateAddress({
      city: address.city,
      state: address.state,
      zipcode: address.zip,
      country: address.country,
    });
    // konsole.log("asp", address);
    props.form.setFieldsValue(address);
    if(address.address=="" || address.city=="" || address.state=="" || address.zipcode=="" || address.country==""){
      setAddressfieldErr(true)
  
     }
  };

  const initAutoComplete = () => {
    if (!addressRef.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
        onChangeAddress(autocomplete)
    );
  };

  return (
    <div>
      <Row className="w-100" gutter={[8, 8]}>
        <Col xs={24} lg={24}>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: "Please enter your Address",
              },
            ]}

          >
            <Input.Group>
              <input
                placeholder="Address"
                className="form-control"
                ref={addressRef}
              />
            </Input.Group>
            {
          // addressfiledErr? <p>Wrong</p> :null
        }
          </Form.Item>
        </Col>

        
        <Col xs={24} lg={8}>
          <Form.Item
            name="city"
            

          >
            <Input placeholder="City" value={address?.city} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="state"

          >
            <Input placeholder="State" value={address?.state} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="country"

          >
            <Input placeholder="Country" value={address?.country} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            name="zipcode"

          >
            <Input placeholder="Zip Code" value={address?.zipcode} disabled />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Address;

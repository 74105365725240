import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { InputCom, InputSelectCom } from './InputCom'
import { message, Popconfirm } from 'antd'
import { FileCabinetContext } from './ParentCabinet'
import commonLib from '../../control/commonLib'
import konsole from '../../network/konsole'
import { createCategoryObjJson, createFolderObjJson, operationForCategoryType, folderOperationType, cabinetFileCabinetId } from '../../control/Constant';
import { ko } from 'date-fns/locale'
import Services from '../../network/Services'
import { UserContext } from '../../App'

const AddCategoryFolder = ({ partnerSubtenantId, callApiGetCabinetCategory, callApiCabinetFolder, manageShowFileType, refrencePage, actionType, selectFolderInfo, setShowAddEditFolder, previousFolderInfo, uniqueref }) => {
    const { belongsScreen, myAllCabinetList, selectedCabinet, setManageShowFileType, categoryTypeListCheckMapping, setShowScreenType } = useContext(FileCabinetContext)
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const { setLoader } = useContext(UserContext)
    let checkShoeAddEditUpdateMapping = categoryTypeListCheckMapping.length > 0 ? false : true
    let allMainCabinetContainer = myAllCabinetList
    //  define state-----------------------------------------------------
    const [manageUpdate, setManageUpdate] = useState({ updatedBy: stateObj.userId, updatedOn: new Date().toISOString() })
    const [manageCreate, setManageCreate] = useState({ createdBy: stateObj.userId, createdOn: new Date().toISOString() })

    const [categoryTypeList, setCatetoryTypeList] = useState([])

    const [cabinetId, setCabinetId] = useState('')
    konsole.log('cabinetId', cabinetId)

    const [fileCategoryId, setfileCategoryId] = useState('')
    const [name, setName] = useState('')
    const [subFolderName, setSubFolderName] = useState('')
    const [description, setDescription] = useState('')
    const [folderIsMandatory, setFolderIsMandatory] = useState(false)
    //  define useEffect----------------------------------------------------------------------------
    konsole.log('manageShowFileTypemanageShowFileType', manageShowFileType, actionType)
    useEffect(() => {
        if (manageShowFileType !== 'Category' && actionType == 'ADD') {
            setFolderIsMandatory(false)
        } else if (manageShowFileType !== 'Category' && actionType == 'EDIT') {
            setFolderIsMandatory(selectFolderInfo?.folderIsMandatory)
        }
        customFunforuseEffect()
    }, [actionType, refrencePage, selectFolderInfo])
    konsole.log('checkShoeAddEditUpdateMappingcheckShoeAddEditUpdateMappingcheckShoeAddEditUpdateMapping', checkShoeAddEditUpdateMapping)

    useEffect(() => {
        if (allMainCabinetContainer.length > 0) {
            setCabinetId(allMainCabinetContainer[0].cabinetId)
            haldleCabinetInfo(allMainCabinetContainer[0].cabinetId)
        }
    }, [allMainCabinetContainer])

    //  updated  multi cabinet ---------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        if (uniqueref == 'STRUCTURE') {
            setCabinetId(selectedCabinet[0].cabinetId)
            haldleCabinetInfo(selectedCabinet[0].cabinetId)
        }
    }, [myAllCabinetList, selectedCabinet])
    //  updated multi  cabinet ---------------------------------------------------------------------------------------------------------------------------------------------
    // useEffect functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const customFunforuseEffect = () => {
        if (refrencePage == 'FolderStructure' && manageShowFileType !== 'Category') {
            konsole.log('selectFolderInfo', selectFolderInfo)
            let { folderCabinetId, folderFileCategoryId, folderName, folderDescription } = selectFolderInfo
            customFunfor(folderCabinetId, folderName, folderDescription, folderFileCategoryId, 'Folder')
        } else if (refrencePage == 'FolderStructure' && manageShowFileType == 'Category') {
            let { categoryCabinetId, fileCategoryType, categoryDescription } = selectFolderInfo
            konsole.log('selectFolderInfoselectFolderInfoselectFolderInfo', selectFolderInfo)
            customFunfor(categoryCabinetId, fileCategoryType, categoryDescription, '', 'Category')
        } else if (selectFolderInfo !== undefined) {
            let { categoryCabinetId, fileCategoryId } = selectFolderInfo
            customFunfor(categoryCabinetId, '', '', fileCategoryId, 'Category')

        }
    }

    konsole.log('categoryTypeListCheckMappingcategoryTypeListCheckMapping', categoryTypeListCheckMapping)

    const customFunfor = (cabinetId, name, description, categoryId) => {
        const filterCategoryList = allMainCabinetContainer?.filter(item => item.cabinetId == cabinetId)
        const categoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList : [];
        setName(name)
        if (actionType == 'EDIT') {
            setDescription(description)
        } else {
            setDescription('')
        }
        setCatetoryTypeList(categoryList);
        setCabinetId(cabinetId)
        setfileCategoryId(categoryId)

    }
    // useEffect functions------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    //  handle cabinet----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleSelectCabinet = (e) => {
        const { value, id } = e.target
        // setCabinetId(value)
        // const filterCategoryList = allMainCabinetContainer?.filter(item => item.cabinetId == value)
        // const categoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList : [];
        // setCatetoryTypeList(categoryList);
        // callApiGetCabinetCategory()
        let myNewArr = JSON.parse(JSON.stringify(myAllCabinetList))
        const filterCategoryList = myNewArr?.filter(item => item.cabinetId == value)
        let fileCategoryId = filterCategoryList.length > 0 && filterCategoryList[0].fileCategoryTypeMappingList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList[0].fileCategoryId : null
        konsole.log('fileCategoryIdazs', filterCategoryList, fileCategoryId)
        callApiGetCabinetCategory(fileCategoryId)
        haldleCabinetInfo(value)

    }

    const haldleCabinetInfo = (value) => {
        setCabinetId(value)
        const filterCategoryList = allMainCabinetContainer?.filter(item => item.cabinetId == value)
        // const categoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList : [];
        let myAllCategoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList.filter(({ subtenantId }) => subtenantId == partnerSubtenantId) : [];
        if (belongsScreen !== 'Firmpartner') {
            myAllCategoryList = filterCategoryList.length > 0 ? filterCategoryList[0].fileCategoryTypeMappingList.filter(({ roleId }) => roleId == stateObj.roleId) : [];
        }
        console.log('myAllCategoryListmyAllCategoryListmyAllCategoryList', myAllCategoryList)
        const categoryList = myAllCategoryList
        let myCabinetArr = [...categoryList]

        setCatetoryTypeList(categoryList);
    }

    konsole.log('selectFolderInfoselectFolderInfo', selectFolderInfo)

    console.log('namenamename')
    //  validate function------------------------------------------------------------------------------------------------------------------------------

    const validateFun = (type) => {
        let errMessage;
        if (cabinetId == '') {
            errMessage = 'Please select cabinet.'
        } else if (type == 'Folder' && fileCategoryId == '') {
            errMessage = "Please select Drawer"
        } else if (name == '' || name == null || name == undefined) {
            errMessage = `${type} name can't be blank`
        }

        if (errMessage) {
            message.error(errMessage)
            return true
        }
        return false

    }
    // save category----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const callCategoryFun = async () => {
        if (validateFun('Drawer')) return;
        // return;
        let fileCategoryId = 0;
        let categoryMappingId = 0;
        let manageDateTime = manageCreate
        if (actionType == 'EDIT') {
            fileCategoryId = selectFolderInfo?.fileCategoryId
            categoryMappingId = selectFolderInfo.categoryMappingId
            manageDateTime = manageUpdate
        }
        let categoryRoleId = belongsScreen == 'Firmpartner' ? 11 : stateObj.roleId;
        let folderSubtenantId = belongsScreen == 'Firmpartner' ? partnerSubtenantId : subtenantId;
        let postJson = createCategoryObjJson({ ...manageDateTime, fileCategoryId, categoryCabinetId: cabinetId, categoryMappingId, roleId: categoryRoleId, subtenantId: folderSubtenantId, fileCategoryType: name, description, operation: operationForCategoryType[0] })
        console.log('postJson', postJson)
        let actionTypemsg = (actionType == 'EDIT') ? 'edited' : 'added'
        let message = `Drawer ${actionTypemsg} successfully.`
        // callApiCabinetCategory([postJson], actionTypemsg)
        konsole.log('postJsonpostJsonpostJson', postJson, JSON.stringify(postJson))
        // return;
        let result = await callApiCabinetCategory([postJson])
        konsole.log('checkShoeAddEditUpdateMappingcheckShoeAddEditUpdateMapping', checkShoeAddEditUpdateMapping)
        konsole.log('resultresultresult', result)
        if (result == 'err') {
            screenReload()
        } else if (checkShoeAddEditUpdateMapping == false && belongsScreen !== 'Firmpartner') {
            konsole.log('aaaaaaaaaaaaaa', result)
            let fileCategoryId2 = result.data.data[0].fileCategoryId
            let postJson = createCategoryObjJson({ ...manageDateTime, fileCategoryId: fileCategoryId2, categoryCabinetId: cabinetId, categoryMappingId, roleId: stateObj.roleId, subtenantId, fileCategoryType: name, description, operation: operationForCategoryType[1] })
            konsole.log('resultMappingresultMapping ', postJson, JSON.stringify(postJson), fileCategoryId)
            let resultMapping = await callApiCabinetCategory([postJson])
            konsole.log('resultMappingresultMapping', resultMapping)
            screenReload(message)
        } else {
            screenReload(message)
        }
        if (uniqueref == 'STRUCTURE') {
            setShowAddEditFolder('')
        }

    }

    const callApiCabinetCategory = (postJson, type) => {
        return new Promise((resolve, reject) => {
            setLoader(true)
            Services.upsertCabinetCategoryType(postJson).then((response) => {
                konsole.log('response of adding updating and deleting category', response)
                setLoader(false)
                setManageShowFileType('')
                setShowScreenType('')
                resolve(response)
            }).catch(err => {
                setLoader(false)
                resolve('err')
                konsole.log('response of adding updating and deleting category', err)
            })

        })
    }
    // save folder --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const callFolderCabinet = async () => {
        if (validateFun('Folder')) return;
        // return;
        let roleId = belongsScreen == 'Firmpartner' ? 11 : stateObj.roleId;
        let belongsTo = stateObj.userId
        let folderSubtenantId = belongsScreen == 'Firmpartner' ? partnerSubtenantId : subtenantId;
        let fileCategoryIdassign = fileCategoryId
        let parentFolderId = 0
        let folderId = 0
        let displayOrder = 0
        let level = 0
        let folderName = name

        if (refrencePage == 'FolderStructure' && actionType == 'ADD') {
            folderName = subFolderName
            level = selectFolderInfo.level + 1
            displayOrder = selectFolderInfo.displayOrder + 1
            parentFolderId = selectFolderInfo.folderId
            fileCategoryIdassign = selectFolderInfo.folderFileCategoryId
        } else if (refrencePage == 'FolderStructure' && actionType == 'EDIT') {
            folderName = name
            level = selectFolderInfo.level
            displayOrder = selectFolderInfo.displayOrder
            folderId = selectFolderInfo.folderId
            fileCategoryIdassign = selectFolderInfo.folderFileCategoryId
            parentFolderId = selectFolderInfo?.parentFolderId
        }
        let manageDateTime = (actionType == 'ADD') ? manageCreate : manageUpdate
        let postJson = createFolderObjJson({ ...manageDateTime, folderIsMandatory, folderRoleId: roleId, folderSubtenantId: folderSubtenantId, folderCabinetId: cabinetId, folderFileCategoryId: fileCategoryIdassign, folderId, parentFolderId, folderName, folderDescription: description, folderOperation: folderOperationType[0], level, displayOrder, belongsTo,sharedRoleId:stateObj?.roleId,folderIsActive: true })
        let message = `Folder ${actionType == 'ADD' ? 'added' : 'updated'} successfully.`

        // callApiCabinetFolder([postJson], `Folder ${message} successfully.`)
        konsole.log('postJsonpostJson', postJson, JSON.stringify(postJson))
        // return;
        let result = await callAddFolderApi([postJson])
        konsole.log('resultresult', result)
        if (result == 'err') {
            screenReload()
        } else if (checkShoeAddEditUpdateMapping == false) {
            let folderId = result.data.data[0].folderId
            let folderOperation = belongsScreen == 'Firmpartner' ? folderOperationType[3] : folderOperationType[2]
            let roleId = belongsScreen == 'Firmpartner' ? 11 : stateObj.roleId;
            let folderSubtenantId = belongsScreen == 'Firmpartner' ? partnerSubtenantId : subtenantId;
            // let jsonObj=folderOperationType[2]
            let jsonObj = { "folderId": Number(folderId), "folderOperation": folderOperation, "folderCabinetId": cabinetFileCabinetId[0], "folderRoleId": Number(roleId), folderSubtenantId: folderSubtenantId }
            let result2 = await callAddFolderApi([jsonObj])
            screenReload(message)
        } else {
            screenReload(message)
        }
        if (uniqueref == 'STRUCTURE') {
            setShowAddEditFolder('')
        }

    }



    const callAddFolderApi = (postJson) => {
        return new Promise((resolve, reject) => {
            setLoader(true)
            Services.upsertCabinetFolder(postJson).then((response) => {
                konsole.log('response of adding updating and deleting folder', response)
                setLoader(false)
                setManageShowFileType('')
                setShowScreenType('')
                resolve(response)
            }).catch(err => {
                setLoader(false)
                resolve('err')
                konsole.log('response of adding updating and deleting folder', err)
            })

        })
    }

    const screenReload = (msg) => {
        (message) && message.success(msg)
        window.location.reload()
    }


    const functionCancelSave = () => {
        if (uniqueref == 'STRUCTURE') {
            setShowAddEditFolder('')
        } else {
            setManageShowFileType('')
        }
    }

    konsole.log('actionTypeactionTypeactionType', refrencePage, actionType)


    const handleIsMandatoryCheckBox = (e) => {
        // let palentFolderId = 0
        // konsole.log('previousFolderInfopreviousFolderInfo', previousFolderInfo)
        // if (refrencePage == 'FolderStructure' && actionType == 'ADD') {
        //     palentFolderId = selectFolderInfo.folderId
        // } else if (refrencePage == 'FolderStructure' && actionType == 'EDIT') {
        //     palentFolderId = selectFolderInfo?.parentFolderId
        // }
        // konsole.log('palentFolderIdpalentFolderId', palentFolderId)

        // if (previousFolderInfo && previousFolderInfo?.folderIsMandatory == false && palentFolderId != 0) {
        //     alert('hhhhhhhhhhhhhhh')
        //     return;
        // }
        setFolderIsMandatory(e.target.checked)
    }

    konsole.log('checkShoeAddEditUpdateMapping', checkShoeAddEditUpdateMapping, manageShowFileType)

    let messsageTitleForSave = (belongsScreen !== 'Firmpartner') ? `This added  ${manageShowFileType == 'Category' ? 'Drawer' : 'Folder'} will reflect under this cabinet for  assigned partners. Are you sure you want to add?.` :
        `This added  ${manageShowFileType == 'Category' ? 'Drawer' : 'Folder'}  will reflect under this cabinet for selected partner. Are you sure you want to add?.`
    // refrencePage !== 'ManageAllFile' && actionType == 'ADD'

    return (
        <>
            <Row>
                <Col lg='12' className='main-col'>
                    <Row className='mt-2'>
                        <Col lg='10' className='ms-2'>
                            <Row className='mt-3'><Col><h6> {actionType == 'ADD' ? 'Add' : 'Edit'} {refrencePage !== 'ManageAllFile' && actionType == 'ADD' ? 'Sub' : ''} {manageShowFileType == 'Category' ? 'Drawer' : 'Folder'}</h6></Col></Row>

                            {/* <InputSelectCom label='Cabinet' placeholder='Select any Cabinet' id='cabinetId' disable={uniqueref == 'STRUCTURE'}
                                selectTypeValue='cabinetId' selectTypeLabel='cabinetName' selectInfo={allMainCabinetContainer} value={cabinetId} handleSelectChange={handleSelectCabinet} /> */}
                            {manageShowFileType !== 'Category' && <InputSelectCom label='Drawer' placeholder='Select  Drawer' id='cabinetId' disable={uniqueref == 'STRUCTURE'} selectTypeValue='fileCategoryId' selectTypeLabel='fileCategoryType' selectInfo={categoryTypeList} value={fileCategoryId} handleSelectChange={(e) => setfileCategoryId(e.target.value)} />}
                            <InputCom label={manageShowFileType == 'Category' ? 'Drawer Name' : 'Folder Name'} placeholder={manageShowFileType == 'Category' ? 'Enter Drawer Name' : ' Enter Folder Name'} disable={refrencePage == 'FolderStructure' && actionType !== 'EDIT'} value={name} handleInputChange={(e) => setName(commonLib.capitalizeFirstLetter(e.target.value))} />
                            {(refrencePage !== 'ManageAllFile' && actionType == 'ADD') && <InputCom label='Sub Folder Name' value={subFolderName} id='subfolderName' handleInputChange={(e) => setSubFolderName(commonLib.capitalizeFirstLetter(e.target.value))} placeholder='Enter Sub Folder Name' />}
                            <InputCom type='textarea' label='Description' id='fileCategoryDescription' placeholder='Description' value={description} handleInputChange={(e) => setDescription(commonLib.capitalizeFirstLetter(e.target.value))} />

                            {/* {manageShowFileType !== 'Category' &&
                                <Row className=''><Col lg="5"></Col><Col lg="7">  <Form.Group className="checkbox-group">
                                    <Form.Check className="custom-checkbox" type="checkbox"
                                        name='folderIsMandatory' id='folderIsMandatory'
                                        checked={folderIsMandatory} onChange={(e) => handleIsMandatoryCheckBox(e)}
                                        label={<span className="custom-checkbox-label">Folder is mandatory</span>}
                                    />
                                </Form.Group>
                                </Col>
                                </Row>} */}
                            <Row className='mt-2'><Col lg="5"></Col><Col lg="7">


                                {checkShoeAddEditUpdateMapping == false ? <Popconfirm
                                    title={messsageTitleForSave}
                                    okButtonProps={{ style: { background: '#720C20', color: 'white', border: 'none' } }}
                                    cancelButtonProps={{ style: { border: 'none', color: 'black' } }}
                                    onConfirm={() => manageShowFileType === 'Category' ? callCategoryFun() : callFolderCabinet()}
                                    okText="Yes" cancelText="No">
                                    <Button 
                                    className='folder-save-button'
                                    >Save</Button>
                                </Popconfirm> : <Button className='folder-save-button'
                                    onClick={() => manageShowFileType === 'Category' ? callCategoryFun() : callFolderCabinet()}
                                >Save</Button>}

                                <Button className='folder-save-button ms-2' onClick={() => functionCancelSave()}>Cancel</Button>
                            </Col></Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
        </>
    )
}

export default AddCategoryFolder
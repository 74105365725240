import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { InputCom, InputSelectCom } from './InputCom'
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import commonLib from '../../control/commonLib';
import { operationForCabinet } from '../../control/Constant';
import { FileCabinetContext } from './ParentCabinet';
import { UserContext } from '../../App';
import { message } from 'antd'

const MappingSubtenant = ({ apiCallUpsertMainCabinet, callApiGetCabinetCategory }) => {
    let { setShowScreenType, myAllCabinetList } = useContext(FileCabinetContext)
    let { setLoader } = useContext(UserContext)
    const stateObj = commonLib.getObjFromStorage("stateObj");
    konsole.log('myAllCabinetListmyAllCabinetList', myAllCabinetList)

    // define state---------------------------------------
    const [subtenantList, setSubtenantList] = useState([])
    const [selectSubtenantForMapping, setSelectSubtenantForMapping] = useState([])
    const [cabinetId, setCabinetId] = useState('')
    const [screenCol, setScreenCol] = useState(10)
    const [subtenantSelectionRadio, setSubtenantSelectionRadio] = useState(0)
    const [selectAllbtn, setSelectAllbtn] = useState(false)
    const [categoryTypeListCheckMapping, setcategoryTypeListCheckMapping] = useState([])
    konsole.log('selectSubtenantForMapping', selectSubtenantForMapping)
    //  define useEffect----------------------------------------------------------------------------------------------------------------------------------------------------------

    konsole.log('categoryTypeListCheckMappingcategoryTypeListCheckMapping', categoryTypeListCheckMapping)
    useEffect(() => {
        callApigetAllSubtenantDetails()
        callApiGetCabinetCategoryForCheckMapping()
    }, [])
    // useEffect(() => {
    //     setCabinetId(allMainCabinetContainer[0].cabinetId)
    // }, [allMainCabinetContainer])




    useEffect(() => {
        if (categoryTypeListCheckMapping.length > 0) {
            setScreenCol(7)
            setSubtenantSelectionRadio(2)

            // subtenantId
        }
    }, [categoryTypeListCheckMapping, subtenantList])
    konsole.log('subtenantListsubtenantListsubtenantList', subtenantList)
    const callApigetAllSubtenantDetails = () => {
        setLoader(true)
        Services.getAllSubtenantDetails({ isActive: true }).then((res) => {
            setLoader(false)
            konsole.log('res of all subtenants', res)
            setSubtenantList(res?.data?.data)
        }).catch((err) => {
            setLoader(false)
            konsole.log('err in all subtenants fetch', err)
        })
    }




    //  call api check subtenant is mapped or not ------------------------------------------------------------------------
    function callApiGetCabinetCategoryForCheckMapping() {
        let postJson = { "roleId": 11 }
        setLoader(true)
        Services.getCabinetCategoryType(postJson).then((res) => {
            konsole.log('callApiGetCabinetCategoryForCheckMapping', res)
            let responseData = res?.data?.data
            setcategoryTypeListCheckMapping(responseData)
            konsole.log('responseDataresponseData', responseData)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            konsole.log('callApiGetCabinetCategoryForCheckMapping', err)
        })
    }

    //  handle Subtenant Checkbox --------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleChangeSubtenantCheckBox = async (index, e) => {
        let { value, checked } = e.target
        konsole.log('checked', checked)
        setSubtenantList((prevArray) => {
            const newArray = [...prevArray];
            newArray[index]['checked'] = checked;
            return newArray;
        });
        forAddDeleteSubtenantFromJson(checked, value)
    }



    const forAddDeleteSubtenantFromJson = async (checked, value) => {
        if (checked == true) {
            setLoader(true)
            let userId = await getUserListBySubtenantId(value)
            setLoader(false)
            setSelectSubtenantForMapping(prevState => [...prevState, { subtenantId: Number(value), categoryCreatedBy: userId }]);
        } else if (checked == false) {
            setSelectSubtenantForMapping((prevArray) => {
                return prevArray.filter(({ subtenantId }) => subtenantId !== Number(value))
            })
        }
    }
    //  handle radio subtenant --------------------------------------------------------------------------------------------------------------------------------------------------------
    const handleChangeRadio = async (e) => {
        konsole.log('value', e.target.value)
        let { value } = e.target
        setScreenCol(7)
        setSubtenantSelectionRadio(value)
        let newArr = [...subtenantList]
        setLoader(true)
        for (let [index, { subtenantId }] of newArr.entries()) {
            konsole.log('itemitem', subtenantId)
            if (value == 1) {
                newArr[index]['checked'] = true
                forAddDeleteSubtenantFromJson(true, subtenantId)
            } else {
                newArr[index]['checked'] = false
                forAddDeleteSubtenantFromJson(false, subtenantId)
            }
        }
        setLoader(false)
        setSubtenantList(newArr)
    }

    const handleSelectAllCheckBox = (e) => {
        setSelectAllbtn(e.target.checked)
        let newArr = [...subtenantList]
        for (let [index, { subtenantId }] of newArr.entries()) {
            forAddDeleteSubtenantFromJson(e.target.checked, subtenantId)
        }

    }

    //  get Subtenant Admin userId --------------------------------------------------------------------------------------------------------------------------------------------------------
    const getUserListBySubtenantId = (subtenantId) => {
        return new Promise((resolve, reject) => {
            Services.getUserListBySubtenantId({ subtenantId: subtenantId }).then((res) => {
                konsole.log('res in get user list', res)
                let responseData = res?.data?.data[0]
                resolve(responseData?.userId)
            }).catch((err) => {
                konsole.log('err in get user list', err)
                resolve(null)
            })
        })

    }

    konsole.log('selectSubtenantForMapping', selectSubtenantForMapping.length)



    const saveMappingFun = () => {
        konsole.log('cabinetId', cabinetId)
        if (!cabinetId) {
            message.error('Please select any cabinet')
            return;
        }
        if (selectSubtenantForMapping.length == 0) {
            message.error('Please select any subtenant admin')
            return;
        }
        let postJson = {
            "cabinetId": Number(cabinetId),
            "cabinetOperation": operationForCabinet[1],
            "fileCategoryTypeMappingList": selectSubtenantForMapping
        }

        konsole.log('jsonObjAz', postJson, "---------------------", JSON.stringify(postJson))

        apiCallUpsertMainCabinet(postJson, 'Cabinet assigned successfully.')

    }

    useEffect(() => {
        let cabinetId = myAllCabinetList.length > 0 ? myAllCabinetList[0].cabinetId : ''
        let json = {
            target: {
                value: cabinetId
            }
        }
        handleSelectCabinet(json)
    },[])


    const handleSelectCabinet = (e) => {
        let { value } = e.target
        setCabinetId(value)
        const filterCategoryList = myAllCabinetList?.filter(item => item.cabinetId == value)
    }


    konsole.log('subtenantListsubtenantList', subtenantList)

    return (
        <>
            <Row>
                <Col lg='12' className='main-col'>

                    <Row>
                        <Col>  <h5 style={{ color: "#720c20" }}>Assign cabinet for partner admin. </h5> </Col>
                    </Row>

                    {/* <Row className='mt-2'>
                        <Col lg={screenCol}>
                            <Row className='mt-2'>
                                <Col lg='4'>
                                    Cabinet
                                    <div>
                                        <Form.Select aria-label="Default select example" disabled={true} value={cabinetId} onChange={(e) => setCabinetId(e.target.value)} id='cabinetId'>
                                            <option value='' disabled defaultValue>Select any Cabinet</option>
                                            {allMainCabinetContainer?.map((item, index) => (<option key={index} value={item['cabinetId']}> {item['cabinetName']}  </option>))}
                                        </Form.Select>
                                    </div>

                                </Col>
                                <Col lg='5'>
                                    <div>
                                        <Form.Check type='radio' label='All subtenants' name='All subtenants' id='1' value='1' className="w-75 " onChange={handleChangeRadio} disabled={categoryTypeListCheckMapping.length > 0} checked={subtenantSelectionRadio == 1} />
                                        <Form.Check type='radio' label='Specific subtenants' name='Specific subtenants' id='2' value='2' className="w-75 " onChange={handleChangeRadio} checked={subtenantSelectionRadio == 2} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'><Col lg="5"></Col><Col lg="7">
                                <Button className='folder-save-button' onClick={() => saveMappingFun()} >Save</Button>
                                <Button className='folder-save-button ms-4' onClick={() => setShowScreenType()} >Cancel</Button>
                            </Col></Row>
                        </Col>
                        {subtenantSelectionRadio !== 0 && <Col lg="3" className='shadow bg-light' style={{ zIndex: '9999999999' }}>
                            <h6 className="filetool-text-tag-h6 mx-2">Choose subtenants</h6>
                            <div className='w-100 border-left p-2' style={{ maxHeight: '11rem', overflowX: 'auto' }}>
                                {subtenantList.length > 0 && subtenantList.map((item, index) => {
                                    konsole.log('itemitemitem', item)
                                    let checkDisabled = categoryTypeListCheckMapping?.some(({ subtenantId }) => subtenantId == item.subtenantId)
                                    let checkValue = checkDisabled == true ? true : item.checked
                                    return (<>
                                        <Form.Group className="ms-2 checkbox-group">
                                            <Form.Check type="checkbox"
                                                label={<span className="custom-checkbox-label">{item.subtenantName}</span>}
                                                className="custom-checkbox"
                                                value={item.subtenantId}
                                                onChange={(e) => handleChangeSubtenantCheckBox(index, e)}
                                                checked={checkValue}
                                                disabled={checkDisabled}
                                            />
                                        </Form.Group>
                                    </>)
                                })}
                            </div>
                        </Col>}
                    </Row> */}
                    <Row className='mt-2'>
                        <Col lg='4' className='d-flex flex-column justify-content-between'>
                            <div>
                                Cabinet
                                <Form.Select aria-label="Default select example" value={cabinetId} disabled onChange={(e) => handleSelectCabinet(e)} id='cabinetId'>
                                    <option value='' disabled defaultValue>Select any Cabinet</option>
                                    {myAllCabinetList?.map((item, index) => (<option key={index} value={item['cabinetId']}> {item['cabinetName']}  </option>))}
                                </Form.Select>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <Button className='folder-save-button' onClick={() => saveMappingFun()} >Save</Button>
                                <Button className='folder-save-button ms-4' onClick={() => setShowScreenType()} >Cancel</Button>
                            </div>
                        </Col>

                        <Col lg="6" className='chooseSubt shadow bg-light'>
                            <div className='d-flex'>
                                <h6 className="filetool-text-tag-h6 mx-2">Choose Firms</h6>
                                <Form.Group className="ms-2  d-flex justify-content-center checkbox-group ">
                                    <Form.Check label='Select All' className="custom-checkbox-permissions" type="checkbox" onChange={(e) => handleSelectAllCheckBox(e)} />
                                </Form.Group>
                            </div>

                            <div className='w-100 border-left p-2' style={{ maxHeight: '11rem', overflowX: 'auto' }}>
                                {subtenantList.length > 0 && subtenantList.map((item, index) => {
                                    konsole.log('itemitemitem', item)
                                    let checkDisabled = categoryTypeListCheckMapping?.some(({ subtenantId }) => subtenantId == item.subtenantId)
                                    let checkValue = (selectAllbtn == true) ? true : checkDisabled == true ? true : item.checked
                                    return (<>
                                        <Form.Group className="ms-2 checkbox-group">
                                            <Form.Check type="checkbox"
                                                label={<span className="custom-checkbox-label">{item.subtenantName}</span>}
                                                className="custom-checkbox"
                                                value={item.subtenantId}
                                                onChange={(e) => handleChangeSubtenantCheckBox(index, e)}
                                                checked={checkValue}
                                                disabled={checkDisabled}
                                            />
                                        </Form.Group>
                                    </>)
                                })}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
        </>
    )
}



export default MappingSubtenant
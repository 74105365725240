import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Select, Space, Row, Col, Card, Button, Modal, Table, message, Popconfirm } from "antd";
import "../components/FirmPartner/Firmpartner.css";
import { UserContext } from "../App";
import "../Styles/subtenant.css";
import Services from "../network/Services";
import konsole from "../network/konsole";
import DeleteConfirmModal from "./DeleteConfirmModal";
import InputMask from "./PhoneInputMask";
import { isValidFuntion } from "../control/Constant";
import commonLib from "../control/commonLib";
const Academy = () => {
    const [number, setNumber] = useState(1);
    const [addUser, setAddUser] = useState(false)
    const [countrycode, setcountryCode] = useState([]);
    const [countryCodeValue, setcountryCodeValue] = useState()
    const [data, setdata] = useState([]);
    const [visible, setVisible] = useState(false);
    const [subtenantValue, setSubtenantValue] = useState('');
    const [subtenantdata, setsubtenantdata] = useState([]);
    const [createAccademyUser, setCreateAccademyUser] = useState()
    const [show, setshow] = useState(false)
    const [selectedSubtenant, setselectedSubtenant] = useState('')
    const { setLoader } = useContext(UserContext)
    const [roleid, setroleid] = useState('')
    const [defaultvalue, setDefaultvalue] = useState('Select a partner')
    const [disabledselect, setdisableselect] = useState(false)
    const [searchevent, setSearchevent] = useState("");
    const [form] = Form.useForm();
    const [upGradeUserData, setupGradeUserData] = useState()
    const [updatePartnerUsersData, setUpdatePartnerUsersData] = useState()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const { Option } = Select;
    const createdBy = null;
    const isActive = true;
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        if (deleteModal == true) {
            showRolesModalFunc()
        }
        if (addUser == true) {

        }
    }, [deleteModal, createAccademyUser])
    const showUserModal = () => {
        setUpdatePartnerUsersData()
        setVisible(true);
        setshow(!show);
    };
    const onChangePhone = (value) => {
        form.setFieldsValue({
            mobile: value,
        });
    };
    useEffect(() => {
        
        const countryCode = Services.getcountrycode();
        countryCode
            .then((response) => {
                setcountryCode(response.data.data);
            })
            .catch((err) => {
                konsole.log("Error", err);
            });
    }, []);

    const showRolesModalFunc = () => {
        setShowDeleteModal(false)
        let promis = Services.getExpirydatetime(upGradeUserData?.userRgstrtnId)
        promis.then((response) => {
            let data = response?.data?.data
            const originalDate = new Date();
            originalDate.setFullYear(originalDate.getFullYear() + 1);
            let createdBy = sessionStorage.getItem('loggedUserId')
            upGradeValidityPeriod(data?.userValidityId, data?.loginUserid, data?.userId, data?.userRoleId, data?.roleId, originalDate, createdBy, true)
        }).catch((err) => {

        })
    };
    const upGradeValidityPeriod = (userValidityId, loginUserid, userId, userRoleId, roleId, expriedDate, createdBy, isCash) => {
        setLoader(true)
        let promises = Services.putUpgradeValidity(userValidityId, loginUserid, userId, userRoleId, roleId, expriedDate, createdBy, isCash);
        promises
            .then(function (response) {
                setLoader(false)
                updateAcademy()
            })
            .catch((err) => {
                setLoader(false);
            });

    }
    const updateAcademy = async () => {
        setLoader(true)
        const userPrimaryDetail = upGradeUserData?.primaryEmail
        await Services.apiCallScripePaymentConfirmation({ userEmail: userPrimaryDetail })
        setShowDeleteModal(false)
        setDeleteModal(false)
        getUserListApi()
        message.success('Upgrade Successfully')
        setLoader(false)
    }

    const hideUserModal = () => {
        setVisible(false);
        setshow(false)
    };

    const onFinish = (values) => {
        konsole.log("Finish:", values);
    };
    useEffect(() => {
        getUserListApi();
    }, [subtenantValue]);
    useEffect(() => {
        let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
        let subtenantId = sessionStorage.getItem("subtenantId")
        setroleid(userroleId?.roleId)
        if (isValidFuntion(subtenantdata) && roleid == "11") {
            setdisableselect(true)
            setSubtenantValue(subtenantId);
            setselectedSubtenant(subtenantId)
            let datafilter = subtenantdata.filter((val) => {
                return val.subtenantId == subtenantId
            })
            if(datafilter.length > 0){
                setDefaultvalue(datafilter[0]?.subtenantName)
                setselectedSubtenant(datafilter[0]?.subtenantName)
                konsole.log("datafilterdatafilter", datafilter)
            }
         

        } else {
            if (subtenantdata.length > 0) {
                const { subtenantName, subtenantId } = subtenantdata[0];
                setDefaultvalue(subtenantName);
                setSubtenantValue(subtenantId);
                setselectedSubtenant(subtenantName);
            }
           
            setdisableselect(false)
        }

    }, [subtenantdata])

    useEffect(() => {
        const Subtenantdetailspromise = Services.getsubtenantdetails(
            createdBy,
            isActive
        );
        Subtenantdetailspromise.then((res) => {
            setsubtenantdata(res.data.data);
        }).catch((err) => {
            konsole.log("err", err);
        });
    }, []);

    const getUserListApi = (startValue, EndValue) => {
        if (startValue !== 1) {
            setLoader(true)
        }
        const subtenantId = subtenantValue;
        if (subtenantId !== '' && subtenantId !== undefined && subtenantId !== null) {

            const getUserlistpromise = Services.getUserListByRoleIdAcadmy(3, true, "AOACADEMY");
            getUserlistpromise.then((res) => {
                setdata(res.data.data);
                form.setFieldsValue({
                    fName:"",
                    lName:"",
                    number:"",
                    mobile:"",
                    email:""
                })
                // setAssignOptionsList(res.data.data);
                setLoader(false)

            })
                .catch((err) => {
                    konsole.log("errerr", err);
                }).finally(() => {
                    setLoader(false);
                });
        }
    };
    const onSearch = (value) => konsole.log(value);
    const searchonchange = () => {
        let filteredData = [];
        if (searchevent !== null && searchevent !== '' && searchevent !== undefined) {
            let filter = data.filter((items) => {
                let name = items.fullName
                let userName = items.userName
                return name.toLowerCase().includes(searchevent.toLocaleLowerCase()) ||
                    userName.toLowerCase().includes(searchevent.toLocaleLowerCase())
            })
            filteredData = filter
        } else {
            filteredData = data
        }

        return filteredData
    }


    const upGrade = (data) => {
        setupGradeUserData(data)
        setShowDeleteModal(true)
    }

    const updatePartnerUsers = (data) => {
        setUpdatePartnerUsersData(data)
        setVisible(true);
        setshow(!show)


    }

    const confirm = (data) => {
        setLoader(true)

        let createdBy = sessionStorage.getItem('loggedUserId')
        konsole.log("aaaaaaWorking", createdBy)

        let promise = Services.postSendUserActivationLink(data?.subtenantId, data?.userRgstrtnId, data?.userId, data?.fullName, data?.primaryEmail, data?.userRgstrtnId, createdBy, "::1")
        promise.then((response) => {
            konsole.log("postSendUserActivationLinkres", response)
            setLoader(false)
            message.success('Link Send Successfully')
        }).catch((error) => {
            konsole.log("postSendUserActivationLinkerror", error)
            setLoader(false)
        }).finally(() => {
            setLoader(false)
        })



    }
    const onFinishForm = (data) => {
        setCreateAccademyUser(data)
        setAddUser(true)


    };
    const callAddUserApi = (data) => {
        setAddUser(false)
        const formdata = form.getFieldsValue();
        setLoader(true)
        const {fName,lName,email,number,mobile} = data
        let countryCode = number == undefined || number == null || number == "" ? "+1" : number

        let jsonObjData =
        {
            "subtenantid": 3,
            "signupplateform": 14,
            "user":
            {
                "roleid": 18,
                "firstname": fName,
                "lastname": lName,
                "emailaddress": email,
                "username": email,
                "countryCode": countryCode,
                "mobileNumber": mobile,
                "packageCode": "PREMIUMUSER300CASH"
            }
        }
        let postAddUserApiCall = Services.postAcademyUserdata(jsonObjData)
        postAddUserApiCall.then((res) => {
            setLoader(false)
            setVisible(false)
            getUserListApi()
            message.success('Registration Successfully')

        }).catch((error) => {
            if (error.response.data.messages.length > 0 && error.response.data.messages[0] == "ALREADY_REGISTERED") {
                message.error(error.response.data.messages[1])
            }
            setLoader(false)
        }).finally(() => {
            setLoader(false)
        })
    };
    const handleChange = (value) => {
        setcountryCodeValue(value);

    };

    function Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    }
    const addUserAcademy = (data) => {
        if (data == true) {
            callAddUserApi(createAccademyUser)
        } else {
            setAddUser(false)
        }

    }
    return (
        <>
            <div className="row d-flex justify-content-between" style={{ width: "100.5%", }}>
                <div className="col-12 d-flex " style={{ paddingTop: "8px",borderBottom:"2.5px solid rgb(242, 242, 242)"}} >
                    <button className="schedule-active me-2 schedule"
                    >Academy Users</button>
                </div>
                <div style={{ paddingRight: "0px", }}>
                    {number == 1 ? (
                        <>
                            <div className="d-flex flex-column">
                                <Form.Provider
                                    onFormFinish={(name, { values, forms }) => {
                                        if (name === "userForm") {
                                            const { basicForm } = forms;
                                            setVisible(false);
                                        }
                                    }}
                                    className="me-4">
                                    <Form name="basicForm" onFinish={onFinish}>
                                        <div className="select-container"></div>
                                        <Row className="mt-3">
                                            <Col xs={24} lg={24} span={24} style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Col span={12} style={{ display: "flex" }}>

                                                    <div className="w-100 d-flex" style={{ border: "2px solid lightgray", borderRadius: "5px", justifyContent: "space-between", height: "39px" }}>
                                                        <input className="form-control fs-20" style={{ border: "none" }} type="text" placeholder="Search for Academy User" onChange={(e) => setSearchevent(e.target.value.toLowerCase())} />
                                                        <img style={{ padding: "2px", height: "20px", marginTop: "8px", marginRight: "4px" }} src="/search icon.png" />
                                                    </div></Col>

                                                <Col>
                                                    <Button
                                                        htmlType="button"
                                                        style={{
                                                            margin: "0 8px",
                                                            backgroundColor: "#720c20",
                                                            color: "white",
                                                            borderRadius: "5px",
                                                            fontWeight: "600"
                                                            , height: "40px"
                                                        }}
                                                        onClick={showUserModal}
                                                    >
                                                        <span style={{ fontFamily: "sans-serif" }}>
                                                            {" "}
                                                            + ADD NEW ACADEMY USER
                                                        </span>
                                                    </Button>
                                                </Col>
                                            </Col>

                                            {(showDeleteModal || addUser) && (
                                                <DeleteConfirmModal
                                                    visible={showDeleteModal || addUser}
                                                    hideModal={showDeleteModal ? setShowDeleteModal : setAddUser}
                                                    titleText={showDeleteModal ? "Upgrade User" : "Create Academy User"}
                                                    bodyContent="Did you receive $300 in cash from this user for Premium Access to AgingOptions Academy ?"
                                                    deleteStatus={showDeleteModal ? setDeleteModal : addUserAcademy}
                                                />
                                            )}

                                        </Row>
                                    </Form>
                                </Form.Provider>

                                <Table
                                    dataSource={searchonchange(data)}
                                    pagination={false}
                                    className=" mt-3 flex-grow-1 subtanantTable"
                                    scroll={{ y: 'calc(100vh - 250px)' }} 
                                  

                                >
                                    <Row
                                        title="Name"
                                        className="manage fw-bold p-3  shadow-sm"
                                        dataIndex="fullName"
                                        key="fullName"
                                        render={(_, record) => (
                                            <a
                                                onClick={() => updatePartnerUsers(record)}
                                            >
                                                {record.fullName}
                                            </a>
                                        )}
                                    />
                                    <Row
                                        title="Start Date"
                                        className="manage fw-bold p-3  shadow-sm"
                                        dataIndex="startDate"
                                        key="startDate"
                                        render={(_, record) => (
                                            <a>
                                                {record?.startDate && commonLib.getFormattedDate(record.startDate)}
                                            </a>
                                        )}
                                    />
                                    <Row
                                        title="Expire Date"
                                        className="manage fw-bold  p-2 border-top-0 border-buttom-0 border-start-0 shadow-sm text-center border-gray"
                                        dataIndex="expriedDate"
                                        key="expriedDate"
                                        render={(_, record) => (<a>{record?.expriedDate && commonLib.getFormattedDate(record.expriedDate)} </a>)}
                                    />
                                    <Row
                                        title="Email"
                                        className="manage fw-bold p-2 shadow-sm text-center "
                                        dataIndex="primaryEmail"
                                        key="primaryEmail"
                                    />
                                    <Row
                                        title="Status"
                                        className="manage fw-bold p-2 shadow-sm "
                                        dataIndex="isActive"
                                        key="isActive"
                                        render={(_, record) => (
                                            
                                            <p>
                                                {record.isActive ?
                                                    <><p style={{ color: "green", marginTop: "18px" }}>Active</p></> :
                                                    <>
                                                        <Popconfirm
                                                            title=" "
                                                            okText={`Resend Activation Link`}
                                                            icon={false}
                                                            showCancel={false}
                                                            onConfirm={() => confirm(record)}>
                                                            <a><p style={{ color: "orange", cursor: "pointer", marginTop: "18px" }}>Inactive</p></a>
                                                        </Popconfirm>
                                                    </>
                                                }
                                            </p>
                                        )}
                                    />

                                    <Row
                                        className="manage fw-bold  shadow-sm "
                                        gutter={[8, 8]}
                                        title="Action"
                                        key="action"
                                        render={(_, record) => (
                                            <Col xs={24} className="d-flex justify-content-center gap-5">

                                                {record?.academyUserType == "TRIAL USER" ?
                                                    <a className="text-center text-decoration-none text-black" style={{ marginLeft: "5px" }} onClick={() => upGrade(record)}>
                                                        {" "}<img src="/images/vector.png"></img><br />Upgrade User
                                                    </a> : <a>{record.academyUserType}</a>}
                                                {/* <a className="text-center text-decoration-none text-black"
                                                    style={{ marginRight: "10px" }}
                                                    onClick={() => deleteData(record)}
                                                >
                                                    {" "}
                                                    <img src="/images/vector.png"></img>
                                                    <br />
                                                    Delete
                                                </a> */}
                                            </Col>
                                        )}
                                        style={{ width: "10px" }}
                                    />
                                </Table>
                            </div>
                        </>
                    ) : (
                        ""

                    )}
                </div>
            </div>
            {show && <>
                <Modal
                    visible={visible}
                    okText="Add"
                    onCancel={hideUserModal}
                    footer={null}
                    closable={true}
                    centered={true}
                    maskClosable={false}
                >
                    <Form
                        initialValues={{ number: "(+1) USA",}}
                        form={form}
                        name="AdduserForm"
                        style={{ width: "100%", marginTop: "30px", paddingRight: "5px", maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}
                        wrapperCol={{ span: 24 }}
                        labelCol={{ span: 0 }}
                        className="w-100"
                        layout="inline"
                        onFinish={onFinishForm}>

                        <div>
                            <div>
                                <Card className=" w-100 mt-1" style={{ border: "1px dashed black", borderRadius: "5px" }}>
                                    <p className="fw-bolder fs-3 text-center" style={{ color: "#720C20" }}>User Information</p>

                                    <Row className="mt-2 p-2">
                                        <Col xs={24} lg={8}>
                                            <Form.Item name="fName" className="mt-1" onSearch={Capitalize("")}
                                                rules={[{ required: true, message: "Please input your First Name!", },
                                                { pattern: /^[a-zA-Z\d\s]+$/, message: "Please input Valid First Name!", },]}
                                            >
                                                <Input placeholder="First Name" onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} lg={8}>
                                            <Form.Item
                                                name="lName"
                                                className="mt-1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your Last Name!",
                                                    },

                                                    {
                                                        pattern: /^[a-zA-Z\d\s]+$/,
                                                        message: "Please input Valid Last Name!",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Last Name" onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="mt-2" lg={12} xs={24} span={12}>
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        type: "email",
                                                        required: true,
                                                        message: "Please enter your E-mail",
                                                    },
                                                ]}

                                            >
                                                <Input placeholder="Email" disabled={(updatePartnerUsersData !== undefined && updatePartnerUsersData !== null && updatePartnerUsersData !== "") ? true : false} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="mt-2 ms-2" lg={4} xs={24} span={3}>
                                            <Form.Item
                                                name="number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) => {
                                                          if (value === "(+1) USA") {
                                                            return Promise.reject("Please select your country code");
                                                          }
                                                          return Promise.resolve();
                                                        },
                                                      },
                                                ]}
                                            >
                                                 <Space wrap>
                                                    <Select
                                                    
                                                    placeholder="Code"
                                                    optionFilterProp="children"
                                                    onChange={handleChange}
                                                    onSearch={onSearch}
                                                    style={{ width: "113px" }}
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    defaultValue={"(+1) USA"}
                                                >
                                                    {countrycode.map((countryProp) =>
                                                    (
                                                        <Option value={countryProp?.value}>
                                                            {countryProp?.label}

                                                        </Option>
                                                    ))}
                                                </Select>
                                             </Space>
                                             
                                            
    
  
                                            </Form.Item>
                                         
                                            
                                        </Col>
                                        <Col className="mt-2 mobileInputContactWidth " lg={7} xs={24} span={8} >
                                            <Form.Item
                                                name="mobile"

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter your number",
                                                    },
                                                    {
                                                        pattern: /^[0-9]+$/,
                                                        message: "Please input valid  Cell number",
                                                    },
                                                    {
                                                        pattern: /^[\d]{0,10}$/,
                                                        message: "Value should be less than 10 character",
                                                    },
                                                ]}
                                            >
                                                <InputMask value={form.getFieldsValue().mobile} onValueChange={onChangePhone} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>

                        </div>
                        <Row className="mt-3 w-100">
                            <Col className="w-100 d-flex justify-content-between">
                                <Button
                                    className="fw-bold"
                                    onClick={hideUserModal}
                                    style={{
                                        color: "#720C20",
                                        borderColor: "#720C20",
                                        borderRadius: 5,
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="fw-bold"
                                    htmlType="submit"
                                    style={{
                                        background: "#720C20",
                                        color: "white",
                                        borderRadius: 5,
                                    }}
                                >
                                   Save
                                    </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </>}
        </>
    )
}

export default Academy
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './Classroom.css';

const Congratulations = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      window.location.replace('/Classroom');
    }, 2000)
  }, [])


  return (

    <div className='container-fluid text-center d-flex justify-content-center
     min-vh-95 align-items-center congrats'>
      <div className='seminarhead mt-5'>
        <h1 className='fs-1 fw-bold' style={{color: "#B06A0A"}}>Congratulation</h1>
        <p className='fs-3 fw-bold'>Classroom Published Sucessfully</p>
      </div>
    </div>
  )
}

export default Congratulations
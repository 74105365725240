import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router';
import { imagePath } from '../../control/Constant';
import '../../components/FileCabinet/Filecabinet.css'
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import commonLib from '../../control/commonLib';
import NewFileCabinet from "../../components/FileCabinet/NewFileCabinet"
import { Row, Col } from "react-bootstrap"
import FolderStructureForm from './FolderStructureForm';
import AllFoldersAndFiles from './AllFoldersAndFiles';
import FileStructureForm from './FileStructureForm';

const Filecabinet = () => {

    // const { setLoader } = useContext(UserContext);
    const inputReff = useRef(null)

    const [userDetail, setUserDetail] = useState({});
    const [selectedIndex, setSelectedIndex] = useState();
    const [fileCabinetTypeList, setFileCabinetTypeList] = useState([]);
    const [fileCabinetType, setFileCabinetType] = useState({});
    const [textValu, setTextValue] = useState("")
    const [drowerData, setDrowerData] = useState("")


    const [doctypeName, setDoctypeName] = useState();
    const [lifePointv, setLifePointv] = useState([]);
    const [showCabinetModal, setShowCabinetModal] = useState(false);
    const [breadcrumbdata, setBreadcrumbdata] = useState();
    const [openModOther, setOpenModOther] = useState(true);

    const [showButton, setShowButton] = useState(false)
    const [pdfname, setpdfname] = useState();

    const [cabinetArray, setCabinetArray] = useState([])

    const handleClose = () => setShowCabinetModal(false);
    const loggedUserDetail = commonLib.getObjFromStorage("userLoggedInDetail");



    const navigate = useNavigate();

    useEffect(() => {
        let userDetail = JSON.parse(sessionStorage.getItem("userLoggedInDetail"));
        setUserDetail(userDetail);
        getFileCabinetType();
    }, []);

    // konsole.log("aaaa",imagePath)
    konsole.log("fileCabinetTypeList", fileCabinetTypeList);
    console.log("doctypeName", doctypeName, selectedIndex);

    useEffect(() => {
        if (breadcrumbdata && !doctypeName?.fileTypeName) {
            setSelectedIndex(-1);
        }
        // setLoader(true);
        // setTimeout(() => {
        //   {
        //     setLoader(false);
        //   }
        // }, 500)
    }, [breadcrumbdata]);

    const getFileCabinetType = () => {
        let promise = Services.getFileCabinet();

        promise
            .then((response) => {
                console.log("ressssss gyhh", response);
                if (response) {


                    let fileTypeObj = response.data.data;
                    for (let loop = 0; loop < fileTypeObj.length; loop++) {
                        fileTypeObj[loop]["imageUrl"] = imagePath[loop];
                    }
                    console.log("fileTypeObjfileTypeObj", fileTypeObj)
                    setFileCabinetTypeList(fileTypeObj);
                    setFileCabinetType(fileTypeObj[0]);
                    setLifePointv(response.data.data);
                }
                // setLoader(false)
            })
            .catch((err) => {
                console.log("errrrrr", err);
            });
    };



    const openModal = (e) => {
        setOpenModOther(true);
        setShowCabinetModal(true);
    };
    const openModalOther = (e) => {
        setOpenModOther(false);
        setShowCabinetModal(true);
    };

    const addNewDrowerFunction = () => {
        setDrowerData(textValu)
        setTextValue("")
        inputReff.current.focus()
        inputReff.current.value = ''
    }

    const getTextValue = (e) => {
        setTextValue(e.target.value);
    };

    const onChange = (value) => {

        console.log(`selected ${value}`);

        setShowButton(true)
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    //new state start

    const [pageToolState, setPageToolState] = useState("")
    const [fileCabinetLable, setFileCabinetLabel] = useState("")
    const [showFolderFormState, setShowFolderFormState] = useState("")
    const [showFileFormState, setShowFileFormState] = useState("")
    const [cabinetHaveFolder, setCabinetHaveFolder] = useState("")
    const [showForm,setShowForm] = useState(false)

    //new state end

    // new code function

    const handleClickButton = (data, index) => {
        setShowCabinetModal(true);
        console.log("fileCabinetTypeList", data)
        setSelectedIndex(index);
        setFileCabinetType(data);
        setFileCabinetLabel(data.label)
        setShowFolderFormState("")
        setCabinetHaveFolder(1)
        setPageToolState(3)

    };

    console.log("fileCabinetLable", fileCabinetLable, fileCabinetType);
    console.log("life", lifePointv);

    const pageToolFunction = (e) => {
        setPageToolState(e)
        setCabinetHaveFolder(8)
        if(e == 2){
            setShowFolderFormState("jkjk")
            setCabinetHaveFolder(8)
            setShowForm(false)
        }

    }

    const addNewFolderFunction = (e) => {
        if (e == "Folder") {
            setShowFolderFormState(e)
            setPageToolState(e)
            setPageToolState(3)
            setShowForm(true)

        }

        if (e == "File") {
            setShowFileFormState(e)
        }

    }

    return (
        <div className='mb-5'>
            <Row >
                <Col span={24}>
                    <Row>
                        <Col lg="2">
                            <Row className="d-flex justify-content-between w-100">
                                <Col className='d-flex justify-content-center'>
                                    <h4>Page Tools</h4>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <div className='file-tool-div' onClick={(e) => pageToolFunction(1)}>
                                        <div className='file-image-div'>
                                            <img src={pageToolState == 1 ? "./filetool.png" : "./file tool black.png"} className='w-75' />
                                        </div>
                                        <div className='file-text-div'>
                                            <h6 className={pageToolState == 1 ? "filetool-text-tag-h6" : ""}>Upload A New File</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col>
                                    <div className='file-tool-div' onClick={(e) => pageToolFunction(2)}>
                                        <div className='file-image-div'>
                                            <img src={pageToolState == 2 ? "./maroonfilemanagecabinet.png" : "./manage-file-cabinet1.png"} className='w-75' />
                                        </div>
                                        <div className='file-text-div'>
                                            <h6 className={pageToolState == 2 ? "filetool-text-tag-h6" : ""}>Manage File Cabinet</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* add new folder and drower */}
                        <Col lg="8">

                            {(pageToolState == 1 && cabinetHaveFolder != 1) && <FileStructureForm/>}
                            {(pageToolState == 2 && cabinetHaveFolder != 1) && <FolderStructureForm showForm={showForm} showFolderFormState={showFolderFormState} lifePointv={lifePointv} />}
                            {(showFolderFormState == "Folder" && cabinetHaveFolder != 1) && <FolderStructureForm showForm={showForm} showFolderFormState={showFolderFormState} lifePointv={lifePointv} />}

                            
                            {(pageToolState == "" )  ?

                                <>
                                    <Row className='mt-3'>
                                        <Col>
                                            <div className='d-flex'>
                                                <h4>Drawer:</h4>
                                                <h4 className='px-2' style={{ color: "#720c20" }}>{fileCabinetLable ? fileCabinetLable : "Life Plan"}</h4>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='d-flex'>

                                                <h6>Here are your existing folders and files.</h6>
                                            </div>
                                        </Col>
                                    </Row>

                                    <hr />

                                    {
                                        (cabinetHaveFolder != 1 && pageToolState =="") && <>
                                            <Row>
                                                <Col>
                                                    <h6>No Folders for this Drawer.</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col >
                                                    <div className='d-flex'>
                                                        <h6>Add New:</h6>
                                                        <div className='d-flex'>
                                                            <h6 className='mx-2 folder-text-class' onClick={(e) => addNewFolderFunction("Folder")}>Folder</h6> |
                                                            <h6 className='mx-2 folder-text-class' onClick={(e) => pageToolFunction(1)}>File</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    }

                                </>
                                :""
                            }  
                                        
                                        {
                                (cabinetHaveFolder == 1 && pageToolState != 1)  &&
                                            <>
                                                <AllFoldersAndFiles />
                                            </>

                                        }
                                    

                        </Col>
                        {/* add new folder and drower code end */}

                        <Col lg="2">
                            <Row className="d-flex justify-content-between w-100">
                                <Col className='d-flex justify-content-center'>
                                    <h4>File Cabinet</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className=''>
                                        <div className='file-cabinet-file'>
                                            <div className="d-flex flex-column align-items-center justify-content-center" id='  ' >
                                                {
                                                    fileCabinetTypeList.length > 0 && fileCabinetTypeList.map((map, index) => {
                                                        return (
                                                            <button className={`${(selectedIndex == index) ? 'activeButtonCard ' : 'buttonCard'}`} onClick={() => handleClickButton(map, index)} key={index}>
                                                                {/* <i><img src='../images/vector.png' alt='vector'/></i> */}
                                                                <img src={map.imageUrl} alt='life' />
                                                                <center className="ms-1">{map.label}</center>
                                                            </button>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Filecabinet
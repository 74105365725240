import React from "react";
import "./Firmpartner.css";
import { Select, Tag } from "antd";
import Menubtns from "./Menubtns";

const options = [{value: "gold",},{value: "lime",},{value: "green",},{value: "cyan",},];
const option = [ {   value: "chandra", }, {   value: "sekhar", }, {   value: "green", }, {   value: "cyan", },];

const FirmPartner = () => (
  <div className="container-fluid">
    <Menubtns  />
  </div>
);
const tagRender = (props) => {
  const { label, value, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div>
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    </div>
  );
};
export default FirmPartner;

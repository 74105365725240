import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  message,
  Tooltip,
  Switch,
  Input,
} from "antd";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import commonLib from "../../control/commonLib";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import "./Classroom.css"
import { UserContext } from "../../App";
const { Title, Text } = Typography;

const PublishedCls = ({
  setIncStepperSteps,
  setEditClassroom,
  setPublishClassroom,...props
}) => {
  const { setLoader } = useContext(UserContext);
  const [classroomData, setClassroomData] = useState([]);
  const [publishseminar, setPublishseminar] = useState(false);
  const [togglecheckbox, settogglecheckbox] = useState(true);
  const [pastSeminar, setPastSeminar] = useState("Present");
  const [show, setShow] = useState(false);
  const [NewStatee, setNewStatee] = useState([]);
  const [searchClassroom, setSearchClassroom] = useState("");

  useEffect(() => {
    getSeminar(745);

    // generateQrCode();
    // setText(`https://events.agingoptions.com/eventlisting/${subtenantId}`);
  }, []);

  const getSeminar = (subtenantId1) => {
    setLoader(true);
    let promise = Services.getSeminarData(745);
    promise
      .then((res) => {
        let classroom = res?.data?.data;
        let finaldata = classroom.filter((e) => {
          return e.subtenantId == 745;
        });


        setLoader(false);
        setClassroomData(finaldata);
      })
      .catch((err) => {
        konsole.log("PublishedSeminarerr", err);
      })
      .finally(() => {
        // setLoader(false);
      });
  };
  const togglecheckboxchange = (e) => {
    settogglecheckbox(e);
    if (e == true) {
      setPastSeminar("Present");
    } else {
      setPastSeminar("past");
    }
  };
  const sortedSeminar = () => {
    const seminarListFiltered = classroomData.filter((e) => {
      var d1 = moment().format("YYYY-MM-DD");
      var d2 = moment(e.seminarDate).format("YYYY-MM-DD");
      if (pastSeminar == "Present") {
        if (d1 <= d2) {
          return true;
        }
      } else {
        if (d1 > d2) {
          return true;
        }
      }
    });
    return seminarListFiltered.sort((a, b) => {
      if (pastSeminar == "Present") {
        return (
          moment(
            a.seminarDate.split(" ")[0] + " " + a.seminarTime.split(".")[0],
            "MM/DD/YYYY HH:mm:ss"
          ) -
          moment(
            b.seminarDate.split(" ")[0] + " " + b.seminarTime.split(".")[0],
            "MM/DD/YYYY HH:mm:s"
          )
        );
      } else {
        return (
          moment(
            b.seminarDate.split(" ")[0] + " " + b.seminarTime.split(".")[0],
            "MM/DD/YYYY HH:mm:ss"
          ) -
          moment(
            a.seminarDate.split(" ")[0] + " " + a.seminarTime.split(".")[0],
            "MM/DD/YYYY HH:mm:ss"
          )
        );
      }
    });
  };
  const searchfun = () => {
    const currValue = searchClassroom;
    let filteredData = [];
    let data1 = [];
    data1 = sortedSeminar();
    if (currValue !== "") {
      filteredData = data1.filter((entry) => {
        if (
          entry.seminarTopic.toLowerCase().includes(currValue.toLowerCase())
        ) {
          return data1;
        }
      });
    } else {
      filteredData = data1;
    }
    return filteredData;
  };

  const copysubtenant = (e) => {
    konsole.log("eeeee", e);
    var text = `https://events.agingoptions.com/eventlisting/${e?.subtenantId}/${e?.seminarId}`;
    navigator.clipboard.writeText(text).then((_) => {
      message.success("copied successfully!");
    });
  };

  const handleShow = (val) => {
    setNewStatee(val);

    setShow(true);
  };

  const handleClose = (val) => {
    setShow(false);
  };

  const EditDataSendToSeminar = (particularData) => {
    setEditClassroom(particularData);
    setIncStepperSteps(0);
    setPublishseminar(true);
    setPublishClassroom(true);
  };

  const toggledatafun = (e, seminarObj) => {
    konsole.log("eeeee", e, seminarObj)
    const loggedInUser = commonLib.getObjFromStorage('stateObj');
    setLoader(true);
    Services.upsertSeminarStaus(seminarObj.seminarId, e, loggedInUser.userId)
      .then(res => {
        konsole.log("change status", res);
        setLoader(false);
        getSeminar();
      })
      .catch(error => {
        konsole.log("error", error);
        setLoader(false);
      }).finally(() => {
        setLoader(false);
      })
  }

  return (
    <>
      <Row span={24} className="mt-2 ">

        <Col span={12} style={{ display: "flex" }}>
          <Input.Search
            placeholder="Search for Workshops"
            onChange={(e) => setSearchClassroom(e.target.value)}
            style={{ width: "100%", border: "1px solid gray", marginLeft: "5px  " }}
          />
        </Col>

      </Row>
      <Row>
        <Col lg={24} className="d-flex  justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center mt-1">
            <h3 className="Modal-Retire-Life-Tag "
              style={{ color: "#720C20", marginLeft: "5px" }}>
              {pastSeminar === "Present" ? "Upcoming" : "Past"} Workshop
            </h3>
          </div>
          {publishseminar === false ? (
            <>
              <div class="ms-2 float-end" style={{ paddingRight: "10px" }}>
                <span
                  className={`${togglecheckbox === false
                    ? "spantogglecolorinlestseminar fw-bold"
                    : ""
                    } m-2`}
                >
                  Past
                </span>
                <Switch
                  className={`${togglecheckbox === true ? "togglecolorinlestseminar" : ""
                    } m-2`}
                  // checkedChildren="on" unCheckedChildren="off"
                  defaultChecked={togglecheckbox}
                  onChange={togglecheckboxchange}
                />
                <span
                  className={`${togglecheckbox === true
                    ? "spantogglecolorinlestseminar fw-bold"
                    : ""
                    } m-2`}
                >
                  Upcoming
                </span>
              </div>
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
      {/* <div className="p-0 m-0" style={{border:"1px solid red"}}> */}

      <div className="" style={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
      }} >
        <div >
          {searchfun().map((classRoom) => {
            konsole.log(classRoom, "classRoom");
            let ToDate = classRoom.remarks.slice(0, 11);
            let ToTime = classRoom.remarks.slice(11, 31);


            return (
              <div style={{ border: "1px dotted black", height: "100%",padding:"0px", margin:"0px",marginRight:"5px",marginBottom:"1rem" }}
              //   key={index}
              >

                <div className="  " >
                  <Row>
                    <Col xs={24} sm={24} lg={24}>
                      <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex">
                          <Title
                            level={1}
                            className="Modal-Retire-Life-Tag "
                            style={{ color: "#B06A0A", marginLeft: "5px" }}
                          >
                            {classRoom.seminarTopic}{" "}
                          </Title>

                          <Text className="fs-4 ms-2" style={{ color: "black" }}>
                            {classRoom.subtenantId !== 26 && (
                              <span
                                classname="ms-2 "
                                style={{ color: "black", fontSize: "15px" }}
                              >
                                (
                                {classRoom.seminarTypeId == 46
                                  ? "Direct"
                                  : "Webinar"}
                                ,{" "}
                                {classRoom.isSeminarPaid == true ? "Paid" : "Free"}
                                {classRoom.isSeminarPaid == true
                                  ? " : " + "$" + classRoom.seminarFee
                                  : ""}
                                )
                                {pastSeminar === "Present" && (
                                  <Tooltip title="Edit">
                                    <img
                                      src="./editImage.png"
                                      className="ms-3"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        EditDataSendToSeminar(classRoom)
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </span>
                            )}
                          </Text>
                        </div>
                        <div className='d-flex justify-content-between align-items-center me-2'>
                          {(togglecheckbox == true) ?
                           <Switch checkedChildren="Show"
                            onChange={(e) => toggledatafun(e, classRoom)}
                            unCheckedChildren="Hide"
                            defaultChecked={classRoom.isActive}
                          />
                        : ""}

                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Title
                        level={3}
                        style={{ marginBottom: 4, color: "#707070", marginLeft: "5px" }}
                      >
                        By:
                        <span style={{ color: "#720C20", fontSize: "24px" }}>
                          {" "}
                          {classRoom?.speakers.length > 0 &&
                            classRoom.speakers[0]?.fName +
                            " " +
                            // classRoom.speakers[0]?.mName ? classRoom.speakers[0]?.mName : '' +
                            " " +
                            classRoom.speakers[0]?.lName}
                        </span>
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {/* <Title level={4} className="m-0" style={{ color: "#707070" }}>On {commonLib.getFormattedDate(seminarObj.seminarDate)}, at {commonLib.getFormattedTime(seminarObj.seminarTime)}</Title> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Title level={4} style={{ color: "#707070", marginLeft: "5px" }}>
                        Date: {commonLib.getFormattedDate(classRoom.seminarDate)} -{" "}
                        {commonLib.getFormattedDate(ToDate)}
                        <br /> Time:{" "}
                        {commonLib.getFormattedTime(classRoom.seminarTime)} -{" "}
                        {commonLib.getFormattedTime(ToTime)}
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {classRoom.seminarVenue !== null && classRoom.seminarVenue !== "" && classRoom.seminarVenue !== undefined &&
                        classRoom.seminarTypeId == 46 ? (
                        <Title level={4} style={{ color: "#707070" }}>
                          Venue : {classRoom.seminarVenue} <br />
                          Location : {classRoom.venueAddressLine1}
                        </Title>
                      ) : (
                        ""
                      )}
                      {/* <Title level={4} style={{ color: "#707070" }}>Location : {seminarObj.seminarVenue}</Title> */}
                    </Col>
                  </Row>
                  {classRoom?.subtenantId !== 26 ? (
                    <>
                      <Row>
                        <Col
                          span={4}
                          xs={24}
                          sm={24}
                          md={4}
                          lg={4}
                          xl={3}
                          className="mt-1 mb-2 me-sm-3"

                        >
                          <div className="eventListingImage" style={{ marginLeft: "5px" }}>
                            <img src={classRoom?.speakers[0]?.speakerProfileUrl} />
                          </div>
                        </Col>
                        <Col xs={24} md={19} className="p-0 m-0 me-1">
                          <Text level={1} className="fs-6 text-justify ">
                            {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. */}
                            {classRoom?.speakers[0]?.speakerProfile}
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} style={{marginLeft:"5px"}}>
                          <Title
                            level={3}
                            style={{
                              
                              color: "maroon",
                              marginBottom: 0,
                            }}
                          >
                            About Workshop
                          </Title>
                        </Col>
                        <Col span={24} className="mb-2" style={{marginLeft:"5px"}}>
                          <Text level={1} className="fs-6">
                            {/* {seminarObj.seminarTopic} */}
                            {classRoom.description}
                          </Text>
                        </Col>

                        <Col span={24} className="d-flex justify-content-between" >
                          <div className="mt-2" style={{marginLeft:"5px"}}>
                            <Text
                              level={1}
                              className="fs-6 border border-2 ps-3  mt-4 pe-2 pt-2 pb-2 "
                            >
                              <span className="pe-2 border-end border-2 mt-3">
                              https://events.agingoptions.com/eventlisting/
                                {classRoom.subtenantId}/{classRoom?.seminarId}{" "}
                              </span>

                              <Tooltip title="copy">
                                <span className="ps-1">
                                  <img
                                    className="pb-1 ps-1"
                                    onClick={() =>
                                      copysubtenant(classRoom)
                                    }
                                    style={{ cursor: "pointer" }}
                                    src="/icons/icon-park-outline_copy-link.svg"
                                    width={25}
                                  />{" "}
                                </span>
                              </Tooltip>
                            </Text>
                          </div>
                          <div>
                            <div
                              
                              style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: 64,
                                width: "100%",
                              }}
                              id="qr-gen"
                            >
                              <QRCode
                                size={256}
                                style={{
                                  height: "auto",
                                  width: "70%",
                                  cursor: "pointer",
                                  marginRight:"5px"
                                }}
                                // className="pe-2"
                                className="float-end"
                                value={`https://events.agingoptions.com/eventlisting/${classRoom.subtenantId}/${classRoom?.seminarId}`}
                                viewBox={`0 0 256 256`}
                                onClick={() =>
                                  handleShow(
                                    `https://events.agingoptions.com/eventlisting/${classRoom.subtenantId}/${classRoom?.seminarId}`
                                  )
                                }
                              />
                            </div>

                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  id="qr-gen"
                                >
                                  <QRCode
                                    size={256}
                                    style={{
                                      height: "auto",
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                    value={NewStatee}
                                    viewBox={`0 0 256 256`}
                                  // onClick={handleShow}
                                  />
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                        </Col>
                        <Col span={24} className="mb-1">
                          {/* <Text
                          level={1}
                          className="fs-6 border border-2 ps-3 pe-2 pt-2 pb-2 "
                        >
                          <span className="pe-2 border-end border-2">
                            https://events.agingoptions.com/eventlisting/
                            {classRoom.subtenantId}{" "}
                          </span>

                          <Tooltip title="copy">
                            <span className="ps-1">
                              <img
                                className="pb-1 ps-1"
                                onClick={() =>
                                  copysubtenant(classRoom.subtenantId)
                                }
                                style={{ cursor: "pointer" }}
                                src="/icons/icon-park-outline_copy-link.svg"
                                width={25}
                              />{" "}
                            </span>
                          </Tooltip>
                        </Text> */}
                          <div>
                            {/* <div
                            className="float-end"
                            style={{
                              height: "auto",
                              margin: "0 auto",
                              maxWidth: 64,
                              width: "100%",
                            }}
                            id="qr-gen"
                          >
                            <QRCode
                              size={256}
                              style={{
                                height: "auto",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              value={`https://events.agingoptions.com/eventlisting/${seminarObj.subtenantId}`}
                              viewBox={`0 0 256 256`}
                              onClick={() =>
                                handleShow(
                                  `https://events.agingoptions.com/eventlisting/${seminarObj.subtenantId}`
                                )
                              }
                            />
                          </div> */}

                            {/* <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <div
                                className="d-flex justify-content-center align-items-center ms-4"
                                id="qr-gen"
                              >
                                <QRCode
                                  size={256}
                                  style={{
                                    height: "auto",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                  value={NewStatee}
                                  viewBox={`0 0 256 256`}
                                  onClick={handleShow}
                                />
                              </div>
                            </Modal.Body>
                          </Modal> */}
                          </div>
                        </Col>
                      </Row>
                      {/* <div className='d-flex justify-content-center my-4'>

                                                    <button style={{ backgroundColor: "#720c20" }} className="text-light px-5 fs-8 fs-sm-4 py-2" >Register Now</button>
                                                </div> */}
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col
                          span={4}
                          xs={24}
                          sm={24}
                          md={4}
                          lg={4}
                          xl={3}
                          className="m-0  me-sm-3"
                        >
                          <div className="eventListingImage">
                            <img src="/icons/rajivSpeakerImage.jpeg" />
                          </div>
                        </Col>
                        <Col xs={24} md={19} className="p-0 m-0 me-1">
                          <Text level={2} className="fs-6 ">
                            70% of Americans not being able to live out their lives
                            at home should be as much of an outrage as racism,
                            gender discrimination, child abuse, etc. BUT IT IS NOT.
                            <br />
                            Seniors are not cute and cuddly.
                            <br />
                            This, as you know, has been my life’s MISSION. To change
                            the way we think of, plan for, and navigate through
                            retirement. And I need your support and help to tear
                            down the walls that keep sunlight out of lives of the
                            retired community members.
                            <br />
                            I am inviting a handful of my over 7,000 clients to
                            share what I have been able to accomplish, and what I
                            feel is needed as the next step to finally break down
                            the walls. And ask for your time and help that will also
                            allow you to leave a sound legacy to be proud of –
                            changing lives of retirees.
                            <br />
                          </Text>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>


              </div>
            );

          })}
        </div>
      </div>

      {/* </div> */}
    </>
  );
};
export default PublishedCls;

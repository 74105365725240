import React, { useState, useEffect, useContext } from "react";
import '../Communication/communication.css'
import {  Col, Row, Modal, Divider, Input, Select, Space, Button, Form, Radio } from 'antd'
import Table from "react-bootstrap/Table";

function Discount() {
    const [showModal, setShowModal] = useState(false)
    const [selectSubtenant ,setSelectSubtenant] = useState(false)
    const [form] = Form.useForm();

    const showGenerateModal = () =>{
        setShowModal(true)
    }
    const closeGenerateModal = () =>{
        setShowModal(false)
    }
  return (
    <>
     {showModal ==true ? <Modal
                centered={true}
                className="Modal-Main-Class"
                title={<div className='d-flex justify-content-center'>Generate New</div>}
                visible={showModal}
                footer={false}
                onCancel={closeGenerateModal}
                closeIcon={<div className='text-light'> X</div>}
                maskClosable={false}

            >

                <Row >
                    <Col span={24}>
                        <Form form={form}>
                            <Row >
                                <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
                                    <h6>Discount scheme type</h6>
                                    <Form.Item
                                        name="ValidityPeriod"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Validity Period"
                                            }
                                        ]}

                                    >
                                        <Select
                                            showSearch
                                            listHeight={100}
                                            // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
                                            style={{
                                                width: '100%',
                                            }}
                                            mode="multiple"
                                            maxTagCount={'responsive'}
                                            showArrow
                                            placeholder="Select Validity Period"

                                        >
                                        </Select>
                                        </Form.Item>

                                </Col>
                                <Col className="asignRoleTo-col ms-1 p-0" span={8} xs={24} md={8} lg={8}>
                                    <h6>Discount amount</h6>
                                    <Form.Item
                                        name="DiscountAmount"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Discount Amount"
                                            }
                                        ]}>
                                        <Input size='sm'
                                            listHeight={100}
                                            style={{ width: "254px" }}
                                            placeholder="Slabs To"
                                            prefix="$"
                                        />

                                        </Form.Item>
                                </Col>
                                <Col span={8} xs={24} md={8} lg={8} className="asignRoleTo-col m-0 p-0">
                                    <h6>SKU</h6>
                                    <Form.Item
                                        name="SKU"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select SKU"
                                            }
                                        ]}

                                    >
                                        <Select
                                            showSearch
                                            listHeight={100}
                                            // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
                                            style={{
                                                width: '100%',
                                            }}
                                            mode="multiple"
                                            maxTagCount={'responsive'}
                                            showArrow
                                            placeholder="Select SKU"

                                        >
                                        </Select>
                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row className='mt-1 gap-3'>
                                
                                <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
                                    <h6>Discount ID</h6>
                                    <Form.Item
                                        name="DiscountID"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter Discount ID"
                                            }
                                        ]}
                                        // className="asignRoleTo-col m-0 pb-4"
                                    >

                                        <Input
                                            // defaultValue={() => ToRoleNameFuction(props?.selectedRowData?.commMediumRoles)}
                                            showSearch
                                            style={{ width: "100%" }}
                                            listHeight={100}
                                            maxTagCount={'responsive'}
                                            mode="multiple"
                                            showArrow
                                            placeholder="Discount ID"/>
                                    </Form.Item>
                                </Col>
                                <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
                                    <h6>Rate Card ID</h6>
                                    <Form.Item
                                        name="ValidityPeriod"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Validity Period"
                                            }
                                        ]}
                                        // className="asignRoleTo-col m-0 pb-2"

                                    >
                                        {/* props?.selectedRowData?.commMediumRoles */}
                                        <Select
                                            showSearch
                                            listHeight={100}
                                            // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
                                            style={{
                                                width: '100%',
                                            }}
                                            mode="multiple"
                                            maxTagCount={'responsive'}
                                            showArrow
                                            placeholder="Select Validity Period"

                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
                                    <h6>No: of Coupons</h6>
                                    <Form.Item
                                        name="NoOfCoupons"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please Enter No. of Coupons"
                                            }
                                        ]}
                                        // className="asignRoleTo-col m-0 pb-4"
                                    >

                                        <Input
                                            // defaultValue={() => ToRoleNameFuction(props?.selectedRowData?.commMediumRoles)}
                                            showSearch
                                            style={{ width: "100%" }}
                                            listHeight={100}
                                            maxTagCount={'responsive'}
                                            mode="multiple"
                                            showArrow
                                            placeholder="No. of Coupons"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="ms-2">
                                <Col span={6} xs={24} md={6} lg={6}>
                                   <div>
                                    <p>Generate Coupons For</p>
                                    <Radio.Group>
                                        <Radio  value={1} onChange={()=>setSelectSubtenant(false)}>
                                           All Subtenants
                                       </Radio>
                                       <Radio  value={2} onChange={()=>setSelectSubtenant(true)}>
                                           Specific Subtenants
                                       </Radio>
                                       </Radio.Group>
                                       </div>
                                
                                </Col>
                                {selectSubtenant ==true ? <Col span={24} xs={24} md={24} lg={24} className="asignRoleTo-col mt-4 p-0">
                                    <h6>Choose Subtenants</h6>
                                    <Form.Item
                                        name="ValidityPeriod"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Validity Period"
                                            }
                                        ]}
                                        // className="asignRoleTo-col m-0 pb-2"

                                    >
                                        {/* props?.selectedRowData?.commMediumRoles */}
                                        <Select
                                            showSearch
                                            listHeight={100}
                                            // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
                                            style={{
                                                width: '100%',
                                            }}
                                            mode="multiple"
                                            maxTagCount={'responsive'}
                                            showArrow
                                            placeholder="Select Subtenant"

                                        >
                                        </Select>
                                    </Form.Item>
                                </Col> : ""}
                            </Row>
                            <Row>
                                <Col span={14}><div className=" d-flex justify-content-end">
                                <button
                                    className="Add-Edit-btn text-white"
                                    type="submit"
                                >
                                    Generate
                                </button>
                            </div></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal> : ""}
      <Row className="mt-4">
                        <Col span={14} xs={14} sm={14} md={14} lg={14} className="input-group Serach-Input-Box-Div">
                            <input type="text" className="form-control" placeholder="Search for Coupon ID" />
                            <a href="#" style={{ backgroundColor: "#F4F6F6" }}><img className='' height={40} src="/icons/eventSearchIngIcon.svg" /></a>
                        </Col>
                        <Col span={10} xs={10} sm={10} md={10} lg={10}>
                            <div className=" d-flex justify-content-end">
                                <button
                                    className="Add-Edit-btn text-white"
                                    type="submit"
                                    onClick={showGenerateModal}
                                >
                                    Generate New
                                </button>
                            </div>

                            {/* <Addcommunicationmodal showAddModal={showAddModal} setShowAddModal={setShowAddModal} GetCommMediumPathApiFuction={() => GetCommMediumPathApiFuction(subtenantID)} /> */}

                        </Col>
                    </Row>
      <div className="CommunicationScroll mt-4">

        <Table bordered>
          <thead>
            <tr style={{ positon: "fixed" }}>
              <th className="text-center ">Coupon ID</th>
              <th className="text-center ">No: of Coupon</th>
              <th className="text-center ">Validity Period</th>
              <th className="text-center ">Validity Start</th>
              <th className="text-center ">Validity End</th>
              <th className="text-center ">Status</th>
            </tr>
          </thead>
              <>
                <tr>

                  <td style={{ border: "0.1px solid gray" }}>
                    <div className="d-flex justify-content-center align-items-center"></div>
                  </td>
                  <td style={{ border: "0.1px solid gray" }}>
                    <div className=" d-flex justify-content-center align-items-center"></div>
                  </td>
                  <td>
                    <div className=" d-flex justify-content-center align-items-center text-center"></div>
                  </td>
                  <td style={{ border: "0.1px solid gray" }}>
                    <div className="d-flex justify-content-center align-items-center text-center flex-column"></div>
                  </td>
                  <td
                    className="m-0 p-0"
                    style={{ border: "0.1px solid gray" }}
                  >
                    <div className="mt-2 mb-1" style={{ cursor: "pointer" }}>
                      View
                    </div>
                  </td>
                  <td style={{ border: "0.1px solid gray" }}>
                    <div className=" mt-2 d-flex justify-content-center align-items-center ">
                      <label className="switch">
                        <input
                          className="switch-input"
                          type="checkbox"
                          defaultChecked={""}
                        />
                        <span
                          className="switch-label"
                          data-on="On"
                          data-off="Off"
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </>
          <tbody></tbody>
        </Table>
      </div>
    </>
  );
}
export default Discount;

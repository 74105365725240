import React, { useEffect } from 'react'
// import {Button} from 'antd';

const ListClassroom = ({ setPublishClassroom, publishclassroom }) => {

  // useEffect(() => {
  //   if (publishclassroom == true) {
  //     document.getElementById('publishClassroom').style.border = '2px solid #720c20'
  //     document.getElementById('PublishedClassroom').style.border = ''
  //   } else {
  //     document.getElementById('PublishedClassroom').style.border = '2px solid #720c20'
  //     document.getElementById('publishClassroom').style.border = ''
  //   }
  // }, [publishclassroom])


  return (

    <div className=' ' style={{ paddingTop: "8px",borderBottom:"2.5px solid rgb(242, 242, 242)" }}>
      <button className={`${(publishclassroom == true) ? "schedule-active" : ""} me-2 schedule`} onClick={() => setPublishClassroom(true)}>Publish Workshop</button>
      {/* <button className='publish fw-bold text-dark m-0 p-0' id='publishClassroom' onClick={() => setPublishClassroom(true)}>Publish Workshop</button> */}
      {/* <button className='publish ms-2 fw-bold text-dark ' id='PublishedClassroom' onClick={() => setPublishClassroom(false)}>Published Workshop</button> */}
      <button className={`${(publishclassroom == !true) ? "schedule-active" : ""} me-2 schedule`} onClick={() => setPublishClassroom(false)}>Published Workshop</button>
    </div>


  )
}

export default ListClassroom
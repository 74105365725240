import React, { useState, useEffect, useContext } from 'react'
import { Form, Input, Cascader, Row, Col, Select, Button, AutoComplete, Modal, notification, message, Divider } from 'antd';
import { GlobalData } from './SeminarFile';
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import './Seminar.css'
import AddEditSpeakerModal from '../AddEditSpeakerModal';
import { UserContext } from '../../App';
import config from '../../config.json'
import InputMask from '../PhoneInputMask';
import Classroom from '../Classroom/Classroom';
import { isValidFuntion } from '../../control/Constant';


const Coordinator = (props) => {
  const { setLoader } = useContext(UserContext)
  const { getDataId, getCoordContactPerson, getCoordEmail, getCoordContactNo, setSubtenantIdAgain, editFuncData, stopEditFunc, setStopEditFunc, previewData } = useContext(GlobalData)
  const { getDataName, idValueSubtenant } = useContext(GlobalData)
  const [form] = Form.useForm();
  const [coordinatorId, setCoordinatorId] = useState("")
  const [coordinatorName, setCoordinatorName] = useState("")
  const [coordUserIdApi, setCoordUserIdApi] = useState("")
  const [coordinatorNameFromApiRes, setCoordinatorNameFromApiRes] = useState("")
  const [coordinatorspeakertype, setcoordinatorspeakertype] = useState('Coordinator')
  const [coOrdinatorList, setCoOrdinatorList] = useState([])
  const [primaryMobile, setPrimaryMobile] = useState();
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [editCoordinator, setEditCoordinator] = useState("")
  const [sendLoggedUserId, setSendLoggedUserId] = useState("")  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getFName, setGetFName] = useState("")
  const [getLName, setGetLName] = useState("")
  const [getEmail, setGetEmail] = useState("")
  const [getcountrycode, setcountrycode] = useState('')
  const [getContact, setGetContact] = useState("")
  const [getEmailCoordinatorApi, setGetEmailCoordinatorApi] = useState("")
  const [getMobileNoCoorApi, setGetMobileNoCoorApi] = useState("")
  const [sendCoordName, setSendCoordName] = useState("")

 


  useEffect(() => {
    if (previewData?.getCoordinatorID?.length > 0) {
      props.form.setFieldsValue({
        'coordinatorField': previewData?.getCoordinatorID
      })
    }
  }, [previewData])


  const { Option } = Select;


 
  let getIdOfSubtenant = props.subtenantValueId;
 

  useEffect(() => {
    let loggedIdUser = sessionStorage.getItem('loggedUserId')
    setSendLoggedUserId(loggedIdUser)
    if (isValidFuntion(editFuncData)) {
      userListApiCall(editFuncData?.subtenantId);

 
    } else {

      if (isValidFuntion(getIdOfSubtenant)) {
        let userroleId = JSON.parse(sessionStorage.getItem("stateObj")).roleId
     
        if (userroleId !== "11") {


          props.form.setFieldsValue({
            "coordinatorField": previewData?.getCoordinatorID,
            "coordinatorContact": " ",
            "coordinatorEmail": " "

          })
          // setTimeout(()=>{
          //   props.form.setFieldsValue({
          //     "coordinatorField": undefined,
          //     "coordinatorContact": "",
          //     "coordinatorEmail": ""

          //   })
          // },2000)
          userListApiCall(getIdOfSubtenant);
        } else {
          userListApiCall(getIdOfSubtenant);

        }
      } else {
        if (isValidFuntion(props.form.getFieldsValue().seminarOrganizingPartner)) {
          userListApiCall(props.form.getFieldsValue().seminarOrganizingPartner);
        }
      }
    }




  }, [getIdOfSubtenant])





  const userListApiCall = (RunApiWithSubtenantId) => {
    Services.getUserListByRoleId(RunApiWithSubtenantId, true, 6)
        .then((res) => {
            const objCoordinator = [];
            let filteredCoordinator = null;

            if (isValidFuntion(editFuncData) && !stopEditFunc) {
                filteredCoordinator = res.data.data.find(x => x.userId === editFuncData?.coordinators[0]?.coordinatorUserId);
                if (filteredCoordinator) {
                    setEditCoordinator(filteredCoordinator.fullName);
                    setPrimaryMobile(filteredCoordinator.primaryMobile);
                    setPrimaryEmail(filteredCoordinator.primaryEmail);
                    setCoordinatorId(filteredCoordinator.userId);
                    setCoordinatorName(filteredCoordinator.fullName);

                    const sPhoneNumber = filteredCoordinator.primaryMobile.split("");
                    const removeCountryCode = sPhoneNumber.splice(sPhoneNumber.length - 10, 10);

                    props.form.setFieldsValue({
                        coordinatorContact: removeCountryCode.join(""),
                        coordinatorEmail: filteredCoordinator.primaryEmail
                    });
                }
            }

            res.data.data.forEach((coordinator) => {
                objCoordinator.push({
                    value: coordinator.userId,
                    label: coordinator.fullName
                });
            });

            if (editFuncData && !stopEditFunc) {
                const edditCoordinatorArray = editFuncData.coordinators.map(coordinator => coordinator.coordinatorUserId);
                props.form.setFieldsValue({
                    coordinatorField: edditCoordinatorArray
                });
                getDataId(edditCoordinatorArray);
            }

            const objCoordinatorFilter = objCoordinator.filter((v, i, a) => a.findIndex(v2 => v2.value === v.value) === i);
            setCoOrdinatorList(objCoordinatorFilter);
            setIsModalVisible(false);
            form.resetFields();
            setLoader(false);
        })
        .catch((error) => {
            setLoader(false);
        });
};





  const fixCoordinatorAllFields = (coordinatorUserID) => {
    let userId = coordinatorUserID;
    konsole.log("fixCoordinatorAllFields", userId, "aaaaaaaaaaaaaaaaaaaaaaaaaa", coOrdinatorList)
    // let resultfilter = coOrdinatorList.filter(function (items) {
    //   return items.userId == userId;
    // });

    // resultfilter.map((item) => {
    //   konsole.log('mapresultfilter', item)
    //   setPrimaryMobile(item.primaryMobile);
    //   setPrimaryEmail(item.primaryEmail)
    //   konsole.log(item.primaryEmail);
    //   konsole.log(item.primaryMobile);
    //   setCoordinatorId(item.userId)
    //   setCoordinatorName(item.fullName)
    //   props.form.setFieldsValue({
    //     "coordinatorField": item.fullName,
    //     "coordinatorContact": item.primaryMobile,
    //     "coordinatorEmail": item.primaryEmail

    //   })


    // })
  }


  const selectPrimaryContactEmail = (fullName, key) => {
    konsole.log("SelectfullName", fullName, key)
    konsole.log("key", key)
    let userId = key.key;
    konsole.log(userId)
    let resultfilter = coOrdinatorList.filter(function (items) {
      return items.userId == userId;
    });

    resultfilter.map((item) => {
      konsole.log('mapresultfilter', item)
      setPrimaryMobile(item.primaryMobile);
      setPrimaryEmail(item.primaryEmail)
      konsole.log(item.primaryEmail);
      konsole.log(item.primaryMobile);
      setCoordinatorId(item.userId)
      setCoordinatorName(item.fullName)
      let sPhoneNumber = item.primaryMobile.split("")
      let removeCountryCode = sPhoneNumber.splice(sPhoneNumber.length - 10, 10)
      konsole.log("sPhoneNumber", removeCountryCode.join(""));

      props.form.setFieldsValue({
        // "coordinatorField": item.fullName,
        "coordinatorContact": removeCountryCode.join(""),
        "coordinatorEmail": item.primaryEmail

      })


    })
    if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "") {

      setStopEditFunc(true)
    }
  }


  getDataName(coordinatorName || coordinatorNameFromApiRes)




  const showModal = () => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj")).roleId
    let subtenantId = sessionStorage.getItem("subtenantId")


    if (userroleId == "4") {
      if (props.form.getFieldsValue().seminarOrganizingPartner !== undefined || (editFuncData !== null && editFuncData !== undefined && editFuncData !== "")) {

        setIsModalVisible(true);

        konsole.log("roleIdroleId", userroleId)
      } else {
        konsole.log("roleIdroleId", 11)
        message.info("Please Select Seminar Organizing Firm")

      }

    } else {

      setIsModalVisible(true);
    }

    if (userroleId == "4") {
      if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "") {
        postSubtenantDetails(editFuncData?.subtenantId)
      } else {
        postSubtenantDetails(props.form.getFieldsValue().seminarOrganizingPartner)

      }
    }


    if (userroleId == "11" || userroleId == "6") {
      postSubtenantDetails(subtenantId)

    }



  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // form.resetFields();
    setIsModalVisible(false);

  };

  const HideBack = (dataCoori) => {
    konsole.log("dataCoori", dataCoori)
    SubmitData(dataCoori.firstName, dataCoori.lastname);




  }


  const openNotification = (placement, Status) => {

    if (Status == 500) {

      notification.error({
        message: `Error ${Status} `,
        description:
          'Server Issue! Please try after sometime.',
        placement,
      });
    } else if (Status == 200) {
      notification.success({
        message: `Organizer Contact added successfully`,

        placement,
      });
    }

  };

  const onChangePhone = (value) => {
    // konsole.log("value",value);
    form.setFieldsValue({
      coordinatorContact: value,
    });
  };

  const SubmitData = (firstCoordinateName, lastCoordinateName) => {

    setLoader(true)

    
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj")).roleId

    let subtenentIdForEdit;
    if (userroleId == "4") {

      if (editFuncData !== null && editFuncData !== undefined && editFuncData !== "") {
        subtenentIdForEdit = editFuncData?.subtenantId
      } else {
        subtenentIdForEdit = props.form.getFieldsValue().seminarOrganizingPartner
      }

    } else if (userroleId == "11" || userroleId == "6") {
      konsole.log("props.form.getFieldsValue().seminarOrganizingPartner", props.form.getFieldsValue().seminarOrganizingPartner)
      subtenentIdForEdit = props.form.getFieldsValue().seminarOrganizingPartner
    }

   


    const jsonObjData = {
      subtenantId: subtenentIdForEdit,
      signUpPlateform: config.AOADMIN,
      createUserCredentials: true,
      createdBy: sendLoggedUserId,
      user: {
        roleId: 6,
        firstName: firstCoordinateName,
        lastName: lastCoordinateName,
        emailAddress: getEmail,
        userName: getEmail,
        countryCode: getcountrycode,
        mobileNumber: getContact,
        // password: "stringst",
        packageCode: null,
        activateUser: false,
        autoConfirmEmail: false,
        autoConfirmMobile: false
      }
    }

    konsole.log("jsonObjData", JSON.stringify(jsonObjData))
    konsole.log("jsonObjData", jsonObjData)




    const speakerRegisterApi = Services.subtenantCreateUser(jsonObjData)
    speakerRegisterApi.then((res) => {
      konsole.log("coordinatorresponse", res)
      konsole.log("SpeakerResasdsada", res.data.data)
      konsole.log("SpeakerRes", res.data.data.userId)
      let fixefFullName = res.data.data.firstName + " " + res.data.data.lastName;
      setCoordinatorNameFromApiRes(fixefFullName)
      props.form.setFieldsValue({
        //  "coordinatorField": fixefFullName,
        "coordinatorContact": getContact,
        "coordinatorEmail": getEmail
      })

      setCoordinatorId(res.data.data.userId)
      setGetEmailCoordinatorApi(getEmail)
      setGetMobileNoCoorApi(getContact)
      userListApiCall(props.subtenantValueId);
      openNotification('topRight', res.status)

    }).catch((err) => {
      konsole.log("errr", err)
      konsole.log("errrCoordinator", err.response.data.messages[1].split(" ").splice(0, 5).join(" "));
      if (err.response.data.messages[0] == "ALREADY_REGISTERED") {
        message.error(err.response.data.messages[1])
      }
      setLoader(false)
    }).finally(() => {
      setLoader(false)
    })



  }



  const CoordName = (e) => {
    setSendCoordName(e)
  }

  getCoordContactPerson(sendCoordName || coordinatorNameFromApiRes)
  getCoordContactNo(primaryMobile || getMobileNoCoorApi)
  getCoordEmail(primaryEmail || getEmailCoordinatorApi)



  const postSubtenantDetails = (getSubtenantId) => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj")).roleId
    let postSubtenantDetailApiCall = Services.postSubtenantDetails()
    postSubtenantDetailApiCall.then((res) => {
      konsole.log("responsedetails", res.data)
      // setpostSubtenantDetailResp(res.data.data)
      if (userroleId == "4") {

        if (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") {
          let filterSubtenant = res.data.data.filter((x) => {
            return x.subtenantId == getSubtenantId
          })
          //   fixedEditValue(filterSubtenant)
          konsole.log("filterSubtenantCoordinator", filterSubtenant)
          form.setFieldsValue({
            "Subtenant": filterSubtenant[0]?.subtenantName
          })
        } else {
          let filterSubtenantId = res.data.data.filter((x) => {
            return x.subtenantId == getSubtenantId
          })
          //   fixedEditValue(filterSubtenant)
          konsole.log("filterSubtenantCoordinator", filterSubtenantId)
          form.setFieldsValue({
            "Subtenant": filterSubtenantId[0]?.subtenantName
          })
        }
      } else if (userroleId == "11" || userroleId == "6") {

        let filterSubtenant = res.data.data.filter((x) => {
          return x.subtenantId == getSubtenantId
        })
        //   fixedEditValue(filterSubtenant)
        konsole.log("filterSubtenantCoordinator", filterSubtenant)
        form.setFieldsValue({
          "Subtenant": filterSubtenant[0]?.subtenantName
        })

      }
    })
      .catch((error) => {
        konsole.log("error", error)
      })

  }



  const multiCoordinatorChange = (e) => {
    // konsole.log("multiCoordinatorChangemultiCoordinatorChange", e);
    // let userId = key.map((x)=>{
    //   return x.key
    // })
    konsole.log("multiCoordinatorChangemultiCoordinatorChange", e)
    getDataId(e);
    setStopEditFunc(true)

    // callNewFunc(userId)
  };


  return (
    <div className='m-0 p-0'>
      <p className='fs-4 fw-bold seminartxt' style={{ paddingLeft: "10px" }}>Coordinator</p>

      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'userForm') {
            const { basicForm } = forms;

            setIsModalVisible(false);

          }
        }}

      >
        <div className='px-2'>
          <Form.Item

            name="coordinatorField"
            label="Name of Contact Person"
            rules={
              [
                {
                  required: true,
                  message: "Feild is required!"
                }
              ]
            }
          >

            <Select
              mode='multiple'
              showSearch
              maxTagCount={"responsive"}
              onChange={multiCoordinatorChange}
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{
                    margin: "8px 0",
                  }} />
                  <div className='d-flex justify-content-between'>

                    <div className='ms-2'>
                      <Input className='d-none' placeholder='Search Coordinator' />
                    </div>
                    <div className='me-2'>
                      <Button style={{ background: "#720c20", color: "white",borderRadius:"5px",fontWeight:500 }} onClick={showModal}>Add Coordinator</Button>
                    </div>
                  </div>
                </>
              )}

            >

              {coOrdinatorList.map((items, index) =>

                <Select.Option value={items.value}>
                  {items.label}
                </Select.Option>

              )}


            </Select>



          </Form.Item>
        </div>

        {/* <Row className='d-flex'>
          <Col flex={4} xs={24} sm={12}>
            <Form.Item
              name="coordinatorContact"
              label="Contact"
            rules={[
              {
                required: true,
                message: "Contact Number Is Required!"
              }
            ]}
            >
              <Input className='border-2 respCoor ' 
              disabled = {(editFuncData !==undefined && editFuncData !== null && editFuncData !== "")? true : false}
              />
              <Input className='border-2 respCoor ' />
              <InputMask placeholder="" 
               disabled = {(editFuncData !==undefined && editFuncData !== null && editFuncData !== "")? true : false}
              value={form.getFieldsValue().coordinatorContact} onValueChange={onChangePhone} />
             
            </Form.Item>
          </Col>
          <Col flex={4} xs={24} sm={12} >
            <Form.Item

              name="coordinatorEmail"
              label="Email"
              className="ms-2 "
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
            rules={[
              {
                required: true,
                message: "Email Id is required!"
              }
            ]}
            >
              <Input className='border-2 respCoor '
              disabled = {(editFuncData !==undefined && editFuncData !== null && editFuncData !== "")? true : false}
              />
            </Form.Item>
          </Col>
        </Row> */}

        <Modal
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
        >
          <Form form={form} name="AddEditSpeakerform"
            onFinish={HideBack}
            layout="vertical"
            style={{
              maxHeight: 'calc(100vh - 90px)',
              overflowY: 'auto'
            }}
          >
            <Row className='w-100 mt-4'  >
              <Col xs={24} lg={24} style={{ paddingRight: "8px" }}>
                <Form.Item label="Subtenant Name" name="Subtenant"
                  rules={[{ required: true, message: 'Please input your Name!' }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <AddEditSpeakerModal form={form} visible={isModalVisible} onCancel={handleCancel} setGetFName={setGetFName} setGetLName={setGetLName} setGetEmail={setGetEmail} setGetContact={setGetContact} getCoordName={sendCoordName} setcountrycode={setcountrycode} coordinatorspeakertype={coordinatorspeakertype} />
            </Row>
            <Button  htmlType='submit' style={{background:"#720c20",color:"white",width:"80px",borderRadius:"5px",fontWeight:"500"}}>Add</Button>
            {/* <Button type='primary' onClick={SubmitData} htmlType='submit'>Add</Button> */}
          </Form>


        </Modal>

      </Form.Provider>

    </div>
  )
}

export default Coordinator
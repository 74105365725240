import React, { useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import AddCategoryFolder from './AddCategoryFolder'
import AddCabinet from './AddCabinet'
import AddFileType from './AddFileType'
import { FileCabinetContext } from './ParentCabinet'
import FolderStructure from './FolderStructure'
import MappingSubtenant from './MappingSubtenant'
import commonLib from '../../control/commonLib'
import AddFile from './AddFile'

const ManageAllFile = ({apiCallGetMainCabinetContainer, callApiGetCabinetCategory, apiCallUpsertMainCabinet, callApiCabinetCategory, callApiCabinetFolder }) => {
    const { showScreenType, myAllCabinetList, allMainCabinetContainer,belongsScreen, manageShowFileType, setManageShowFileType, categoryTypeListCheckMapping } = useContext(FileCabinetContext)
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    // define state-----------------------------------------------------------

    return (
        <>
            <Row>
                <Col lg='12' className='folderstructure'>
                    {showScreenType == 'showManageFileCabinetScreen' && stateObj.roleId == 4 ? <>
                        <Row>
                            <Col>  <h4 style={{ color: "#720c20" }}>Manage Your File Cabinet </h4> </Col>
                        </Row>
                        <Row>
                            <Col className='mt-1'>
                                <div className='d-flex'>
                                    <h6>Add New:</h6>
                                    <div className='d-flex'>
                                        {/* <h6 className={`${manageShowFileType == "Cabinet" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('Cabinet')} >Cabinet </h6> */}
                                        {stateObj.roleId == 4 && myAllCabinetList.length == 0 && <>  <h6 className={`${manageShowFileType == "Cabinet" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('Cabinet')} >Cabinet </h6>|</>}
                                        {stateObj.roleId == 4 && <> <h6 className={`${manageShowFileType == "Category" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('Category')} >Drawer </h6> |
                                            <h6 className={`${manageShowFileType == "Folder" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('Folder')} >Folder</h6> </>}
                                        {/* {stateObj.roleId == 4 && <>  <h6 className={`${manageShowFileType == "FileType" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('FileType')} >File Type </h6></>} */}
                                        {/* <h6 className={`${manageShowFileType == "File" ? "filetool-text-tag-h6" : "folder-text-class"} mx-3 `} onClick={() => setManageShowFileType('File')} > File</h6> */}
                                    </div>
                                </div>
                            </Col>
                            <hr />
                        </Row></> : (showScreenType == 'showManageCabinetMapping') ?
                        <MappingSubtenant apiCallUpsertMainCabinet={apiCallUpsertMainCabinet} callApiGetCabinetCategory={callApiGetCabinetCategory} /> : ''
                    }

                    {(manageShowFileType == 'Category' || manageShowFileType == 'Folder') ?
                        <AddCategoryFolder
                            callApiCabinetCategory={callApiCabinetCategory}
                            callApiCabinetFolder={callApiCabinetFolder}
                            manageShowFileType={manageShowFileType}
                            actionType='ADD'
                            refrencePage="ManageAllFile"
                            uniqueref='FILE'
                            callApiGetCabinetCategory={callApiGetCabinetCategory}
                            key={manageShowFileType}

                        // />:(manageShowFileType=='File') ? <AddFile   refrencePage="ManageAllFile"  

                        /> : ''

                    }
                    {((manageShowFileType == 'Cabinet') && (subtenantId == 3)) && <AddCabinet setManageShowFileType={setManageShowFileType} apiCallUpsertMainCabinet={apiCallUpsertMainCabinet} />}
                    {((manageShowFileType == 'FileType') && (subtenantId == 3)) && <AddFileType setManageShowFileType={setManageShowFileType} />}



                    <Row>
                        {(myAllCabinetList.length > 0 ||  belongsScreen =='Firmpartner') &&
                            <FolderStructure
                                callApiCabinetFolder={callApiCabinetFolder}
                                callApiCabinetCategory={callApiCabinetCategory}
                                key={myAllCabinetList}
                                allMainCabinetContainer={allMainCabinetContainer}
                                setManageShowFileTypeFromManageFile={setManageShowFileType}
                                callApiGetCabinetCategory={callApiGetCabinetCategory}
                                apiCallGetMainCabinetContainer={apiCallGetMainCabinetContainer}
                            />}
                    </Row>
                    {(myAllCabinetList.length == 0 && stateObj.roleId != 4) &&
                        <Row>
                            <div> No Cabinet available</div>
                        </Row>}
                </Col>
            </Row>
        </>
    )
}



export default ManageAllFile
import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import konsole from '../../network/konsole';
import { useEffect, useState, useRef } from 'react';


const Acordianfile = (props) => {
  const [spouseFiles, setSpouseFiles] = useState([])
  const [clientFiles, setClientFiles] = useState([])
  const [showDragNDrop, setDragNDrop] = useState(false);
  const [clientData, setClientData] = useState({})
  const [uploadFromFileCabinet, setUploadFromFileCabinet] = useState(false)
  const [fromAccordain, setfromAccordain] = useState("")

  // const data = props.pdfdata;
  konsole.log("datnnata", props?.docFileData)

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const numSlides = 5; // Number of slides to display at a time

  const nextSlide = () => {
    // const totalSlides = Math.ceil(sliderRef.current.children.length / numSlides);
    const nextIndex = (currentIndex + 1);
    setCurrentIndex(nextIndex);
  };

  const prevSlide = () => {
    // const totalSlides = Math.ceil(sliderRef.current.children.length / numSlides);
    const prevIndex = (currentIndex - 1);
    setCurrentIndex(prevIndex);
  };

  // useEffect(() => {
  //   const memberId = sessionStorage.getItem('SessPrimaryUserId')
  //   const client = {
  //     memberId: memberId,
  //   }
  //   setClientData(client)

  // }, [data])


  konsole.log("clientData", clientFiles)
  konsole.log("currentIndex", currentIndex)

  return (
    <>
      <div>
        {props?.docFileData ? (
          <Row className="d-flex justify-content-start " style={{ border: "1px solid gray" }}>
            <Col className='m-0 p-0 ' >
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="AcodiItem"
                  style={{ border: "none" }}
                >
                  <Accordion.Header>
                    <div style={{ fontWeight: 600, fontSize: "17px" }}>
                      {/* {clientFiles[0].belongsToMemberName} */}
                      AuthTest Administrator
                    </div>
                    <div style={{ width: "70%", }} className="px-1" >
                      <hr className="w-100" />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body
                  // className="d-flex m-0 p-0 mt-1 mb-0 me-5 customScroll Card-Col vertical-scroll"
                  // style={{ overflow: "scroll", overflowX: "auto", overflowY: "hidden", border: "1px solid red" }}
                  >
                    <Row className='d-flex'>

                      <Col md="10" lg="10" className="d-flex m-0 p-0 custom-slider" style={{ overflow: "hidden" }}>
                        {props?.docFileData.map((filemap) => (
                          <>
                            <div>
                              <div
                                className="d-flex justify-content-center align-items-center flex-column mx-2  slider-track"
                                onClick={() => props.handleClose(filemap)}
                                // style={{ transform: `translateX(-${currentIndex * (100 / 1)}%)` }}
                                style={{ transform: `translateX(-${currentIndex * (100 / .40)}%)` }}
                                ref={sliderRef}
                              >
                                <div className="img-Div" style={{ width: "70px" }}>
                                  <img
                                    src="images/file-folder.png"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className=" " style={{ height: "36px" }}>
                                  <h6
                                    className=" text-center mt-1 fw-bold  "
                                    style={{ fontSize: "14px" }}
                                  >
                                    file 1
                                    {/* {filemap.fileTypeName} */}
                                    {/* - {filemap.fileStatusName} */}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}

                      </Col>
                      <Col md="2" lg="2" className=''>
                        {/* <div className='d-flex justify-content-center align-items-center'> */}
                        <div className='cursor-pointer d-flex justify-content-center align-items-center flex-column mt-1' style={{ border: "2px solid #EBEDEF", borderRadius: "3px" }}>
                          <img src="/icons/add-icon.svg" alt="" className="mt-3"
                          // onClick={() => handleUpload("fromAccordian")} 
                          />
                          <div className='text-center mb-2' style={{ fontSize: "13px", fontWeight: 600 }}>Add File</div>
                        </div>
                        {/* </div> */}
                      </Col>
                    </Row>
                    {
                      props?.docFileData?.length > 5 ? <div className='d-flex justify-content-end p-0 m-0 gap-2 '>
                        <button onClick={prevSlide} style={{ border: "1px solid #720c20", background: "white", color: "#720c20", fontWeight: "600", borderRadius: "3px" }}
                          disabled={props?.docFileData?.length > 5 && currentIndex > 0 ? false : true}
                        >Back</button>
                        <button onClick={nextSlide} style={{ background: "#720c20", color: "white", fontWeight: "600", borderRadius: "3px", border: "none" }}

                          disabled={props?.docFileData?.length > 6 && props?.docFileData?.length - 5 > currentIndex ? false : true}
                        >Next</button>

                      </div> : ""
                    }



                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        ) : <></>}

        {/* {props?.docFileData ?
          <Row className="ms-2d-flex justify-content-start mt-3">
            <Col>
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  eventKey="0"
                  className="AcodiItem"
                  style={{ border: "none" }}
                >
                  <Accordion.Header>
                    <div style={{ fontWeight: 600, fontSize: "17px" }}>
                      {(spouseFiles?.length > 0) && spouseFiles[0]?.belongsToMemberName}
                    </div>
                    <div style={{ width: "70%" }} className="px-1">
                      <hr className="w-100" />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="d-flex m-0 p-0 mt-1 mb-0 me-5 customScroll Card-Col vertical-scroll"
                    style={{ overflow: "scroll", overflowY: "auto" }}>
                    <div className="d-flex justify-content-center align-items-center">
                      <div className='cursor-pointer ms-3' style={{ border: "2px solid #EBEDEF", borderRadius: "3px", padding: "5px", marginBottom: "auto", marginTop: "13px" }}>
                        <img className='ms-3 me-3' src="/icons/add-icon.svg" alt=""
                        // onClick={() => handleUpload("fromAccordian")} 
                        />
                        <div className='text-center'>Add File</div>
                      </div>
                      {props?.docFileData?.map((filemap) => (
                        <div
                          className="d-flex justify-content-center align-items-center flex-column mx-2 mb-2 "
                          onClick={() => props.handleClose(filemap)}
                        >
                          <div className="img-Div" style={{ width: "50px" }}>
                            <img
                              src="images/file-folder.png"
                              className="img-fluid"
                            />
                          </div>
                          <div className=" " style={{ height: "36px" }}>
                            <h6
                              className=" text-center mt-1 fw-bold  "
                              style={{ fontSize: 10 }}
                            >
                              {filemap.fileTypeName}
                              {/* - {filemap.fileStatusName} 
                            </h6>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row> : ""} */}
        {/* {(clientFiles?.length == 0 && spouseFiles?.length == 0) ?
          <div>
            <div className="d-flex justify-content-center align-items-center" >
              <img
                className=""
                style={{ height: "35px", cursor: "pointer" }}
                src="icons/fileUpload.svg"
              // onClick={() => handleUpload("fromAccordian")}
              />
            </div>
            <p className="text-center mt-1">No Files Available</p>
          </div>
          : <></>
          } */}
      </div>
      {/* {showDragNDrop && (
        <BulkUpload
          showDragNDrop={showDragNDrop}
          handleDragNDrop={handleDragNDrop}
          client={clientData}
          uploadFromFileCabinet={uploadFromFileCabinet}
          text={props.text}
          fromAccordian={fromAccordain}
          filecabinetdocumentfunc={props.filecabinetdocumentfunc}
        />
      )} */}
    </>
  );
}

export default Acordianfile
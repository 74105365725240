import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Input, Select, Modal } from 'antd';
import konsole from "../network/konsole";
import Services from "../network/Services";
import commonLib from "../control/commonLib";
import InputMask from "./PhoneInputMask";
const { TextArea } = Input;
const { Option } = Select;






const AddEditSpeakerModal = ({ setGetFName, setGetLName, setGetEmail, setGetContact, getCoordName ,setcountrycode, form,coordinatorspeakertype}) => {
    

    

    const [firstNames, setFirstNames] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [contactNum, setContactNum] = useState()
    // const [form] = Form.useForm();
    const [linkrequired,setLinkrequired]=useState(true)


    useEffect(()=>{
if(coordinatorspeakertype !==undefined && coordinatorspeakertype=='Coordinator' ){
    setLinkrequired(false)

}
    },[])


    const ModalPhoneNoValidation = (value) =>{
        // if(!commonLib.isNumberRegex(e.target.value)){
            form.setFieldsValue({
              'PhoneNumber': value
            })
        // } 
        // else{
            setGetContact(value)
            setContactNum(value)
        // }
      }
    const onFinish = (values) => {
        konsole.log('Success:', values);

    };

    const onFinishFailed = (errorInfo) => {
        konsole.log('Failed:', errorInfo);
    };

    setGetFName(firstNames)
    setGetLName(lastName)
    setGetEmail(email)

    konsole.log("CoordinatorName", getCoordName)

    const [contactCode, setContactCode] = useState([])

    useEffect(() => {


        const promises = Services.getPhoneCode()
        promises.then((res) => {
            konsole.log("PhoneCode", res.data.data)
            setContactCode(res.data.data)
        }).catch((err) => {
            konsole.log("PhoneCodeError", err)
        })


        let splitGetCoordName = getCoordName.split(" ")
        konsole.log("splitGetCoordName", splitGetCoordName)

        // konsole.log("MySended", getCoordName)
        form.setFieldsValue({
            "firstName": splitGetCoordName[0],
            // "lastname": splitGetCoordName[1].charAt(0).toUpperCase() + splitGetCoordName[1].slice(1)
        })
        if(splitGetCoordName.length ==2){
            form.setFieldsValue({
                "lastname": splitGetCoordName[1].charAt(0).toUpperCase() + splitGetCoordName[1].slice(1)
                ,      });
      
          }


        let GetFieldFirstName = form.getFieldValue("firstName")
        konsole.log("GetFieldFirstName", GetFieldFirstName);
        setFirstNames(GetFieldFirstName)

        let getFieldLastName = form.getFieldValue("lastname")
        setLastName(getFieldLastName)


    }, [getCoordName])




    const [codeMobile, setCodeMobile] = useState("")
    const GetContactCode = (a, key) => {
        konsole.log("GetContactCode", a)
        setCodeMobile(a)
        setcountrycode(a)
        // konsole.log("GetContact",key)
    }


    let ConCatMobileNo = codeMobile.concat(contact)
    konsole.log("codeMobile", ConCatMobileNo)
    // setGetContact(ConCatMobileNo)

    return (
        <>



            <Row className='w-100' gutter={[8, 8]}>
                <Col xs={24} lg={24}>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                            {pattern: new RegExp(/^[a-zA-Z]*$/),message: "No Space or Special Characters Allowed"},
                            { required: true, message: 'Please input your first Name!' }]}

                    >

                        <Input  onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}/>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                    <Form.Item
                        label="Last Name"
                        name="lastname"
                        rules={[
                            {pattern: new RegExp(/^[a-zA-Z]*$/),message: "No Space or Special Characters Allowed"},
                            { required: true, message: 'Please input your last name!' }]}
                    >

                        <Input  onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}/>

                    </Form.Item>
                </Col>

                <br></br>
                <Col xs={24} lg={24}>
                    <Form.Item
                        label="Profile"
                        name="Profile"
                        rules={[
                            { message: 'Please enter your Profile Details' }]}
                    >
                        
                        <TextArea rows={2}  onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}/>
                    </Form.Item>

                </Col>


                <Col xs={24} lg={24}>

                    <Form.Item
                        name="Video Link"
                        label=" Link"
                        rules={[
                            {pattern: new RegExp('https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}')
                            , message: 'Please enter correct url format!'},
                            { required: linkrequired, message: 'Please input link!' }
                
                          ]}
                    >
                        <Input placeholder="https://" />
                    </Form.Item>

                </Col>
                <Col xs={24} lg={24}>
                    <Form.Item
                        name="email"
                        label="E-mail"

                        rules={[
                            {pattern: new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"),message: "Please enter correct email address"},
                            {
                              required: true,
                              message: 'Please input your E-mail!',
                            },
                          ]}
                    >
                        <Input onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                    <Form.Item
                        name="phone"
                        label="Cell Number"
                        

                    // rules={[{ required: true, message: 'Please input your phone number!' }]}

                    >
                        <Input.Group compact>
                            <Form.Item
                                name="countryCode"

                                noStyle
                                rules={[{ required: true, message: 'Please select country code' }]}
                            >
                                <Select placeholder="Country Code" onChange={GetContactCode} style={{ width: '23%',marginRight:"2%" }}  >
                                    {
                                        contactCode.map((items) => {
                                            return <Option value={items.value} >{items.label}</Option>
                                        })
                                    }

                                    {/* <Option value="=1">1</Option>
                                    <Option value="=2">2</Option> */}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="PhoneNumber"
                                noStyle
                                rules={[{required: true,message: ''}
                                ]}
                            >

                                {/* <Input style={{ width: '70%' }} maxLength={10}  onChange={ModalPhoneNoValidation}  /> */}
                                <div   style={{ width: "75%" }}>
                                <InputMask value={contactNum}  onValueChange={ModalPhoneNoValidation}   />
                                {<p className="ps-3 text-danger">{contactNum?.length == 0 ? "" : contactNum?.length < 10 ? "Please enter the valid Cell Number" : ""}</p>}

                                </div>
                            </Form.Item>



                        </Input.Group>
                    </Form.Item>
                </Col>

            </Row>

        </>
    )
}

export default AddEditSpeakerModal;
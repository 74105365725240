import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Button, Form, Table } from 'react-bootstrap'
import { InputCom, InputSelectCom } from './InputCom'
import commonLib from '../../control/commonLib'
import { FileCabinetContext } from './ParentCabinet'
import konsole from '../../network/konsole'
import Services from '../../network/Services'
import { UserContext } from '../../App'
import { message } from 'antd'




const fileObj = () => {
    return { "fileTypeId": 0, "fileCategoryId": '', "fileType": "", "isMultipleUpload": true, "displayOrder": 0, "isPublicReadAccess": true, "isFolder": true, "isCategory": true, "createdBy": "", "updatedBy": "", }
}
const AddFileType = ({ setManageShowFileType }) => {
    const { myAllCabinetList } = useContext(FileCabinetContext)
    const loggedUserId = sessionStorage.getItem('loggedUserId')
    const { setLoader } = useContext(UserContext)
    const [cabinetId, setCabinetId] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [fileTypeCategoryList, setFileTypeCategoryList] = useState([])
    const [fileTypeJson, setFileTypeJson] = useState({ ...fileObj(), createdBy: loggedUserId })
    konsole.log('all cabinet in file type', myAllCabinetList)
    console.log('fileTypeJsonfileTypeJson', fileTypeJson)


    const handleSelectCabinet = (e) => {
        setCabinetId(e.target.value)
        updateFileTypeJson('fileCategoryId', '')
        let filterCabinetNCategoryList = myAllCabinetList.find(o => o.cabinetId == e.target.value).fileCategoryTypeMappingList;
        setCategoryList(filterCabinetNCategoryList)
        konsole.log('filterCabinetNCategoryListfilterCabinetNCategoryList', filterCabinetNCategoryList)
        // let filterCabinetNCategoryList=myAllCabinetList.find?

    }

    const handleSelectCategory = (e) => {
        updateFileTypeJson('fileCategoryId', e.target.value)
        // apiCallgetFileTypeByCategory(e.target.value)

    }

    const updateFileTypeJson = (key, value) => {
        setFileTypeJson((prev) => ({
            ...prev, [key]: value
        }))
    }

    const saveCategory = () => {
        if (cabinetId == '') {
            message.error('Please select cabinet')
            return;
        }
        if (fileTypeJson.fileCategoryId == '') {
            message.error('Please select category')
            return;
        }
        if (fileTypeJson.fileType == '') {
            message.error("Name can't be blank")
            return;
        }
        konsole.log('fileTypeJsonApi', fileTypeJson)
        setLoader(true)
        Services.upsertFileTypeByCategory(fileTypeJson).then((res) => {
            konsole.log('res of adding updating file type', res)
            setLoader(false)
            message.success('File type added successfully')
            cancelCategory()
        }).catch((err) => {
            setLoader(false)
            konsole.log('err in adding updating file type', err,)
        })
        konsole.log('fileTypeJsonfileTypeJson', fileTypeJson)

    }
    const cancelCategory = () => {
        setCabinetId('')
        setFileTypeJson({ ...fileObj })
        setManageShowFileType('')

    }





    const apiCallgetFileTypeByCategory = (fileCategoryId) => {
        setLoader(true)
        Services.getFileTypeByCategory(fileCategoryId)
            .then((res) => {
                setLoader(false)
                console.log('getFileTypeByCategory', res)
                setFileTypeCategoryList(res.data.data)
            }).catch((err) => {
                setLoader(false)
                console.log('getFileTypeByCategory', err)
            })
    }

    const msgForAddCategory = (cabinetId !== undefined && cabinetId != '' && categoryList.length == 0) ? 'No Category available please add category inside cabinet' : ''

    return (
        <>
            <Row>
                <Col lg='12' className='main-col'>
                    <Row><Col>  <h5 style={{ color: "#720c20" }}>Manage Your File Type</h5> </Col></Row>
                    {myAllCabinetList.length !== 0 ?
                        <Row className='mt-2'>
                            <Col lg='10'>
                                <InputSelectCom label='Cabinet' placeholder='Select any Cabinet' id='cabinetId' selectTypeValue='cabinetId' selectTypeLabel='cabinetName' selectInfo={myAllCabinetList} value={cabinetId} handleSelectChange={handleSelectCabinet} />
                                <InputSelectCom label='Category' warninglabel={msgForAddCategory} placeholder='Select any Category' id='cabinetId' selectTypeValue='fileCategoryId' selectTypeLabel='fileCategoryType' selectInfo={categoryList} value={fileTypeJson.fileCategoryId} handleSelectChange={handleSelectCategory} />
                                <InputCom label='Name' placeholder='Enter Name' value={fileTypeJson.fileType} handleInputChange={(e) => updateFileTypeJson("fileType", commonLib.capitalizeFirstLetter(e.target.value))} />

                                <Row className='mt-2'>
                                    <Col lg="5"></Col><Col lg="7">
                                        <Button className='folder-save-button' onClick={() => saveCategory()} >Save</Button>
                                        <Button className='folder-save-button ms-3' onClick={() => cancelCategory()} >Cancel</Button>
                                    </Col></Row>


                                {/* {fileTypeCategoryList.length > 0 &&
                                    <Table striped bordered className="text-center" size="sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="">Name</th>
                                                <th className="">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fileTypeCategoryList.map((item, index) => {
                                                return (
                                                    <tr className="cursor-pointer text-center" key={index} >
                                                        <td>{index + 1}</td>
                                                        <td className="text-start text-truncate w-50" style={{ maxWidth: '400px' }}>{item.fileType}</td>
                                                        <td> <a>Edit</a>  |  <a>Delete</a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                } */}
                            </Col>
                        </Row> : 'No Cabinet Available'}

                </Col>
            </Row>
            <hr />
        </>
    )
}

export default AddFileType
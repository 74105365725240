import React, { useContext, useState } from 'react'
import { Steps, Popover, Row, Col, form } from 'antd';
import './Seminar.css'
import { StpperStepsContext } from './SeminarFile';
const { Step } = Steps;



const Stepper = ({ form, callFunction, setChangePage, preData, setPreviewBtn ,speakerId}) => {
  const { IncStepperSteps, setStepperSteps, getCallPreviewFunction, getCallMetaDataFunction} = useContext(StpperStepsContext)

  let CallPreviewFunction;
  let CallMataDataFunction;

  const [current, setCurrent] = useState(0);


  const PreviemPage = () => {

    
    if (preData == true) {
      form.submit(() => callFunction())
      if(speakerId?.length != 0){
      setChangePage(1)
      setStepperSteps(1)
      }
      
    }else{
      form.submit(() => callFunction())
    setPreviewBtn("1")
    }
  };
  const BackSeminarFile = () => {

    if (IncStepperSteps == "2") {
      CallMataDataFunction = "1";
      getCallMetaDataFunction(CallMataDataFunction)
      // setStepperSteps(0)
      // setChangePage(0)
    }
    else if (IncStepperSteps == "1") {
      CallPreviewFunction = 1
      getCallPreviewFunction(CallPreviewFunction)
    }



  }

  const MetaData = () => {
    form.submit(()=>callFunction())

    if (preData == true) {
      setChangePage(2)
      setStepperSteps(2)
    }


  }
  // const Congratulation = ()=>{
  //   if(preData == true){
  //     setChangePage(3)
  //   setStepperSteps(4)
  //   }

  // }

  return (
    <>



      <div className="mt-3">
        {/* <div style={{ width: 810 }}></div> */}
        <Steps labelPlacement="vertical" current={IncStepperSteps} size="small">
          <Step title="Create" onClick={BackSeminarFile} style={{ cursor: "pointer" }} />
          <Step title="Preview" onClick={PreviemPage} style={{ cursor: "pointer" }} />
          {/* <Step title="Metadata" onClick={MetaData} style={{ cursor: "pointer" }} /> */}
          <Step title=" Publish" style={{ cursor: "pointer" }} />
          {/* <Step title=" Publish" onClick={Congratulation} style={{ cursor: "pointer" }} /> */}
        </Steps>
      </div>



    </>


  )
}

export default Stepper


import React, { useEffect, useContext, useState, } from "react";
import commonLib from "./control/commonLib";
import konsole from "./network/konsole";
import Services from "./network/Services";
import { UserContext } from "./App"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SeminarFile from "./components/Seminar/SeminarFile";
import { AOLOGINBASEURL } from "./network/url";


const LoginPage = () => {

  // const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const search = useLocation().search;
  // const AtobSearch = window.atob(search)
  // konsole.log("AtobSearch",AtobSearch)
  const { setLoader } = useContext(UserContext);
  // const { setSubtenantLogo } = useContext(UserContext);
  // const currentParams = Object.fromEntries([...searchParams]);
  // konsole.log("currentParams",currentParams)



  // const atobTokenValue = window.atob(currentParams.token)
  // ................ Remove Part ............................................
  // const qrlQuery = props.location.search;
  // const uId = commonLib.getQueryParameters(qrlQuery, 'UID');
  // const subtenantId = commonLib.GetQueryValues(qrlQuery, 'SubtenantId');
  // konsole.log("props at dashboard", props, uId);
  // ....................................................................................



  const tokenKey = search;
  useEffect(() => {
    const tokenMapKey = commonLib.GetQueryValues(tokenKey, "token");
    konsole.log("tokenMapKey", tokenMapKey);
    const decodedKey = window.atob(tokenMapKey);
    konsole.log("dece", decodedKey);
    const loggenInId = commonLib.GetQueryValues(decodedKey, "loggenInId") || "";
    const userId = commonLib.GetQueryValues(decodedKey, "userId") || "";
    const roleId = commonLib.GetQueryValues(decodedKey, "roleId") || "";
    const appState = commonLib.GetQueryValues(decodedKey, "appState") || "";

    const stateObj = {
      loggenInId: loggenInId,
      userId: userId,
      roleId: roleId,
      appState: appState
    }
    commonLib.setSessionStoarge("stateObj", JSON.stringify(stateObj));
    commonLib.setSessionStoarge("loggedUserId", stateObj.userId);

    if (loggenInId !== '' && userId !== '' && roleId !== '' && appState !== '') {
      getLoggedInUser(userId, appState, loggenInId, roleId);
    }
    else {
      window.location.replace( `${AOLOGINBASEURL}/Account/Signin`);
    }
  }, [])

  const getLoggedInUser = (userId, appState, loggedInUserId, roleId) => {
    setLoader(true);
    const promises = Services.getLoggedInUser(userId, appState, loggedInUserId, roleId)
    promises.then(response => {
      if (response) {
        setLoader(false);
        let roleUserId = 0;
        let loginDetail = {};
        let loggedUserId = "";
        if (response.data !== null || response.data !== "") {
          sessionStorage.setItem("AuthToken", response.data.data.accessToken);
          loginDetail = {
            memberName: response.data && response.data.data.memberName,
            primaryEmailId: response.data && response.data.data.primaryEmailId,
            userName: response.data && response.data.data.userName,
            subtenantName :response?.data && response.data.data.subtenantName
          }
          if (response.data) {
            loggedUserId = response.data.data.memberUserId;
            roleUserId = response.data.data.roleId;
            sessionStorage.setItem("roleUserId", response.data.data.roleId);
            sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginDetail));
          }
          const subtenantId = response.data.data.subtenant_Id;
          sessionStorage.setItem("subtenantId", response.data.data.subtenant_Id);
          if (roleId == 4 || roleId == 11) {
            navigate('/Dashboard')
          }
          if(roleId == 6){
            navigate('/SeminarFile')
          }
        }
      }
    }).catch(err => {
      konsole.log("err", err.response);
    })
      .finally(() => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  return (
    <div>

    </div>
  )
};


export default LoginPage;


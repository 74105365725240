import { Modal, Form, Upload, Row, Col, Select, Button, Card, Input, Typography, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AiOutlineDelete } from 'react-icons/ai';
import config from '../../config.json'
import "../../Styles/subtenant.css";
import Services from "../../network/Services";
import { useRef } from "react";
import { UserContext } from "../../App";
import Address from "../Address";
import commonLib from "../../control/commonLib";
import konsole from "../../network/konsole";
import PersonalInfo from "./PersonalInfo";
import InputMask from "../PhoneInputMask";
import TextArea from "antd/lib/input/TextArea";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { isValidFuntion } from "../../control/Constant";


function AddSubtenantUsers({ visible, onCancel, getUserListApi, selectedSubtenant, subtenantValueSelected, setdisableselect, disabledselect, updatePartnerUsersData }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { setLoader } = useContext(UserContext)
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [updateImage, setUpdateImage] = useState(false)
  const [updateSign, setUpdateSign] = useState(false)
  const [file, setFile] = useState();
  const [options, setOptions] = useState([])
  const [optionsBackUp, setOptionsBackUp] = useState([])
  const [subtenantId, setSubtenentId] = useState()
  const [speakerDetailsData, setSpeakerDetailsData] = useState()
  const [subtenantdata, setsubtenantdata] = useState([])
  const [getSubtenantRoleId, setGetSubtenantRoleId] = useState()
  const [deletedRoleName, setDeletedRoleName] = useState("")
  const [getMemberDetailsData, setGetMemberDetailsData] = useState()
  const [addressAPIStatus, setAddressAPIStatus] = useState()
  const [citizenship, setCitizenship] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [getContactStatus, setGetContactStatus] = useState()
  const [countryCodeValue, setcountryCodeValue] = useState("+1")
  const createdBy = null;
  const isActive = true;
  const fileInputRef = useRef(null);
  const [countrycode, setcountryCode] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [signatureData, setSignatureData] = useState()
  const [multiRole, setMultiRole] = useState([])
  const [multiRoleIdData, setMultiRoleIdData] = useState([])
  const [removeRoles, setRemoveRoles] = useState()
  let loginUserIdFromSession = sessionStorage.getItem('loggedUserId')
  const [closeModals, setCloseModals] = useState(false)
  const [roleRemoveinFilter, setroleRemoveinFilter] = useState()
  const { Option } = Select;
  const { Title } = Typography;

  useEffect(() => {
    allRolesDetails()
    getCitizenship();
    getContactTypecode();

  }, []);
  useEffect(() => {
    if (selectedSubtenant !== '') {
      form.setFieldsValue({ "subtenantName": selectedSubtenant })
    }
    /////////////  get Subtinent details /////////////
    Services.getsubtenantdetails(createdBy, isActive).then((res) => {
      setsubtenantdata(res.data.data);
    }).catch((err) => {
    });
    //////////////   get country code //////////////////
    Services.getcountrycode().then((response) => {
      setcountryCode(response.data.data);
    }).catch((err) => {
      konsole.log("Error", err);
    });
  }, [])

  useEffect(() => {
    if (isValidFuntion(updatePartnerUsersData)) {
      let roleId = commonLib.getObjFromStorage('stateObj')?.roleId
      if (roleId != 4) {
        setdisableselect(true)
      }
      form.setFieldsValue({
        'email': updatePartnerUsersData?.userName,
        "charge" :updatePartnerUsersData?.userPrice
      })
      getContactApiFunction(updatePartnerUsersData?.userId)
      getMemberAPiFunction(updatePartnerUsersData?.userId)

    }
  }, [updatePartnerUsersData])

  const removeRolesFilter = (filtered, unfiltered) => {
    return unfiltered.filter(ele => !filtered.map(obj => obj).includes(ele));
  };


  const allRolesDetails = () => {
    const arr2 = ["1", "4", "2", "5", "9", "10", "12", "17", "18", "19", "20"];
    const roleId = commonLib.getObjFromStorage('stateObj')?.roleId;
    const subtenant = sessionStorage.getItem('subtenantId');
    Services.getAllUserRoleIdDetails().then((response) => {
      let filterData = response.data?.data.filter(element => !arr2.includes(element.value));
      setOptionsBackUp(filterData);

      if (isValidFuntion(updatePartnerUsersData)) {
        getUserRoleFunction(updatePartnerUsersData?.userRgstrtnId, updatePartnerUsersData?.subtenantId, filterData, roleId, subtenant);
      } else {
        let filterLegalRoleData = [3, 13, 14, 15];
        if (roleId == 4 || subtenant == config.AOADMINSUBTENANTID) {
          filterData = filterData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
        }

        setOptions(filterData);

        setOptionsBackUp(filterData);
      }
    }).catch((error) => {
      konsole.log("getAllUserRoleIdDetailserror", error);
    }).finally(() => {
      konsole.log("getAllUserRoleIdDetailsEnd");
    });

  }
  const getUserRoleFunction = (LoginUserId, SubtenantId, hideData, roleId, subtenant) => {
    Services.getUserRoleDetails(LoginUserId, SubtenantId).then(response => {
      const filterData = response.data.data.map(items => items.roleId);
      setMultiRoleIdData(response.data.data); // Setting multi-role data here
      SepkaerListOptionApi(updatePartnerUsersData?.userId, filterData);

      const rolesToRemove = [10, 2, 1];
      rolesToRemove.forEach(role => {
        const index = filterData.indexOf(role);
        if (index !== -1) filterData.splice(index, 1);
      });

      const hideArrValue = [3, 13, 14, 15];
      const intersection = hideArrValue.filter(x => filterData.includes(x));

      if (intersection.length > 0) {
        hideArrValue.splice(hideArrValue.indexOf(intersection[0]), 1);
        hideData.forEach(disableFields => {
          if (hideArrValue.includes(disableFields.value)) {
            disableFields.disabled = true;
          }
        });

        let difference = hideData.filter(x => !hideArrValue.includes(parseInt(x.value)));
        if ((roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant == config.AOADMINSUBTENANTID)) ||
          (roleId != 4 && subtenant == config.AOADMINSUBTENANTID)) {
          difference = difference.filter(x => ![3, 13, 14, 15].includes(parseInt(x.value)));
        }

        setOptions(difference);
        setOptionsBackUp(difference);
      } else {
        let difference = hideData;
        if ((roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant == config.AOADMINSUBTENANTID)) ||
          (roleId != 4 && subtenant == config.AOADMINSUBTENANTID)) {
          difference = difference.filter(x => ![3, 13, 14, 15].includes(parseInt(x.value)));
        }
        setOptions(difference);
        setOptionsBackUp(difference);
      }

      setGetSubtenantRoleId(filterData);
      const allRoles = response.data.data.length > 0 && response.data.data.map(items => items.roleId);
      setroleRemoveinFilter(removeRolesFilter(filterData, allRoles))
      form.setFieldsValue({ 'subtenantRole': filterData });
      setMultiRole(filterData);
    })
      .catch(error => {
        konsole.log("getUserRoleFunctionerror", error);
      })
      .finally(() => {
        konsole.log("getUserRoleFunctionEnd");
      });
  };

  const SepkaerListOptionApi = (userId, roleIdData) => {
    Services.getPartnerUserSpeakerDetailsList(userId, true).then((response) => {
      let responseData = response.data?.data?.speakers[0]
      setSpeakerDetailsData(responseData)

      form.setFieldsValue({
        'speakerLink': responseData?.videoLinkPath, 'speakerDescription': responseData?.speakerProfile,
      })
      if (isValidFuntion(responseData?.speakerProfileUrl) && roleIdData.includes(7)) {
        setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: responseData?.speakerProfileUrl }])
      }


    }).catch((error) => {
      konsole.log("ErrorSpeakerList", error);
    });

  };
  const getCitizenship = () => {
    Services.getCitizenshipType().then((res) => {
      setCitizenship(res.data.data);
    }).catch((err) => {
      konsole.log(err);
    });
  };
  const getContactTypecode = () => {
    Services.getContactType().then((res) => {
      setContactType(res.data.data);
    }).catch((err) => {
      konsole.log(err);
    });
  };
  const getContactApiFunction = (userId) => {
    Services.getContactDetails(userId).then((response) => {
      let responseData = response.data?.data
      setGetContactStatus(responseData)
      let mobileNumber = responseData?.contact?.mobiles[0]?.mobileNo.split("");
      let seprateCountryCode = mobileNumber.splice(mobileNumber.length - 10, 10).join("");
      form.setFieldsValue({ 'mobile': seprateCountryCode, 'number': mobileNumber.splice(mobileNumber.length - 10, 10).join("") })
    }).catch((error) => {
      konsole.log("getContactDetailsError", error)
    }).finally(() => {
      konsole.log("getContactDetailsEnd")
    })

  }
  const getMemberAPiFunction = async (userId) => {
    Services.getMemberDetails(userId).then(async (response) => {
      const memberData = response.data?.data?.member;
      setGetMemberDetailsData(memberData)

      if (isValidFuntion(memberData?.signatureId)) {
        const signatureFileData = { primaryUserId: memberData?.userId, fileId: memberData.signatureId, fileTypeId: 63, fileCategoryId: 1, requestedBy: loginUserIdFromSession };
        const signatureResult = await getFileDetails(signatureFileData);
        setImageUrl(signatureResult === 'err' ? null : signatureResult?.fileUrlPath);
        setSignatureData(signatureResult === 'err' ? null : signatureResult);
      }

      if (isValidFuntion(memberData?.fileId)) {
        const imageFileData = { primaryUserId: memberData?.userId, fileId: memberData.fileId, fileTypeId: 15, fileCategoryId: 1, requestedBy: loginUserIdFromSession };
        const imageResult = await getFileDetails(imageFileData);
        setFileList([{ uid: '-1', name: 'image.png', status: 'done', url: imageResult === 'err' ? null : imageResult?.fileUrlPath }]);
        setFile([{ uid: '-1', name: 'image.png', status: 'done', url: imageResult === 'err' ? null : imageResult?.fileUrlPath }]);
      }
    })
      .catch((error) => {
        konsole.log("getMemberAPiFunctionError", error);
      })
      .finally(() => {
        konsole.log("getContactDetailsEnd");
      });
  };
  const getFileDetails = (openFileInfo) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      Services.getFileForView({ ...openFileInfo }).then((res) => {
        setLoader(false)
        resolve(res?.data?.data)
        setLoader(false)

      }).catch((err) => {
        setLoader(false)
        resolve('err')
      })

    })

  }

  const onFinishForm = async (data) => {

    if (isValidFuntion(updatePartnerUsersData)) {
      const formData = form.getFieldsValue();
      const intersectionData = multiRole?.filter(element => !formData?.subtenantRole?.includes(element));
      let removedObjects = multiRoleIdData.filter(obj => !formData?.subtenantRole.includes(obj.roleId));

      if (roleRemoveinFilter.length > 0 && removedObjects.length > 0) {
        removedObjects = removedObjects.filter(obj => !roleRemoveinFilter.includes(obj.roleId));
      }

      if (removedObjects.length > 0) {
        setRemoveRoles(removedObjects)
        const rolesArray = intersectionData.map(roleId => multiRoleIdData.find(data => data.roleId === roleId)?.roleKey);
        const deletedRoleToString = rolesArray.join(", ");
        setDeletedRoleName(deletedRoleToString);
      }
      upDateAllData(removedObjects);
    } else {
      callAddUserApi(data);
    }
  }
  const onSearch = (value) => {
  };
  const handleProfileChange = ({ fileList: newFileList }) => {
    if (isValidFuntion(updatePartnerUsersData)) {
      setUpdateImage(true)
    }

    if (newFileList?.length > 0) {
      const isJpgOrPng = newFileList[0].type === "image/jpeg" || newFileList[0].type === "image/png";
      if (!isJpgOrPng) return;
    }
    setFileList(newFileList);
    setFile(newFileList[0]?.originFileObj);
  };
  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }

    const isLt2M = file.size / 1024 / 1024 > 1;


    if (isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };
  const onSelectLifePointLaw = (e, key) => {
    let filterLegalRoleData = [3, 13, 14, 15]
    let subtenantValue = key.key
    form.setFieldsValue({
      'subtenantRole': undefined
    })

    if (subtenantValue == config.AOADMINSUBTENANTID) {
      let difference = options.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      setOptions(difference)

    } else {
      setOptions(optionsBackUp)
    }
    setSubtenentId(key.key)
  }
  const onChangePhone = (value) => {
    form.setFieldsValue({
      mobile: value,
    });
  };
  const getSubtenantId = (e) => {
    setGetSubtenantRoleId(e)

    let hideArrValue = [3, 13, 14, 15]
    let intersection = hideArrValue.filter(x => e.includes(x));

    if (intersection.length > 0) {
      hideArrValue.splice(hideArrValue.indexOf(intersection[0]), 1)
      for (let i = 0; i < hideArrValue.length; i++) {
        options.filter((disableFields) => {
          if (disableFields.value == hideArrValue[i]) {
            disableFields['disabled'] = true
          }

        })
      }
    } else {
      for (let i = 0; i < hideArrValue.length; i++) {
        options.filter((disableFields) => {
          if (disableFields.value == hideArrValue[i]) {
            disableFields['disabled'] = false
          }

        })
      }

    }




  }
  const handleDeleteSign = () => {
    setImageUrl(null);
    setSignatureData(null)
    if (isValidFuntion(updatePartnerUsersData)) {
      setUpdateSign(true)
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (isValidFuntion(updatePartnerUsersData)) {
      setUpdateSign(true)
    }
    setSignatureData(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const deletestatus = (data) => {
    if (data == true) {
      addorUpdateUserRoles()
    } else if (data == false) {
      onCancel()
      getUserListApi()
    }

  }
  const callAddUserApi = (data) => {
    const { subtenantRole, fName, lName, email, mobile, subtenantName, mName,charge } = form.getFieldsValue()
    const subtenantId = subtenantdata.find(data => data.subtenantName === subtenantName)?.subtenantId;
    const userId = sessionStorage.getItem("loggedUserId");
    setLoader(true);
    const jsonObjData = {
      subtenantId,
      signUpPlateform: config.AOADMIN,
      createUserCredentials: false,
      createdBy: userId,
      user: {
        roleId: subtenantRole[0],
        firstName: fName,
        mName: mName,
        lastName: lName,
        emailAddress: email,
        userName: email,
        countryCode: countryCodeValue,
        mobileNumber: mobile,
        packageCode: null,
        activateUser: false,
        autoConfirmEmail: false,
        autoConfirmMobile: false,
        userPrice:charge
      }
    };
    PostNewUser(jsonObjData, data);
  };



  const PostNewUser = async (jsonObjData, data) => {
    const postAddUserApiCall = Services.subtenantCreateUser(jsonObjData);
    return postAddUserApiCall.then(async (res) => {
      message.success("Firm User created Successfully");
      const formdata = form.getFieldsValue();
      const subtenantId = subtenantdata.find(data => data.subtenantName === formdata.subtenantName)?.subtenantId;

      if (data?.subtenantRole.length > 1) {
        for (let i = 1; i < data?.subtenantRole.length; i++) {
          const response = res?.data?.data;
          await multiRoleAPIFunction(response?.userId, response?.id, subtenantId, data?.subtenantRole[i], true, response?.createdBy);
        }
      }

      const createdBy = res.data.data.createdBy;
      const userId = res.data.data.userId;
      const fileId = isValidFuntion(file) ? await fileSignupload(file, createdBy, userId, 15, 1, 2) : null;
      const signatureId = isValidFuntion(signatureData) ? await fileSignupload(signatureData, createdBy, userId, 63, 1, 2) : null;

      if (data?.subtenantRole?.includes(7)) {
        await postspekaeruser(0, userId, data.speakerDescription, data.speakerLink, fileId, "Done", true, createdBy, signatureId);
      }
      await updateMemberDataByID(userId, fileId || "", signatureId || "");
      getUserListApi()
      onCancel()
    })
      .catch((error) => {
        if (error.response.data.messages[0] === "ALREADY_REGISTERED") {
          message.error(error.response.data.messages[1]);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const multiRoleAPIFunction = (userId, loginUserId, subtenantId, roleId, isActive, createdBy) => {
    setLoader(true)
    return new Promise((resolve, reject) => {
      Services.postRoleIdData(userId, loginUserId, subtenantId, roleId, isActive, createdBy).then((res) => {
        resolve(res)
      }).catch((err) => {
        resolve('err')
        setLoader(false)
      })
    })


  }
  const fileSignupload = (file, createdBy, userId, fileTypeId, fileCategoryId, fileStatusId) => {
    return new Promise((resolve, reject) => {
      Services.postfileuploadspeaker(file, createdBy, userId, fileTypeId, fileCategoryId, fileStatusId).then((res) => {
        let response = res?.data?.data?.fileId
        resolve(response)
        setFile(null)

      }).catch((err) => {
        resolve('err')
      })
    })



  };
  const updateMemberDataByID = (userId, fileId, signatureId) => {
    setLoader(true)
    const { subtenantRole, fName, lName, email, mobile, subtenantName, dob, Citizenship, genderId, mName,charge} = form.getFieldsValue()
    const updatedBy = sessionStorage.getItem("loggedUserId");
    const subtenantId = subtenantdata.filter((data, index) => { return data.subtenantName == subtenantName })[0]?.subtenantId
    let jsondata = {
      subtenantId: subtenantId, fName: fName, mName: mName, lName: lName, fileId: fileId ?? null,userPrice:charge,
      signatureId: signatureId ?? null, dob: isValidFuntion(dob) && dob !== false ? moment(dob).format("YYYY-MM-DD") : "",
      citizenshipId: Citizenship, genderId: genderId, userId: userId, updatedBy: updatedBy
    }
    return new Promise((resolve, reject) => {
      Services.subtenantmemberbyuserid(jsondata).then((res) => {
        if (!isValidFuntion(updatePartnerUsersData)) {
          addressByUserId(userId);
        }
        setLoader(false)
        resolve(res)
      }).catch((err) => {
        konsole.log("errerr", err)
        resolve('err')
        setLoader(false)
      }).finally(() => {
        setLoader(false)
      })
    })

  };
  const postspekaeruser = async (speakerUserId, userId, speakerProfile, videoLinkPath, speakerImageId, remarks, isActive, upsertedBy, speakersignId) => {
    return new Promise((resolve, reject) => {
      Services.postspeakerupsertuser(speakerUserId, userId, speakerProfile, videoLinkPath, speakerImageId, remarks, isActive, upsertedBy, speakersignId).then((res) => {
        resolve(res)
      }).catch((err) => {
        resolve('err')
      }).finally(() => {
      })
    })
  };
  const addressByUserId = (userId, deleteRolesStatus) => {
    const { address, zipcode, country, city, state, subtenantRole, } = form.getFieldsValue();
    const createdBy = sessionStorage.getItem("loggedUserId");

    let addressjson = {
      userId: userId,
      address: {
        lattitude: "string",
        longitude: "string",
        addressLine1: address,
        addressLine2: "string",
        addressLine3: "string",
        zipcode: zipcode,
        county: country,
        city: city,
        state: state,
        country: country,
        addressTypeId: 1,
        createdBy: createdBy,
      }
    }

    Services.addAddressByuserId(addressjson).then((res) => {
      setLoader(false)

    }).catch((err) => {
      konsole.log("errassress", err)
      message.error("Enter valid details")
      setLoader(false)

    }).finally(() => {
      setLoader(false)

    })

  }
  const addorUpdateUserRoles = async () => {
    let formData = form.getFieldsValue()
    const subtenantId = subtenantdata.filter((data, index) => { return data.subtenantName == formData.subtenantName })[0]?.subtenantId
    if (removeRoles.length > 0 && formData?.subtenantRole.length > 0) {
      setLoader(true)
      for (let i = 0; i < removeRoles.length; i++) {
        let result = await putRolesDetailsData(removeRoles[0]?.loginUserId, subtenantId, removeRoles[i]?.roleId, false, removeRoles[i]?.userRoleId, loginUserIdFromSession, "")
        if (i == removeRoles.length - 1 && closeModals == true) {
          removeRoles.length = 0;
          message.success("Updated Successfully");
          setShowDeleteModal(false)
          setLoader(false)
          getUserListApi()
          onCancel()

        }
      }


    }

  };
  const putRolesDetailsData = (loginUserId, subtenantId, roleId, isActive, userRoleId, loginUserIdFromSession, extraParam) => {
    return new Promise((resolve, reject) => {
      Services.putRoleIdDetails(loginUserId, subtenantId, roleId, isActive, userRoleId, loginUserIdFromSession, extraParam).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err); // Reject the promise with the error
      }).finally(() => {

      });
    });
  };


  const upDateAllData = async (removedObjects) => {

    if (isValidFuntion(updatePartnerUsersData)) {
      const { fName, mName, lName, genderId, subtenantRole, Citizenship, dob, mobile, subtenantName, number, address, zipcode, city, state, country,charge } = form.getFieldsValue()
      const subtenantId = subtenantdata.filter((data, index) => { return data.subtenantName == subtenantName })[0]?.subtenantId
      let mobileNumber = number + mobile

      if (updatePartnerUsersData?.primaryMobile !== mobileNumber) {

        let promise = Services.putContactDetails(getContactStatus?.userId, 1, getContactStatus?.contact?.mobiles[0]?.contactTypeId, mobileNumber, getContactStatus?.contact?.mobiles[0]?.contactId, loginUserIdFromSession)
        promise.then((res) => {
          konsole.log("putContactDetails", res)
        }).catch((err) => {
          konsole.log("putContactDetailserr", err)
          // setLoader(false)

        }).finally(() => {
          // setLoader(false)

        })
      }
      //////////////////// address update ///////////////////////
      if (isValidFuntion(addressAPIStatus?.addressLine1)) {
        if (addressAPIStatus?.addressLine1 !== address) {


          let promise = Services.putAddressDetails(address, zipcode, city, state, country, addressAPIStatus?.addressTypeId, addressAPIStatus?.addressId, loginUserIdFromSession, true)
            .then((response) => {
            }).catch((error) => {
              // setLoader(false)
              konsole.log("putAddressDetailsError", error)

            }).finally(() => {
              // setLoader(false)
              konsole.log("putAddressDetailsEnd")

            })


        }
      } else {
        addressByUserId(updatePartnerUsersData?.userId)
      }


      const isValidDob = isValidFuntion(getMemberDetailsData?.dob) === true ? getMemberDetailsData?.dob.substr(0, 10) : null;
      const dobValue = isValidFuntion(dob) === true && dob !== false ? dob.substr(0, 10) : null;
      if (getMemberDetailsData?.fName !== fName ||  getMemberDetailsData?.mName !== mName ||
        getMemberDetailsData?.lName !== lName ||  isValidDob !== dobValue || getMemberDetailsData?.genderId !== genderId ||
        getMemberDetailsData?.citizenshipId !== Citizenship || updateSign ||   updateImage || subtenantRole?.includes(7) ||
        getMemberDetailsData?.userPrice !==  charge) {

        let fileId = null;
        let signatureId = null;
        // Upload updated file if image has changed
        if (isValidFuntion(file) && updateImage) {
          fileId = await fileuploadForUpdate(file, loginUserIdFromSession, updatePartnerUsersData?.userId, 15, 1, 2);
        }

        // Upload updated signature if it has changed
        if (isValidFuntion(signatureData) && updateSign) {
          signatureId = await fileuploadForUpdate(signatureData, loginUserIdFromSession, updatePartnerUsersData?.userId, 63, 1, 2);
        }

        // Check if speaker details need to be updated
        if (subtenantRole?.includes(7)) {

          const files = fileId || (isValidFuntion(file) ? getMemberDetailsData?.fileId : null);
          const sign = signatureId || (isValidFuntion(signatureData) ? getMemberDetailsData?.signatureId : null);
          await putSpekaeruser(files, sign);
          await updateMemberDataByID(updatePartnerUsersData?.userId, files, sign);
        } else {

          const files = fileId || (isValidFuntion(file) ? getMemberDetailsData?.fileId : null);
          const sign = signatureId || (isValidFuntion(signatureData) ? getMemberDetailsData?.signatureId : null);
          await updateMemberDataByID(updatePartnerUsersData?.userId, files, sign);
        }
      }

      //////////////////////  roles update///////////////////////////
      let addedObjects = subtenantRole.filter(id => !multiRoleIdData.some(obj => obj.roleId === id));
      if (addedObjects.length > 0) {
        for (let i = 0; i < addedObjects.length; i++) {

          let reasult = await multiRoleAPIFunction(updatePartnerUsersData?.userId, updatePartnerUsersData?.userRgstrtnId, subtenantId, addedObjects[i], true, loginUserIdFromSession)
          if (i == addedObjects.length - 1) {
            addedObjects.length = 0;
            setLoader(false)
          }
        }
      }
    }
    if (showDeleteModal == false && removedObjects.length == 0) {
      getUserListApi()
      message.success("Updated Successfully")
      onCancel()
    } else {
      setCloseModals(true)
      setShowDeleteModal(true);
    }


  }
  const fileuploadForUpdate = (file, loginUserIdFromSession, createdByuserId, fileTypeId, fileCategoryId, fileStatusId) => {
    setLoader(true)
    return new Promise((resolve, reject) => {
      Services.postfileuploadspeaker(file, loginUserIdFromSession, createdByuserId, fileTypeId, fileCategoryId, fileStatusId).then((res) => {
        let fileId = res.data.data.fileId
        resolve(fileId)
        setLoader(false)
        setUpdateImage(false)
        setUpdateSign(false)
      }).catch((err) => {
        resolve('err')
        setLoader(false)
      })
    })







  }
  const putSpekaeruser = (fileId, signatureId) => {
    setLoader(true)
    const { speakerDescription, speakerLink } = form.getFieldsValue()
    return new Promise((resolve, reject) => {
      Services.postspeakerupsertuser(speakerDetailsData?.speakerUserId, updatePartnerUsersData?.userId, speakerDescription, speakerLink, fileId, "Done", true, loginUserIdFromSession, signatureId).then((res) => {
        resolve(res)
        setLoader(false)
      }).catch((err) => {
        resolve('err')
      }).finally(() => {
        // setLoader(false)
      })

    })

  }
  const fieldValues = form.getFieldValue();
  const subtenantRole = fieldValues.subtenantRole;








  return (
    <>
      <Modal visible={visible}
        okText="Add" onCancel={onCancel} footer={null} closable={true} centered={true} maskClosable={false}>
        <Form form={form} name="AdduserForm" style={{ width: "100%", marginTop: "30px", paddingRight: "5px", maxHeight: 'calc(100vh - 100px)', overflowY: 'auto' }}
          wrapperCol={{ span: 24 }} labelCol={{ span: 0 }} className="w-100" layout="inline" onFinish={onFinishForm}>

          <Row lg={24} className="w-100 d-flex justify-content-between" style={{ marginTop: "20px" }}>
            <Col span={6} xs={10} md={6} lg={6} >
              <Form.Item name="fileUpload">

                <Upload listType="picture-card" fileList={fileList} onChange={handleProfileChange} onPreview={onPreview} beforeUpload={beforeUpload}>
                  {fileList.length < 1 && "+ Upload Profile Image"}
                </Upload>
              </Form.Item>
            </Col>

            <Col span={18} xs={24} md={18} lg={18} className="w-100" >

              <Row className="w-100 mb-2">

                <Col xs={24} md={12} lg={12} >
                  <Form.Item name="subtenantName"
                    rules={[
                      {
                        required: true,
                        message: "Please select Firm",
                      },
                    ]}
                  >
                    <Select disabled={isValidFuntion(updatePartnerUsersData)} placeholder="Firm" className="fs-7" dropdownMatchSelectWidth={false} onSelect={onSelectLifePointLaw}>
                      {subtenantdata.map((dataprop) => (
                        <Option value={dataprop.subtenantName} key={dataprop.subtenantId}>
                          {dataprop.subtenantName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                </Col>

                <Col span={12} xs={24} md={12} lg={12} className="selectoffirmpartner">
                  <Form.Item name="subtenantRole"
                    rules={[
                      {
                        required: true,
                        message: "Please select role",
                      },
                    ]}
                  >
                    <Select mode='multiple' placeholder="Role" optionFilterProp="children" maxTagCount={'responsive'} showArrow allowClear id="selectoffirmpartner" className="fs-7 select-multi-select " onChange={getSubtenantId}>
                      {options?.map((items) => {
                        return (
                          <>
                            <Option value={parseInt(items?.value)} disabled={(items?.disabled) ? items?.disabled : false} >{items?.label}</Option>
                          </>
                        )
                      })
                      }


                    </Select>
                  </Form.Item>
                  <Form.Item name="userSign">
                    <div className="signature-uploader-container">
                      {imageUrl ? (
                        <div className="container profileSign" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                          <img src={imageUrl} style={{ filter: isHovered ? 'blur(1px)' : 'none' }} alt="Uploaded Signature" className="uploaded-image" />
                          {isHovered == true ? (
                            <div className="delete-icon" onClick={handleDeleteSign}>
                              <AiOutlineDelete />
                            </div>
                          ) : ""}
                        </div>
                      ) : (
                        <div>
                          <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                          <h6 onClick={handleClick} className="upload-button text-center">Upload Signature</h6>
                        </div>
                      )}
                    </div>
                  </Form.Item>

                </Col>

              </Row>
            </Col>
          </Row>
          <div>
            <Row wrapperCol={{ span: 24 }} labelCol={{ span: 0 }} className="w-100" layout="inline" >
              <PersonalInfo form={form} updatePartnerUsersData={updatePartnerUsersData} getMemberDetailsData={getMemberDetailsData} />
              {form.getFieldValue()?.subtenantRole?.some(role => [3, 13, 14, 15].includes(role)) && (
                <Card className="w-100 mt-1" style={{ border: "1px dashed black", borderRadius: "5px", padding: "5px" }}>
                  <Title level={5} style={{ color: "#720C20" }}>Consultation Charges</Title>
                  <Row className="w-100" gutter={[8, 8]}>
                    <Col xs={24} lg={8}>
                      <Form.Item
                        name="charge"
                        className="mt-1"
                      >
                        <Input type="text" placeholder="$" onInput={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              )}


              <card className="w-100 mt-1" style={{ border: "1px dashed black", borderRadius: "5px", padding: "5px" }}>
                <Title level={5} style={{ color: "#720C20" }}>Contact Address</Title>
                <Address form={form} updatePartnerUsersData={updatePartnerUsersData} setAddressAPIStatus={setAddressAPIStatus} />
              </card>
            </Row>

            <div>
              <Card className=" w-100 mt-1" style={{ border: "1px dashed black", borderRadius: "5px" }}>
                <p className="fw-bold" style={{ color: "#720C20" }}>Contact Information</p>
                <Row className="mt-2 p-2 justify-content-around" style={{ border: "ridge", borderRadius: "10px" }}>
                  <Col className="mt-2" lg={12} xs={24} span={12}>
                    <Form.Item name="email"
                      rules={[
                        {
                          type: "email", required: true, message: "Please enter your E-mail",
                        },
                      ]}>

                      <Input placeholder="Email" disabled={(updatePartnerUsersData !== undefined && updatePartnerUsersData !== null && updatePartnerUsersData !== "") ? true : false} />
                    </Form.Item>
                  </Col>
                  <Col className="mt-2 ms-2" lg={4} xs={24} span={3}>
                    <Form.Item name="number"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if (value === "(+1) USA") {
                              return Promise.reject("Please select your country code");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Select placeholder="Code" optionFilterProp="children" onChange={(value) => setcountryCodeValue(value)} onSearch={onSearch} style={{ width: "113px" }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())} defaultValue={"+1"} >
                        {countrycode.map((countryProp) =>
                        (
                          <Option value={countryProp?.value}>{countryProp?.label}</Option>
                        )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="mt-2 mobileInputContactWidth " lg={7} xs={24} span={8} >
                    <Form.Item
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                        {
                          pattern: /^(\+\d{1,3}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                          message: "Please input a valid phone number",
                        },
                      ]}
                    >
                      <InputMask value={form.getFieldsValue().mobile} onValueChange={onChangePhone} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </div>
            {
              getSubtenantRoleId?.includes(7) &&

              <div>
                <Card className=" w-100 mt-1" style={{ border: "1px dashed black", borderRadius: "5px" }}>
                  <p className="fw-bold" style={{ color: "#720C20" }}> About</p>
                  <Row className="mt-2 p-2" style={{ border: "ridge", borderRadius: "10px" }}>
                    <Col className="mt-2" lg={24} xs={24} span={24}>
                      <Form.Item name="speakerLink"
                        rules={[
                          {
                            required: true, message: "Please Enter Speaker Link",
                          },
                        ]}
                      >

                        <Input placeholder="Enter Link" />
                      </Form.Item>
                    </Col>
                    <Col className="mt-2" lg={24} xs={24} span={24}>
                      <Form.Item name="speakerDescription"
                        rules={[
                          {
                            required: true, message: "Please Enter Speaker Description!",
                          },
                        ]}
                      >

                        <TextArea placeholder="Speaker Description" rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </div>
            }
          </div>
          <Row className="mt-3 w-100">
            <Col className="w-100 d-flex justify-content-between">
              <Button className="fw-bold" onClick={onCancel} style={{ color: "#720C20", borderColor: "#720C20", borderRadius: 5, }}> Cancel </Button>
              <Button className="fw-bold" htmlType="submit" style={{ background: "#720C20", color: "white", borderRadius: 5 }}>
                {(updatePartnerUsersData !== null && updatePartnerUsersData !== undefined && updatePartnerUsersData !== "") ? "Update" : "Save"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {showDeleteModal == true && <DeleteConfirmModal visible={showDeleteModal} hideModal={setShowDeleteModal} titleText="Delete Role" bodyContent={`Do you want to delete "${deletedRoleName}" ${deletedRoleName.split(",").length == 1 ? "role" : "roles"} for this user?`} deleteStatus={deletestatus} />}
    </>
  )
}

export default AddSubtenantUsers
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import konsole from '../../network/konsole';
import Services from '../../network/Services';

function ContactInfo({ keyIndex, handleChange, handleClick, index, items, selectedIndex, setSelectedIndex }) {
    const { Option } = Select;

    const [countryCode, setcountryCode] = useState([])

    useEffect(() => {
        konsole.log("useEffect")
        callcountrycodeapi();
        konsole.log(items);
        if (selectedIndex !== null) {
            setSelectedIndex(null);
        }
    }, [])

    const callcountrycodeapi = () => {
        let promises = Services.getcountrycode()
        promises
            .then((res) => {
                konsole.log("countrycode", res.data.data)
                setcountryCode(res.data.data)

            })
            .catch((error) => {
                konsole.log("Errorcountry", error)
            })
    }

    return (
        <div key={keyIndex}>

            {
                (items.Type == 'add') ?
                    <Row className="mt-2 p-2 rounded border" style={{ border: "ridge" }} gutter={[8, 8]}>
                        
                        <Col span={24}>
                            <h6>{items.name}</h6>
                        </Col>
                        <Col className="" xs={24} md={8} >
                            <Form.Item>
                                <Input.Group className='border rounded' size='large'>
                                    <input placeholder="Email" className='w-100 p-1 border-0' defaultValue={items.emailId.email} onChange={(value) => handleChange("email", index, value)} />{''}
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col className="" xs={24} md={4} >
                            <Form.Item>
                                <Select placeholder="code" defaultValue={items.mobiles.countryCode} onSelect={(value) => handleChange('code',index,value)}>

                                    {
                                        countryCode.map((code, index) => {
                                            return <Option value={code.value} >{code.value}</Option>
                                        })
                                    }


                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="" xs={24} md={8} lg={8} xl={10}>
                            <Form.Item>
                                <Input.Group className='border rounded' size='large' >
                                    <input placeholder="Cell Number" defaultValue={items.mobiles.mobileNo} maxLength={10} minLength={10} className='w-100 p-1 border-0' onChange={(value) => handleChange("mobile", index, value)} />{''}
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <div className='d-flex justify-content-between w-100'>
                        <p className='text-danger'>{items.error}</p>
                            <Button
                                className="fw-bold rounded"
                                style={{
                                    background: "#720C20",
                                    color: "white",
                                }}
                                onClick={() => handleClick('add', index)}
                            >
                                ADD
                            </Button>
                        </div>
                    </Row>
                    :
                    <Row className="mt-2 p-2" style={{ border: "ridge" }} gutter={[8, 8]}>
                        <Col xs={24} md={24} className="d-flex w-100 justify-content-between">
                            <h6>{items.name}</h6>
                            <div className="fw-bold" style={{ color: "#720C20" }}>
                                <img className="cursor-pointer" src="/images/akar-icons_edit.png" onClick={() => handleClick('Edit', index)} />
                                Edit
                            </div>
                        </Col>
                        <Col className="mt-2 d-flex align-items-center" xs={24} md={10}>
                            {
                                (items.emailId.email !== '') ?
                                    <>
                                        <Input.Group className='border rounded' size='large'>
                                            <input className="border-0 p-1 form-control disabledInput" disabled value={items.emailId.email} />
                                        </Input.Group>
                                        <img
                                            src="/images/Group 1562.png"
                                            className='h-100'
                                        />
                                    </>
                                    : <></>
                            }   
                        </Col>

                        <Col className="mt-2 d-flex align-items-center" xs={24} md={10}>
                            {
                                (items.mobiles.mobileNo !== "") ?
                                    <>
                                        <Input.Group className='border rounded' size='large'>
                                            <input className="border-0 p-1 form-control w-100 disabledInput" disabled value={items.mobiles.countryCode + items.mobiles.mobileNo} />
                                        </Input.Group>
                                        <img
                                            src="/images/Group 1562.png"
                                            className='h-100'
                                        />
                                    </>
                                    :
                                    <></>
                            }
                        </Col>
                    </Row>
            }
        </div>
    )
}

export default ContactInfo


import React, { useEffect, useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Document, Page, pdfjs } from "react-pdf";
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import konsole from '../../network/konsole';
import { Modal, Row } from 'antd';
import { UserContext } from '../../App';

const FileViewer = ({ openFileInfo, isFileOpen, setIsFileOpen }) => {
    const { setLoader } = useContext(UserContext)
    let primaryUserId = sessionStorage.getItem('loggedUserId')
    let loggesInId = sessionStorage.getItem('loggedUserId')
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const subtenantId = commonLib.getSessionStoarge("subtenantId");
    console.log('openFileInfo', openFileInfo)
    // state define -----------------------------------
    const [base64data, setBase64Data] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [isRemarksOpen, setIsRemarksOpen] = useState(false);

    useEffect(() => {
        getFileDetails(openFileInfo)
    }, [openFileInfo])

    // this function use for getting information of file -----------------------
    const getFileDetails = (openFileInfo) => {
        setLoader(true)
        Services.getFileForView({ ...openFileInfo }).then((res) => {
            setLoader(false)
            konsole.log('response of file info', res)
            let fileDocObj = res?.data?.data?.fileInfo
            let binaryData = 'data:application/pdf;base64,' + fileDocObj?.fileDataToByteArray;
            setBase64Data(binaryData);
            setLoader(false)
            konsole.log('fileDocObj', fileDocObj)
        }).catch((err) => console.log('err in response file info', err))
    }


    //  this fun use for deleting file --------------------------------------------------- 
    const apiDeleteFile = () => {
        let { fileId, primaryUserId, fileCabinetId, fileCategoryId, fileTypeId, } = openFileInfo
        let josnObj = { "userId": primaryUserId, "fileCabinetId": Number(fileCategoryId), "fileTypeId": Number(fileTypeId), "fileId": Number(fileId), "deletedBy": loggesInId }
        konsole.log('josnObj', josnObj)
        setLoader(true)
        Services.deleteFileCabinetByFileId(josnObj).then((res) => {
            konsole.log('res of file deleting ', res)
            window.location.reload()
        }).catch((err) => konsole.log('err in deling file ', err))
        setLoader(false)
    }



    //  this is predefine for view  file 
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    //  this is predefine for view  file 

    return (
        <div>
            <Modal visible={isFileOpen} onOk={() => setIsFileOpen(false)} onCancel={() => setIsFileOpen(false)} footer={null} okText="Add" centered={true} maskClosable={false}>
                <Row className="modal-border">
                    <div className='container-fluid pdf' id='pdfId'>
                        <div id='logo' className='w-100'>
                            <div className="container-fluid mt-2 p-0 m-0">
                                <div className="side-menu p-0 mx-2 d-flex align-items-center justify-content-between">
                                    <div><p><b>File Status:</b> {openFileInfo?.fileStatusName} </p><p><b>File Name: </b>{openFileInfo?.userFileName}</p></div>
                                    <div>
                                        <button className="rounded-circle me-3 " id='remark' onClick={() => apiDeleteFile()}>
                                            <img src='/icons/deleteIcon.svg' alt='g4' />
                                            <p>Delete</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Document className="outer-doc" file={base64data} onLoadSuccess={onDocumentLoadSuccess} onContextMenu={(e) => e.preventDefault()}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </Row>
            </Modal>
        </div>
    )
};


export default FileViewer;

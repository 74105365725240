import React, { useEffect, useState, useRef,useContext} from "react";
import "./Manage.css";
import { Row, Col, Select, Typography, Card, Button, Divider } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { Dropdown, Form } from "react-bootstrap";
import { Accordion, Table } from "react-bootstrap";
import { Input, Space } from "antd";
import AddRateCard from "./AddRateCard";
import AddRateCardPartner from "./AddRateCardPartner";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import { formatDateIntoDDMMYY } from "../../control/Constant";
import { UserContext } from "../../App";
import { completeEventMessage } from "../../control/Constant";

let index = 0;
export default function SubscriptionRate() {
  const [AddShowModal, setAddShowModal] = useState(false);
  // const [updateModal, setUpdateModal] = useState(false)
  const [items, setItems] = useState([
    "Life Point Law (S 12354)",
    "Texas law firm (S 12354)",
    "Texas law firm (S 12354)",
    "Texas law firm (S 12354)",
  ]);
  const [name, setName] = useState("");
   const[radio, setradio ] = useState('All')
  const [rateCardsList, setRateCardsList] = useState([])
  const [subtenantsList, setSubtenantsList] = useState([])
  const [rateCardSearchValue, setRateCardSearchValue] = useState("")
  const [updateRateCard, setUpdateRateCard] = useState("")
  const [selectedSubtenantId, setSelectedSubtenantId] = useState(null)
  const [rcListToFiltByStatus, setRcListToFiltByStatus] = useState([])
  const [statusValue, setStatusValue] = useState(null)
  const [discountValue, setDiscountValue] = useState(null)
  const { setLoader } = useContext(UserContext);
  const statusArray = [{value : 1, label : "Active"},{value : 2, label : "InActive"}]
  const discountArray = [{value : 1, label : "All"},{value : 2, label : "Yes"},{value : 3, label : "No"}]
  const productTypeArray = [{value : 1, label : "Product"},{value : 2, label : "Product group"},{value : 2, label : "Package"}]
  const subtenantID = sessionStorage.getItem("subtenantId")
  const loginUserID = sessionStorage.getItem("loggedUserId")


  konsole.log("rateCardsList232",rateCardsList,rcListToFiltByStatus,statusValue)
  
  useEffect(()=>{
    if(radio != "Partner"){
      rateCards("","",subtenantID)
    }
    subtenantDetails()
  },[])

  const showModal = (e,data) => {
    konsole.log("data1212",data)
    if(e == "update"){
      rateCards(data.rateCardId,e)
    }
    else{
      setUpdateRateCard("")
    }
    setAddShowModal(true);
  };

  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `New item ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const rateCards = (rateCardID,e,subtenantID) =>{
    konsole.log("IDDDD",rateCardID,e,subtenantID)
    setLoader(true)
    const promise = Services.getRateCard(rateCardID,'',subtenantID,'')
    promise.then((res)=>{
      setLoader(false)
      const responseData = res.data.data
      konsole.log("ressRateCards",responseData,subtenantID)
      if(e == "update"){
        konsole.log("filterOnlySuperAdminCreatedRC111",responseData)
        setUpdateRateCard(responseData[0])
      }
      else if(e == "onlySuperAdminCreated"){
        const filterOnlySuperAdminCreatedRC = responseData.filter(item => item.createdBy == loginUserID && item.isActive == true)
        konsole.log("filterOnlySuperAdminCreatedRC",e,responseData,filterOnlySuperAdminCreatedRC)
        setStatusValue(null)
        setDiscountValue(1)
        setRateCardsList(filterOnlySuperAdminCreatedRC)
        setRcListToFiltByStatus(filterOnlySuperAdminCreatedRC)
      }
      else{
      setRateCardsList(responseData)
      setRcListToFiltByStatus(responseData)
      }
    }).catch((err)=>{
      setLoader(false)
      konsole.log("errr",err)
    })
  }

  const activeRadioButton = (showFor)=>{
    konsole.log("etargetRadio",showFor)
    if(showFor == "Partner"){
      setRateCardsList([])
      setSelectedSubtenantId(null)
    }
    else if(showFor == "All"){
      setSelectedSubtenantId(null)
      rateCards("","",subtenantID)
    }
    setradio(showFor)
    setRateCardSearchValue("")
  }
  // function allowDrop(ev) {
  //     ev.preventDefault();
  //   }

  //   function drag(ev) {
  //     console.log("ev", ev.target.id);
  //     ev.dataTransfer.setData("text", ev.target.id);
  //   }

  //   function drop(ev) {
  //     ev.preventDefault();
  //     var data = ev.dataTransfer.getData("text");
  //     console.log("ev", ev.target);
  //     ev.target.appendChild(document.getElementById(data));
  //   }
    
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(!show); // Toggle accordion
  };

  // ----------------------------------- New Design of rate card --------------------------------------

  const subtenantDetails = () => {
    const createdBy = null;
    const isActive = true;
    setLoader(true)
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
      konsole.log("promiseSubtenant", res);
      setSubtenantsList(res.data.data)
      setLoader(false)
    }).catch((err) => {
      konsole.log("err", err);
    }).finally(() => {
      setLoader(false)
    })
  };

  konsole.log("rateCardSearchValue121212",rateCardSearchValue)

  const searchRateCard = (e) => {
      let filteredRateCards = [];
      let data = [];
      let id;
      konsole.log("rateCardSearchValue",rateCardSearchValue,e)
      if (e == "1") {
        data = rateCardsList;
        if (
          rateCardSearchValue !== undefined &&
          rateCardSearchValue !== null &&
          rateCardSearchValue !== ""
        ) {
          filteredRateCards = data.filter((item) => { 
            return item.rateCardName?.toLowerCase().includes(rateCardSearchValue?.toLowerCase());
          });
          konsole.log("filteredRateCards1212",filteredRateCards)
        } else {
          return data;
        }
        return filteredRateCards;
      }
      return filteredRateCards;
  };

      const choosePartnerToSearch = (e) =>{
        const eventValue = e.target.value;
        setSelectedSubtenantId(eventValue)
        rateCards("","onlySuperAdminCreated",eventValue)
      }

      const cardStatus = async(e, item, subtDet) => {
        const eventValue = e.target.value;
        let { applicableSubsModel,createdOn,createdBy,...rest } = item;
        let handleRateCardStatus = [];
        
        if (radio === "All") {
          handleRateCardStatus = [{ ...rest, isActive: eventValue === "2" ? false : true, upsertedBy: loginUserID }];
          addRateCard(handleRateCardStatus);
        } else if (radio === "Partner") {
          const updatedApplicableSubsModel = applicableSubsModel.map((model) => {
            const { subtenantRateCard, ...restModel } = model;
            if (subtenantRateCard) {
              const updatedSubtenantRateCard = subtenantRateCard.map((subIs) => {
                if (subIs.subtenantRateCardId === subtDet?.subtenantRateCardId) {
                  subIs.subIsActive = eventValue === "2" ? false : true;
                }
                return subIs;
              });
              return { ...restModel, subtenantRateCard: updatedSubtenantRateCard, appUpsertedBy : loginUserID };
            } else {
              return model;
            }
          });
          await Services.postAppSubtForRateCard(updatedApplicableSubsModel);
          rateCards("","onlySuperAdminCreated",selectedSubtenantId ?? subtenantID)
        } 
      };
    
      const addRateCard = (rateCardData) => {
        konsole.log("rateCardJson55",rateCardData)
        setLoader(true)
        const promise = Services.postToAddRateCard(rateCardData);
        promise.then((res) => {
          setLoader(false)
          const responseData = res.data.data;
            konsole.log("AddRateCardres", responseData,selectedSubtenantId);

            // const rateCardId = res.data.data[0]?.rateCardId
                rateCards("","",selectedSubtenantId ?? subtenantID)
            }).catch((err) => {
                konsole.log("error", err);
            });
    };

    const handleSorting = (e,sortfor) =>{
      const eventValue = e.target.value
      let sortByStatus = rateCardsList ?? sortByDiscount;
      let sortByDiscount;
      const filterRateCardList = rcListToFiltByStatus;
      if(rateCardsList?.length > 0){
        
        if(sortfor == "sortStatus"){
          sortByStatus = filterRateCardList.filter(item =>  eventValue == 2 ? ((item.applicableSubsModel.some(check => check.subtenantRateCard.every(det => !det.subIsActive))) || !item.isActive) : eventValue == 1 ? item.isActive && (item.applicableSubsModel.some(check => check.subtenantRateCard.some(det => det.subIsActive))) : item);
          konsole.log("sortByStatus1212",sortfor,eventValue,filterRateCardList,sortByStatus)
          setStatusValue(eventValue)
          setRateCardsList(sortByStatus)
        }
        else if(sortfor == "sortDiscount"){
          sortByDiscount = sortByStatus.filter(item => item.applicableSubsModel.some(check => check.subtenantRateCard.some(det => (eventValue == 2 ? det.subIsDisAllowed : eventValue == 3 ? !det.subIsDisAllowed : det))));
          konsole.log("eventValue1212",eventValue,filterRateCardList,sortByDiscount)
          setDiscountValue(eventValue)
          setRateCardsList(sortByDiscount)
          }
      }
      else{
        if(rateCardsList?.length > 0){
          if(sortfor == "sortStatus"){
            const sortByStatus = filterRateCardList.filter(item =>  eventValue == 2 ? ((item.applicableSubsModel.some(check => check.subtenantRateCard.every(det => !det.subIsActive))) || !item.isActive) : eventValue == 1 ? item.isActive && (item.applicableSubsModel.some(check => check.subtenantRateCard.some(det => det.subIsActive))) : item);
            konsole.log("sortByStatus23232",sortByStatus)
            setStatusValue(eventValue)
            setRateCardsList(sortByStatus)
          }
          else if(sortfor == "sortDiscount"){
            const sortByDiscount = filterRateCardList.filter(item => item.applicableSubsModel.some(check => check.subtenantRateCard.some(det => (eventValue == 2 ? det.subIsDisAllowed : eventValue == 3 ? !det.subIsDisAllowed : det))));
            setDiscountValue(eventValue)
            setRateCardsList(sortByDiscount)
            }
        }
      }
    }

    const handleSelectMouseDown = (e,data) => {
    if (data) {
      e.preventDefault();
      completeEventMessage("error","Since there is no firm linked with this rate card, status cannot be altered.")
    }
  };


  return (
    <div>
      <AddRateCardPartner
        AddShowModal = {AddShowModal}
        setAddShowModal = {setAddShowModal}
        subtenantsList = {subtenantsList}
        rateCards = {rateCards}
        rateCardsList = {rateCardsList}
        updateRateCard = {updateRateCard}
        key={AddShowModal}
      />
      <div className="container-fluid">
        <div className="subscriptonRateScroll">
        <div className="position-relative">
            {radio ==("All") ?   
            <div>
            <div className="mainDiv1">
              <div className="subscrip">
                <h5>Pricing</h5>
                <div className="mainRadioDiv me-4">
                <div className={`${radio == ("All") ? "radio1Div1" : "radio2Div2"} d-flex ps-4 mt-1 cursor-pointer`}  onClick={()=>activeRadioButton('All')}  >
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check  style={{padding:"6px 0px"}}  type="radio" checked={radio == 'All'} name="All" />
                  <label style={{padding:"6px 12px"}}>All</label>
                  </div>
                </div>
                <div style={{border:"1px solid #954958", margin:"8px"}}></div>
                <div className={`${radio == ("Partner") ? "radio1Div1" : "radio2Div2"} d-flex ps-4 mt-1 cursor-pointer`}  onClick={()=>activeRadioButton('Partner')} >
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check style={{padding:"6px 0px"}}  type="radio" checked={radio == ('Partner')} name="Partner" />
                  <lable style={{padding:"6px 12px"}} >Firm Rate Card</lable>
                  </div>
                </div>  
              </div>
              </div>
              <hr style={{color:"#FFFFFF", margin:"0px 20px", marginTop:"15px"}}></hr>
              <div className="d-flex justify-content-between">
                <div className="d-flex ms-3"></div>
              <div className="numCountPartner">
                <div className="countHadding">
                <h4>Status :</h4>
                <select onChange={(e)=>handleSorting(e,"sortStatus")} >
                  <option>All</option>
                          {statusArray.map((det)=>{
                            return(
                              <option value={det.value}>{det.label}</option>
                            )
                          })}
                        </select>
                </div>
              </div>
              </div>
            </div>

            {/* -----------Table------------------- */}
            
            <div className="d-flex justify-content-between">
              <div>
              <div className="allRateCard"><h4>All Rate Cards</h4><span style={{border:"1px solid #EDEDED", borderRadius:"6px", width:"35px", textAlign:"center",
               height:"27px", color:"#6A6A6A", backgroundColor:"#EDEDED",padding:"2px",fontSize:"15px"}}>{searchRateCard("1")?.length}</span></div></div>
               
                <div className="d-flex gap-2" style={{marginTop:"17px"}}>
                <div className="searchInput d-flex" style={{backgroundColor:"#EEEEEE"}}>
                  <input className="searchInp" type="search" placeholder="Search Rate Card By Name" value={rateCardSearchValue} onChange={(e)=>setRateCardSearchValue(e.target.value)}/>
               <span><img style={{padding:"16px 0px"}} src="/images/rateCardSearch.svg" alt=" "/></span>
                </div>
               <div>
                <button className="SubscriptionAddButton ms-1" onClick={() => showModal()}>+ Add Pricing</button></div></div>
            </div>
       
              <div className="mt-4" style={{backgroundColor:"white", overflowY:"auto", height:"34vh"}}>
                <table className="m-0" style={{width:"100%", borderCollapse:"collapse"}}>
                  <thead className="" style={{position:"sticky", top:"0px", zIndex:"111",backgroundColor:"white"}}>
                    <tr className="table_th">
                    <th style={{ textAlign: "left" }}>
                    <div style={{display:"flex" }} className="ms-3" >
                    {/* <Form.Check type="checkbox" className="m-2"/> */}
                      <span>Rate Card Name</span></div></th>
                      {/* <th>Slabs from</th>
                      <th>Slabs to</th> */}
                      <th>AO % Share</th>
                      <th>No. Of Firms</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="border-0 p-2 sticky-top" style={{zIndex:"1"}}>
                    {rateCardsList.length > 0 && searchRateCard("1")?.map((item,ind)=>{
                      const { applicableSubsModel} = item;
                      const lastSubtIsInActive = (applicableSubsModel[0]?.subtenantRateCard?.length == 1 && applicableSubsModel[0]?.subtenantRateCard[0]?.subIsActive == false)
                      return(
                         <tr className="rateCardTable">
                        <td style={{ textAlign: "left" }}>
                        <div style={{display:"flex" }} className="ms-3">
                         {/* <Form.Check type="checkbox" className="m-2"/> */}
                        <span className="subSpan">{item.rateCardName}</span></div></td>
                        {/* <td>{item.slabStartingNo}</td>
                        <td>{item.slabEndingNo}</td> */}
                        <td>{item.minPriceSkuPercent}</td>
                        <td>{item.partnerCount}</td>
                        <td><select className={`${(lastSubtIsInActive == true || item.isActive == false) ? "inActiveRateCard" : "activeRateCard"}`} value = {(lastSubtIsInActive == true || item.isActive == false) ? 2 : 1} onChange={(e)=>cardStatus(e,item)}>
                          {statusArray.map((det)=>{
                            return(
                              <option value={det.value}>{det.label}</option>
                            )
                          })}
                        </select>
                        {lastSubtIsInActive && (<div onClick={(e)=>handleSelectMouseDown(e,lastSubtIsInActive)} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, }} />)}</td>
                        <td><div className="d-flex justify-content-center gap-2"><span  onClick={() => showModal("update",item)} className="editSpan cursor-pointer">Edit</span>
                        {/* <div className="editDot cursor-pointer"><span>Delete</span> </div> */}
                        </div></td>
                    </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
                <div>
                </div>
              </div>
              </div>
             :
            
             <div>
            <div className="mainDiv1">
              <div className="subscrip">
                <h5>Pricing</h5>
                <div className="mainRadioDiv me-4">
                <div className={`${radio == ("All") ? "radio1Div1" : "radio2Div2"} d-flex ps-4 mt-1 cursor-pointer`}  onClick={()=>activeRadioButton('All')}  >
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check  style={{padding:"7px 0px"}}  type="radio" checked={radio == 'All'} name="All" />
                  <label style={{padding:"7px 12px"}}>All</label>
                </div>
                </div>
                <div style={{border:"1px solid #954958", margin:"8px"}}></div>
                <div className={`${radio == ("Partner") ? "radio1Div1" : "radio2Div2"} d-flex ps-4 mt-1 cursor-pointer`}  onClick={()=>activeRadioButton('Partner')} >
                  <div className="d-flex justify-content-center align-items-center">
                  <Form.Check style={{padding:"7px 0px"}} type="radio" checked={radio == ('Partner')} name="Partner" />
                  <lable style={{padding:"7px 12px"}} >Firm Rate Card</lable>
                  </div>
                </div>  
              </div>
              </div>
              <hr style={{color:"#FFFFFF", margin:"0px 20px", marginTop:"15px"}}></hr>
              <div className="d-flex justify-content-between">
              <div className="selectPartner ms-4">
                  <img src="/images/selectPartnerImg.svg"/>
                  <Form.Select onChange={(e)=>choosePartnerToSearch(e)}>
                  <option>Select Firm</option>
                 {subtenantsList.length && subtenantsList.map((item,ind)=>{
                  return(
                    <option value={item.subtenantId}>{item.subtenantName}</option>
                  )
                 })}
                 </Form.Select>    
                </div>
              <div className="numCountPartner">
                <div className="discountHadding">
                <h4>Discount:</h4>
                <select onChange={(e)=>handleSorting(e,"sortDiscount")} value={discountValue}>
                          {discountArray.map((det)=>{
                            return(
                              <option value={det.value}>{det.label}</option>
                            )
                          })}
                        </select>
                </div>
                <div className="statusActive">
                <h4>Status :</h4>
                <select onChange={(e)=>handleSorting(e,"sortStatus")} value={statusValue ?? 0 }>
                  <option value={0}>All</option>
                          {statusArray.map((det)=>{
                            return(
                              <option value={det.value}>{det.label}</option>
                            )
                          })}
                        </select>
                </div>
              </div>
              </div>
            </div>

            {/* -----------Table------------------- */}

            <div className="d-flex justify-content-between">
              <div>
              <div className="allRateCard"><h4>All Rate Cards</h4><span style={{border:"1px solid #EDEDED", borderRadius:"6px", width:"35px", textAlign:"center",
               height:"25px", color:"#6A6A6A", backgroundColor:"#EDEDED",padding:"0px 7px"}}>{searchRateCard("1")?.length}</span></div></div>
               
                <div className="d-flex gap-2" style={{marginTop:"17px"}}>
                <div className="searchInput d-flex" style={{backgroundColor:"#EEEEEE"}}>
                  <input className="searchInp" type="search" placeholder="Search Rate Card By Name" value={rateCardSearchValue} onChange={(e)=>setRateCardSearchValue(e.target.value)}/>
               <span><img style={{padding:"16px 0px"}} src="/images/rateCardSearch.svg" alt=" "/></span>
                </div>
               </div>
            </div>
       
              <div className="mt-4" style={{backgroundColor:"white", overflowY:"auto", height:"33vh"}}>
                <table className="m-0" style={{width:"100%"}} >
                  <thead style={{position:"sticky", top:"0px", zIndex:"111",backgroundColor:"white"}}>
                    <tr className="table_th">
                    <th style={{ textAlign: "left"}} className="w-25">
                    <div style={{display:"flex"}} className="w-75 ms-3" >
                     {/* <Form.Check type="checkbox" className="m-2"/> */}
                      <span>Rate Card Name</span></div></th>
                      {/* <th>Slabs From-To</th> */}
                      <th>Validity Period</th>
                      <th>Validity From</th>
                      <th>Validity To</th>
                      <th>Discount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                   <tbody className="border-0">
                   {rateCardsList.length > 0 ? searchRateCard("1")?.map((item, ind) => {
                    const { rateCardName, slabStartingNo, slabEndingNo, applicableSubsModel, isActive } = item;
                    konsole.log("itemPartner", item);
                    return (
                        <React.Fragment key={ind}>
                            {applicableSubsModel[0]?.subtenantRateCard.map((det, index) => {
                                const { validityPeriod, validityFromDate, validityToDate, subIsActive, subIsDisAllowed } = det;
                                konsole.log("itemPartnerdet", det, subIsActive);
                                return (
                                    <tr className="rateCardTable" key={index}>
                                        <td style={{ textAlign: "left" }}>
                                            <div style={{ display: "flex" }} className="ms-3">
                                                <span className="d-inline-block mt-1">{rateCardName}</span>
                                            </div>
                                        </td>
                                        <td>{validityPeriod}</td>
                                        <td>{formatDateIntoDDMMYY(validityFromDate)}</td>
                                        <td>{formatDateIntoDDMMYY(validityToDate)}</td>
                                        <td>{subIsDisAllowed === true ? "Yes" : "No"}</td>
                                        <td>
                                            <select className={`${((radio === "Partner" && subIsActive === true) || (radio === "All" && isActive === true)) ? "activeRateCard" : "inActiveRateCard"}`} value={((radio === "Partner" && subIsActive === true) || (radio === "All" && isActive === true)) ? 1 : 2} onChange={(e) => cardStatus(e, item, det)}>
                                                {statusArray.map((status, index) => (
                                                    <option key={index} value={status.value}>{status.label}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                );
                            })}
                        </React.Fragment>
                    );
                }) :
                <tr style={{ textAlign: "center", color: "rgba(106, 106, 106, 1)" }}>
                    <td colSpan="7" style={{ height: "150px", verticalAlign: "middle" }}>
                        <p className="fs-5" style={{ display: "inline-block", opacity: "0.4" }}>
                            Select a firm to view associated rate card
                        </p>
                    </td>
                </tr>}

                  </tbody>
                </table>
                <div>
                </div>
              </div>
              </div>
                }
          </div>
        </div>
         </div>
      </div>

  );
}

import React, { useState, useContext, useEffect } from 'react';
import { Input, InputNumber, Button, Modal ,message } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { UserContext } from "../../App";
import Services from '../../network/Services';
import konsole from "../../network/konsole";
import styles from './EditModal.module.css';

const isValidEmail = ( email ) => {
    const emailREG = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailREG.test(email);
}

const isValidMobNum = ( number ) => {
    if(number.length === 12 && number.substring(0,2) === "+1") {
        return true; // USA
    } else if(number.length === 13 && number.substring(0,3) === "+91") {
        return true; // IN
    } else return false;
}

const EditModal = ({ selectedUserDetails, refreshData }) => {
    const loggedUserId = sessionStorage.getItem("loggedUserId");
    konsole.log("kyu lodId", loggedUserId);

    const [visibility, setVisiblity] = useState(false);
    const { setLoader } = useContext(UserContext);

    const [curEmail, setCurEmail] = useState("");
    const [curMobNum, setcurMobNum] = useState("");

    useEffect(() => {
        setCurEmail(selectedUserDetails.emailId);
        setcurMobNum(selectedUserDetails.mobileNo);
        setVisiblity(Object.keys(selectedUserDetails).length);
    }, [selectedUserDetails]);

    const onSubmit = async () => {
        if(curEmail === selectedUserDetails.emailId && curMobNum === selectedUserDetails.mobileNo) {
            message.warning("You can't give same contact no. please give a different input.");
            return;
        }
        
        setLoader(true);

        if(curEmail !== selectedUserDetails.emailId) {
            if(!isValidEmail(curEmail)) {
                message.error("Please Enter Valid Email!");
                setLoader(false);
                return;
            }
        }

        if(curMobNum !== selectedUserDetails.mobileNo) {
            if(!isValidMobNum(curMobNum)) {
                message.error("Please Enter Valid Mobile Number!");
                setLoader(false);
                return;
            }
        }

        let successMsg = {
            email: 0, 
            mobNum: 0,
            msg: ""
        };

        if(curEmail !== selectedUserDetails.emailId) {
            await Services.putUserName(selectedUserDetails.emailId, curEmail, loggedUserId, "::1").then((res) => {
                successMsg.email = 1;
            }).catch((err) => {
                successMsg.email = 2;
                konsole.log("kyu err", err)
            }).finally(() => {
                konsole.log("kyu finished email update")
            })
        }

        if(curMobNum !== selectedUserDetails.mobileNo) {
            if(selectedUserDetails.primaryContactId) {
                await Services.updateContactDetails(selectedUserDetails.userId, 4, 1, curMobNum, 1, selectedUserDetails.primaryContactId, loggedUserId).then((res) => {
                    successMsg.mobNum = 1;
                }).catch((err) => {
                    successMsg.mobNum = 2;
                    konsole.log("kyu err", err)
                }).finally(() => {
                    konsole.log("kyu finished mobile update")
                })
            } else {
                await Services.postAPIForContact(selectedUserDetails.userId, 4, 1, curMobNum, 1, loggedUserId).then((res) => {
                    successMsg.mobNum = 1;
                }).catch((err) => {
                    successMsg.mobNum = 2;
                    konsole.log("kyu err", err)
                }).finally(() => {
                    konsole.log("kyu finished mobile update")
                })
            }
        }
        
        
        if(successMsg.email === 1 && successMsg.mobNum === 1) {
            successMsg.msg = "Email and phone number updated successfully!";
        } else if(successMsg.email === 1 || successMsg.mobNum === 1) {
            successMsg.msg = successMsg.email === 1 ? "Email" : "Phone number";
            successMsg.msg += " updated successfully!";
            successMsg.msg += successMsg.email === 2 ? " But not able to update email" : "";
            successMsg.msg += successMsg.mobNum === 2 ? " But not able to update phone number" : "";
        } else {
            successMsg.msg = "Sorry unable to update contact!";
        }

        setLoader(false);
        refreshData(true);
        
        if (successMsg.email === 2 || successMsg.mobNum === 2) {
            message.error(successMsg.msg);
        } else {
            message.success(successMsg.msg);
        }
    }

    return (
        <>
            <Modal
                visible={visibility}
                closable={false}
                footer={false}
                maskClosable={false}
                className="modalcssofEditmodal"
            >
                <div className={styles.moduleTitle} >
                    <div className=""></div>
                    <div className="fs-5">{selectedUserDetails.userFullName} - Edit Contact</div>

                    <div className="me-2 mt-1" style={{cursor: "pointer"}} onClick={e => refreshData(false)}>&#x2715;</div>
                </div>
                <form style={{ marginTop: '30px'}} >
                        <div className={styles.dFlex}>
                            <label className={styles.formLabel} >Email: </label>
                            <Input style={{flex: '2', margin: '5px'}} disabled={selectedUserDetails.isEmailVerified} type='email' value={curEmail} onChange={e => {setCurEmail(e.target.value)}} />
                        </div>
                        <div className={styles.dFlex}>
                            <label className={styles.formLabel} >Phone No.:</label>
                            <PhoneInput style={{flex: '3', margin: '5px'}} onlyCountries={['us', 'in']} value={curMobNum} onChange={value => {setcurMobNum("+" + value)}} countryCodeEditable={false}  />
                        </div>
                        <div className={styles.dFlex} 
                        style={{
                            justifyContent: "flex-end"
                        }}>
                            <Button className={styles.moduleButton} type="submit" onClick={onSubmit}>Update</Button>
                        </div>
                </form>
            </Modal>
        </>
    )

}

export default EditModal;
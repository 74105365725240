// import {UserAddOutlined} from '@ant-design/icons';
import {
  Modal,
  Form,
  Upload,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Card,
  Input,
  DatePicker,
  Typography,
  Item,
  message,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import config from '../../config.json'
import "../../Styles/subtenant.css";
import PrimaryContact from "./PrimaryContact";
import Services from "../../network/Services";
import { useRef } from "react";
import { UserContext } from "../../App";
import FormItem from "antd/lib/form/FormItem";
import Address from "../Address";
import commonLib from "../../control/commonLib";
import EditContactInfo from "../FirmPartner/EditContactInfo";
import ContactInfo from "./ContactInfo";
import konsole from "../../network/konsole";
import PersonalInfo from "./PersonalInfo";
import InputMask from "../PhoneInputMask";
import TextArea from "antd/lib/input/TextArea";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
import DeleteConfirmModal from "../DeleteConfirmModal";
import { ko } from "date-fns/locale";
// import ImgCrop from "antd-img-crop";
// import message from "../message"
const { Option } = Select;

const { confirm } = Modal;

const onChange = (value) => {
  konsole.log(`selected ${value}`);
};

const onSearch = (value) => {
  konsole.log("search:", value);
};


//  const options = [
//   {
//     label: "Administrator",
//     value: 11
//   },
//   {
//     label: "Speaker",
//     value: 7
//   },
//   {
//     label: "Event Coordinator",
//     value: 6
//   },
//   {
//     label: "Planner",
//     value: 8
//   },
//   {
//     label: "Paralegal",
//     value: 3
//   },
//  ];



const AddSubtenantUser = ({
  visible,
  onCancel,
  // subtenantId,
  getUserListApi,
  selectedSubtenant,
  subtenantValueSelected,
  setdisableselect,
  disabledselect,
  updatePartnerUsersData
}) => {
  const {setLoader} = useContext(UserContext)
  const [form] = Form.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [gender, setGender] = useState([]);
  const [showDeleteModalStatus, setShowDeleteModalStatus] = useState(false)
  const { Option } = Select;
  const { Title } = Typography;
  const addressRef = useRef(null);
  const [citizenship, setCitizenship] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [deletestatus, setDeletestatus] = useState();
  const formValue = form.getFieldsValue();
  const [fname, setFname] = useState("");
  const [file, setFile] = useState();
  const [countrycode, setcountryCode] = useState([]);
  const [getSubtenantRoleId, setGetSubtenantRoleId] = useState()
  const [fileList, setFileList] = useState([]);
  const [subtenantdata, setsubtenantdata] = useState([])
  const [subtenantValue, setSubtenantValue] = useState(null);
  const [subtenantId, setSubtenentId] = useState()
  const [createUserCredentials, setcreateUserCredentials] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(true)
  const [fileTypeId, setFileTypeId] = useState(15)
  const [fileCategoryId, setFileCategoryId] = useState(1)
  const [fileStatusId, setFileStatusId] = useState(2)
  const [addressAPIStatus, setAddressAPIStatus] = useState()
  const [fileLists, setfileLists] = useState()
  const [signatureData, setSignatureData] = useState()
  const handleForm = (values) => {
    addressRef.current.saved();
  };

  // const onOk = () => {
  //   onCancel();
  // };

  konsole.log("fileListfileList",fileList)
  konsole.log("configconfig", config.AOADMIN)
  konsole.log("getSubtenantRoleIdgetSubtenantRoleId", getSubtenantRoleId)

  const [getContactStatus , setGetContactStatus] = useState()

  useEffect(()=>{
    if(deletestatus == true){
      callOnFinishData(2)
    }else if(deletestatus == false){
        onCancel()

    }
  },[deletestatus])

  konsole.log("deletestatusdeletestatus",deletestatus)

  useEffect(()=>{
if(updatePartnerUsersData !== undefined && updatePartnerUsersData !== null && updatePartnerUsersData !== ""){
  let roleId = commonLib.getObjFromStorage('stateObj')?.roleId
    let subtenant = sessionStorage.getItem('subtenantId')
    if(roleId != 4){

      setdisableselect(true)
    }
  konsole.log("updatePartnerUsersDataupdatePartnerUsersData",updatePartnerUsersData)
  form.setFieldsValue({
    'email': updatePartnerUsersData?.userName,
    // 'subtenantRole' : updatePartnerUsersData?.roleKey
  })
  getContactApiFunction(updatePartnerUsersData?.userId)
  getMemberAPiFunction(updatePartnerUsersData?.userId)
  // getUserRoleFunction(updatePartnerUsersData?.userRgstrtnId, updatePartnerUsersData?.subtenantId, roleId, subtenant)
  
}
  },[updatePartnerUsersData])


  const [speakerDetailsData, setSpeakerDetailsData] = useState()
  const [speakerIdData, setSpeakerIdData] = useState()
  const SepkaerListOptionApi = (userId, roleIdData) => {
    const Details = Services.getPartnerUserSpeakerDetailsList(userId, true);
    Details.then((response) => {
      konsole.log("PartenUserSpeakerDetails", response);
      konsole.log("PartenUserSpeakerDetails", response.data?.data?.speakers[0]);
      setSpeakerDetailsData(response.data?.data?.speakers[0])
      // konsole.log("Getted", response.data.data.speakers[0].speakerUserId);
      // setuserDetails(response.data.data.speakers);

      // if(editFuncData !== null && editFuncData !== undefined && editFuncData !== "" && stopSpeakerEditFunc !== true){
        // let filterData = response.data.data.speakers.filter((x)=>{
        //   return x?.emailAddress == "simmigzoz@mailinator.com"
        // })
        form.setFieldsValue({
          'speakerLink': response.data?.data?.speakers[0]?.videoLinkPath,
          'speakerDescription': response.data?.data?.speakers[0]?.speakerProfile,
          // 'fileUpload': response.data?.data?.speakers[0]?.speakerProfileUrl
        })

konsole.log("roleIdData.includes(4)",roleIdData.includes(7), roleIdData)
if(response.data?.data?.speakers[0]?.speakerImageId !== null && response.data?.data?.speakers[0]?.speakerImageId !== undefined && response.data?.data?.speakers[0]?.speakerImageId !== "" && roleIdData.includes(7)){
  
  konsole.log("roleIdData.includes(7)",roleIdData.includes(7), roleIdData)
  setSpeakerIdData(response.data?.data?.speakers[0]?.speakerImageId)
}else{
  
  setSpeakerIdData(null)
}
if(response.data?.data?.speakers[0]?.speakerProfileUrl !== null && response.data?.data?.speakers[0]?.speakerProfileUrl !== undefined && response.data?.data?.speakers[0]?.speakerProfileUrl !=="" && roleIdData.includes(7)){
  
  konsole.log("roleIdData.includes(7)",roleIdData.includes(7), roleIdData)
          setFileList(
            [{
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: response.data?.data?.speakers[0]?.speakerProfileUrl,
            }]
            )
          }
      //   editSpeakerDataFun(filterData)
        // konsole.log("filterDatafilterData", filterData)
      // }
     
    }).catch((error) => {
      konsole.log("ErrorSpeakerList", error);
    });

  }






  const [options , setOptions] = useState([])
  const [optionsBackUp , setOptionsBackUp] = useState([])

  useEffect(()=>{
    let arr2 = ["1","4","2","5", "9", "10", "12","17","18","19","20"];
    let roleId = commonLib.getObjFromStorage('stateObj')?.roleId
    let subtenant = sessionStorage.getItem('subtenantId')
    let promise = Services.getAllUserRoleIdDetails()
    promise.then((response)=>{
      konsole.log("getAllUserRoleIdDetailsRes",response.data?.data)
      let filterData = response.data?.data.filter(element => !arr2.includes((element.value)));
      konsole.log("filterDatagetAllUserRoleIdDetailsRes",filterData)

      
      
      konsole.log("filterDatagetAllUserRoleIdDetailsRes",filterData)
      
      let filterData1 = filterData.filter((x)=>{
        if(x.value == 11){
          x.label = "Administrator"
          
        }
      })
      setOptionsBackUp(filterData)
      konsole.log("filterDatagetAllUserRoleIdDetailsRes",filterData1)
      // let filterData = response.data?.data.filter((data)=>{
      //   return data.value
      // }) 
      if(updatePartnerUsersData !== undefined && updatePartnerUsersData !== null && updatePartnerUsersData !== ""){
        getUserRoleFunction(updatePartnerUsersData?.userRgstrtnId, updatePartnerUsersData?.subtenantId, filterData, roleId, subtenant)
      }else{
        if( roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant ==config.AOADMINSUBTENANTID)){
          // alert("Super Admin")
          let filterLegalRoleData = [3,13,14,15]

        let difference = filterData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      
  
      
        }
        else if(roleId != 4 && subtenant == config.AOADMINSUBTENANTID){
          
          let filterLegalRoleData = [3,13,14,15]

        let difference = filterData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
        }
        else{
          // alert("partner admin")
          setOptions(filterData)
          setOptionsBackUp(filterData)
        }
      }
    }).catch((error)=>{
      konsole.log("getAllUserRoleIdDetailserror",error)
    }).finally(()=>{
      konsole.log("getAllUserRoleIdDetailsEnd")
    })
  },[])

const [getMemberDetailsData , setGetMemberDetailsData] = useState()
  const getMemberAPiFunction = (userId) =>{

    let promise = Services.getMemberDetails(userId)
    promise.then((response)=>{
      konsole.log("getMemberAPiFunction",response.data.data)
     
      setGetMemberDetailsData(response.data?.data?.member)
     
    }).catch((error)=>{
      konsole.log("getMemberAPiFunctionError",error)
      
    }).finally(()=>{
      konsole.log("getContactDetailsEnd")

    })

  }

  const [multiRole , setMultiRole] = useState([])
  const [multiRoleIdData , setMultiRoleIdData] = useState([])
  const getUserRoleFunction = (LoginUserId, SubtenantId, hideData, roleId, subtenant) =>{
    let promise = Services.getUserRoleDetails(LoginUserId, SubtenantId)
    promise.then((response)=>{
      konsole.log("getUserRoleFunctionres",response.data.data)
      setMultiRoleIdData(response.data.data)
      let filterData = response.data.data.map((items)=>{
        return items.roleId
      })
      konsole.log("getUserRoleFunctionres",filterData)
      SepkaerListOptionApi(updatePartnerUsersData?.userId, filterData)
      
      if(filterData.includes(10)){
        
        filterData.splice(filterData.indexOf(10), 1)
        
        konsole.log("indexValueindexValue",filterData)
      }
      
      if(filterData.includes(2)){
        
        filterData.splice(filterData.indexOf(2), 1)
        
        konsole.log("indexValueindexValue",filterData)
      }
      if(filterData.includes(1)){
        
        filterData.splice(filterData.indexOf(1), 1)
        
        konsole.log("indexValueindexValue",filterData)
      }

      if(roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant ==config.AOADMINSUBTENANTID)){
        let filterLegalRoleData = [3,4,10,13,14,15]
        konsole.log("filterDatafilterDataffaarrr",filterData)

        for(let i =0; i<filterLegalRoleData.length; i++){
            
            if(filterData.includes(parseInt(filterLegalRoleData[i]))){
              
              filterData.splice(filterData.indexOf(filterLegalRoleData[i]), 1)
              
              konsole.log("indexValueindexValueforlast",filterData)
            }
            
          }




      }else if(roleId != 4 && subtenant == config.AOADMINSUBTENANTID){

        let filterLegalRoleData = [3,4,10,13,14,15]
        konsole.log("filterDatafilterDataffaarrr",filterData)

        for(let i =0; i<filterLegalRoleData.length; i++){
            
            if(filterData.includes(parseInt(filterLegalRoleData[i]))){
              
              filterData.splice(filterData.indexOf(filterLegalRoleData[i]), 1)
              
              konsole.log("indexValueindexValueforlast",filterData)
            }
            
          }

      }


      // ..................................................................................


      let hideArrValue = [3,13,14,15]
    
      let intersection = hideArrValue.filter(x => filterData?.includes(x));
  
      if(intersection.length > 0){
        
        hideArrValue.splice(hideArrValue?.indexOf(intersection[0]), 1)
        
        for(let i = 0; i< hideArrValue.length; i++){
          
          hideData.filter((disableFields)=>{
            if(disableFields.value == hideArrValue[i]){
              disableFields['disabled'] = true
            }
            
          })
        }

        if( roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant ==config.AOADMINSUBTENANTID)){
          // alert("Super Admin")
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
      
  
      
        }
        else if(roleId != 4 && subtenant == config.AOADMINSUBTENANTID){
          
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
        }else{

          setOptions(hideData)
          setOptionsBackUp(hideData)
        }







        
        
      }else if(intersection.length <= 0){

        if( roleId == 4 && (subtenantValueSelected == config.AOADMINSUBTENANTID || subtenant ==config.AOADMINSUBTENANTID)){
          // alert("Super Admin")
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
      
  
      
        }
        else if(roleId != 4 && subtenant == config.AOADMINSUBTENANTID){
          
          let filterLegalRoleData = [3,13,14,15]

        let difference = hideData.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
      setOptionsBackUp(difference)
        }else{
          setOptions(hideData)
          setOptionsBackUp(hideData)

        }




        // setOptions(hideData)
      }







      // ..................................................................................



      
      // if(filterData.includes(7)){
        // alert("7")
        setGetSubtenantRoleId(filterData)
      // }else{
      //   // alert("no 7")
      // }
      form.setFieldsValue({
        'subtenantRole': filterData
      })
      setMultiRole(filterData)
    }).catch((error)=>{
      konsole.log("getUserRoleFunctionerror",error)
    }).finally(()=>{
      konsole.log("getUserRoleFunctionEnd")
    })
    
  }
  konsole.log("multiRolemultiRole",multiRole)

  const getContactApiFunction  = (userId) =>{
    let promise = Services.getContactDetails(userId)
    promise.then((response)=>{
      konsole.log("getContactDetails",response.data.data)
      setGetContactStatus(response.data?.data)
      let mobileNumber = response.data?.data?.contact?.mobiles[0]?.mobileNo.split("");
      let seprateCountryCode = mobileNumber.splice(mobileNumber.length - 10, 10).join("");
      form.setFieldsValue({
        'mobile' : seprateCountryCode,
        'number': mobileNumber.splice(mobileNumber.length - 10, 10).join("")
      })
      // konsole.log("getContactDetails",mobileNumber)
      // konsole.log("getContactDetails",seprateCountryCode)
      
    }).catch((error)=>{
      konsole.log("getContactDetailsError",error)
      
    }).finally(()=>{
      konsole.log("getContactDetailsEnd")

    })

  }

  konsole.log("getContactStatusgetContactStatus",subtenantValueSelected)

  const handleCancel = () => setPreviewVisible(false);
  const createdBy = null;
  const isActive = true;
  useEffect(() => {
    if(selectedSubtenant !==''){
      form.setFieldsValue({
        "subtenantName": selectedSubtenant,
      })
    }
   
   
    const Subtenantdetailspromise = Services.getsubtenantdetails(
      createdBy,
      isActive
    );
    // konsole.log("promise", Subtenantdetailspromise);
    Subtenantdetailspromise.then((res) => {
      konsole.log("ressss1111", res.data.data);
      setsubtenantdata(res.data.data);
    }).catch((err) => {
      konsole.log("err", err);
      // message.error("Enter valid details")
    });
  }, []);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (value) => {
    konsole.log(`selected aaqaqaqaq ${value}`);
    setSubtenantValue(value);
  };
  konsole.log("subtenantValue", typeof (subtenantValue), subtenantValue);

  const onChange = ({ fileList: newFileList }) => {
    if(newFileList?.length >0 ){
      const isJpgOrPng = newFileList[0].type === "image/jpeg" || newFileList[0].type === "image/png";
      if(!isJpgOrPng) return;

    }
    setFileList(newFileList);
    setFile(newFileList[0]?.originFileObj);
        konsole.log("newfilelist", newFileList[0]?.originFileObj)
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
      // message.warn("You can only upload JPG/PNG file!")
    }

    const isLt2M = file.size / 1024 / 1024 > 2;

    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!');
    // }

    return isJpgOrPng && isLt2M;
  };

  useEffect(() => {
    getCitizenship();
    getContactTypecode();
    
  }, []);

  const getCitizenship = () => {
    let getCitizenshipPromise = Services.getCitizenshipType();
    getCitizenshipPromise
      .then((res) => {
        setCitizenship(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
        // message.error("Enter valid details")
      });
  };

  const getContactTypecode = () => {
    let getContactTypepromise = Services.getContactType();
    getContactTypepromise
      .then((res) => {
        setContactType(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
        // message.error("Enter valid details")
      });
  };

  useEffect(() => {
    const countryCode = Services.getcountrycode();
    countryCode
      .then((response) => {
        setcountryCode(response.data.data);
      })
      .catch((err) => {
        konsole.log("Error", err);
        // message.error("Enter valid details")
      });
  }, []);

  const getDateData = (date, dateString) => {
    konsole.log("dateString", dateString);
  };



 konsole.log("speakerDetailsDataspeakerDetailsData",addressAPIStatus?.addressLine1)

 const callOnFinishData = (deleteRolesCount, deleteRolesStatus) =>{

  let subtenantId = sessionStorage.getItem('subtenantId')
  let createdByLocalSession = commonLib.getObjFromStorage('stateObj').userId
  let roleIdSession = commonLib.getObjFromStorage('stateObj').roleId
  // let formData = form.getFieldsValue()
  if(updatePartnerUsersData !== null && updatePartnerUsersData !== undefined && updatePartnerUsersData !== ""){
    let userIDFromSession = sessionStorage.getItem('loggedUserId')
    let formData = form.getFieldsValue()
    let mobileNumber = formData?.number + formData?.mobile 

    konsole.log("form.getFieldsValue()",formData)
    konsole.log("form.getFieldsValue()",moment(formData?.dob).format())
    // konsole.log("form.getFieldsValue()",moment(formData?.dob).format())
    
 
      
    if(deleteRolesCount == 1){
    if(updatePartnerUsersData?.primaryMobile !== mobileNumber){

      if(deleteRolesStatus !== 1){

        setLoader(true)
      }
      let promise =  Services.putContactDetails(getContactStatus?.userId, 1, getContactStatus?.contact?.mobiles[0]?.contactTypeId, mobileNumber, getContactStatus?.contact?.mobiles[0]?.contactId, userIDFromSession )
    promise.then((res)=>{
      konsole.log("putContactDetails",res)
      // message.success("Updated Successfully")
      // getUserListApi()
      // onCancel()
      // setLoader(false)
    }).catch((err)=>{
      konsole.log("putContactDetailserr",err)
      // setLoader(false)
      
    }).finally(()=>{
      // setLoader(false)
      
    })
  }

  if(addressAPIStatus?.addressLine1 !== undefined && addressAPIStatus?.addressLine1 !== null && addressAPIStatus?.addressLine1 !== "" ){

    if(addressAPIStatus?.addressLine1 !== formData?.address){

      if(deleteRolesStatus !== 1){

        setLoader(true)
      }
    let promise = Services.putAddressDetails(formData?.address, formData?.zipcode, formData?.city, formData?.state, formData?.country, addressAPIStatus?.addressTypeId, addressAPIStatus?.addressId, userIDFromSession, true)
    .then((response)=>{
      konsole.log("putAddressDetails",response)
      // message.success("Updated Successfully")
      // getUserListApi()
      // onCancel()
      // setLoader(false)
    }).catch((error)=>{
      // setLoader(false)
      konsole.log("putAddressDetailsError",error)
      
    }).finally(()=>{
      // setLoader(false)
      konsole.log("putAddressDetailsEnd")

    })

    
  }
}else{
  addressByUserId(updatePartnerUsersData?.userId, deleteRolesStatus)
}

  if(getMemberDetailsData?.fName !== formData?.fName || getMemberDetailsData?.mName !== formData?.mName || getMemberDetailsData?.lName !== formData?.lName || getMemberDetailsData?.dob !== moment(formData?.dob).format() || getMemberDetailsData?.genderId !== formData?.genderId || getMemberDetailsData?.citizenshipId !== formData?.Citizenship ){
    if(deleteRolesStatus !== 1){

      setLoader(true)
    }
    let promise = Services.putMemberDetails(subtenantId, formData?.fName, formData?.mName, formData?.lName, formData?.dob !== false? moment(formData?.dob).format() : null, formData?.Citizenship, formData?.genderId, getMemberDetailsData?.userId, userIDFromSession)
    promise.then((response)=>{
      konsole.log("putMemberDetailsRes",response)
      if(deleteRolesStatus !== 1){

        message.success("Updated Successfully")
      }
      getUserListApi(deleteRolesStatus)
      // setLoader(false)
      // onCancel()
    }).catch((error)=>{
      konsole.log("putMemberDetailsError",error)
      getUserListApi()
      // setLoader(false)
    }).finally(()=>{
      konsole.log("putMemberDetailsEnd")
      // setLoader(false)
    })

  }

  if(JSON.stringify(multiRole) !== JSON.stringify(formData?.subtenantRole)){

    for(let i = 0; i< formData?.subtenantRole.length; i++){
      
      if(deleteRolesStatus !== 1){
        
        setLoader(true)
      }
      let promise = Services.postRoleIdData(updatePartnerUsersData?.userRgstrtnId, parseInt(subtenantId), formData?.subtenantRole[i], true, userIDFromSession)
      promise.then((response)=>{
        konsole.log("postRoleIdDatares",response)
        if(i == formData?.subtenantRole.length){

          // getUserListApi()
          // setLoader(false)
        }
        // onCancel()
      }).catch((error)=>{
        konsole.log("postRoleIdDataError",error)
        // setLoader(false)
      }).finally(()=>{
        konsole.log("postRoleIdDataEnd")
        // setLoader(false)
      })
      
    }

  }

  
  


  if(speakerDetailsData !== null && speakerDetailsData !== undefined && speakerDetailsData !== "" && formData?.subtenantRole?.includes(7)){

    
    if(speakerDetailsData?.videoLinkPath !== formData?.speakerLink || speakerDetailsData?.speakerDescription !== formData?.speakerDescription){

      if(file !== undefined && file !== null && file !== ""){
        fileuploadForUpdate(updatePartnerUsersData?.userId, formData, deleteRolesStatus);

      }else{
        putSpekaeruser(speakerIdData,formData, deleteRolesStatus)
      }
    

    
      
      
      
    }
  }else if(formData?.subtenantRole?.includes(7)){

    if(file !== undefined && file !== null && file !== ""){
      fileuploadForUpdate(updatePartnerUsersData?.userId, formData, deleteRolesStatus);

    }else{
      postspekaeruser(speakerIdData,updatePartnerUsersData?.userId, formData, deleteRolesStatus)
    }



  }
  
}

if(deleteRolesCount !== 1){


  if(JSON.stringify(multiRole) !== JSON.stringify(formData?.subtenantRole)){

    setLoader(true)
    konsole.log("intersection",multiRole)
    konsole.log("intersection",formData?.subtenantRole)
    const intersection = multiRole.filter(element => !formData?.subtenantRole.includes(element));

    for(let i =0; i< intersection.length; i++){

      
      let filterData = multiRoleIdData.filter((data)=>{
        return data.roleId == intersection[i]
      })
      konsole.log("filterDatafilterData",filterData)
      
      konsole.log("intersection",intersection)
        let promise = Services.putRoleIdDetails(filterData[0]?.loginUserId, subtenantId, filterData[0]?.roleId, false, filterData[0]?.userRoleId, userIDFromSession, "")
        promise.then((response)=>{
        konsole.log("putRoleIdDetailsRes", response)
        if(i == intersection.length-1){

          message.success("Update Successfully")
          getUserListApi()

        }
        // setLoader(false)
        // onCancel()
      }).catch((error)=>{
        konsole.log("putRoleIdDetailsError",error)
        // setLoader(false)
      }).finally(()=>{
        konsole.log("putRoleIdDetailsEnd")
      })
        
    
}

  }


}

}


else{

// callAddUserApi(data);
// konsole.log('callAddUserApiPost', data)


// if(data?.subtenantRole?.includes(7)){
//   alert("yes 7")
// }else{
//   alert('no 7')
// }
    
  }
  

  // onCancel();
 }

 const [deletedRoleName, setDeletedRoleName] = useState("")
  const onFinishForm = (data) => {
    if(updatePartnerUsersData !== null && updatePartnerUsersData !== undefined && updatePartnerUsersData !== ""){
    let formData = form.getFieldsValue()
    const intersectionData = multiRole?.filter(element => !formData?.subtenantRole?.includes(element));
    konsole.log("intersectionintersection",intersectionData)

    let deleteRolesStatus = 0
    if(intersectionData?.length !== 0){
      let rolesArray = []
      for(let i = 0; i < intersectionData.length; i++){
        let filterData = multiRoleIdData.filter((x)=>{
          return x.roleId == intersectionData[i]
        })
        rolesArray.push(filterData[0]?.roleKey)
      }
      
      
      let deletedRoleToString = rolesArray.join(", ")
      konsole.log("rolesArrayrolesArray",deletedRoleToString)
      setDeletedRoleName(deletedRoleToString)

      deleteRolesStatus = 1
      setShowDeleteModalStatus(true)
      callOnFinishData(1, deleteRolesStatus)
    }
    else{

      callOnFinishData(1)
    }

    }else{
      callAddUserApi(data);
      // callOnFinishData(data)
    }
    
   
  };

// ..............................................................................................................................................







  const fileuploadForUpdate = (createdByuserId, formData, deleteRolesStatus) => {
    let upsertedloggedIn = sessionStorage.getItem('loggedUserId')

    const fileuploadCallApi = Services.postfileuploadspeaker(file, upsertedloggedIn, createdByuserId, fileTypeId, fileCategoryId, fileStatusId)
    fileuploadCallApi
      .then((res) => {
        konsole.log("responsefileupload", res)
        // setspeakerImageId(res.data.data.fileId)
        konsole.log('response',res.data.data.fileId)
        if(speakerDetailsData !== null && speakerDetailsData !== undefined && speakerDetailsData !== ""){
          putSpekaeruser(res.data.data.fileId, formData, deleteRolesStatus)

        }else{
          postspekaeruser(res.data.data.fileId,createdByuserId, formData , deleteRolesStatus)
        }

      })
      .catch((error) => {
        konsole.error("errrr", error)
      }).finally(()=>{
        // setLoader(false)
      })



  }


  const putSpekaeruser=(speakerImageId,formData, deleteRolesStatus)=>{
    // const checkingApi = (SpeakerDetailsUserId,speakerImageId) => {
      let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
  
  
      const putSpeakerupsertCallApi = Services.postspeakerupsertuser(speakerDetailsData?.speakerUserId, updatePartnerUsersData?.userId, formData?.speakerDescription, formData?.speakerLink, speakerImageId, "Done", true,upsertedloggedIn)
      putSpeakerupsertCallApi
        .then((res) => {
          konsole.log("responsepostspeakerupsertCallApi",res)
          // message.success("Updated Successfully")
        
          // sendSpeakerListApi();
  
          // hideModal(false);
          // form.resetFields();
          // setLoader(false)
        // openNotification('topRight', res.status)
  
        })
        .catch((error) => {
          konsole.log("upsertError", error)
        }).finally(()=>{
          // setLoader(false)
        })
    }









  const fileupload = (createdByuserId,datauserid, data) => {
    let upsertedloggedIn = sessionStorage.getItem('loggedUserId')

    const fileuploadCallApi = Services.postfileuploadspeaker(file, upsertedloggedIn, createdByuserId, fileTypeId, fileCategoryId, fileStatusId)
    fileuploadCallApi
      .then((res) => {
        konsole.log("responsefileupload", res)
        // setspeakerImageId(res.data.data.fileId)
        konsole.log('response',res.data.data.fileId)
        postspekaeruser(res.data.data.fileId,datauserid, data)

      })
      .catch((error) => {
        konsole.error("errrr", error)
      }).finally(()=>{
        // setLoader(false)
      })



  }


  const postspekaeruser=(speakerImageId,createdByuserId, data)=>{
    // const checkingApi = (SpeakerDetailsUserId,speakerImageId) => {
      let upsertedloggedIn = sessionStorage.getItem('loggedUserId')
  
  
      const postspeakerupsertCallApi = Services.postspeakerupsertuser(0, createdByuserId, data.speakerDescription, data.speakerLink, speakerImageId, "Done", true,upsertedloggedIn)
      postspeakerupsertCallApi
        .then((res) => {
          konsole.log("responsepostspeakerupsertCallApi",res)
        
          // sendSpeakerListApi();
  
          // hideModal(false);
          // form.resetFields();
          // setLoader(false)
        // openNotification('topRight', res.status)
  
        })
        .catch((error) => {
          konsole.log("upsertError", error)
        }).finally(()=>{
          // setLoader(false)
        })
    }




  // ..........................................................................................................................................

  const multiRoleAPIFunction = (loginUserId, createdBy, roleId) =>{
    let subtenantId = sessionStorage.getItem('subtenantId')
    setLoader(true)
        let promise = Services.postRoleIdData(loginUserId, subtenantId, roleId, true, createdBy)
        promise.then((response)=>{
          konsole.log("postRoleIdDataresAdd",response)
          getUserListApi()
          // setLoader(false)
          // onCancel()
        }).catch((error)=>{
          konsole.log("postRoleIdDataError",error)
          // setLoader(false)
        }).finally(()=>{
          konsole.log("postRoleIdDataEnd")
          // setLoader(false)
        })

  }



  const callAddUserApi = (data) => {
    // form.submit();
    const formdata = form.getFieldsValue();
    konsole.log("formdataformdata.subtenantRole", formdata.subtenantRole);
    const userId = sessionStorage.getItem("loggedUserId");
    
    const subtenantId = subtenantdata.filter((data, index) => {return data.subtenantName == formdata.subtenantName})[0]?.subtenantId
    konsole.log("formdataformdata.subtenantRole", formdata.subtenantRole, subtenantId, data);

    // const jsonObjData = {
    //   subtenantId: subtenantId,
    //   signUpPlateform: 0,
    //   createUserCredentials: true,
    //   createdBy: "string",

    //   user: {
    //     roleId: formdata.subtenantRole,
    //     firstName: formdata.fName,
    //     middleName: formdata.mName,
    //     lastName: formdata.lName,
    //     dob: formdata.dob,
    //     genderId: formdata.genderId,
    //     citizenshipId: formdata.Citizenship,
    //     createUserCredentials: true,
    //     contacts: {
    //       mobiles: [
    //         {
    //           contactTypeId: 1,
    //           mobileNo: formdata.mobile,
    //           createdBy: userId,
    //         },
    //       ],
    //       emails: [
    //         {
    //           contactTypeId: 1,
    //           emailId: formdata.email,
    //           createdBy: userId,
    //         },
    //       ],
    //     },
    //     address: [
    //       {
    //         lattitude: "string",
    //         longitude: "string",
    //         addressLine1: formdata.address,
    //         addressLine2: "string",
    //         addressLine3: "string",
    //         zipcode: formdata.zipcode,
    //         county: formdata.country,
    //         city: formdata.city,
    //         state: formdata.state,
    //         country: formdata.country,
    //         addressTypeId: 1,
    //         createdBy: userId,
    //       },
    //     ],
    //     createdBy: userId,
    //   },
    // };

    setLoader(true)
    const jsonObjData = {
      subtenantId: subtenantId,
      signUpPlateform: config.AOADMIN,
      createUserCredentials: createUserCredentials,
      createdBy: userId,
      user: {
        roleId: formdata.subtenantRole[0],
        firstName: formdata.fName,
        lastName: formdata.lName,
        emailAddress: formdata.email,
        userName: formdata.email,
        countryCode: subtenantValue,
        mobileNumber: formdata.mobile,
        // password: "stringst",
        packageCode: null,
        activateUser: false,
        autoConfirmEmail: false,
        autoConfirmMobile: false
      }
    }
    konsole.log("jsonDatajsonData", jsonObjData)
    konsole.log("date", moment(formdata.dob).format())
    PostNewUser(jsonObjData , data);
  };

  const PostNewUser = (jsonObjData, data) => {
    // let postAddUserApiCall = Services.postAddUser(jsonObjData);
    let postAddUserApiCall = Services.subtenantCreateUser(jsonObjData)
    postAddUserApiCall
      .then((res) => {
        konsole.log("responseresponseaddress", res);
         message.success("PartnerUser created Successfully")

         if(data?.subtenantRole.length >1){
    for(let i =1; i <data?.subtenantRole.length ; i++){

      multiRoleAPIFunction(res.data?.data?.id, res.data?.data?.createdBy, data?.subtenantRole[i])
    }
   
  }
         
         if(data?.subtenantRole?.includes(7)){
          if(file !== undefined && file !== null && file !== ""){
            fileupload(res.data.data.createdBy,res.data.data.userId, data);

          }else{
            postspekaeruser(null,res.data.data.userId, data)
          }
          }
        postmemberdoblastname(res.data.data.userId )
        
      })
      .catch((error) => {
        konsole.log("err", error.response);
        // if (error.response.data.statusCode == 409){
        //   message.info('The user is already registered with us. Please assign new roles.' )
        // }
        if(error.response.data.messages[0] == "ALREADY_REGISTERED"){
          message.error(error.response.data.messages[1])
        }
        setLoader(false)
      }).finally(()=>{
        setLoader(false)

      })
  };




  const postmemberdoblastname = (userId) => {
    const formdata = form.getFieldsValue();
    // konsole.log("formdata", formdata, formdata.subtenantRole);
    const updatedBy = sessionStorage.getItem("loggedUserId");
    const subtenantId = subtenantdata.filter((data, index) => { return data.subtenantName == formdata.subtenantName })[0]?.subtenantId
    let jsondata = {
      subtenantId: subtenantId,
      fName: formdata.fName,
      mName: formdata.mName,
      lName: formdata.lName,
      dob:  (formdata.dob !== null && formdata.dob !== undefined && formdata.dob !== "") ? moment(formdata.dob).format() : "",
      citizenshipId: formdata.Citizenship,
      genderId: formdata.genderId,
      userId: userId,
      updatedBy: updatedBy

    }
  konsole.log("jsondatajsondatajsondatajsondata", jsondata)

    let apidobmember = Services.subtenantmemberbyuserid(jsondata)
    apidobmember.then((res) => {

      konsole.log("responseresponseaddress", res)
      getUserListApi();
      addressByUserId(userId);
    }).catch((err) => {
      konsole.log("errerr", err)
      // message.error("Enter valid details")
      setLoader(false)
      
    }).finally(()=>{
      setLoader(false)
      
    })

  }

  const addressByUserId = (userId, deleteRolesStatus) => {
    const formdata = form.getFieldsValue();
    konsole.log("formdata", formdata, formdata.subtenantRole);
    const createdBy = sessionStorage.getItem("loggedUserId");

    let addressjson = {
      userId: userId,
      address: {
        lattitude: "string",
        longitude: "string",
        addressLine1: formdata.address,
        addressLine2: "string",
        addressLine3: "string",
        zipcode: formdata.zipcode,
        county: formdata.country,
        city: formdata.city,
        state: formdata.state,
        country: formdata.country,
        addressTypeId: 1,
        createdBy: createdBy,
      }
    }

    konsole.log("responseresponse", addressjson)

    let postaddress = Services.addAddressByuserId(addressjson)
    postaddress.then((res) => {
      konsole.log("responseresponseaddress", res)
    
      if(deleteRolesStatus !== 1){

        
      getUserListApi();
      // form.resetFields();
      onCancel();
      // setLoader(false)
    }
    }).catch((err) => {
      konsole.log("errassress", err)
      message.error("Enter valid details")
      setLoader(false)
      
    }).finally(()=>{
      // setLoader(false)

    })

  }


  const onSelectLifePointLaw = (e, key) => {
    let filterLegalRoleData = [3,13,14,15]
    konsole.log("eeeee", e, key.key)
    let subtenantValue = key.key
    form.setFieldsValue({
      'subtenantRole': undefined
    })
    
    if(subtenantValue == config.AOADMINSUBTENANTID){
      // alert("DataError")
      let difference = options.filter(x => !filterLegalRoleData.includes(parseInt(x.value)));
      konsole.log("differencedifference",difference)
      setOptions(difference)
  
      }else{
        setOptions(optionsBackUp)
      }
      setSubtenentId(key.key)
    }
    konsole.log("filterLegalRoleDatafilterLegalRoleData",options)
  
  const onChangePhone = (value) => {
    // konsole.log("value",value);
    form.setFieldsValue({
      mobile: value,
    });
  };

 

  const modalhandleOk = () => {
    setIsModalVisible(true)
  }

  const modalhandleCancel = () => {
    setIsModalVisible(false)
  }

  konsole.log("aaaassssss",form.getFieldValue().subtenantRole)

  

  const getSubtenantId = (e) =>{
    konsole.log("getSubtenantIdgetSubtenantId",e)
    // konsole.log("getSubtenantIdgetSubtenantId",e.target.value)
    setGetSubtenantRoleId(e)

    let hideArrValue = [3,13,14,15]
    
    let intersection = hideArrValue.filter(x => e.includes(x));

    if(intersection.length > 0){

      hideArrValue.splice(hideArrValue.indexOf(intersection[0]), 1)

      for(let i = 0; i< hideArrValue.length; i++){
        
        options.filter((disableFields)=>{
          if(disableFields.value == hideArrValue[i]){
            disableFields['disabled'] = true
          }
         
        })
      }
      
      
    }else{
      for(let i = 0; i< hideArrValue.length; i++){
        
        options.filter((disableFields)=>{
          if(disableFields.value == hideArrValue[i]){
            disableFields['disabled'] = false
          }
         
        })
      }

    }
      
   
    
  
  }


  konsole.log("filefilefile",file)

  return (
    <>

      <Modal
        visible={visible}
        okText="Add"
        onCancel={onCancel}
        footer={null}
        closable={true}
        centered={true}
        maskClosable={false}
      >
        <Form
          form={form}
          name="AdduserForm"
          style={{ width: "100%",marginTop:"30px",paddingRight:"5px" ,maxHeight: 'calc(100vh - 100px)',
          overflowY: 'auto'}}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 0 }}
          className="w-100"
          layout="inline"
          onFinish={onFinishForm}
          
        >
          <Row lg={24} className="w-100 d-flex justify-content-between">
            <Col span={6} xs={10} md={6} lg={6} >
              <Form.Item name="fileUpload">

              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                >
                {fileList.length < 1 && "+ Upload"}
              </Upload>
                </Form.Item>
            </Col>

            <Col span={18} xs={24} md={18} lg={18}  style={{ marginTop: "70px"}} className="w-100" >

              {/* <Row className="mb-1">
                <Col lg={24} style={{ float: "right" }}>
                  <Form.Item>
                    <Checkbox
                      className="ms-2 fs-6 fw-bold"
                      style={{ color: "#720C20", fontFamily: "sans-serif", float: "right" }}

                      onChange={(e) => setcreateUserCredentials(e.target.checked)}
                    >
                      Create User Credentials
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row className="w-100 mb-2">

                <Col xs={24} md={12} lg={12} >
                  <Form.Item
                    name="subtenantName"
                    rules={[
                      {

                        required: true,
                        message: "Please select Firm",
                      },
                    ]}
                  >
                    <Select
                    disabled={disabledselect}
                      // defaultValue="Life Point Law"
                      placeholder="Firm"
                      className="fs-7 "
                      dropdownMatchSelectWidth={false}
                      onSelect={onSelectLifePointLaw}
                    >
                      {subtenantdata.map((dataprop) => (
                        <Option
                          value={dataprop.subtenantName}
                          key={dataprop.subtenantId}
                        >
                          {dataprop.subtenantName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                </Col>

                <Col span={12} xs={24} md={12} lg={12}  className="selectoffirmpartner">
                  <Form.Item
                    name="subtenantRole"

                    rules={[
                      {

                        required: true,
                        message: "Please select role",
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      placeholder="Role"
                      optionFilterProp="children"
                      // defaultValue={multiRole}
                      // value={multiRole}
                      maxTagCount={'responsive'}
                      showArrow
                      allowClear
                      id="selectoffirmpartner"
                      className="fs-7 select-multi-select " 
                      onChange={getSubtenantId}
                      // options={options}
                    >
                    {
                      options?.map((items)=>{
                        // konsole.log("adadasdsdwqeqwdsadasd",items)
                        return (

                          <>
                        <Option value={parseInt(items?.value)} disabled={(items?.disabled)? items?.disabled : false } >{items?.label}</Option>
                        </>
                          )
                      })
                    }
                      

                    </Select>
                  </Form.Item>
                </Col>

              </Row>
            </Col>
          </Row>
          <div>
            <Row
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 0 }}
              className="w-100"
              layout="inline"
            >
              <PersonalInfo  form={form} updatePartnerUsersData={updatePartnerUsersData} getMemberDetailsData={getMemberDetailsData}/>
              <card
                className="w-100 mt-1"
                style={{
                  border: "1px dashed black",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                <Title level={5} style={{ color: "#720C20" }}>
                  Contact Address
                </Title>
                <Address form={form} updatePartnerUsersData={updatePartnerUsersData} setAddressAPIStatus={setAddressAPIStatus}/>
              </card>
            </Row>

            <div>
              <Card
                className=" w-100 mt-1"
                style={{ border: "1px dashed black", borderRadius: "5px" }}
              >
                <p className="fw-bold" style={{ color: "#720C20" }}>
                  Contact Information
                </p>
                <Row
                  className="mt-2 p-2 justify-content-around"
                  style={{ border: "ridge", borderRadius: "10px" }}
                >
                  <Col className="mt-2" lg={12} xs={24} span={12}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please enter your E-mail",
                        },
                      ]}
                      
                    >
                      <Input placeholder="Email" disabled={(updatePartnerUsersData !== undefined && updatePartnerUsersData !== null && updatePartnerUsersData !== "")? true : false} />
                    </Form.Item>
                  </Col>
                  <Col className="mt-2 ms-2" lg={4} xs={24} span={3}>
                    <Form.Item
                      name="number"
                      rules={[
                        {
                          required: true,
                          message: "Please select your country code",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Code"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onSearch={onSearch}
                        style={{ width: "113px" }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {countrycode.map((countryProp) =>
                        (
                          <Option value={countryProp?.value}>
                            {countryProp?.label}

                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="mt-2 mobileInputContactWidth " lg={7} xs={24} span={8} >
                    <Form.Item
                      name="mobile"
                      
                      rules={[
                        {
                          required: true,
                          message: "Please enter your number",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please input valid  Cell number",
                        },
                        {
                          pattern: /^[\d]{0,10}$/,
                          message: "Value should be less than 10 character",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Mobile no." /> */}
                      <InputMask value={form.getFieldsValue().mobile} onValueChange={onChangePhone} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </div>
            {
          getSubtenantRoleId?.includes(7) &&

              <div>
              <Card
                className=" w-100 mt-1"
                style={{ border: "1px dashed black", borderRadius: "5px" }}
              >
                <p className="fw-bold" style={{ color: "#720C20" }}>
                  About
                </p>
                <Row
                  className="mt-2 p-2"
                  style={{ border: "ridge", borderRadius: "10px" }}
                >
                  <Col className="mt-2" lg={24} xs={24} span={24}>
                    <Form.Item
                      name="speakerLink"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Speaker Link",
                        },
                      ]}
                    >
                      {/* <Select
                        placeholder="code"
                        optionFilterProp="children"
                        onChange={handleChange}
                        onSearch={onSearch}
                        style={{ width: "80px" }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {countrycode.map((countryProp) => (
                          <Option value={countryProp.value}>
                            {countryProp.title}
                          </Option>
                        ))}
                      </Select> */}
                      <Input placeholder="Enter Link"  />
                    </Form.Item>
                  </Col>
                  <Col className="mt-2" lg={24} xs={24} span={24}>
                    <Form.Item
                      name="speakerDescription"
                      rules={[
                        {
                          
                          required: true,
                          message: "Please Enter Speaker Description!",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Speaker Description" /> */}
                      <TextArea placeholder="Speaker Description"  rows={4}  />
                    </Form.Item>
                  </Col>
                  
                  {/* <Col className="mt-2" lg={8} xs={24} span={8}>
                    <Form.Item

                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your number",
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Please input valid number",
                        },
                        {
                          pattern: /^[\d]{0,10}$/,
                          message: "Value should be less than 10 character",
                        },
                      ]}
                    > */}
                      {/* <Input placeholder="Mobile no." /> */}
                      {/* <InputMask placeholder="Mobile no." value={form.getFieldsValue().mobile} onValueChange={onChangePhone} />
                      
                    </Form.Item>
                  </Col> */}
                </Row>
              </Card>
            </div>
            }
          </div>
          <Row className="mt-3 w-100">
            <Col className="w-100 d-flex justify-content-between">
              <Button
                className="fw-bold"
                onClick={onCancel}
                style={{
                  color: "#720C20",
                  borderColor: "#720C20",
                  borderRadius: 5,
                }}
              >
                Cancel
              </Button>
              <Button
                className="fw-bold"
                htmlType="submit"
                style={{
                  background: "#720C20",
                  color: "white",
                  borderRadius: 5,
                }}
              >
                {
                  (updatePartnerUsersData !== null && updatePartnerUsersData !== undefined && updatePartnerUsersData !== "" )?
                   "Update" 
                  :
                  "Save"
                }
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
      {
        showDeleteModalStatus == true && <DeleteConfirmModal visible={showDeleteModalStatus} hideModal={setShowDeleteModalStatus} titleText="Delete Role" bodyContent={`Do you want to delete "${deletedRoleName}" ${deletedRoleName.split(",").length ==1? "role" : "roles" } for this user?`} deleteStatus={setDeletestatus} />
      }
    </>
  );
};

export default AddSubtenantUser;

import React from "react";
import { useState } from "react";
import Services from "../../network/Services";
import AddRateCardPartner from "./AddRateCardPartner";
// import { Col, Row, Modal, Divider, Input, Select, Space, Button, Form, Radio } from 'antd';
import {
  Button,
  Modal,
  Container,
  Row,
  Col,
  Form,
  Accordion,
} from "react-bootstrap";
import "./Manage.css";
import konsole from "../../network/konsole";
import { useEffect } from "react";
import commonLib from "../../control/commonLib";

function AddRateCard(props) {
  const [selectSubtenant, setSelectSubtenant] = useState(false);
  const [selectSubtenantRate, setSelectSubtenantRate] = useState(false)

  const [selectedSKU, setSelectedSKU] = useState("");
  const [validated, setValidated] = useState(false);
  const [loginUserID, setloginUserID] = useState("");

  useEffect(() => {
    const loginUserId = commonLib.getSessionStoarge("loggedUserId");
    setloginUserID(loginUserId);
    konsole.log("loginUserId", loginUserId);
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  // const [form] = Form.useForm();
  const closeModal = () => {
    props.setAddShowModal(false);
  };

  const addRateCard = () => {
    const rateCardJson = [
      {
        rateCardId: 0,
        skuListId: 1,
        slabStartingNo: 3,
        slabEndingNo: 4,
        minPriceSku: 22,
        currency: "USD",
        stCeilingPrice: 220,
        isActive: true,
        upsertedBy: loginUserID,
      },
    ];
    const promise = Services.postToAddRateCard(rateCardJson);
    promise
      .then((res) => {
        konsole.log("res", res);
        appSubtForRateCard(res.data.data[0].rateCardId);
      })
      .catch((err) => {
        konsole.log("error", err);
      });
  };
  const SelectSubtenantRatepoint = () => {
    setSelectSubtenantRate(true)
    // console.log("selectSubtenantRate ", selectSubtenantRate)
    console.log("Yes")
  };

  const SelectSubtenantRatepointter = () => {
    setSelectSubtenantRate(false)
    console.log("No")


  };

  console.log("selectSubtenantRate ", selectSubtenantRate)
  const appSubtForRateCard = (rateCardId) => {
    const addApplSubtForRateCrdJson = [
      {
        subtenantRateCardId: 0,
        skuSubsModelId: 1,
        rateCardId: rateCardId,
        subsModelId: 0,
        endSubscriptionAmt: 20,
        currency: "USD",
        isDisAllowed: true,
        subtenantId: 5,
        skuListId: 3,
        validityPeriod: "1 year",
        validityPeriodDuration: "1 year",
        validityFromDate: "2023-02-03T06:13:13.954Z",
        validityToDate: "2023-02-03T06:13:13.954Z",
        marginAmt: 20,
        marginPercent: 20,
        isActive: true,
        upsertedBy: loginUserID,
      },
    ];
    const promise = Services.postAppSubtForRateCard(addApplSubtForRateCrdJson);
    promise
      .then((res) => {
        konsole.log("res12", res);
      })
      .catch((err) => {
        konsole.log("error12", err);
      });
  };
  // const addRateCard = () =>{
  //    form.resetFields()
  // }
  let array2 = [
    {
      id: 1,
      label: "RadioOne",
    },
    {
      id: 2,
      label: "RadioTwo",
    },
    {
      id: 3,
      label: "RadioThree",
    },
  ];
  return (
    <>
    
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="Modal-Main-Class"
        centered
        show={props?.AddShowModal}
        onHide={closeModal}
      //   footer={false}
      >
        <Modal.Header closeButton className="modalHeader" closeVariant="white">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex justify-content-center"
          >
            <div>Add Rate Card</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Form.Group className="mb-3 modal-title-vcenter">
                    <Form.Label>Slabs Froms</Form.Label>
                    <Form.Control required placeholder="" />
                    <Form.Control.Feedback type="invalid">

                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Group className="mb-3 modal-title-vcenter">
                    <Form.Label>Slabs To</Form.Label>
                    <Form.Control required placeholder="" />
                    <Form.Control.Feedback type="invalid">
                      Slabs To
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Group className="mb-3 modal-title-vcenter">
                    <Form.Label> Partner Min Price</Form.Label>
                    <Form.Control required placeholder="$" />
                    <Form.Control.Feedback type="invalid">
                    Partner Min Price
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* <Col xs={12} md={3} lg={3} className=" d-flex">
                  <Form.Group className="mb-3   modal-title-vcenter">
                    <Form.Label>Partner Min Price</Form.Label>
                    <Form.Control required placeholder="$" />
                    <Form.Control.Feedback type="invalid">
                    Partner Min Price
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                <Col xs={12} md={3} lg={3}>
                  <Form.Group className="mb-3   modal-title-vcenter">
                    <Form.Label>Slabs To</Form.Label>
                    <Form.Control required placeholder="$" />
                    <Form.Control.Feedback type="invalid">
                      Slabs To
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

              </Row>
              <Row className="mt-2">
                <Col xs={12} md={8} lg={4}>
                  <Form.Label>Generate Rate Card for</Form.Label>
                  <Form.Check
                    reverse
                    label="All Subtenants"
                    name="group1"
                    type="radio"
                    onChange={() => setSelectSubtenant(false)}
                  // id={`reverse-${type}-1`}
                  />
                  <Form.Check
                    reverse
                    label="Specific Subtenants"
                    name="group1"
                    type="radio"
                    onChange={() => setSelectSubtenant(true)}
                  // id={`reverse-${type}-1`}
                  />
                </Col>
              </Row>
              <Row>

              </Row>

              <Row>

                {selectSubtenant == true ? (
                  
                  <Col className="mt-3" xs={12} md={12} lg={12}>
                  
                  <p> Choose Subtenants</p>
                   
                    <Accordion className="mb-3" id="primaryButton2">
                      <Accordion.Item eventKey="0" id="acitm">
                     
                        <Accordion.Header id="accheader">
                          {selectedSKU !== "" ? selectedSKU : "Second"}
                        </Accordion.Header>
                        {array2.map((item) => (
                          <Accordion.Body className="map_Div1">
                            <div className="map_Div">
                              <>
                                <div className="ms-3">
                                  <input
                                    name="someName"
                                    key={item.id}
                                    id={item.id}
                                    value={item.label}
                                    type="checkbox"
                                  // onChange={(e) => selectRadioOption(e)}
                                  />
                                  <label className="ms-3" htmlFor={item.id}>
                                    {item.label}
                                  </label>
                                </div>
                                <hr className="hrr" />
                              </>
                            </div>
                          </Accordion.Body>
                        ))}
                      </Accordion.Item>
                    </Accordion>
                    {/* <Form.Group>
      <Form.Select aria-label="Default select example">
      <option>Select Subtenant</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
      </Form.Group> */}
                  </Col>
                ) : (
                  ""
                )}

              </Row>
              {/* <Row>
                <Col xs={12} md={6} lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Min Price</Form.Label>
                    <Form.Control placeholder="Min Price" required />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Min Price
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Subtenant Max Price</Form.Label>
                    <Form.Control placeholder="Subtenant Max Price" required />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Subtenant Max Price
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Discount</Form.Label>
                    <Form.Select aria-label="Default select example" required>
                      <option>Discount</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Discount
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row> */}

              <Row className="mt-3" style={{ border: '2px dotted gray' }}>
                <Col xs={12} md={12} lg={12} >
                  Would you like to have same validity period for selected partners.
                </Col>
                <Col>
                  <Form>
                    {[, 'radio'].map((type) => (
                      <div key={`inline-${type}`} className="m-2">
                        <Form.Check
                          inline
                          label="Yes"
                          name="group1"
                          type={type}
                          id={`inline-${type}-Yes`}
                          onClick={SelectSubtenantRatepoint==1}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="group1"
                          type={type}
                          id={`inline-${type}-No`}
                          onClick={SelectSubtenantRatepointter==1}
                        />

                      </div>
                    ))}
                  </Form>
                </Col>
                {/*  */}
                <Col xs={12} md={12} lg={12} >
                  Would you like to have same validity starting time for selected partners.
                </Col>
                <Col>
                  <Form>
                    {[, 'radio'].map((type) => (
                      <div key={`inline-${type}`} className="m-2">
                        <Form.Check
                          inline
                          label="Yes"
                          name="group1"
                          type={type}
                          onClick={SelectSubtenantRatepoint==2}
                          id={`inline-${type}-Yes`}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="group1"
                          type={type}
                          onClick={SelectSubtenantRatepointter==2}
                          id={`inline-${type}-No`}
                        />

                      </div>
                    ))}
                  </Form>
                </Col>
                {/*  */}
                <Col xs={12} md={12} lg={12} className="mb-2">
                  Would you like to have discount for selected partners.
                </Col>
                <Col>
                  <Form>
                    {[, 'radio'].map((type) => (
                      <div key={`inline-${type}`} className="m-2">
                        <Form.Check
                          inline
                          label="Yes"
                          name="group1"
                          type={type}
                          onClick={SelectSubtenantRatepoint==3}
                          id={`inline-${type}-Yes`}
                        />
                        <Form.Check
                          inline
                          label="No"
                          name="group1"
                          type={type}
                          onClick={SelectSubtenantRatepointter==3}
                          id={`inline-${type}-No`}
                        />

                      </div>
                    ))}
                  </Form>
                </Col>
                <Row className="mb-2" style={{justifyContent:"space-around"}}>
                {selectSubtenantRate== true ?<>
                  <Col sm={12} md={4} lg={4}>
                  Discount
                    <Form.Select aria-label="Default select example">
                      <option></option>
                      <option value="1">One</option>
                      <option value="2">Two</option>

                    </Form.Select></Col>
                    </>:''}
                  {SelectSubtenantRatepoint ==1 ?<>
                  <Col sm={12} md={4} lg={4}>
                  Validity Start Date
                    <input type="date" className="p-1 pe-5 "></input></Col>
                    </>:""}
                    {SelectSubtenantRatepoint ==2 ?<>
                  <Col sm={12} md={4} lg={4}>
                  Validity Period
                    <Form.Select aria-label="Default select example">
                      <option></option>
                      <option value="1">One</option>
                      <option value="2">Two</option>

                    </Form.Select>
                    </Col>
                    </>:""}


                </Row>
              </Row>

              {selectSubtenantRate == 3 ? (<AddRateCardPartner />) : <>
                <Row>
                  <Col className="mt-3 d-flex justify-content-center">
                    <Button type="submit" className="modalHeader" onClick={addRateCard}>
                      Next
                    </Button>
                  </Col>
                </Row>
              </>}

            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>

    //    <Modal
    //         centered={true}
    //         className="Modal-Main-Class"
    //         title={<div className='d-flex justify-content-center'>Add Rate Card</div>}
    //         // visible={props?.AddShowModal}
    //         footer={false}
    //         onCancel={closeModal}
    //         closeIcon={<div className='text-light'> X</div>}
    //         maskClosable={false}

    //     >

    //         <Row >
    //             <Col span={24}>
    //                 <Form form={form}>
    //                     <Row>
    //                         <Col span={7} xs={24} sm={24} md={7} lg={7} >
    //                             <h6>Slabs From</h6>
    //                             <Form.Item
    //                                 name="SlabsFrom"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please Enter Slabs From"
    //                                     }
    //                                 ]}>

    //                                 <Input placeholder="Slabs From"/>

    //                             </Form.Item>

    //                         </Col>
    //                         <Col className='ms-3' span={7} xs={24} sm={24} md={7} lg={7}>
    //                             <h6>Slabs To</h6>
    //                             <Form.Item
    //                                 name="SlabsTo"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please Enter Slabs To"
    //                                     }
    //                                 ]}>
    //                                 <Input size='sm'
    //                                     listHeight={100}
    //                                     style={{ width: "254px" }}
    //                                     placeholder="Slabs To"
    //                                 />

    //                                 </Form.Item>
    //                         </Col>
    //                         <Col span={8} xs={24} md={7} lg={7} className="ms-5">
    //                             <h6>Validity Period</h6>
    //                             <Form.Item
    //                                 name="ValidityPeriod"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please select Validity Period"
    //                                     }
    //                                 ]}
    //                                 // className="asignRoleTo-col m-0 pb-2"

    //                             >
    //                                 {/* props?.selectedRowData?.commMediumRoles */}
    // <Select
    //     showSearch
    //     listHeight={100}
    //     // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
    //     style={{
    //         width: '100%',
    //     }}
    //     mode="multiple"
    //     maxTagCount={'responsive'}
    //     showArrow
    //     placeholder="Select Validity Period"

    // >
    // </Select>
    //                             </Form.Item>

    //                         </Col>

    //                     </Row>

    //                     <Row className='mt-1 gap-3'>

    //                         <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
    //                             <h6>Min Price</h6>
    //                             <Form.Item
    //                                 name="MinPrice"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please Enter Min Price"
    //                                     }
    //                                 ]}
    //                                 className="asignRoleTo-col m-0 pb-4"
    //                             >

    //                                 <Input
    //                                     // defaultValue={() => ToRoleNameFuction(props?.selectedRowData?.commMediumRoles)}
    //                                     showSearch
    //                                     style={{ width: "100%" }}
    //                                     listHeight={100}
    //                                     maxTagCount={'responsive'}
    //                                     mode="multiple"
    //                                     showArrow
    //                                     prefix="$"
    //                                     placeholder="Min Price"/>
    //                             </Form.Item>
    //                         </Col>
    //                         <Col span={7} xs={24} md={7} lg={7} className="asignRoleTo-col m-0 p-0">
    //                             <h6>Subtenant Max Price</h6>
    //                             <Form.Item
    //                                 name="SubtenentMaxPrice"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please Enter Subtenant Max Price"
    //                                     }
    //                                 ]}
    //                             >

    //                                 <Input
    //                                     // defaultValue={() => ToRoleNameFuction(props?.selectedRowData?.commMediumRoles)}
    //                                     showSearch
    //                                     style={{ width: "100%" }}
    //                                     listHeight={100}
    //                                     maxTagCount={'responsive'}
    //                                     mode="multiple"
    //                                     showArrow
    //                                     prefix="$"
    //                                     placeholder="Subtenant Max Price"/>
    //                             </Form.Item>
    //                         </Col>
    //                     </Row>
    //                     <Row className="ms-2">
    //                         <Col span={6} xs={24} md={6} lg={6}>
    //                            <div>
    //                             <p>Generate Rate Card For</p>
    //                             <Radio.Group>
    //                                 <Radio  value={1} onChange={()=>setSelectSubtenant(false)}>
    //                                    All Subtenants
    //                                </Radio>
    //                                <Radio  value={2} onChange={()=>setSelectSubtenant(true)}>
    //                                    Specific Subtenants
    //                                </Radio>
    //                                </Radio.Group>
    //                                </div>

    //                         </Col>
    //                         {selectSubtenant ==true ? <Col span={24} xs={24} md={24} lg={24} className="asignRoleTo-col mt-4 p-0">
    //                             <h6>Choose Subtenants</h6>
    //                             <Form.Item
    //                                 name="ValidityPeriod"
    //                                 rules={[
    //                                     {
    //                                         required: true,
    //                                         message: "Please select Validity Period"
    //                                     }
    //                                 ]}
    //                                 // className="asignRoleTo-col m-0 pb-2"

    //                             >
    //                                 {/* props?.selectedRowData?.commMediumRoles */}
    //                                 <Select
    //                                     showSearch
    //                                     listHeight={100}
    //                                     // defaultValue={() => fromRoleNameFuction(GetCommMediumData[0]?.commMediumRoles)}
    //                                     style={{
    //                                         width: '100%',
    //                                     }}
    //                                     mode="multiple"
    //                                     maxTagCount={'responsive'}
    //                                     showArrow
    //                                     placeholder="Select Subtenant"

    //                                 >
    //                                 </Select>
    //                             </Form.Item>
    //                         </Col> : ""}
    //                     </Row>
    //                     <Row>
    //                         <Col span={14}><div className=" d-flex justify-content-end">
    //                         <button
    //                             className="Add-Edit-btn text-white"
    //                             type="submit"
    //                             onClick={addRateCard}
    //                         >
    //                             ADD
    //                         </button>
    //                     </div></Col>
    //                     </Row>
    //                 </Form>
    //             </Col>

    //         </Row>

    //     </Modal>
  );
}
export default AddRateCard;

import React, { useContext } from "react";
import commonLib from "../../control/commonLib";
import konsole from "../../network/konsole";
import moment from "moment";
import "./Classroom.css"
import Services from "../../network/Services";
import { UserContext } from "../../App";
import { Button } from "antd";

const Preview = ({
  classroomData,
  clsthumbnailurl,
  speakerImg,
  setIncStepperSteps,
  subtenantValueId,
  coordinatorId,
  speakerId,
  loggedId,
  venueaddressId,
  speakerprofile,
  editClassroom,
  radioValueNo,
  location,

}) => {
  const { setLoader } = useContext(UserContext);
  // console.log("Preview",classroomData)

  let FromDate = moment(classroomData?.FromDate).format("MM/DD/YYYY");
  let ToDate = moment(classroomData?.ToDate).format("MM/DD/YYYY");
  let FromTime = moment(classroomData?.FromTime).format("h:mm a");
  let ToTime = moment(classroomData?.ToTime).format("h:mm a");
  let FromTime1 = moment(classroomData?.FromTime).format("H:mm:ss ");
  let ToTime1 = moment(classroomData?.ToTime).format("H:mm:ss ");

  konsole.log("editClassroomeditClassroom",editClassroom)
  const speakerdata = [{
    "seminarSpeakerId": 0,
    "speakerUserId": speakerId,
    "remarks": null,
    "isActive": true
  }]
 
  const CallFormApi = () => {
    setLoader(true);
    let seminarID =
      editClassroom !== undefined &&
      editClassroom !== null &&
      editClassroom !== ""
        ? editClassroom?.seminarId
        : 0;
    let seminarSpeakerId =
      editClassroom !== undefined &&
      editClassroom !== null &&
      editClassroom !== ""
        ? editClassroom?.speakers[0]?.seminarSpeakerId
        : 0;
    let seminarCoordinatorId =
      editClassroom !== undefined &&
      editClassroom !== null &&
      editClassroom !== ""
        ? editClassroom?.coordinators[0]?.seminarCoordinatorId
        : 0;
        
        let coordinatorIdArrayObj = []

        if(editClassroom !== undefined &&
          editClassroom !== null &&
          editClassroom !== ""){

            let mapEditFuncCoordUserId = editClassroom?.coordinators.map((data)=>{
              return data?.coordinatorUserId
            })
          
            konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId",mapEditFuncCoordUserId)
          
            let NewUserId = coordinatorId
                       .filter(x => !mapEditFuncCoordUserId.includes(x))
                       .concat(coordinatorId.filter(x => !coordinatorId.includes(x))); // new userID
          
          let RemovedUserId = mapEditFuncCoordUserId.filter(x => !coordinatorId.includes(x)); // removed userId
          
          let RepeatedUserId = mapEditFuncCoordUserId.filter(x => coordinatorId.includes(x)); // repeated userId
          
          konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId",NewUserId)
          konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId",RemovedUserId)
          konsole.log("mapEditFuncCoordUserIdmapEditFuncCoordUserId",RepeatedUserId)
          
          
          
          // ..........................................................................................
                                  //  Removed Data
          
          
          for(let i = 0; i < RemovedUserId.length; i++){
          let RemovedUserIdData = editClassroom?.coordinators.filter(x => {
          return x.coordinatorUserId == RemovedUserId[i] 
          });
          // RemovedUserIdData[0]['isActive'] = false
          coordinatorIdArrayObj.push({
          'seminarCoordinatorId' : RemovedUserIdData[0]?.seminarCoordinatorId, 
          'coordinatorUserId' : RemovedUserIdData[0]?.coordinatorUserId, 
          'isActive' : false
          })
          konsole.log("RemovedUserIdDataRemovedUserIdData",RemovedUserIdData)
          }
          konsole.log("coordinatorIdArrayObj",coordinatorIdArrayObj)
          
          // Repeated Data
          
          for(let i = 0; i < RepeatedUserId.length; i++){
          let RepeatedUserIdData = editClassroom?.coordinators.filter(x => {
          return x.coordinatorUserId == RepeatedUserId[i] 
          });
          // RepeatedUserIdData[0]['isActive'] = false
          coordinatorIdArrayObj.push({
          'seminarCoordinatorId' : RepeatedUserIdData[0]?.seminarCoordinatorId, 
          'coordinatorUserId' : RepeatedUserIdData[0]?.coordinatorUserId, 
          'isActive' : true
          })
          konsole.log("RepeatedUserIdData",RepeatedUserIdData)
          }
          konsole.log("coordinatorIdArrayObj",coordinatorIdArrayObj)
          
          // NewUser 
          
          for(let i = 0; i < NewUserId.length; i++){
          // let NewUserIdData = editClassroom?.coordinators.filter(x => {
          //   return x.coordinatorUserId == NewUserId[i] 
          // });
          // NewUserIdData[0]['isActive'] = false
          coordinatorIdArrayObj.push({
          'seminarCoordinatorId' : 0, 
          'coordinatorUserId' : NewUserId[i], 
          'isActive' : true
          })
          // konsole.log("NewUserIdData",NewUserIdData)
          }
          konsole.log("coordinatorIdArrayObj",coordinatorIdArrayObj)


          }else{

            
            
            for(let i = 0; i < coordinatorId.length; i++){
              coordinatorIdArrayObj.push({
                'seminarCoordinatorId' : 0, 
                'coordinatorUserId' : coordinatorId[i], 
                'isActive' : true
              })
            }
            
          }
         
          const venueaddressId1 = classroomData?.Venue === "" || classroomData?.Venue === undefined || classroomData?.Venue === null  ? null : venueaddressId
      const promise = Services.postSeminarPublisherData(
      745,
      seminarID,
      classroomData?.topic,
      radioValueNo,
      FromDate,
      FromTime1,
      classroomData?.No_of_Seats,
      classroomData?.Venue,
      venueaddressId1,
      classroomData?.Webinar ? classroomData?.Webinar : "",
      classroomData?.Maps_link,
      "",
      classroomData?.Discription,
      true,
      1,
      ToDate + " " + ToTime1 + " " + classroomData?.radioSelectWeek,
      seminarSpeakerId,
      speakerId,
      "create new Seminar",
      // seminarCoordinatorId,
      coordinatorIdArrayObj,
      speakerdata,
      "true",
      classroomData?.Price,
      loggedId
    );
    promise
      .then((res) => {
        konsole.log("PreviousData", res);
        setIncStepperSteps(2);
        setLoader(false);
      })
      .catch((err) => {
        konsole.log("PreviousDataError", err);
        setIncStepperSteps(1);
        setLoader(false);
      });
  };

  return (
    <div className="container-fluid PublishedSeminar-scroll pb-5">
      <div>
        <p className="ms-2 fs-4 fw-bold seminartxt">Preview</p>
        <div style={{ border: "1px dashed black", padding: "4px" }}>
          <div className="ms-2">
            <div className="d-flex  justify-content-between">
              {" "}
              <h1 className="fw-bold seminarhead">
                {classroomData?.topic}{" "}
                <small className="fs-4 fw-bold text-dark">
                  ({radioValueNo == 46 ? "Direct" : "Webinar"})
                </small>{" "}
              </h1>
              {/* <button className='clspreviewbtn mt-2 me-2 ' >Book Now</button> */}
            </div>

            <div className=" display-flex ">
              <p className="fs-5 fw-bold text-secondary ">
                By
                <span className="fs-5 fw-bold seminartxt ms-2">
                  {classroomData?.Name_of_Speaker}
                </span>
                <br />
                Date : {commonLib.getFormattedDate(
                  classroomData?.FromDate
                )} - {commonLib.getFormattedDate(classroomData?.ToDate)}
                <br />
                Time : {FromTime} - {ToTime}
                <br />
                Fare : ${classroomData?.Price}
                <br />
                {radioValueNo == 46 ? (
                  <>
                    Venue : {classroomData?.Venue} <br />
                    Location: {classroomData?.Venue == "" ? ""  : location}
                  </>
                ) : (
                  ``
                )}
                <br />
              </p>
              {/* <img className="clsroom_img " src={clsthumbnailurl} /> */}
            </div>
          </div>

          <div className="d-flex">
            <div>
              <img src={speakerImg} className="seminarimg ms-2 float-start" />
            </div>
            <p className="ms-3">
              <span className=" fw-bold class  seminartxt">
                {classroomData?.Name_of_Speaker}
              </span>
              <span className="ms-2">{speakerprofile}</span>
            </p>
          </div>
          <p className="ms-2">
            <span className=" fs-4  fw-bold seminartxt">About Workshop</span>
            <br />
            {classroomData?.Discription}{" "}
          </p>

          <div className="d-flex justify-content-center">
            <p className="seminarbtn mt-5 ms-3 text-white pt-2 ps-3 mb-4">
              Register Now
            </p>
          </div>
        </div>

        <div className="float-start pb-3">
          <button
            className="seminarbtn mt-5 ms-3 text-white mb-4"
            onClick={() => setIncStepperSteps(0)}
          >
            Back
          </button>
        </div>
        <div className="float-end pb-3">
          <button
            className="seminarbtn mt-5 me-3 text-white mb-4"
            onClick={CallFormApi}
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;

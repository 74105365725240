import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Cascader, Select, Button, Row, Col } from 'antd';
import SeminarFare from './SeminarFare';
import Coordinator from './Coordinator';
import { GlobalData } from './SeminarFile';
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import './Seminar.css';

const OrganizerInformation = ({ form }) => {

  const { seminarOrg, sendSubtenantId, presubtenantId, editFuncData } = useContext(GlobalData)

  const { Option } = Select;

  const [valueSelectName, setValuaSelectName] = useState("")
  const [subtenantValueId, setSubtenantValueId] = useState("")
  const [roleid, setroleid] = useState('')
  const [defaultvalue, setDefaultvalue] = useState('')

  const onGenderChange = (value, key) => {
    setValuaSelectName(key.children)
    setSubtenantValueId(key.value)
    seminarOrg(key.value)
    sendSubtenantId(key.value)
  };

  const [postSubtenantDetailResp, setpostSubtenantDetailResp] = useState([])


  useEffect(() => {
    postSubtenantDetails();

  }, [])

  useEffect(() => {
    if (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") {
      postSubtenantDetails(editFuncData?.subtenantId)
    }
  }, [editFuncData])

  const postSubtenantDetails = (editfuncDataValue) => {
    let postSubtenantDetailApiCall = Services.postSubtenantDetails()
    postSubtenantDetailApiCall.then((res) => {
      konsole.log("responsedetails", res.data)
      setpostSubtenantDetailResp(res.data.data)
      if (editFuncData !== undefined && editFuncData !== null && editFuncData !== "") {
        let filterSubtenant = res.data.data.filter((x) => {
          return x.subtenantId == editfuncDataValue
        })
        fixedEditValue(filterSubtenant)
        konsole.log("filterSubtenant", filterSubtenant)
      }
    })
      .catch((error) => {
        konsole.log("error", error)
      })

  }

  useEffect(() => {
    let userroleId = JSON.parse(sessionStorage.getItem("stateObj"))
    let subtenantId = sessionStorage.getItem("subtenantId")
    setroleid(userroleId?.roleId)
    if (postSubtenantDetailResp !== '' && postSubtenantDetailResp !== undefined && (roleid == "11" || roleid == "6")) {

      let datafilter = postSubtenantDetailResp.filter((val) => {
        return val.subtenantId == subtenantId
      })
      form.setFieldsValue({
        "seminarOrganizingPartner": datafilter[0]?.subtenantId
      })
      setDefaultvalue(datafilter[0]?.subtenantName)
      // setselectedSubtenant(datafilter[0]?.subtenantName)

      setValuaSelectName(datafilter[0]?.subtenantName)
      setSubtenantValueId(subtenantId)
      seminarOrg(datafilter[0]?.subtenantName)
      sendSubtenantId(subtenantId)
    }

  }, [postSubtenantDetailResp])



  konsole.log("OrganizationEditDataFun", editFuncData, "OrganizationEditDataFunSeminar", editFuncData?.subtenantId)

  const fixedEditValue = (editSubtenatId) => {
    konsole.log("editSubtenatIdeditSubtenatId", editSubtenatId)
    setValuaSelectName(editSubtenatId.subtenantName)
    // setSubtenantValueId(editSubtenatId.subtenantId)
    // seminarOrg(editSubtenatId.subtenantName)
    // sendSubtenantId(editSubtenatId.subtenantId)
  }

  return (
    



      <div className="seminarcard bg-white mt-2" style={{ marginRight: "5px" }}>
        <Row>
          <Col
            wrapperCol={{
              // offset: 8,
              span: 16,
            }}
            className="Border_dashed"
          // style={{ width: "600px", borderRight: "1px dashed" }}
          >
            <div className="seminarcard bg-white border-0">
              <p className='fs-4 fw-bold seminartxt'>Organizer Information</p>
              <Form.Item
                name="seminarOrganizingPartner"
                label="Seminar Organizing Firm"
          
              >
                <Select
                  onChange={onGenderChange}
                  allowClear
                  style={{  }}
                  defaultValue={editFuncData?.subtenantId}
                  value={editFuncData?.subtenantId}
                  disabled={(editFuncData !== undefined && editFuncData !== null && editFuncData !== "") || (roleid == "11" || roleid == "6") ? true : false}
                >
                  {
                    postSubtenantDetailResp.map((items, index) => {

                      return <Option value={items.subtenantId} key={items.value}>
                        {items.subtenantName}
                        </Option>
                    })
                  }

                </Select>
              </Form.Item>
            </div>
            <Coordinator form={form} subtenant={valueSelectName} subtenantValueId={subtenantValueId} />
          </Col>
          <Col
            wrapperCol={{
              span: 8,
            }}
          >
            <div>
              <SeminarFare form={form} />
            </div>
          </Col>
        </Row>
      </div>


  
  )
}

export default OrganizerInformation